import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    equipmentCreatedId: false,
    personAssign: false,
    areaShared: false,
    equipmentsSearched: false,
    quantity: false,
    equipmentsFiltered: false,
    loadingEquipment: false,
    states: false,
    locations: false,
    equipsWithCaseReports: false,
    loadingEquipsWithCaseReport: false,
    quantityEquipsWithCaseReports: false,
    equipAndReportPDF: false,
    equipsForTimeline: false,
    loadingEquipsForTimeline: false,
    allEquipsForTimeline: false,
    equipsWithTimelines: false,
    loadingEquipsWithTimeline: false,
    quantityEquipsWithTimelines: false,
    fullDataEquipment: false,
    loadingFullDataEquipment: false,
    loadingUpdateEquipment: false,
    loadingCreateEquipment: false,
    isFiltered: false,
    partsComputersEquipment: false,
    loadingPartsComputersEquipment: false,
    masiveInventory: false,
    loadingMasiveInventory: false,
    annexesPdf: false,
    loadingAnnexesPdf: false,
    textXLSX: false,
    equipmentsToXLSX: false,
    loadingDeleteEquipmentAnnexe: false,
};

const EquipmentSlice = createSlice({
    name: "Equipment",
    initialState,
    reducers: {
        setEquipmentCreatedId(state, { payload }) {
            state.equipmentCreatedId = payload
        },
        createEquipment(state, { payload }) {
            state.loadingCreateEquipment = true
        },
        updateEquipment(state, { payload }) {
            state.loadingUpdateEquipment = true
        },
        deleteEquipment() { },
        createBiomedicComponent() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setEquipmentsSearched(state, { payload }) {
            state.equipmentsSearched = payload
        },
        searchEquipment(state) {
            state.loadingEquipment = true
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        setEquipmentsFiltered(state, { payload }) {
            state.equipmentsFiltered = payload
        },
        getEquipments(state, { payload }) {
            state.loadingEquipment = true
            state.equipmentsSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingEquipment = payload
        },
        getStates() { },
        getLocations() { },
        setStates(state, { payload }) {
            state.states = payload
        },
        setLocations(state, { payload }) {
            state.locations = payload
        },
        getEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = false
            state.loadingEquipsWithCaseReport = true;
        },
        setEquipsWithCaseReports(state, { payload }) {
            state.equipsWithCaseReports = payload
        },
        setQuantityEquipsWithCaseReports(state, { payload }) {
            state.quantityEquipsWithCaseReports = payload
        },
        setLoadingEquipsWithCaseReport(state, { payload }) {
            state.loadingEquipsWithCaseReport = payload
        },
        searchCaseReport(state) {
            state.loadingEquipsWithCaseReport = true
        },
        getEquipAndReportPDF() { },
        setEquipAndReportPDF(state, { payload }) {
            state.equipAndReportPDF = payload
        },
        getEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = true
        },
        setEquipsForTimeline(state, { payload }) {
            state.equipsForTimeline = payload
        },
        setAllEquipsForTimeline(state, { payload }) {
            state.allEquipsForTimeline = payload
        },
        setLoadingEquipsForTimeline(state, { payload }) {
            state.loadingEquipsForTimeline = payload
        },
        getEquipsWithTimelines(state, { payload }) {
            state.loadingEquipsWithTimeline = true
        },
        setEquipsWithTimelines(state, { payload }) {
            state.equipsWithTimelines = payload
            state.loadingEquipsWithTimeline = false
        },
        setQuantityEquipsWithTimelines(state, { payload }) {
            state.quantityEquipsWithTimelines = payload
        },
        getFullDataEquipment(state, { payload }) {
            state.loadingFullDataEquipment = true
        },
        getFullDataNetworkingAndCommunicationsEquipment(state, { payload }) {
            state.loadingFullDataEquipment = true
        },
        getFullDataPeripheralsAndAccessoriesEquipment(state, { payload }) {
            state.loadingFullDataEquipment = true
        },
        getFullDataComputersEquipment(state, { payload }) {
            state.loadingFullDataEquipment = true
        },
        setFullDataEquipment(state, { payload }) {
            state.fullDataEquipment = payload
        },
        setLoadingFullDataEquipment(state, { payload }) {
            state.loadingFullDataEquipment = payload
        },
        setLoadingUpdateEquipment(state, { payload }) {
            state.loadingUpdateEquipment = payload
        },
        setLoadingCreateEquipment(state, { payload }) {
            state.loadingCreateEquipment = payload
        },
        getEquipmentsByModuleWork() { },
        getEquipmentsByModuleWorkAndState(state, { payload }) {
            state.loadingEquipment = true
        },
        search(state, { payload }) {
            state.loadingEquipment = true
        },
        setIsFiltered(state, { payload }) {
            state.isFiltered = payload
        },
        getEquipsWithCaseReportsByModuleWork() { },
        getEquipsWithTimelinesByModuleWork(state, { payload }) {
            state.loadingEquipsForTimeline = true
        },
        getPartsComputersEquipment(state, { payload }) {
            state.loadingPartsComputersEquipment = true
        },
        setPartsComputersEquipment(state, { payload }) {
            state.partsComputersEquipment = payload
        },
        setLoadingPartsComputersEquipment(state, { payload }) {
            state.loadingPartsComputersEquipment = payload
        },
        getMasiveInventory(state, { payload }) {
            state.loadingMasiveInventory = true
        },
        setMasiveInventory(state, { payload }) {
            state.masiveInventory = payload
        },
        setLoadingMasiveInventory(state, { payload }) {
            state.loadingMasiveInventory = payload
        },
        getEquipsWithCalibrationCertificate() { },
        getFullDataIndustrialEquipment(state, { payload }) {
            state.loadingFullDataEquipment = true
        },
        getFullDataVehicleEquipment(state, { payload }) {
            state.loadingFullDataEquipment = true
        },
        getEquipsWithDailyVehicle(state, { payload }) {
            state.loadingEquipsWithCaseReport = true
        },
        getAnnexesPdf(state, { payload }) {
            state.loadingAnnexesPdf = true
        },
        setAnnexesPdf(state, { payload }) {
            state.annexesPdf = payload
        },
        setLoadingAnnexesPdf(state, { payload }) {
            state.loadingAnnexesPdf = payload
        },
        setTextXLSX(state, { payload }) {
            state.textXLSX = payload
        },
        getEquipmentsToXLSX(state, { payload }) {
            state.loadingEquipment = true
        },
        setEquipmentsToXLSX(state, { payload }) {
            state.equipmentsToXLSX = payload
        },
        deleteEquipmentAnnexe(state) {
            state.loadingDeleteEquipmentAnnexe = true
        },
        setDeleteEquipmentAnnexe(state, { payload }) {
            state.loadingDeleteEquipmentAnnexe = payload
        },
        
    },
});

const EquipmentActions = EquipmentSlice.actions;
const EquipmentReducer = EquipmentSlice.reducer;

export { EquipmentActions, EquipmentReducer };
