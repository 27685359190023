import { Steps, Button, Radio, message, AutoComplete, Checkbox } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../Input/Input'
import { PlusOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { useForm, Controller } from "react-hook-form"
import { apiUrl } from '../../common/config/Environments'
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EquipmentIndustrialActions } from '../../services/EquipmentIndustrial/EquipmentIndustrialSlice';


export const FormEquipmentIndustrial = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { fullDataEquipment, loadingUpdateEquipment, loadingCreateEquipment } = useSelector(state => state.Equipment)
    const { loadingCreateEquipmentIndustrial, EquipmentIndustrialCreatedId } = useSelector(state => state.EquipmentIndustrial)

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        if (EquipmentIndustrialCreatedId) {
            setOption('Search')
            dispatch(EquipmentIndustrialActions.setEquipmentIndustrialCreatedId(false))
        }
    }, [EquipmentIndustrialCreatedId])

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const machineTypes = {
        "Plantas Eléctricas": ["Planta eléctrica", "Equipo de transferencia eléctrica"],
        "Equipos de lavanderia y cocina": ["Lavadora", "Plancha", "Secadora", "Olla", "Carro termo", "Batidora", "Licuadora"],
        "Bombas de agua, calderas": ["Caldera", "Bomba de agua", "Bomba de vacio", "Motobomba", "Calentador"],
        "Autoclaves y esterilización": ["Esterilizador", "Autoclave"],
        "Refrigeración y aire acondicionado": ["Nevera para conservación biológica", "Aire acondicionado", "Congelador"],
        "Relacionados con los servicios de apoyo": ["Herramienta de aseo", "Aspiradora", "Extintor", "Herramienta de taller", "Alarma sonora y visual"],
        "Otros": ["Ascensor", "Compresor", "Torno", "Taladro"],
    };

    function findCategory(equipType) {
        for (let category in machineTypes) {
            if (machineTypes[category].includes(equipType)) {
                return category;
            }
        }
        return null;
    }

    const {
        register: registerIndustrial,
        handleSubmit: handleSubmitIndustrial,
        setValue: setValueIndustrial,
        watch: watchIndustrial,
        control: controlIndustrial,
        formState: { errors: errorsIndustrial },
        trigger: triggerIndustrial,
    } = useForm({
        defaultValues: {
            category: edit ? findCategory(fullDataEquipment.industrialEquipment.equipType) : '',
            dependency: edit ? fullDataEquipment.dependency.id : '',
            image: edit ? fullDataEquipment.industrialEquipment.image : null,
            equipType: edit ? fullDataEquipment.industrialEquipment.equipType : '',
            brand: edit ? fullDataEquipment.industrialEquipment.brand : '',
            modell: edit ? fullDataEquipment.industrialEquipment.modell : '',
            type: edit ? fullDataEquipment.industrialEquipment.type : '',
            btu: edit ? fullDataEquipment.industrialEquipment.btu : '',
            serie: edit ? fullDataEquipment.industrialEquipment.serie : '',
            licensePlate: edit ? fullDataEquipment.licensePlate : '',
            adquisitionDate: edit ? moment(fullDataEquipment.industrialEquipment.adquisitionDate) : null,
            instalationDate: edit ? moment(fullDataEquipment.industrialEquipment.instalationDate) : null,
            startWarrantyDate: edit ? moment(fullDataEquipment.industrialEquipment.startWarrantyDate) : null,
            endWarrantyDate: edit ? moment(fullDataEquipment.industrialEquipment.endWarrantyDate) : null,
            operatingVoltageMax: edit ? fullDataEquipment.industrialEquipment.operatingVoltageMax : 0,
            operatingVoltageMin: edit ? fullDataEquipment.industrialEquipment.operatingVoltageMin : 0,
            powerConsumed: edit ? fullDataEquipment.industrialEquipment.powerConsumed : 0,
            weight: edit ? fullDataEquipment.industrialEquipment.weight : 0,
            speed: edit ? fullDataEquipment.industrialEquipment.speed : 0,
            operatingCurrentMax: edit ? fullDataEquipment.industrialEquipment.operatingCurrentMax : 0,
            operatingCurrentMin: edit ? fullDataEquipment.industrialEquipment.operatingCurrentMin : 0,
            hertzFrequency: edit ? fullDataEquipment.industrialEquipment.hertzFrequency : '',
            pressure: edit ? fullDataEquipment.industrialEquipment.pressure : '',
            temperature: edit ? fullDataEquipment.industrialEquipment.temperature : '',
            typePowerAdapter: edit ? fullDataEquipment.industrialEquipment.typePowerAdapter : '',
            hasCalibration: edit ? fullDataEquipment.industrialEquipment.hasCalibration : '',
            use: edit ? fullDataEquipment.industrialEquipment.use : '',
            manuals: edit ? fullDataEquipment.industrialEquipment.manuals : [],
            blueprints: edit ? fullDataEquipment.industrialEquipment.blueprints : [],
            clasification: edit ? fullDataEquipment.industrialEquipment.clasification : '',
            location: edit ? fullDataEquipment.location.name : '',
        }
    })

    const [areaSelected, setAreaSelected] = useState(fullDataEquipment && fullDataEquipment.dependency.area.id)
    const [Dep, setDep] = useState(false);
    const [compInfo, setCompInfo] = useState([])
    const canvas = useRef(null)

    const [image, setImage] = useState(photo)

    const { departments, municipalitys } = useSelector(state => state.DepMun)

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watchIndustrial('dependency')) {
            dispatch(LoginActions.getBoss(watchIndustrial('dependency')))
        }
    }, [watchIndustrial('dependency')])

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueIndustrial("image", dataImg) }, 1000)
    };

    useEffect(() => {
        if (edit && watchIndustrial('image')) {
            var ctx = canvas.current.getContext('2d');
            var img = new Image();

            canvas.current.width = 350;
            canvas.current.height = 280;

            img.onload = function () {
                ctx.drawImage(img, 0, 0, 350, 280);
            };

            // Convertir el string base64 en un objeto URL
            var imageBase64 = watchIndustrial('image');

            // fetch es una operación asíncrona, por lo que necesitamos usar async/await
            (async () => {
                var response = await fetch(`${imageBase64}`);
                var imageBlob = await response.blob();

                img.src = URL.createObjectURL(imageBlob);
            })();
        }
    }, [watchIndustrial('image'), edit]);

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    return (
        <div className='FormEquipmentIndustrial'>
            <p className='titleSection'>Hoja de Vida</p>
            <div className='initialSelects'>
                <Select
                    className="select"
                    placeholder='Area'
                    disabled={edit}
                    onChange={e => {
                        setAreaSelected(e)
                        setValueIndustrial('dependency', undefined)
                    }}
                >
                    {dependencys && areas.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                {areaSelected &&
                    <Controller
                        name="dependency"
                        control={controlIndustrial}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder='Dependencia'
                                value={watchIndustrial('dependency')}
                                className="select"
                            >
                                {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                }
                {
                    boss &&
                    <Select
                        value="valor"
                        disabled
                        className='select'
                    >
                        <Option value="valor">
                            {boss && boss.name
                                ?
                                boss.name + " " +
                                boss.secondName + " " +
                                boss.lastName + " " +
                                boss.secondLastName
                                :
                                "No hay coordinador"
                            }
                        </Option>
                    </Select>
                }
                <Controller
                    name="headquarter"
                    control={controlIndustrial}
                    disabled={edit}
                    render={({ field }) => (
                        <Select
                            {...field}
                            placeholder='Sede'
                            value={watchIndustrial('headquarter')}
                            className="select"
                        >
                            <Option value={null}>Sede principal</Option>
                            {headquarters && headquarters.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    )}
                />
                <Controller
                    name="location"
                    control={controlIndustrial}
                    rules={{
                        required: "La ubicación es requerida",
                    }}
                    render={({ field }) => (
                        <AutoComplete
                            {...field}
                            value={watchIndustrial('location')}
                            placeholder='Ubicación'
                            className="select"
                            style={{ width: '12vw' }}
                            options={locations && locations.map((location) => ({
                                value: location.name,
                                label: location.name,
                            }))}
                            filterOption={(inputValue, option) =>
                                option ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                            }
                        />
                    )}
                />
                {errorsIndustrial.location && <p className='errorMessage'>{errorsIndustrial.location.message}</p>}
            </div>
            {((watchIndustrial('dependency') && watchIndustrial('location')) || edit) &&
                <div>
                    <p className='titleSection'>Seleccione equipo</p>
                    <div>
                        <Select
                            {...registerIndustrial('category', {
                            })}
                            className="selectEquips"
                            placeholder='Categoría'
                            value={watchIndustrial('category')}
                            onChange={e => {
                                setValueIndustrial("category", e)
                            }}
                        >
                            <Option value={"Plantas Eléctricas"}>Plantas Eléctricas</Option>
                            <Option value={"Equipos de lavanderia y cocina"}>Equipos de lavanderia y cocina</Option>
                            <Option value={"Bombas de agua, calderas"}>Bombas de agua, calderas</Option>
                            <Option value={"Autoclaves y esterilización"}>Autoclaves y esterilización</Option>
                            <Option value={"Refrigeración y aire acondicionado"}>Refrigeración y aire acondicionado</Option>
                            <Option value={"Relacionados con los servicios de apoyo"}>Relacionados con los servicios de apoyo</Option>
                            <Option value={"Otros"}>Otros</Option>
                        </Select>
                        <Select
                            {...registerIndustrial('equipType', {
                            })}
                            className="selectEquips"
                            placeholder='Tipo de Maquina'
                            value={watchIndustrial('equipType')}
                            onChange={e => {
                                setValueIndustrial("equipType", e)
                            }}
                        >
                            {machineTypes[watchIndustrial('category')]?.map(type => (
                                <Option key={type} value={type}>{type}</Option>
                            ))}
                        </Select>
                    </div>
                    {(watchIndustrial("equipType") === 'Nevera para conservación biológica' ||
                        watchIndustrial("equipType") === 'Planta eléctrica' ||
                        watchIndustrial("equipType") === 'Congelador' ||
                        watchIndustrial("equipType") === 'Aire acondicionado') ?
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', width: '68%', flexDirection: 'column' }}>
                                <p className='titleSection'>Datos del equipo</p>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw', boxShadow: '0px 4px 4px 0px #00000040', paddingLeft: '1vw', paddingRight: '1vw' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                            {...registerIndustrial('brand', {
                                            })}
                                            label={"Marca"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('brand')}
                                            setData={e => setValueIndustrial('brand', e)}
                                            error={errorsIndustrial.brand && errorsIndustrial.brand.message}
                                        />
                                        <Input
                                            {...registerIndustrial('modell', {
                                            })}
                                            label={"Modelo"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('modell')}
                                            setData={e => setValueIndustrial('modell', e)}
                                            error={errorsIndustrial.modell && errorsIndustrial.modell.message}
                                        />
                                        <Input
                                            {...registerIndustrial('type', {
                                            })}
                                            label={"Tipo"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('type')}
                                            setData={e => setValueIndustrial('type', e)}
                                            error={errorsIndustrial.type && errorsIndustrial.type.message}
                                        />
                                        <Input
                                            {...registerIndustrial('btu', {
                                            })}
                                            label={"British Thermal Unit"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('btu')}
                                            setData={e => setValueIndustrial('btu', e)}
                                            error={errorsIndustrial.btu && errorsIndustrial.btu.message}
                                        />
                                        <Input
                                            {...registerIndustrial('serie', {
                                            })}
                                            label={"Serie"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('serie')}
                                            setData={e => setValueIndustrial('serie', e)}
                                            error={errorsIndustrial.serie && errorsIndustrial.serie.message}
                                        />
                                    </div>
                                    {watchIndustrial("equipType") === "Planta eléctrica" &&
                                        <div style={{ display: 'flex', marginTop: '1vw' }}>
                                            <Input
                                                {...registerIndustrial('brandMotor', {
                                                })}
                                                label={"Marca motor"}
                                                mr={"0.7vw"}
                                                width={'9.5vw'}
                                                height={'2.4vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                labelMB={'-0.1vw'}
                                                va={watchIndustrial('brandMotor')}
                                                setData={e => setValueIndustrial('brandMotor', e)}
                                                error={errorsIndustrial.brandMotor && errorsIndustrial.brandMotor.message}
                                            />
                                            <Input
                                                {...registerIndustrial('modellMotor', {
                                                })}
                                                label={"Modelo Motor"}
                                                mr={"0.7vw"}
                                                width={'9.5vw'}
                                                height={'2.4vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                labelMB={'-0.1vw'}
                                                va={watchIndustrial('modellMotor')}
                                                setData={e => setValueIndustrial('modellMotor', e)}
                                                error={errorsIndustrial.modellMotor && errorsIndustrial.modellMotor.message}
                                            />
                                            <Input
                                                {...registerIndustrial('serieMotor', {
                                                })}
                                                label={"Serie Motor"}
                                                mr={"0.7vw"}
                                                width={'9.5vw'}
                                                height={'2.4vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                labelMB={'-0.1vw'}
                                                va={watchIndustrial('serieMotor')}
                                                setData={e => setValueIndustrial('serieMotor', e)}
                                                error={errorsIndustrial.serieMotor && errorsIndustrial.serieMotor.message}
                                            />
                                            <Input
                                                {...registerIndustrial('hourometer', {
                                                })}
                                                label={"Horometro"}
                                                mr={"0.7vw"}
                                                width={'9.5vw'}
                                                height={'2.4vw'}
                                                labelstyle='inputLabelBordered'
                                                fontSize={'1vw'}
                                                labelMB={'-0.1vw'}
                                                va={watchIndustrial('hourometer')}
                                                setData={e => setValueIndustrial('hourometer', e)}
                                                error={errorsIndustrial.hourometer && errorsIndustrial.hourometer.message}
                                            />
                                        </div>}
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                            {...registerIndustrial('licensePlate', {
                                            })}
                                            label={"Placa"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('licensePlate')}
                                            setData={e => setValueIndustrial('licensePlate', e)}
                                            error={errorsIndustrial.licensePlate && errorsIndustrial.licensePlate.message}
                                        />
                                        <div className='divDate'>
                                            <p className='label'>Fecha Adquisición</p>
                                            <DatePicker
                                                value={watchIndustrial("adquisitionDate")}
                                                {...registerIndustrial('adquisitionDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueIndustrial("adquisitionDate", d)}
                                            />
                                        </div>
                                        <div className='divDate'>
                                            <p className='label'>Fecha instalación</p>
                                            <DatePicker
                                                value={watchIndustrial("instalationDate")}
                                                {...registerIndustrial('instalationDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueIndustrial("instalationDate", d)}
                                            />
                                        </div>
                                        <div className='divDate'>
                                            <p className='label'>Inicio Garantia</p>
                                            <DatePicker
                                                value={watchIndustrial("startWarrantyDate")}
                                                {...registerIndustrial('startWarrantyDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueIndustrial("startWarrantyDate", d)}
                                            />
                                        </div>
                                        <div className='divDate'>
                                            <p className='label'>Fin Garantia</p>
                                            <DatePicker
                                                value={watchIndustrial("endWarrantyDate")}
                                                {...registerIndustrial('endWarrantyDate', {
                                                })}
                                                style={{ width: '9.5vw', height: '2.4vw', marginRight: '0.7vw' }}
                                                onChange={d => setValueIndustrial("endWarrantyDate", d)}
                                            />
                                        </div>
                                    </div>
                                    <p className='titleSection'>Registro técnico</p>
                                    <div style={{ display: 'flex', marginTop: '2vw' }}>
                                        <Input
                                            {...registerIndustrial('operatingVoltageMax', {
                                            })}
                                            label={"Voltaje de operación"}
                                            placeholder={"Maximo/Volt"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            type='number'
                                            va={watchIndustrial('operatingVoltageMax')}
                                            setData={e => setValueIndustrial('operatingVoltageMax', e)}
                                            error={errorsIndustrial.operatingVoltageMax && errorsIndustrial.operatingVoltageMax.message}
                                        />
                                        <Input
                                            {...registerIndustrial('operatingVoltageMin', {
                                            })}
                                            placeholder={"Minimo/Volt"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            type='number'
                                            va={watchIndustrial('operatingVoltageMin')}
                                            setData={e => setValueIndustrial('operatingVoltageMin', e)}
                                            error={errorsIndustrial.operatingVoltageMin && errorsIndustrial.operatingVoltageMin.message}
                                        />
                                        <Input
                                            {...registerIndustrial('powerConsumed', {
                                            })}
                                            label={"Potencia Consumida"}
                                            placeholder={"Watios"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            type='number'
                                            va={watchIndustrial('powerConsumed')}
                                            setData={e => setValueIndustrial('powerConsumed', e)}
                                            error={errorsIndustrial.powerConsumed && errorsIndustrial.powerConsumed.message}
                                        />
                                        <Input
                                            {...registerIndustrial('weight', {
                                            })}
                                            label={"Peso"}
                                            mr={"0.7vw"}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            type='number'
                                            va={watchIndustrial('weight')}
                                            setData={e => setValueIndustrial('weight', e)}
                                            error={errorsIndustrial.weight && errorsIndustrial.weight.message}
                                        />
                                        <div >
                                            <p className='label'>Tipo de refrigerante</p>
                                            <Select
                                                {...registerIndustrial('coolantType', {
                                                })}
                                                className="selectEquips"
                                                style={{ width: '9.5vw', height: '2.4vw' }}
                                                placeholder='Tipo de refrigerante'
                                                value={watchIndustrial('coolantType')}
                                                onChange={e => {
                                                    setValueIndustrial("coolantType", e)
                                                }}
                                            >
                                                <Option value={"R-22"}>R-22</Option>
                                                <Option value={"R-134a"}>R-134a</Option>
                                                <Option value={"R-600a"}>R4R-600a04a</Option>
                                                <Option value={"R-32"}>R-32</Option>
                                                <Option value={"R-290"}>R-290</Option>
                                                <Option value={"R-407c"}>R-407c</Option>
                                                <Option value={"R-404a"}>R-404a</Option>
                                                <Option value={"R-410a"}>R-410a</Option>
                                                <Option value={"R-417"}>R-417</Option>
                                                <Option value={"Otros"}>Otros</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                            {...registerIndustrial('operatingCurrentMax', {
                                            })}
                                            label={"Corriente operación"}
                                            placeholder={"Maximo/Amp"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            type='number'
                                            va={watchIndustrial('operatingCurrentMax')}
                                            setData={e => setValueIndustrial('operatingCurrentMax', e)}
                                            error={errorsIndustrial.operatingCurrentMax && errorsIndustrial.operatingCurrentMax.message}
                                        />
                                        <Input
                                            {...registerIndustrial('operatingCurrentMin', {
                                            })}
                                            placeholder={"Minimo/Amp"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            type='number'
                                            va={watchIndustrial('operatingCurrentMin')}
                                            setData={e => setValueIndustrial('operatingCurrentMin', e)}
                                            error={errorsIndustrial.operatingCurrentMin && errorsIndustrial.operatingCurrentMin.message}
                                        />
                                        <Input
                                            {...registerIndustrial('hertzFrequency', {
                                            })}
                                            label={"Frecuencia Hertz"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('hertzFrequency')}
                                            setData={e => setValueIndustrial('hertzFrequency', e)}
                                            error={errorsIndustrial.hertzFrequency && errorsIndustrial.hertzFrequency.message}
                                        />
                                        <Input
                                            {...registerIndustrial('pressure', {
                                            })}
                                            label={"Presión"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('pressure')}
                                            setData={e => setValueIndustrial('pressure', e)}
                                            error={errorsIndustrial.pressure && errorsIndustrial.pressure.message}
                                        />
                                        <Input
                                            {...registerIndustrial('temperature', {
                                            })}
                                            label={"Temperatura"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('temperature')}
                                            setData={e => setValueIndustrial('temperature', e)}
                                            error={errorsIndustrial.temperature && errorsIndustrial.temperature.message}
                                        />
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                            {...registerIndustrial('typePowerAdapter', {
                                            })}
                                            label={"Tipo Adaptador Energia"}
                                            mr={"0.7vw"}
                                            mt={'0.2vw'}
                                            width={'9.5vw'}
                                            height={'2.4vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            labelMB={'-0.1vw'}
                                            va={watchIndustrial('typePowerAdapter')}
                                            setData={e => setValueIndustrial('typePowerAdapter', e)}
                                            error={errorsIndustrial.typePowerAdapter && errorsIndustrial.typePowerAdapter.message}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <div>
                                            <p className='label'>Uso</p>
                                            <Radio.Group
                                                className='radioG'
                                                style={{ width: '25vw' }}
                                                {...registerIndustrial('use', {
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("El uso es requerido");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                })}
                                                onChange={d => setValueIndustrial('use', d.target.value)}
                                                value={watchIndustrial('use')}>
                                                <Radio className='radio' value={'Medico'}>Medico</Radio>
                                                <Radio className='radio' value={'Basico'}>Basico</Radio>
                                                <Radio className='radio' value={'Apoyo'}>Apoyo</Radio>
                                            </Radio.Group>
                                            {errorsIndustrial.use && <p className='errorMessage'>{errorsIndustrial.use.message}</p>}
                                        </div>
                                    </div>
                                    <p className='titleSection'>Manuales</p>
                                    <div>
                                        <Controller
                                            control={controlIndustrial}
                                            name="manuals"
                                            rules={{
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value.length === 0) {
                                                        errors.push("Manuales es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            }}
                                            render={({ field }) => (
                                                <Checkbox.Group
                                                    {...field}
                                                    className='radioGManuals'
                                                    style={{ width: '32vw' }}
                                                    onChange={d => setValueIndustrial('manuals', d)}
                                                    value={watchIndustrial('manuals')}
                                                >
                                                    <Checkbox className='radio' value={'Operación'}>Operación</Checkbox>
                                                    <Checkbox className='radio' value={'Mantenimiento'}>Mantenimiento</Checkbox>
                                                    <Checkbox className='radio' value={'Partes'}>Partes</Checkbox>
                                                    <Checkbox className='radio' value={'Despiece'}>Despiece</Checkbox>
                                                </Checkbox.Group>
                                            )}
                                        />
                                        {errorsIndustrial.manuals && <p className='errorMessage'>{errorsIndustrial.manuals.message}</p>}
                                    </div>
                                    <p className='titleSection'>Planos</p>
                                    <div>
                                        <Controller
                                            control={controlIndustrial}
                                            name="blueprints"
                                            rules={{
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value.length === 0) {
                                                        errors.push("Planos es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            }}
                                            render={({ field }) => (
                                                <Checkbox.Group
                                                    {...field}
                                                    className='radioGBlueprints'
                                                    style={{ width: '40vw' }}
                                                    onChange={d => setValueIndustrial('blueprints', d)}
                                                    value={watchIndustrial('blueprints')}>
                                                    <Checkbox className='radio' value={'Eléctricos'}>Eléctricos</Checkbox>
                                                    <Checkbox className='radio' value={'Electrónicos'}>Electrónicos</Checkbox>
                                                    <Checkbox className='radio' value={'Hidráulicos'}>Hidráulicos</Checkbox>
                                                    <Checkbox className='radio' value={'Neumáticos'}>Neumáticos</Checkbox>
                                                    <Checkbox className='radio' value={'Mecánicos'}>Mecánicos</Checkbox>
                                                </Checkbox.Group>
                                            )}
                                        />
                                        {errorsIndustrial.blueprints && <p className='errorMessage'>{errorsIndustrial.blueprints.message}</p>}
                                    </div>
                                    <p className='titleSection'>Clasificación</p>
                                    <div>
                                        <Radio.Group
                                            className='radioG'
                                            style={{ width: '43vw' }}
                                            {...registerIndustrial('clasification', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La clasificación es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            onChange={d => setValueIndustrial('clasification', d.target.value)}
                                            value={watchIndustrial('clasification')}>
                                            <Radio className='radio' value={'Diagnostico'}>Diagnostico</Radio>
                                            <Radio className='radio' value={'Prevención'}>Prevención</Radio>
                                            <Radio className='radio' value={'Rehabilitación'}>Rehabilitación</Radio>
                                            <Radio className='radio' value={'Análisis Laboratorio'}>Análisis Laboratorio</Radio>
                                            <Radio className='radio' value={'Tto y manto de la vida'}>Tto y manto de la vida</Radio>
                                        </Radio.Group>
                                        {errorsIndustrial.clasification && <p className='errorMessage'>{errorsIndustrial.clasification.message}</p>}
                                    </div>
                                    <button className='save' disabled={isSubmitting} onClick={() => handleSubmitIndustrial((dataSubmit) => {
                                        dataSubmit.userCreator = idUser
                                        dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                                        !edit && dispatch(EquipmentIndustrialActions.createEquipmentIndustrial(dataSubmit));
                                        edit && dispatch(EquipmentIndustrialActions.updateEquipmentIndustrial({ "payload": dataSubmit, "id": fullDataEquipment.id }));
                                    })()}>
                                        <img src={SaveIcon} alt='inventoryIcon' className='icon'></img>
                                        {!edit && (loadingCreateEquipmentIndustrial ? <LoadingOutlined className='icon' /> : "Guardar")}
                                        {edit && (loadingCreateEquipmentIndustrial ? <LoadingOutlined className='icon' /> : "Editar")}
                                    </button>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '31%', flexDirection: 'column', marginLeft: '1%' }}>
                                <p className='titleSection'>Imagen del equipo</p>
                                <Controller
                                    name="image"
                                    control={controlIndustrial}
                                    rules={{
                                        required: "La imagen es requerida",
                                    }}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={canvas} className='canvas'> </canvas>
                                            <p>Imagen</p>
                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setImage(photo)
                                                        setValueIndustrial('image', undefined)
                                                        var ctx = canvas.current.getContext('2d')
                                                        var img = new Image()
                                                        canvas.current.width = 350
                                                        canvas.current.height = 280
                                                        img.onload = function () {
                                                            ctx.drawImage(img, 0, 0, 350, 280)
                                                        }
                                                        var dataImg;
                                                        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
                                                        img.src = photo
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',
                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage(e);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {errorsIndustrial.image && <p className='errorMessage'>{errorsIndustrial.image.message}</p>}
                            </div>
                        </div>
                        :
                        <div>
                            {watchIndustrial("equipType") &&
                                <p>Por ahora solo se pueden crear equipos de tipo Nevera para conservación biológica, Congelador, Planta eléctrica y Aire acondicionado</p>}
                        </div>
                    }
                </div>
            }
        </div>
    )
}