import { combineReducers } from "redux";
import { AreaReducer } from "../services/Area/AreaSlice";
import { CaseReportReducer } from "../services/caseReport/CaseReportSlice";
import { PagReducer } from "../services/common/Pag/PagSlice";
import { DepMunReducer } from "../services/DepMun/DepMunSlice";
import { EnterpriseReducer } from "../services/Enterprise/EnterpriseSlice";
import { EquipmentReducer } from "../services/Equipment/EquipmentSlice";
import { LoginReducer } from "../services/Login/LoginSlices";
import { TimelineReducer } from "../services/Timeline/TimelineSlice";
import { SummaryReducer } from "../services/Summary/SummarySlice";
import { EquipmentSystemReducer } from "../services/EquipmentSystem/EquipmentSystemSlice";
import { CaseReportSystemReducer } from "../services/caseReportSystem/caseReportSystemSlice";
import { TransferReducer } from "../services/Transfer/TransferSlice";
import { TicketReducer } from "../services/Ticket/TicketSlice";
import { EquipmentIndustrialReducer } from "../services/EquipmentIndustrial/EquipmentIndustrialSlice";
import { EquipmentVehicleReducer } from "../services/EquipmentVehicle/EquipmentVehicleSlice";
import { CaseReportPowerPlantReducer } from "../services/caseReportPowerPlant/caseReportPowerPlantSlice"
import { dailyVehicleReducer } from "../services/dailyVehicle/dailyVehicleSlice";
import { SpecializedCenterReducer } from "../services/SpecializedCenter/SpecializedCenterSlice";
import { DriverReducer } from "../services/Driver/DriverSlice";

export default combineReducers({
  Pag: PagReducer,
  Area: AreaReducer,
  DepMun: DepMunReducer,
  Login: LoginReducer,
  Equipment: EquipmentReducer,
  CaseReport: CaseReportReducer,
  Timeline: TimelineReducer,
  Enterprise: EnterpriseReducer,
  Summary: SummaryReducer,
  EquipmentSystem: EquipmentSystemReducer,
  CaseReportSystem: CaseReportSystemReducer,
  Transfer: TransferReducer,
  Ticket: TicketReducer,
  EquipmentIndustrial: EquipmentIndustrialReducer,
  EquipmentVehicle: EquipmentVehicleReducer,
  CaseReportPowerPlant: CaseReportPowerPlantReducer,
  DailyVehicle: dailyVehicleReducer,
  SpecializedCenter: SpecializedCenterReducer,
  Driver: DriverReducer,
});
