import { Steps, Button, Radio, message, AutoComplete } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../Input/Input'
import { PlusOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { DatePicker, Select, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { useForm, Controller } from "react-hook-form"
import { apiUrl } from '../../common/config/Environments'
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import { EditLocationTwoTone } from '@material-ui/icons';

export const FormEquipmentResume = ({ edit, data }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys, userCredentialsFirebase } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { fullDataEquipment, loadingUpdateEquipment, loadingCreateEquipment } = useSelector(state => state.Equipment)

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
        trigger,
    } = useForm({
        defaultValues: {
            location: edit ? fullDataEquipment.location.name : '',
            dependency: edit ? fullDataEquipment.dependency.id : '',
            sectorCode: edit ? data.sectorCode : '',
            municipality: edit ? fullDataEquipment.municipality.id : '',
            nit: edit ? data.nit : '',
            number: edit ? data.number : '',
            licensePlate: edit ? data.licensePlate : '',
            name: edit ? data.name : '',
            brand: edit ? data.biomedicEquipment.brand : '',
            image: edit ? fullDataEquipment.biomedicEquipment.image : '',
            serie: edit ? data.biomedicEquipment.serie : '',
            modell: edit ? data.biomedicEquipment.modell : '',
            service: edit ? data.biomedicEquipment.service : '',
            cost: edit ? fullDataEquipment.biomedicEquipment.cost : '',
            buyDate: edit ? moment(fullDataEquipment.biomedicEquipment.adquisitionDate) : '',
            startOperationDate: edit ? moment(fullDataEquipment.biomedicEquipment.startOperationDate) : '',
            healthRegister: edit ? fullDataEquipment.biomedicEquipment.healthRegister : '',
            representative: edit ? fullDataEquipment.biomedicEquipment.representative : '',
            webPage: edit ? fullDataEquipment.biomedicEquipment.webPage : '',
            instalationDate: edit ? moment(fullDataEquipment.biomedicEquipment.instalationDate) : '',
            warrantyExpirationDate: edit ? moment(fullDataEquipment.biomedicEquipment.warrantyExpirationDate) : '',
            fabricationYear: edit ? moment(fullDataEquipment.biomedicEquipment.fabricationDate) : '',
            maker: edit ? fullDataEquipment.biomedicEquipment.maker : '',
            makerCountry: edit ? fullDataEquipment.biomedicEquipment.makerCountry : '',
            risk: edit ? fullDataEquipment.biomedicEquipment.riskClasification : '',
            voltageRange: edit ? fullDataEquipment.biomedicEquipment.voltageRange : '',
            powerRange: edit ? fullDataEquipment.biomedicEquipment.powerRange : '',
            speedRange: edit ? fullDataEquipment.biomedicEquipment.speedRange : '',
            temperatureRange: edit ? fullDataEquipment.biomedicEquipment.temperatureRange : '',
            dimensions: edit ? fullDataEquipment.biomedicEquipment.dimensions : '',
            currentRange: edit ? fullDataEquipment.biomedicEquipment.currentRange : '',
            pressureRange: edit ? fullDataEquipment.biomedicEquipment.pressureRange : '',
            humidityRange: edit ? fullDataEquipment.biomedicEquipment.humidityRange : '',
            frequency: edit ? fullDataEquipment.biomedicEquipment.frequency : '',
            weight: edit ? fullDataEquipment.biomedicEquipment.weight : '',
            adquisition: edit ? fullDataEquipment.biomedicEquipment.adquisitionWay : '',
            feeding: edit ? fullDataEquipment.biomedicEquipment.powerSource : '',
            manual: edit ? fullDataEquipment.biomedicEquipment.manual : '',
            use: edit ? fullDataEquipment.biomedicEquipment.use : '',
            tech: edit ? fullDataEquipment.biomedicEquipment.tecnology : '',
            classi: edit ? fullDataEquipment.biomedicEquipment.biomedicClasification : '',
            plane: edit ? fullDataEquipment.biomedicEquipment.plane : '',
            op: edit ? fullDataEquipment.biomedicEquipment.operation : '',
            sec: edit ? fullDataEquipment.biomedicEquipment.electricSecurity : '',
            own: edit ? fullDataEquipment.biomedicEquipment.equipmentOwner : '',
            mantenimiento: edit ? fullDataEquipment.biomedicEquipment.maintenance : '',
            irregularCause: edit ? fullDataEquipment.biomedicEquipment.irregularCause : '',
            importRegistration: edit ? fullDataEquipment.biomedicEquipment.importRegistration : '',
            INVIMA: edit ? fullDataEquipment.biomedicEquipment.INVIMA : '',
            manuals: edit ? fullDataEquipment.biomedicEquipment.manuals : '',
            quickHandlingGuide: edit ? fullDataEquipment.biomedicEquipment.quickHandlingGuide : '',
            dataSheet: edit ? fullDataEquipment.biomedicEquipment.dataSheet : '',
            warranty: edit ? fullDataEquipment.biomedicEquipment.warranty : '',
            preventiveMaintenance: edit ? fullDataEquipment.biomedicEquipment.preventiveMaintenance : '',
            induction: edit ? fullDataEquipment.biomedicEquipment.induction : '',
        }
    })

    const [areaSelected, setAreaSelected] = useState(fullDataEquipment && fullDataEquipment.dependency.area.id)
    const [Dep, setDep] = useState(false);
    const [compInfo, setCompInfo] = useState([])
    const canvas = useRef(null)

    const [image, setImage] = useState(photo)

    const { departments, municipalitys } = useSelector(state => state.DepMun)

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watch('dependency')) {
            dispatch(LoginActions.getBoss(watch('dependency')))
        }
    }, [watch('dependency')])

    const next = async () => {
        const fieldsToValidate = {
            0: ['sectorCode', 'municipality', 'nit', 'number', 'licensePlate', 'name',
                'brand', 'image', 'serie', 'modell', 'service', 'cost',
                'buyDate', 'startOperationDate', 'healthRegister', 'representative',
                'webPage', 'instalationDate', 'warrantyExpirationDate', 'fabricationYear',
                'maker', 'makerCountry',
            ],
            1: ['risk', 'voltageRange', 'powerRange', 'speedRange', 'temperatureRange',
                'dimensions', 'currentRange', 'pressureRange', 'humidityRange', 'frequency',
                'weight', 'adquisition', 'risk', 'feeding', 'manual', 'use', 'tech', 'classi', 'plane'
            ],
            2: ['op', 'sec', 'own', 'mantenimiento', 'irregularCause'],
        };

        const isValid = await trigger(fieldsToValidate[current]);

        if (isValid) {
            setCurrent(current + 1)
            window.scrollTo(0, 0)
        } else {
            message.warn("Falta")
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0)
    };

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValue("image", dataImg) }, 1000)
    };
    const Comp = ({ index, values }) => {
        const [exist, setExist] = useState(true);
        const [text, setText] = useState(values[0])
        const [text1, setText1] = useState(values[1])
        const [text2, setText2] = useState(values[2])
        const [text3, setText3] = useState(values[3])

        const deleteComp = () => {
            setExist(false)
            var aux = compInfo
            aux.splice(index, 1)
            setCompInfo(aux)
        }
        useEffect(() => {
            var aux = compInfo
            aux[index][0] = text
            aux[index][1] = text1
            aux[index][2] = text2
            aux[index][3] = text3
            text !== "" && text1 !== "" && text2 !== "" && text3 !== "" && setCompInfo(aux)
        }, [text, text1, text2, text3, index])

        return (
            exist && <div className='comp'>
                <Input mt={'0vw'}
                    height={'3vw'} va={text} setData={setText} label='Nombre' labelstyle='inputLabelBordered' />
                <Input mt={'0vw'}
                    height={'3vw'} va={text1} setData={setText1} label='Marca' labelstyle='inputLabelBordered' />
                <Input mt={'0vw'}
                    height={'3vw'} va={text2} setData={setText2} label='Referencia' labelstyle='inputLabelBordered' />
                <Input mt={'0vw'}
                    height={'3vw'} va={text3} setData={setText3} label='Numero de Parte' labelstyle='inputLabelBordered' />
                <CloseOutlined onClick={() => deleteComp()} className='closeIcon' />
            </div>
        )
    }

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    /* const handleFileChange = ({ target }, keyForm) => {
        if (target.files.length < 1 || !target.validity.valid) {
            return
        }
        const file = target.files[0];
        const fileSizeInBytes = file.size;
        const fileSizeInKilobytes = fileSizeInBytes / 1024; // convertir a Kilobytes

        if (fileSizeInKilobytes > 341) { // cambiar el 341 al tamaño máximo que desees permitir
            message.error("El archivo es demasiado grande (341KB max). Intente comprimir el documento");
        } else {
            fileToBase64(file, (err, result) => {
                if (err) {
                    message.error("Error al cargar el archivo")
                } else if (result) {
                    setValue(keyForm, result)
                    message.success("Archivo cargado")
                }
            })
        }
    } */

    const steps = [
        {
            title: 1,
            content:
                <div>
                    <div className='first'>
                        <Input
                            {...register('sectorCode', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("El codigo del sector es requerido");
                                    }
                                    if (!/^[0-9]*$/.test(value)) {
                                        errors.push("El codigo del sector debe ser numérico");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            mt={'0vw'}
                            height={'3vw'}
                            va={watch('sectorCode')}
                            setData={e => setValue('sectorCode', e)}
                            label='Código del sector'
                            labelMB={'-0.5vw'}
                            labelstyle='inputLabelBordered'
                            error={errors.sectorCode && errors.sectorCode.message}
                        />
                        <div className='divSelect'>
                            <p className='titleSelect'>Departamento</p>
                            <Select
                                placeholder='Departamento'
                                onChange={e => setDep(e)}
                                className="select"
                            >
                                {departments.map(d => (
                                    <Option key={d.nombre} value={d.id}>
                                        {d.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className='divSelect'>
                            <p className='titleSelect'>Municipio</p>
                            <Controller
                                name="municipality"
                                control={control}
                                rules={{
                                    required: "El municipio es requerido",
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder='Municipio'
                                        value={watch('municipality')}
                                        className="select"
                                    >
                                        {municipalitys && municipalitys.map(d => (
                                            <Option key={d.nombre} value={d.id}>
                                                {d.nombre}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.municipality && <p className='errorMessage'>{errors.municipality.message}</p>}
                        </div>
                        <Input
                            {...register('nit', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("El NIT es requerido");
                                    }
                                    if (!/^[0-9]*$/.test(value)) {
                                        errors.push("El NIT debe ser numérico");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            mt={'0vw'}
                            height={'3vw'}
                            va={watch('nit')}
                            setData={e => setValue('nit', e)}
                            label='NIT'
                            labelstyle='inputLabelBordered'
                            labelMB={'-0.5vw'}
                            error={errors.nit && errors.nit.message}
                        />
                        <Input
                            {...register('number', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("El Numero es requerido");
                                    }
                                    if (!/^[0-9]*$/.test(value)) {
                                        errors.push("El Numero debe ser numérico");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            mt={'0vw'}
                            height={'3vw'}
                            va={watch('number')}
                            setData={e => setValue('number', e)}
                            label='N°'
                            labelMB={'-0.5vw'}
                            labelstyle='inputLabelBordered'
                            error={errors.number && errors.number.message}
                        />
                    </div>
                    <p className='featuresText'>Caracteristicas Tecnicas del Equipo</p>
                    <hr />
                    <div className='featuresFirstDiv'>
                        <Input
                            {...register('licensePlate', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("La placa es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            label={"Placa"}
                            width={'14vw'}
                            height={'2.4vw'}
                            labelstyle='inputLabelBordered'
                            fontSize={'1vw'}
                            labelMB={'-0.6vw'}
                            va={watch('licensePlate')}
                            setData={e => setValue('licensePlate', e)}
                            error={errors.licensePlate && errors.licensePlate.message}
                        />
                        <Input
                            {...register('name', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("El nombre es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            label={"Nombre"}
                            width={'14vw'}
                            height={'2.4vw'}
                            labelstyle='inputLabelBordered'
                            fontSize={'1vw'}
                            labelMB={'-0.6vw'}
                            va={watch('name')}
                            setData={e => setValue('name', e)}
                            error={errors.serie && errors.name.message}
                        />
                        <Input
                            {...register('brand', {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("La marca es requerida");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            label={"Marca"}
                            width={'14vw'}
                            height={'2.4vw'}
                            labelstyle='inputLabelBordered'
                            fontSize={'1vw'}
                            labelMB={'-0.6vw'}
                            va={watch('brand')}
                            setData={e => setValue('brand', e)}
                            error={errors.brand && errors.brand.message}
                        />
                    </div>
                    <div className='featuresDiv'>
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: "La imagen es requerida",
                            }}
                            render={({ field }) => (
                                <div className='imageDiv'>
                                    <canvas ref={canvas} className='canvas'> </canvas>
                                    {(!fullDataEquipment || image !== photo) ?
                                        <img alt='imag' src={image}></img>
                                        :
                                        <img alt='imag' src={fullDataEquipment.biomedicEquipment.image}></img>
                                    }
                                    <div className='imageButtons'>
                                        <Button
                                            onClick={() => {
                                                setImage(photo)
                                                setValue('image', undefined)
                                            }}
                                            style={{
                                                backgroundColor: '#ffff',
                                                color: '#C4C4C4',
                                                borderRadius: '10px',
                                                width: '10vw',
                                                height: '3vw',
                                                fontSize: '1.2vw'
                                            }}
                                        >
                                            Descartar
                                        </Button>
                                        <label className="custom-file-upload">
                                            <input
                                                accept="image/png, image/jpeg"
                                                onClick={(event) => { event.target.value = null }}
                                                onChange={e => {
                                                    uploadImage(e);
                                                }}
                                                type='file'
                                            ></input>
                                            <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                        </label>
                                    </div>
                                </div>
                            )}
                        />
                        {errors.image && <p className='errorMessage'>{errors.image.message}</p>}

                        <div className='firstInfoDiv'>
                            <Input
                                {...register('serie', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("La serie es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Serie"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('serie')}
                                setData={e => setValue('serie', e)}
                                error={errors.serie && errors.serie.message}
                            />
                            <Input
                                {...register('modell', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El modelo es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Modelo"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('modell')}
                                setData={e => setValue('modell', e)}
                                error={errors.modell && errors.modell.message}
                            />
                            <Input
                                {...register('service', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El servicio es requerid");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Servicio"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('service')}
                                setData={e => setValue('service', e)}
                                error={errors.service && errors.service.message}
                            />
                            <Input
                                {...register('cost', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El costo es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Costo"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('cost')}
                                setData={e => setValue('cost', e)}
                                error={errors.cost && errors.cost.message}
                            />
                        </div>
                        <div className='secondInfoDiv'>
                            <div>
                                <p>Fecha de Compra</p>
                                <DatePicker
                                    value={watch("buyDate")}
                                    {...register('buyDate', {

                                    })}
                                    style={{ width: '14vw', height: '2.4vw' }}
                                    onChange={d => setValue("buyDate", d)}
                                />
                                <p></p>
                                <CloseOutlined className="buttonDeleteDate" onClick={() => setValue("buyDate", undefined)} />
                            </div>
                            {errors.buyDate && <p className='errorMessage'>{errors.buyDate.message}</p>}
                            <div>
                                <p>Fecha Inicio Operacion</p>
                                <DatePicker
                                    value={watch("startOperationDate")}
                                    {...register('startOperationDate', {

                                    })}
                                    style={{ width: '14vw', height: '2.4vw' }}
                                    onChange={d => setValue("startOperationDate", d)}
                                />
                                <p></p>
                                <CloseOutlined className="buttonDeleteDate" onClick={() => setValue("startOperationDate", undefined)} />
                            </div>
                            {errors.startOperationDate && <p className='errorMessage'>{errors.startOperationDate.message}</p>}
                            <Input
                                {...register('healthRegister', {
                                })}
                                label={"Registro sanitario"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('healthRegister')}
                                setData={e => setValue('healthRegister', e)}
                                error={errors.healthRegister && errors.healthRegister.message}
                            />
                            <Input
                                {...register('representative', {
                                })}
                                label={"Representante"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('representative')}
                                setData={e => setValue('representative', e)}
                                error={errors.representative && errors.representative.message}
                            />
                            <Input
                                {...register('webPage', {
                                })}
                                label={"Pagina web"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('webPage')}
                                setData={e => setValue('webPage', e)}
                                error={errors.webPage && errors.webPage.message}
                            />
                        </div>
                        <div className='thirdInfoDiv'>
                            <div>
                                <p>Fecha Instalación</p>
                                <DatePicker
                                    value={watch("instalationDate")}
                                    {...register('instalationDate', {

                                    })}
                                    style={{ width: '14vw', height: '2.4vw' }}
                                    onChange={d => setValue("instalationDate", d)}
                                />
                                <p></p>
                                <CloseOutlined className="buttonDeleteDate" onClick={() => setValue("instalationDate", undefined)} />
                            </div>
                            {errors.instalationDate && <p className='errorMessage'>{errors.instalationDate.message}</p>}
                            <div>
                                <p>Fecha Vence Garantia</p>
                                <DatePicker
                                    value={watch("warrantyExpirationDate")}
                                    {...register('warrantyExpirationDate', {

                                    })}
                                    style={{ width: '14vw', height: '2.4vw' }}
                                    onChange={d => setValue("warrantyExpirationDate", d)}
                                />
                                <p></p>
                                <CloseOutlined className="buttonDeleteDate" onClick={() => setValue("warrantyExpirationDate", undefined)} />
                            </div>
                            {errors.warrantyExpirationDate && <p className='errorMessage'>{errors.warrantyExpirationDate.message}</p>}
                            <div>
                                <p>Año de fabricación</p>
                                <DatePicker
                                    value={watch("fabricationYear")}
                                    {...register('fabricationYear', {

                                    })}
                                    style={{ width: '14vw', height: '2.4vw' }}
                                    onChange={d => setValue("fabricationYear", d)}
                                    picker="year"
                                />
                                <p></p>
                                {data && typeof (watch("fabricationYear")) == "string" && watch("fabricationYear")}
                                <CloseOutlined className="buttonDeleteDate" onClick={() => setValue("fabricationYear", undefined)} />
                            </div>
                            {errors.fabricationYear && <p className='errorMessage'>{errors.fabricationYear.message}</p>}
                            <Input
                                {...register('maker', {
                                })}
                                label={"Fabricante"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('maker')}
                                setData={e => setValue('maker', e)}
                                error={errors.maker && errors.maker.message}
                            />
                            <Input
                                {...register('makerCountry', {
                                })}
                                label={"Pais de Fabricación"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('makerCountry')}
                                setData={e => setValue('makerCountry', e)}
                                error={errors.makerCountry && errors.makerCountry.message}
                            />
                        </div>
                    </div>
                </div>,
        },
        {
            title: 2,
            content:
                <div className='secondSection'>
                    <div className='risk'>
                        <p className='featuresText'>Riesgo Técnico</p>
                        <hr />
                        <div className='risksDiv'>
                            <Input
                                {...register('voltageRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de voltaje es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Voltaje"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('voltageRange')}
                                setData={e => setValue('voltageRange', e)}
                                error={errors.voltageRange && errors.voltageRange.message}
                            />
                            <Input
                                {...register('powerRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de potencia es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Potencia"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('powerRange')}
                                setData={e => setValue('powerRange', e)}
                                error={errors.powerRange && errors.powerRange.message}
                            />
                            <Input
                                {...register('speedRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de velocidad es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Velocidad"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('speedRange')}
                                setData={e => setValue('speedRange', e)}
                                error={errors.speedRange && errors.speedRange.message}
                            />
                            <Input
                                {...register('temperatureRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de temperatura es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Temperatura"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('temperatureRange')}
                                setData={e => setValue('temperatureRange', e)}
                                error={errors.temperatureRange && errors.temperatureRange.message}
                            />
                            <Input
                                {...register('dimensions', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("Dimensiones es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Dimensiones"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('dimensions')}
                                setData={e => setValue('dimensions', e)}
                                error={errors.dimensions && errors.dimensions.message}
                            />
                        </div>
                        <div className='risksDiv1'>
                            <Input
                                {...register('currentRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de corriente es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Corriente"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('currentRange')}
                                setData={e => setValue('currentRange', e)}
                                error={errors.currentRange && errors.currentRange.message}
                            />
                            <Input
                                {...register('pressureRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de presión es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Presión"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('pressureRange')}
                                setData={e => setValue('pressureRange', e)}
                                error={errors.pressureRange && errors.pressureRange.message}
                            />
                            <Input
                                {...register('humidityRange', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El rango de humedad es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Rango de Humedad"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('humidityRange')}
                                setData={e => setValue('humidityRange', e)}
                                error={errors.humidityRange && errors.humidityRange.message}
                            />
                            <Input
                                {...register('frequency', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("Frecuencia es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Frecuencia"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('frequency')}
                                setData={e => setValue('frequency', e)}
                                error={errors.frequency && errors.frequency.message}
                            />
                            <Input
                                {...register('weight', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El peso es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                label={"Peso"}
                                width={'14vw'}
                                height={'2.4vw'}
                                labelstyle='inputLabelBordered'
                                fontSize={'1vw'}
                                labelMB={'-0.6vw'}
                                va={watch('weight')}
                                setData={e => setValue('weight', e)}
                                error={errors.weight && errors.weight.message}
                            />
                        </div>

                    </div>
                    <div className='checkBoxes'>
                        <div>
                            <p>Forma de Adquisición</p>
                            <Controller
                                control={control}
                                name="adquisition"
                                rules={{
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value.length === 0) {
                                            errors.push("La forma de adquisición es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                }}
                                render={({ field }) => (
                                    <Checkbox.Group
                                        className='checkboxG'
                                        onChange={value => field.onChange(value)}
                                        value={field.value}
                                    >
                                        <Checkbox className='checkbox' value={'Compra directa'}>Compra directa</Checkbox>
                                        <Checkbox className='checkbox' value={'Donación de Particulares'}>Donación de Particulares</Checkbox>
                                        <Checkbox className='checkbox' value={'Donación de Beneficiencia'}>Donación de Beneficiencia</Checkbox>
                                        <Checkbox className='checkbox' value={'Asignado por el gobierno'}>Asignado por el gobierno</Checkbox>
                                        <Checkbox className='checkbox' value={'Comodato'}>Comodato</Checkbox>
                                        <Checkbox className='checkbox' value={'No aplica'}>No aplica</Checkbox>
                                    </Checkbox.Group>
                                )}
                            />
                            {errors.adquisition && <p className='errorMessage'>{errors.adquisition.message}</p>}
                        </div>
                        <div>
                            <p>Clasificacion De Riesgo</p>
                            <Controller
                                control={control}
                                name="risk"
                                rules={{
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value.length === 0) {
                                            errors.push("La clasificación del riesgo es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                }}
                                render={({ field }) => (
                                    <Checkbox.Group
                                        className='checkboxG'
                                        onChange={value => field.onChange(value)}
                                        value={field.value}
                                    >
                                        <Checkbox className='checkbox' value={'I Bajo'}>I Bajo</Checkbox>
                                        <Checkbox className='checkbox' value={'IIA Moderado'}>IIA Moderado</Checkbox>
                                        <Checkbox className='checkbox' value={'IIB Alto'}>IIB Alto</Checkbox>
                                        <Checkbox className='checkbox' value={'III Muy Alto'}>III Muy Alto</Checkbox>
                                        <Checkbox className='checkbox' value={'No aplica'}>No aplica</Checkbox>
                                    </Checkbox.Group>
                                )}
                            />
                            {errors.risk && <p className='errorMessage'>{errors.risk.message}</p>}
                        </div>
                        <div>
                            <p>Clasificación Biomédica</p>
                            <Controller
                                control={control}
                                name="classi"
                                rules={{
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value.length === 0) {
                                            errors.push("La clasificación biomédica es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                }}
                                render={({ field }) => (
                                    <Checkbox.Group
                                        className='checkboxGH'
                                        onChange={value => field.onChange(value)}
                                        value={field.value}
                                    >
                                        <div>
                                            <Checkbox className='checkbox' value={'Diagnostico'}>Diagnóstico</Checkbox>
                                            <Checkbox className='checkbox' value={'Tratamiento'}>Tratamiento</Checkbox>
                                            <Checkbox className='checkbox' value={'Análisis de laboratorio'}>Análisis de laboratorio</Checkbox>
                                        </div>
                                        <div>
                                            <Checkbox className='checkbox' value={'Rehabilitación'}>Rehabilitación</Checkbox>
                                            <Checkbox className='checkbox' value={'Prevención'}>Prevención</Checkbox>
                                            <Checkbox className='checkbox' value={'Otro'}>Otro</Checkbox>
                                        </div>
                                        <Checkbox className='checkbox' value={'No aplica'}>No aplica</Checkbox>
                                    </Checkbox.Group>
                                )}
                            />
                            {errors.classi && <p className='errorMessage'>{errors.classi.message}</p>}
                        </div>
                        <div>
                            <p>Fuente De Alimentación</p>
                            <Controller
                                control={control}
                                name="feeding"
                                rules={{
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value.length === 0) {
                                            errors.push("La fuente de alimentación es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                }}
                                render={({ field }) => (
                                    <Checkbox.Group
                                        className='checkboxG'
                                        onChange={value => field.onChange(value)}
                                        value={field.value}
                                    >
                                        <Checkbox className='checkbox' value={'Agua'}>Agua</Checkbox>
                                        <Checkbox className='checkbox' value={'Aire'}>Aire</Checkbox>
                                        <Checkbox className='checkbox' value={'Vapor'}>Vapor</Checkbox>
                                        <Checkbox className='checkbox' value={'Electricidad'}>Electricidad</Checkbox>
                                        <Checkbox className='checkbox' value={'Baterias'}>Baterias</Checkbox>
                                        <Checkbox className='checkbox' value={'No aplica'}>No aplica</Checkbox>
                                    </Checkbox.Group>
                                )}
                            />
                            {errors.feeding && <p className='errorMessage'>{errors.feeding.message}</p>}
                        </div>
                    </div>
                    {!data && <p className='featuresText'>Componentes Del Equipo</p>}
                    {!data && <hr />}
                    {!data && <div className='newComp'>
                        <Button type="primary"
                            icon={<PlusOutlined />}
                            style={{
                                backgroundColor: '#4E8E30',
                                borderRadius: '10px',
                                width: '14vw',
                                height: '3vw',
                                fontSize: '1.3vw',
                                padding: 0
                            }}
                            onClick={() => setCompInfo([...compInfo, ['', '', '', '']])}>
                            Añadir Componente
                        </Button>
                    </div>}
                    {compInfo && compInfo.map((data, i) => (
                        <Comp key={i} index={i} values={data} />
                    ))}
                    <div className='firstCheckBoxes'>
                        <div>
                            <p>Manuales</p>
                            <Radio.Group
                                className='radioG'
                                {...register('manual', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("Manuales es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue('manual', d.target.value)}
                                value={watch('manual')}>
                                <Radio className='radio' value={'Operacion'}>Operacion</Radio>
                                <Radio className='radio' value={'Mantenimiento'}>Mantenimiento</Radio>
                                <Radio className='radio' value={'Despieces'}>Despieces</Radio>
                                <Radio className='radio' value={'Partes'}>Partes</Radio>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.manual && <p className='errorMessage'>{errors.manual.message}</p>}
                        </div>
                        <div>
                            <p>Uso</p>
                            <Radio.Group
                                className='radioG'
                                {...register('use', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El uso es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue("use", d.target.value)}
                                value={watch('use')}>
                                <Radio className='radio' value={'Medico'}>Medico</Radio>
                                <Radio className='radio' value={'Basico'}>Basico</Radio>
                                <Radio className='radio' value={'Apoyo'}>Apoyo</Radio>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.use && <p className='errorMessage'>{errors.use.message}</p>}
                        </div>
                        <div>
                            <p>Tecnología Predominante</p>
                            <Controller
                                control={control}
                                name="tech"
                                rules={{
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value.length === 0) {
                                            errors.push("La tecnología predominante es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                }}
                                render={({ field }) => (
                                    <Checkbox.Group
                                        className='checkboxGH'
                                        onChange={value => field.onChange(value)}
                                        value={field.value}
                                    >
                                        <div>
                                            <Checkbox className='checkbox' value={'Eléctrico'}>Eléctrico</Checkbox>
                                            <Checkbox className='checkbox' value={'Electrónico'}>Electrónico</Checkbox>
                                            <Checkbox className='checkbox' value={'Mecánico'}>Mecánico</Checkbox>
                                            <Checkbox className='checkbox' value={'Electromecánico'}>Electromecánico</Checkbox>
                                        </div>
                                        <div>
                                            <Checkbox className='checkbox' value={'Hidráulico'}>Hidráulico</Checkbox>
                                            <Checkbox className='checkbox' value={'Neumático'}>Neumático</Checkbox>
                                            <Checkbox className='checkbox' value={'Vapor'}>Vapor</Checkbox>
                                            <Checkbox className='checkbox' value={'Solar'}>Solar</Checkbox>
                                            <Checkbox className='checkbox' value={'No aplica'}>No aplica</Checkbox>
                                        </div>
                                    </Checkbox.Group>
                                )}
                            />
                            {errors.tech && <p className='errorMessage'>{errors.tech.message}</p>}
                        </div>

                        <div>
                            <p>Planos</p>
                            <Radio.Group
                                className='radioG'
                                {...register('plane', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El plano es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue('plane', d.target.value)}
                                value={watch('plane')}>
                                <Radio className='radio' value={'Electricos'}>Electricos</Radio>
                                <Radio className='radio' value={'Mecanicos'}>Mecanicos</Radio>
                                <Radio className='radio' value={'Hidraulicos'}>Hidraulicos</Radio>
                                <Radio className='radio' value={'Neumaticos'}>Neumaticos</Radio>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.plane && <p className='errorMessage'>{errors.plane.message}</p>}
                        </div>
                    </div>
                </div>,
        },
        {
            title: 3,
            content:
                <div className='third'>
                    <p className='featuresText'>Evaluación Fisica y Funcional</p>
                    <hr />
                    <div className='thirdCheckBoxes'>
                        <div>
                            <p>Operación</p>
                            <Radio.Group
                                className='radioG'
                                {...register('op', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("La operación es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue("op", d.target.value)}
                                value={watch('op')}
                            >
                                <Radio className='radio' value={'Fijo (Anclado)'}>Fijo (Anclado)</Radio>
                                <Radio className='radio' value={'Portátil'}>Portátil</Radio>
                                <Radio className='radio' value={'Normal'}>Normal</Radio>
                                <Radio className='radio' value={'Irregular'}>Irregular</Radio>
                                <Radio className='radio' value={'Fuera de servicio'}>Fuera de servicio</Radio>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.op && <p className='errorMessage'>{errors.op.message}</p>}
                        </div>

                        <div>
                            <p>Seguridad eléctrica</p>
                            <Radio.Group
                                className='radioG'
                                {...register('sec', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("La seguridad eléctrica es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue("sec", d.target.value)}
                                value={watch('sec')}
                            >
                                <Radio className='radio' value={'Clase I'}>Clase I</Radio>
                                <Radio className='radio' value={'Clase II'}>Clase II</Radio>
                                <Radio className='radio' value={'Clase IP'}>Clase IP</Radio>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.sec && <p className='errorMessage'>{errors.sec.message}</p>}
                        </div>

                        <div>
                            <p>Propiedad Equipo</p>
                            <Radio.Group
                                className='radioG'
                                {...register('own', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("La propiedad del equipo es requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue("own", d.target.value)}
                                value={watch('own')}
                            >
                                <Radio className='radio' value={'Propio'}>Propio</Radio>
                                <Radio className='radio' value={'Medico'}>Medico</Radio>
                                <Radio className='radio' value={'Firma'}>Firma</Radio>
                                <Radio className='radio' value={'Otro'}>Otro</Radio>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.own && <p className='errorMessage'>{errors.own.message}</p>}
                        </div>

                        <div>
                            <p>Mantenimiento</p>
                            <div className='littleTexts'>
                                <p className='littleText'>Preventivo</p>
                                <p className='littleText'>Correctivo</p>
                            </div>
                            <Radio.Group
                                className='radioGH'
                                {...register('mantenimiento', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El mantenimiento es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValue('mantenimiento', d.target.value)}
                                value={watch('mantenimiento')}>
                                <div className='firstDivRadios'>
                                    <Radio className='radio' value={'Preventivo-Contratado'}></Radio>
                                    <Radio className='radio' value={'Preventivo-Propio'}></Radio>
                                    <Radio className='radio' value={'Preventivo-Garantia'}></Radio>
                                    <Radio className='radio' value={'Preventivo-Comodato'}></Radio>
                                </div>
                                <div className='secondDivRadios'>
                                    <Radio className='radio' value={'Correctivo-Contratado'}>Contratado</Radio>
                                    <Radio className='radio' value={'Correctivo-Propio'}>Propio</Radio>
                                    <Radio className='radio' value={'Correctivo-Garantia'}>Garantia</Radio>
                                    <Radio className='radio' value={'Correctivo-Comodato'}>Comodato</Radio>
                                </div>
                                <Radio className='radio' value={'No aplica'}>No aplica</Radio>
                            </Radio.Group>
                            {errors.mantenimiento && <p className='errorMessage'>{errors.mantenimiento.message}</p>}
                        </div>
                    </div>
                    <p>Causa Irregular</p>
                    <textarea
                        {...register('irregularCause', {
                            validate: (value) => {
                                const errors = [];
                                if (!value || value === '') {
                                    errors.push("La causa irregular es requerida");
                                }
                                return errors.length === 0 || errors.join(', ');
                            },
                        })}
                        value={watch('irregularCause')}
                        onChange={e => setValue('irregularCause', e.target.value)}
                    >
                        {errors.irregularCause && <p className='errorMessage'>{errors.irregularCause.message}</p>}
                    </textarea>

                    <p className='featuresText'>Documentos anexos</p>
                    <hr />
                    <div className='files'>
                        <div>
                            <p>Registro de importación</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("importRegistration", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/*  {watch("importRegistration") === '' && <p className='errorMessage'>{"Registro de importación es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Registro INVIMA</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("INVIMA", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("INVIMA") === '' && <p className='errorMessage'>{"Invima es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Manuales</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("manuals", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("manuals") === '' && <p className='errorMessage'>{"Manuales es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Guia rápida de manejo</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("quickHandlingGuide", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("quickHandlingGuide") === '' && <p className='errorMessage'>{"Guia rapida de manejo es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Ficha técnica</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("dataSheet", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("dataSheet") === '' && <p className='errorMessage'>{"Ficha técnica es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Garantía</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("warranty", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("warranty") === '' && <p className='errorMessage'>{"Garantia es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Mantenimientos preventivos durante la garantía</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("preventiveMaintenance", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("preventiveMaintenance") === '' && <p className='errorMessage'>{"Mantenimientos es requerido"}</p>} */}
                        </div>
                        <div>
                            <p>Inducción</p>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={event => {
                                    const file = event.target.files[0];
                                    if (file) {
                                        setValue("induction", file);
                                    } else {
                                        message.error('Error al obtener archivo')
                                    }
                                }}
                                placeholder="Seleccione pdf"
                                className="file"
                                style={{ width: '30vw' }}
                            />
                            {/* {watch("induction") === '' && <p className='errorMessage'>{"Inducción es requerido"}</p>} */}
                        </div>
                    </div>

                    {/* {data && <p className='processDescription'>Estado final del equipo</p>}
                    {data && <Radio.Group className='radioG' onChange={d => setStatusEquip(d.target.value)} value={statusEquip}>
                        <Radio className='radio' value={"Funcional"}>Funcional</Radio>
                        <Radio className='radio' value={"No funcional"}>No funcional</Radio>
                        <Radio className='radio' value={"Dado de baja"}>Dado de baja</Radio>
                    </Radio.Group>} */}
                </div>
        },

    ];

    return (
        <div className='FormEquipment'>
            <div className='initialSelects'>
                <Select
                    className="select"
                    placeholder='Area'
                    onChange={e => {
                        setAreaSelected(e)
                        setValue('dependency', undefined)
                    }}
                >
                    {dependencys && areas.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                {areaSelected &&
                    <Controller
                        name="dependency"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder='Dependencia'
                                value={watch('dependency')}
                                className="select"
                            >
                                {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                }
                {
                    boss &&
                    <Select
                        value="valor"
                        disabled
                        className='select'
                    >
                        <Option value="valor">
                            {boss.length > 0 && boss[0] !== null
                                ?
                                boss[0].name + " " +
                                boss[0].secondName + " " +
                                boss[0].lastName + " " +
                                boss[0].secondLastName
                                :
                                "No hay coordinador"
                            }
                        </Option>
                    </Select>
                }
                <Controller
                    name="headquarter"
                    control={control}
                    disabled={edit}
                    render={({ field }) => (
                        <Select
                            {...field}
                            placeholder='Sede'
                            value={watch('headquarter')}
                            className="select"
                        >
                            <Option value={null}>Sede principal</Option>
                            {headquarters && headquarters.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    )}
                />
                <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                        <AutoComplete
                            {...field}
                            value={watch('location')}
                            placeholder='Ubicación'
                            className="select"
                            style={{ width: '12vw' }}
                            options={locations && locations.map((location) => ({
                                value: location.name,
                                label: location.name,
                            }))}
                            filterOption={(inputValue, option) =>
                                option ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                            }
                        />
                    )}
                />
            </div>
            {((watch('dependency') && watch('location')) || edit) &&
                <Steps current={current} >
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} icon={item.icon} />
                    ))}
                </Steps>
            }
            {((watch('dependency') && watch('location')) || edit) &&
                <div className="steps-content" >
                    {steps[current].content}
                </div>
            }
            {((watch('dependency') && watch('location')) || edit) &&
                <div className="steps-action" >
                    {current > 0 && (
                        <Button style={{ margin: '0 8px', backgroundColor: '#C4C4C4', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => prev()}>
                            Atras
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => next()}>
                            Continuar
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }}
                            onClick={() => handleSubmit((dataSubmit) => {
                                /* if (watch("importRegistration") === '' || watch("INVIMA") === '' || watch("manuals") === '' || watch("quickHandlingGuide") === '' || watch("dataSheet") === '' || watch("warranty") === '' || watch("preventiveMaintenance") === '' || watch("induction") === '') {
                                    message.error("Todos los documentos son requeridos")
                                } else { */
                                dataSubmit.userCreator = idUser
                                dataSubmit.biomedicComponents = compInfo
                                dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                                const techWatch = watch('tech');
                                dataSubmit.tech = Array.isArray(techWatch) ? techWatch.join(', ') : techWatch;
                                const adquisitionWatch = watch('adquisition');
                                dataSubmit.adquisition = Array.isArray(adquisitionWatch) ? adquisitionWatch.join(', ') : adquisitionWatch;
                                const riskWatch = watch('risk');
                                dataSubmit.risk = Array.isArray(riskWatch) ? riskWatch.join(', ') : riskWatch;
                                const classiWatch = watch('classi');
                                dataSubmit.classi = Array.isArray(classiWatch) ? classiWatch.join(', ') : classiWatch;
                                const feedingWatch = watch('feeding');
                                dataSubmit.feeding = Array.isArray(feedingWatch) ? feedingWatch.join(', ') : feedingWatch;
                                !edit && dispatch(EquipmentActions.createEquipment(dataSubmit))
                                edit && dispatch(EquipmentActions.updateEquipment({ "payload": dataSubmit, "id": data.id }))
                                /* } */
                            })()} >
                            {(loadingUpdateEquipment || loadingCreateEquipment) ? <LoadingOutlined /> : "Hecho"}
                        </Button>
                    )}
                </div>
            }
        </div>
    )
}