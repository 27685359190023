import { CaseReportActions } from "../../services/caseReport/CaseReportSlice"
import { Modal, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";


export const ModalBitacora = ({ data, toFirm, setEdit, setEquipData, visibleModalBitacora, setVisibleModalBitacora }) => {
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(0);
    const [visibleModal, setVisibleModal] = useState(false);
    const { moduleWork } = useSelector(state => state.Area)
    const { fullUser } = useSelector(state => state.Login)

    const { loadingFullDataCaseReport, allCaseReportsByEquipmentId, idEquipmentSelected, loadingPdfFromCalibrationCertificate } = useSelector(state => state.CaseReport)

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const onDelete = (id) => {
        if (window.confirm('¿Seguro deseas eliminar este reporte?')) {
            const dataDelete = {
                "id": id
/*                 "id": data.userAssigned.dependencyUser !== undefined ? data.id : data.userAssigned.person1
 */            }
            dispatch(CaseReportActions.deleteCaseReport(dataDelete))
        }
    }

    const pageSize = 6
    const totalPages = Math.ceil(data[1] / pageSize);

    const onSides = (n) => {
        if (currentPage < totalPages - 1 & n === 1) {
            setCurrentPage(prev => prev + n)
        }
        if (currentPage > 0 & n === -1) {
            setCurrentPage(prev => prev + n)
        }
    }
    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    useEffect(() => {
        if (idEquipmentSelected && data) {
            // Obtén los datos que quieres verificar

            // Verifica si data[0][0] contiene un biomedicCaseReport
            if ('biomedicCaseReport' in data[0][0]) {
                dispatch(CaseReportActions.getAllCaseReportsByEquipmentId({ "id": idEquipmentSelected, "page": currentPage }));
            } else {
                dispatch(CaseReportActions.getAllCalibrationCertificateByEquipmentId({ "id": idEquipmentSelected, "page": currentPage, take: 6 }));
            }
        }
    }, [currentPage, idEquipmentSelected]); // Asegúrate de incluir todas las dependencias necesarias en el array de dependencias

    return (
        <Modal className='ModalBitacora' width={'70vw'} visible={visibleModalBitacora} footer={null} onCancel={() => {
            setVisibleModalBitacora(false)
            if (data && 'biomedicCaseReport' in data[0][0]) {
                dispatch(CaseReportActions.setAllCaseReportsByEquipmentId(false))
            } else {
                dispatch(CaseReportActions.setAllCalibrationCertificateByEquipmentId(false))
            }
            dispatch(CaseReportActions.setIdEquipmentSelected(false))
        }}>
            <div className="header">
                <p>Bitacora</p>
            </div>
            <div>
                {!toFirm && data && (data.biomedicEquipment === null ? <p className="name">{data.name}</p> : <p className="name">{data[0][0].equipment.name}</p>)}                <div className="headers">
                    <p>Fecha</p>
                    <p>Tipo de mantenimiento</p>
                    <p>Descripción</p>
                    <p>Nombre del agente</p>
                    <p>N° Reporte de servicio</p>

                </div>
                <hr></hr>
                {data && data.biomedicEquipment !== null && data[0].map((d, i) => (
                    <div key={i}>
                        <div className="data">
                            <p>{padStr(new Date(d.date).getDate()) + "-" +
                                padStr(1 + new Date(d.date).getMonth()) + "-" +
                                padStr(new Date(d.date).getFullYear())}</p>
                            <p style={{ width: '17%', marginRight: '1%' }}>
                                {('biomedicCaseReport' in d) ? (d.biomedicCaseReport === null ? "Datos en el PDF" : d.biomedicCaseReport.serviceType) : "Certificado Calibracion"}
                            </p>
                            <p style={{ width: '17%', marginRight: '2%' }}>
                                {('biomedicCaseReport' in d) ? (d.biomedicCaseReport === null ? "Datos en el PDF" : d.biomedicCaseReport.descriptionAnswer) : "Certificado Calibracion"}
                            </p>
                            <p style={{ marginRight: '8%' }}>
                                {
                                    [
                                        d.userCreator && d.userCreator.person.name,
                                        d.userCreator && d.userCreator.person.secondName,
                                        d.userCreator && d.userCreator.person.lastName,
                                        d.userCreator && d.userCreator.person.secondLastName
                                    ].filter(Boolean).join(' ')
                                }
                            </p>
                            <button className="button"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    /* setVisibleModal(true) */
                                    /* setVisibleModalBitacora(false) */
                                    if ('biomedicCaseReport' in data[0][0]) {
                                        dispatch(CaseReportActions.getFullDataCaseReport({ "id": d.id, "moduleWork": moduleWork[0].moduleWork.id }))
                                    } else {
                                        dispatch(CaseReportActions.getPdfFromCalibrationCertificate(d.id))
                                    }
                                }}>
                                {'biomedicCaseReport' in data[0][0] ? loadingFullDataCaseReport ? <LoadingOutlined /> : "Ver Reporte" : loadingPdfFromCalibrationCertificate ? <LoadingOutlined /> : "Ver certificado"}
                            </button>
                            {fullUser.canCreate && 'biomedicCaseReport' in d && moduleWork[0].moduleWork.id === 1 &&
                                <button className="button"
                                    style={{ marginRight: '5%', cursor: "pointer" }}
                                    onClick={() => {
                                        setEdit(true)
                                        setEquipData(d)
                                    }}>
                                    {loadingFullDataCaseReport ? <LoadingOutlined /> : "Editar"}
                                </button>}
                            <div className='deleteDiv'><CloseOutlined style={{ color: 'red', fontSize: '1vw' }} onClick={() => onDelete(d.id)} /></div>
                        </div>
                        <hr></hr>
                    </div>
                ))}
                {data && <div className='showingText'>
                    Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, data[1])} de {data[1]} elementos                </div>}
                {data && <div className='divButtonsPages'>
                    <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                    <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                        {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                    </Radio.Group>
                    <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                </div>}
            </div>
        </Modal>
    )
}
