import { DatePicker, Select, TimePicker, ConfigProvider, message, Radio } from "antd"
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import esES from 'antd/es/locale/es_ES';
import 'moment/locale/es';
import 'antd/dist/antd.css';
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DownOutlined, LoadingOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { AreaActions } from "../../services/Area/AreaSlice"
import { Input } from "../Input/Input"
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice";
import { TimelineActions } from "../../services/Timeline/TimelineSlice";
import { EnterpriseActions } from "../../services/Enterprise/EnterpriseSlice";
import { useForm, Controller } from "react-hook-form"
import { Equipment } from "../Equipment/Equipment";
import { apiUrl } from "../../common/config/Environments";
import { Close, CloseOutlined } from "@material-ui/icons";


moment.locale('es');

export const FormTimeline = () => {
    const history = useHistory()
    const [headquarter, setHeadquarter] = useState(undefined)
    const [areaSelected, setAreaSelected] = useState(undefined)
    const [dependencyS, setDependencyS] = useState(undefined)
    const [location, setLocation] = useState(undefined)
    const [periodicity, setPeriodicity] = useState(false)
    const [search, setSearch] = useState("")
    const [visibleOptions, setVisibleOptions] = useState(-1)
    const [equipSelected, setEquipSelected] = useState(false)
    const { equipsForTimeline, loadingEquipsForTimeline, equipmentsSearched, quantity, isFiltered, loadingEquipment } = useSelector(state => state.Equipment)
    const { moduleWork, locations } = useSelector(state => state.Area)
    const { enterprise, dependencys, idUser, fullUser } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const [selectedMonths, setSelectedMonths] = useState({});
    const [initialMonth, setInitialMonth] = useState(false)
    const [equipsToSubmit, setEquipsToSubmit] = useState([])
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentPageFiltered, setCurrentPageFiltered] = useState(-1);
    const [isAllDependencies, setIsAllDependencies] = useState(false)

    const { Option } = Select
    const dispatch = useDispatch()

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    useEffect(() => {
        moduleWork && search !== "" && currentPageFiltered !== -1 && dispatch(EquipmentActions.search(
            {
                "plateOrSerie": search,
                "take": 8,
                "page": currentPageFiltered,
                "moduleWork": moduleWork[0].moduleWork.id,
                "dependency": dependencyS,
                "isForTimeline": true,
                "year": initialMonth
            }))
        setSearch("")
    }, [currentPageFiltered, dispatch, moduleWork, search])

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const {
        register: registerSearchForTimeline,
        handleSubmit: handleSubmitSearchForTimeline,
        setValue: setValueSearchForTimeline,
        watch: watchSearchForTimeline,
        control: controlSearchForTimeline,
        formState: { errors: errorsSearchForTimeline },
        trigger: triggerSearchForTimeline,
        reset: resetSearchForTimeline
    } = useForm({
        defaultValues: {
            search: "",
        }
    })

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    const periodOptions = [
        { value: 'mensual', label: 'Mensual', increment: 1 },
        { value: 'bimestral', label: 'Bimestral', increment: 2 },
        { value: 'trimestral', label: 'Trimestral', increment: 3 },
        { value: 'cuatrimestral', label: 'Cuatrimestral', increment: 4 },
        { value: 'Quintimestral', label: 'Quintimestral', increment: 5 },
        { value: 'semestral', label: 'Semestral', increment: 6 }
    ];

    const pageSize = 8
    const totalPages = Math.ceil(quantity / pageSize);

    const onSides = (n) => {
        if (isFiltered) {
            if (currentPageFiltered < totalPages - 1 & n === 1) {
                setCurrentPageFiltered(prev => prev + n)
            }
            if (currentPageFiltered > 0 & n === -1) {
                setCurrentPageFiltered(prev => prev + n)
            }
        } else {
            if (currentPage < totalPages - 1 & n === 1) {
                setCurrentPage(prev => prev + n)
            }
            if (currentPage > 0 & n === -1) {
                setCurrentPage(prev => prev + n)
            }
        }
    }

    const generateNButtons = (N, onClick, currentPage, totalPages) =>
        [...Array(N).keys()].map(i => (
            currentPage === i ? <div className='radiobuttonSelected'
                key={`Button_${i}`}
                onClick={() => onClick(i)}
            >
                {i + 1}
            </div>
                :
                Math.abs(i - currentPage) <= 2 ?
                    <div className='radiobutton'
                        key={`Button_${i}`}
                        onClick={() => onClick(i)}
                    >
                        {i + 1}
                    </div>
                    :
                    i === totalPages - 1 ?
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            {i + 1}
                        </div>
                        :
                        Math.abs(i - currentPage) === 3 &&
                        <div className='radiobutton'
                            key={`Button_${i}`}
                            onClick={() => onClick(i)}
                        >
                            ...
                        </div>
        ))

    useEffect(() => {
        if (initialMonth && periodicity) {
            const increment = periodOptions.find(
                (option) => option.value === periodicity
            ).increment;
            let currentMonth = initialMonth.clone();
            const newSelectedMonths = {};
            for (let i = currentMonth.month(); i < 12; i += increment) {
                if (currentMonth.isSameOrAfter(initialMonth)) {
                    newSelectedMonths[months[currentMonth.month()]] = true;
                }
                currentMonth.add(increment, 'months');
            }
            setSelectedMonths(newSelectedMonths);
        } else {
            setSelectedMonths({});
        }
    }, [initialMonth, periodicity]);

    useEffect(() => {
        if (isAllDependencies && equipmentsSearched) {
            const arr = Object.keys(selectedMonths).map(key => [key, selectedMonths[key]]);
            let aux = []
            equipmentsSearched.map((d, i) => {
                arr.map((item, index) => {
                    aux.push({
                        "image": d.image,
                        "name": d.name,
                        "brand": d.biomedicEquipment.brand,
                        "modell": d.biomedicEquipment.modell,
                        "serie": d.biomedicEquipment.serie,
                        "licensePlate": d.licensePlate,
                        "month": item[0],
                        "state": d.state,
                        "periodicity": periodicity,
                        "id": d.id,
                    })
                })
            })
            setEquipsToSubmit(prev => [...prev, ...aux])
        }
    }, [equipmentsSearched, isAllDependencies])

    const EquipmentTimeline = ({ data }) => {
        const [image, setImage] = useState(false)

        useEffect(() => {
            fetch(`${apiUrl}/biomedicEquipment/imageById?id=${data.id}`)
                .then(response => response.json())
                .then(data => setImage(data))
                .catch(error => console.error(error))
        }, [])

        return (
            <div className="EquipTimeline">
                {image ?
                    <img className='equipmentImage' src={image.image} alt='img' />
                    :
                    <div style={{ marginRight: '5vw' }}></div>
                }
                <div className='dataDiv' onClick={() => {
                    const arr = Object.keys(selectedMonths).map(key => [key, selectedMonths[key]]);
                    let aux = []
                    arr.map((item, index) => {
                        aux.push({
                            "image": image.image,
                            "name": data.name,
                            "brand": data.biomedicEquipment.brand,
                            "modell": data.biomedicEquipment.modell,
                            "serie": data.biomedicEquipment.serie,
                            "licensePlate": data.licensePlate,
                            "month": item[0],
                            "state": data.state,
                            "periodicity": periodicity,
                            "id": data.id,
                        })
                    })
                    setEquipsToSubmit(prev => [...prev, ...aux])
                    setIsAllDependencies(false)
                }}>
                    <div><p className='pFontData name'>{data.name}</p></div>
                    <div><p className='pFontData'>{data.biomedicEquipment.brand}</p></div>
                    <div><p className='pFontData'>{data.biomedicEquipment.modell}</p></div>
                    <div><p className='pFontData'>{data.biomedicEquipment.serie}</p></div>
                    <div><p className='pFontData'>{data.licensePlate}</p></div>
                    <div><p className='pFontData'>{data.biomedicEquipment.location}</p></div>
                    {data.state === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                        : data.state === "No funcional" ? <div className='status1'><p className='pFontStatus'>{data.state}</p></div>
                            : data.state === "Dado de baja" && <div className='status2'><p className='pFontStatus'>Baja</p></div>}
                </div>
            </div>
        )
    }

    return (
        <div className="FormTimeline">
            <div className="filtersAndEquips">
                <div className="filters">
                    <p className='inventoryText'>Programar MPP</p>
                    <div className="fils">
                        <Select
                            className='select'
                            placeholder="Sede"
                            value={headquarter}
                            onChange={event => setHeadquarter(event)}
                            color='primary'
                        >
                            <Option value={null}>Sede principal</Option>
                            {headquarters && headquarters.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                        <Select
                            className='select'
                            placeholder="Area"
                            value={areaSelected}
                            onChange={event => setAreaSelected(event)}
                            color='primary'
                        >
                            {dependencys && areas.map(d => (
                                <Option key={d.id} value={d.id} >{d.name}</Option>
                            ))}
                        </Select>
                        {/* <Select
                            className='select'
                            placeholder='Ubicación'
                            value={location}
                            onChange={event => setLocation(event)}
                            color='primary'
                        >
                            {locations && locations.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select> */}
                        <Select
                            className='select'
                            placeholder="Dependencia"
                            value={dependencyS}
                            onChange={event => setDependencyS(event)}
                            color='primary'
                        >
                            {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                        <Select
                            className='select'
                            onChange={(value) => setPeriodicity(value)}
                            placeholder="Periodicidad"
                        >
                            {periodOptions.map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="divDate">
                            <p className="label">* Mes de inicio</p>
                            <ConfigProvider locale={esES} style={{ width: '16.2vw', height: '2vw' }}>
                                <DatePicker picker="month" onChange={(e) => {
                                    setInitialMonth(e)
                                    dispatch(EquipmentActions.setEquipmentsSearched(false))
                                }} />
                            </ConfigProvider>
                        </div>
                    </div>
                    <p>Meses programados</p>
                    <div className="row" style={{ marginTop: "1vw", width: '100%' }}>
                        <table style={{ borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    {months.map((month) => (
                                        <th key={month} style={{ border: '1px solid black', padding: '2px', fontSize: '0.645vw' }}>
                                            {month}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {months.map((month) => (
                                        <td key={month} style={{ border: '1px solid black', padding: '2px', textAlign: 'center' }}>
                                            <input
                                                type="checkbox"
                                                disabled={true}
                                                checked={selectedMonths[month] || false}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="buttons">
                        <div
                            className="green"
                            onClick={() => {
                                if (moduleWork && initialMonth && periodicity && dependencyS) {
                                    setEquipsToSubmit([])
                                    dispatch(EquipmentActions.search(
                                        {
                                            "take": 0,
                                            "page": 0,
                                            "moduleWork": moduleWork[0].moduleWork.id,
                                            "dependency": dependencyS,
                                            "isForTimeline": true,
                                            "year": initialMonth
                                        }))
                                    setIsAllDependencies(true)
                                } else {
                                    message.warn("Debe elegir un módulo, mes de inicio, periodicidad y dependencia")
                                }
                            }}>
                            <PlusOutlined className='icon' />
                            <p>Todos Equipos Dependencia</p>
                        </div>
                        {fullUser.canCreate &&
                            <div className="yellow" onClick={() => {
                                let aux = []
                                equipsToSubmit.map(d => {
                                    aux.push({
                                        "month": d.month,
                                        "location": d.location,
                                        "dbState": true,
                                        "periodicity": d.periodicity,
                                        "state": "Programado",
                                        "equipment": d.id,
                                        "headquarter": d.headquarter === "null" ? null : d.headquarter,
                                        "user": idUser
                                    })
                                })
                                if (aux.length > 0) {
                                    dispatch(TimelineActions.createMany(aux))
                                    history.push("/MPP")
                                } else {
                                    message.warn("No hay nada para programar")
                                }
                            }}>
                                <SettingOutlined className='icon' />
                                <p>Programar Mantenimiento</p>
                            </div>}
                    </div>
                </div>
                <div className="divEquips">
                    <form onSubmit={handleSubmitSearchForTimeline(e => {
                        if (moduleWork[0] && initialMonth && periodicity) {
                            setSearch(e.search)
                            setCurrentPageFiltered(0)
                            /* dispatch(EquipmentActions.search(
                                {
                                    "plateOrSerie": e.search,
                                    "take": 8,
                                    "page": 0,
                                    "moduleWork": moduleWork[0].moduleWork.id,
                                    "dependency": dependencyS,
                                })) */
                        } else {
                            message.warn("Debe elegir un módulo, mes de inicio y periodicidad")
                        }
                    })}>
                        <p style={{ fontSize: '0.9vw', color: '#FDB600' }}>Saldrán equipos que no tengan cronograma del año seleccionado</p>
                        <Input
                            {...registerSearchForTimeline(`search`, {
                                validate: (value) => {
                                    const errors = [];
                                    if (!value || value === '') {
                                        errors.push("El buscar es requerido");
                                    }
                                    return errors.length === 0 || errors.join(', ');
                                },
                            })}
                            height={'2vw'}
                            mr={'1vw'}
                            mt={'0.125vw'}
                            width={'18vw'}
                            va={watchSearchForTimeline(`search`)}
                            setData={e => setValueSearchForTimeline(`search`, e)}
                            label='Buscar'
                            labelstyle='inputLabelBordered'
                            error={errorsSearchForTimeline[`search`] && errorsSearchForTimeline[`search`].message}
                        />
                        {loadingEquipment && <LoadingOutlined />}
                    </form>
                    <div className="equipsSearched">
                        {equipmentsSearched && equipmentsSearched.map((d, i) => (<EquipmentTimeline data={d} key={i} />))}
                    </div>
                    <div>
                        {!isAllDependencies &&
                            <div className='showingText'>
                                {!isFiltered
                                    ? `Mostrando ${(currentPage * pageSize) + 1}-${Math.min((currentPage + 1) * pageSize, quantity)} de ${quantity} elementos`
                                    : `Mostrando ${(currentPageFiltered * pageSize) + 1}-${Math.min((currentPageFiltered + 1) * pageSize, quantity)} de ${quantity} elementos`
                                }
                            </div>}
                        {!isAllDependencies &&
                            <div className='divButtonsPages'>
                                <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
                                {!isFiltered ?
                                    <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                        {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
                                    </Radio.Group>
                                    :
                                    <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
                                        {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
                                    </Radio.Group>
                                }
                                <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
                            </div>}
                    </div>
                </div>
            </div>

            <p style={{ marginTop: '1vw', fontSize: '1.1vw', color: '#000000B2', marginBottom: '0' }}>Equipos en lista a programar</p>
            <hr style={{ backgroundColor: '#0000004D', width: '80vw' }}>
            </hr>
            <div className="columnsNameFormTimeline">
                <p>Imagen</p>
                <p style={{ marginLeft: "1vw" }}>Nombre</p>
                <p>Marca</p>
                <p>Modelo</p>
                <p>Serie</p>
                <p>Placa</p>
                <p>Periodiocidad</p>
                <p style={{ marginRight: "1vw" }}>Estado</p>
                <p style={{ marginRight: "2vw" }}>Fecha</p>
            </div>
            {equipsToSubmit.length > 0 && equipsToSubmit.map((d, i) => (
                <div className='equips' key={i}>
                    <img className='equipmentImage' src={d.image} alt='img' />
                    <div className='dataDiv' >
                        <div><p className='pFontData name'>{d.name}</p></div>
                        <div><p className='pFontData'>{d.brand}</p></div>
                        <div><p className='pFontData'>{d.modell}</p></div>
                        <div><p className='pFontData'>{d.serie}</p></div>
                        <div><p className='pFontData'>{d.licensePlate}</p></div>
                        <div><p className='pFontData'>{d.periodicity}</p></div>
                        {d.state && (d.state === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                            : d.state === "No funcional" ? <div className='status1'><p className='pFontStatus'>{d.state}</p></div>
                                : d.state === "Dado de baja" ? <div className='status2'><p className='pFontStatus'>Baja</p></div>
                                    : <div className='status3'><p className='pFontStatus'>PDF</p></div>)}
                        <div><p className='pFontData'>{d.month}</p></div>
                    </div>
                    <div className="deleteDiv">
                        <CloseOutlined className='icon' onClick={() => {
                            setEquipsToSubmit(prev => prev.filter(item => item.id !== d.id))
                        }} />
                    </div>
                </div>
            ))}

        </div>
    )
}