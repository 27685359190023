import { Image, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import ArimoBold from '../../assets/fonts/Arimo-Bold.ttf';

Font.register({
    family: 'Arimo-Bold',
    src: ArimoBold,
});

export const PdfIndustrialEquipment = ({ fullDataEquipment, data, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }
    console.log(fullDataEquipment)
    const blueprintsFS = 28 - fullDataEquipment.industrialEquipment.blueprints.length * 3
    const manualsFS = 28 - fullDataEquipment.industrialEquipment.manuals.length * 3

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            alignItems: 'center'
        },
        header: {
            border: '3px solid #000',
            marginTop: '3%',
            width: '95%',
            height: '8%',
            flexDirection: 'row'
        },
        headersub0: {
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '3px solid #000'
        },
        headersub1: {
            width: '60%',
            borderRight: '3px solid #000'
        },
        headerRow: {
            height: '70%',
            width: '100%',
            borderBottom: '3px solid #000',
            alignItems: 'center',
            justifyContent: 'center'
        },
        headerRow1: {
            height: '30%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        bigText: {
            fontSize: '32px'
        },
        text: {
            fontSize: '18px'
        },
        generalInfo: {
            border: '3px solid #000',
            marginTop: '1%',
            width: '93%',
            height: '18%',
            flexDirection: 'row'
        },
        viewImg: {
            width: '25%',
            borderRight: '3px solid #000'
        },
        viewData: {
            width: '75%',
            flexDirection: 'column'
        }

    });

    return (
        <Page size="A1" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headersub0}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={styles.headersub1}>
                    <View style={styles.headerRow}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>E.S.E HOSPITAL MALVINAS HECTOR OROZCO OROZCO</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>FLORENCIA-CAQUETA</Text>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>NIT: E.S.E HOSPITAL MALVINAS HECTOR OROZCO OROZCO</Text>
                    </View>
                    <View style={styles.headerRow1}>
                        <Text style={{ fontFamily: 'Arimo-Bold', fontSize: '25px' }}>HOJA DE VIDA-EQUIPO DE TECNOLOGIA DE APOYO</Text>
                    </View>
                </View>
                <View style={styles.headersub0}>
                    <View style={styles.headerRow}>
                        <View style={{ width: '100%', height: '60%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>CODIGO</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>FT-VH-TA-01</Text>
                            </View>
                        </View>
                        <View style={{ width: '100%', height: '40%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>VERSION</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>1</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.headerRow1}>
                        <View style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '40%', borderRight: '3px solid #000', justifyContent: 'center' }}>
                                <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold', marginLeft: '2%' }}>PAGINAS</Text>
                            </View>
                            <View style={{ height: '100%', width: '60%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold', textAlign: 'center' }}>1 DE 1</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            <View style={{ width: '95%', height: '5%', border: '5px solid #000', marginTop: '0.7%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: '24px', fontFamily: 'Arimo-Bold' }}>IDENTIFICACION:</Text>
                <Text style={{ fontSize: '21px', }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Nombre:</Text> E.S.E HOSPITAL MALVINAS HECTOR OROZCO OROZCO <Text style={{ fontFamily: 'Arimo-Bold' }}>Departamento:</Text> CAQUETA. <Text style={{ fontFamily: 'Arimo-Bold' }}>Municipio:</Text> FLORENCIA</Text>
                <Text style={{ fontSize: '21px', }}><Text style={{ fontFamily: 'Arimo-Bold' }}>Dirección:</Text> AVENIDA CIRCUNVALAR CALLE 4 BARRIO LAS MALVINAS <Text style={{ fontFamily: 'Arimo-Bold' }}>Telefono:</Text> 4352100</Text>
            </View>

            <View style={{ width: '95%', height: '6.5%', border: '3px solid #000', borderBottom: 0, marginTop: '0.4%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={{ width: '100%', height: '32%', borderBottom: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold' }}>REGISTRO</Text>
                </View>
                <View style={{ width: '100%', height: '34%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '13%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '21px', fontFamily: 'Arimo-Bold' }}>Nombre del Equipo</Text>
                    </View>
                    <View style={{ width: '32%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.name}</Text>
                    </View>
                    <View style={{ width: '10%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>BTU</Text>
                    </View>
                    <View style={{ width: '13%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.btu + " "}</Text>
                    </View>
                    <View style={{ width: '12%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Marca</Text>
                    </View>
                    <View style={{ width: '20%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.brand + " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '34%', display: 'flex', flexDirection: 'row', borderBottom: '3px solid #000' }}>
                    <View style={{ width: '13%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Modelo</Text>
                    </View>
                    <View style={{ width: '16%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.modell + " "}</Text>
                    </View>
                    <View style={{ width: '5%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Tipo</Text>
                    </View>
                    <View style={{ width: '11%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.type + " "}</Text>
                    </View>
                    <View style={{ width: '10%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Serie</Text>
                    </View>
                    <View style={{ width: '25%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.serie + " "}</Text>
                    </View>
                    <View style={{ width: '10%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Inventario</Text>
                    </View>
                    <View style={{ width: '10%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.licensePlate + " "}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '34%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '13%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Area</Text>
                    </View>
                    <View style={{ width: '21%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.dependency.area.name}</Text>
                    </View>
                    <View style={{ width: '11%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Dependencia</Text>
                    </View>
                    <View style={{ width: '23%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px' }}>{fullDataEquipment.dependency.name}</Text>
                    </View>
                    <View style={{ width: '12%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Ubicación</Text>
                    </View>
                    <View style={{ width: '20%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.location.name}</Text>
                    </View>
                </View>
            </View>
            <View style={{ width: '95%', height: '20%', border: '3px solid #000', borderTop: 0, display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '50%', height: '100%', borderTop: 0, marginTop: '0.5%' }}>
                    <View style={{ width: '100%', height: '10%', border: '3px solid #000', borderLeft: 0, borderBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>REGISTRO TÉCNICO</Text>
                    </View>
                    <View style={{ width: '100%', height: '10%', border: '3px solid #000', borderLeft: 0, display: 'flex', flexDirection: 'row', }}>
                        <View style={{ width: '25%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Inicio garantía</Text></View>
                        <View style={{ width: '22.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.startWarrantyDate ? padStr(new Date(fullDataEquipment.industrialEquipment.startWarrantyDate).getDate()) + "/" + padStr(1 + new Date(fullDataEquipment.industrialEquipment.startWarrantyDate).getMonth()) + "/" + padStr(new Date(fullDataEquipment.industrialEquipment.startWarrantyDate).getFullYear()) : ''}</Text></View>
                        <View style={{ width: '30%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '22px', fontFamily: 'Arimo-Bold' }}>Vencimiento garantía</Text></View>
                        <View style={{ width: '22.5%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.endWarrantyDate ? padStr(new Date(fullDataEquipment.industrialEquipment.endWarrantyDate).getDate()) + "/" + padStr(1 + new Date(fullDataEquipment.industrialEquipment.endWarrantyDate).getMonth()) + "/" + padStr(new Date(fullDataEquipment.industrialEquipment.endWarrantyDate).getFullYear()) : ''}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10%', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '47.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Fecha de adquisición</Text></View>
                        <View style={{ width: '52.5%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.adquisitionDate ? padStr(new Date(fullDataEquipment.industrialEquipment.adquisitionDate).getDate()) + "/" + padStr(1 + new Date(fullDataEquipment.industrialEquipment.adquisitionDate).getMonth()) + "/" + padStr(new Date(fullDataEquipment.industrialEquipment.adquisitionDate).getFullYear()) : ''}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10%', border: '3px solid #000', borderTop: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '47.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Fecha de instalación</Text></View>
                        <View style={{ width: '52.5%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.instalationDate ? padStr(new Date(fullDataEquipment.industrialEquipment.instalationDate).getDate()) + "/" + padStr(1 + new Date(fullDataEquipment.industrialEquipment.instalationDate).getMonth()) + "/" + padStr(new Date(fullDataEquipment.industrialEquipment.instalationDate).getFullYear()) : ''}</Text></View>
                    </View>

                    <View style={{ width: '100%', height: '10.9%', border: '3px solid #000', borderBottom: 0, borderLeft: 0, display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                        <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Voltaje de operación: </Text></View>
                        <View style={{ width: '32.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{"Max: " + fullDataEquipment.industrialEquipment.operatingVoltageMax + " Volt"}</Text></View>
                        <View style={{ width: '32.5%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px' }}>{"min: " + fullDataEquipment.industrialEquipment.operatingVoltageMin + " Volt"}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10.9%', border: '3px solid #000', borderBottom: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Corriente Operación: </Text></View>
                        <View style={{ width: '32.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{"Max: " + fullDataEquipment.industrialEquipment.operatingCurrentMax + " Amp"}</Text></View>
                        <View style={{ width: '32.5%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px' }}>{"min: " + fullDataEquipment.industrialEquipment.operatingCurrentMin + " Amp"}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10.9%', border: '3px solid #000', borderBottom: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Potencia consumida: </Text></View>
                        <View style={{ width: '32.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.powerConsumed + " Watios"}</Text></View>
                        <View style={{ width: '15.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Peso: </Text></View>
                        <View style={{ width: '17%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px' }}>{fullDataEquipment.industrialEquipment.weight}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10.9%', border: '3px solid #000', borderBottom: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Frecuencia Hertz: </Text></View>
                        <View style={{ width: '32.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.hertzFrequency + " "}</Text></View>
                        <View style={{ width: '15.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Presión: </Text></View>
                        <View style={{ width: '17%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px' }}>{fullDataEquipment.industrialEquipment.pressure + " "}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10.9%', border: '3px solid #000', borderBottom: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '35%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Velocidad: </Text></View>
                        <View style={{ width: '32.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.speed + " m/min"}</Text></View>
                        <View style={{ width: '15.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '18px', fontFamily: 'Arimo-Bold' }}>Temperatura: </Text></View>
                        <View style={{ width: '17%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px' }}>{fullDataEquipment.industrialEquipment.temperature + " "}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '12%', border: '3px solid #000', borderBottom: 0, borderLeft: 0, display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '67.5%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Fuente de alimentación: </Text></View>
                        <View style={{ width: '32.5%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.typePowerAdapter + " "}</Text></View>
                    </View>
                </View>
                <View style={{ width: '50%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image style={{ width: '90%', height: '90%' }} src={fullDataEquipment.industrialEquipment.image}></Image>
                </View>
            </View>

            <View style={{ width: '95%', height: '6.5%', border: '3px solid #000', borderBottom: 0, marginTop: '0.4%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={{ width: '100%', height: '32%', borderBottom: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '25px', fontFamily: 'Arimo-Bold' }}>REGISTRO DE APOYO TÉCNICO</Text>
                </View>
                <View style={{ width: '100%', height: '34%', borderBottom: '3px solid #000', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Uso:</Text>
                    </View>
                    <View style={{ width: '31%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.use.toUpperCase()}</Text>
                    </View>
                    <View style={{ width: '18%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Clasificación:</Text>
                    </View>
                    <View style={{ width: '31%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '25px' }}>{fullDataEquipment.industrialEquipment.clasification && fullDataEquipment.industrialEquipment.clasification.toUpperCase()}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', height: '34%', display: 'flex', flexDirection: 'row', borderBottom: '3px solid #000' }}>
                    <View style={{ width: '20%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Manuales:</Text>
                    </View>
                    <View style={{ width: '31%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: `${manualsFS}px` }}>{fullDataEquipment.industrialEquipment.manuals.length > 0 && fullDataEquipment.industrialEquipment.manuals.map((d, i, arr) => i !== arr.length - 1 ? d.toUpperCase() + ", " : d.toUpperCase())}</Text>
                    </View>
                    <View style={{ width: '18%', height: '100%', borderRight: '3px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: '23px', fontFamily: 'Arimo-Bold' }}>Planos:</Text>
                    </View>
                    <View style={{ width: '31%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: `${blueprintsFS}px` }}>{fullDataEquipment.industrialEquipment.blueprints.length > 0 && fullDataEquipment.industrialEquipment.blueprints.map((d, i, arr) => i !== arr.length - 1 ? d.toUpperCase() + ", " : d.toUpperCase())}</Text>
                    </View>
                </View>
            </View>

            <View style={{ marginTop: '0%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <Image style={{ width: '30%', height: '150%' }} src={fullDataEquipment.dependency.dependencyUser[0].user.person.firm ? fullDataEquipment.dependency.dependencyUser[0].user.person.firm : blanco}></Image> */}
                <Image style={{ width: '25%', height: '100%' }} src={(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person.firm) ? fullDataEquipment.userCreator.person.firm : blanco}></Image>
            </View>

            <View style={{ marginTop: '1%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View> */}
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            fullDataEquipment.dependency.dependencyUser[0].user.person.firm && fullDataEquipment.dependency.dependencyUser[0].user.person.name + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.secondName + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.lastName + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.secondLastName
                        }
                    </Text> */}
                <Text
                    style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person) &&
                        [
                            fullDataEquipment.userCreator.person.name,
                            fullDataEquipment.userCreator.person.secondName,
                            fullDataEquipment.userCreator.person.lastName,
                            fullDataEquipment.userCreator.person.secondLastName
                        ].filter(Boolean).join(' ')
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.position
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <Text style={{ fontSize: '29px', marginTop: '0.7%' }}>JEFE DE DEPENDENCIA</Text> */}
                <Text style={{ fontSize: '24px', marginTop: '0.1%', fontFamily: 'Arimo-Bold' }}>AGENTE</Text>
            </View>
        </Page >
    )
}