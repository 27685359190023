import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { SummaryActions } from './SummarySlice'
import { TimelineActions } from '../Timeline/TimelineSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(SummaryActions.setMunicipalitys(muns.payload))
  }
  yield put(SummaryActions.setLoading(false))
} */

function* getLastSummaryNumber({ payload }) {
  const lastSummaryNumber = yield Api.get(`/summary/getLastSummaryNumberByModuleWork?id=${payload}`)
  if (lastSummaryNumber.ok) {
    yield put(SummaryActions.setLastSummaryNumber(lastSummaryNumber.payload))
  }
}

function* createSummary({ payload }) {
  const summary = yield Api.post(`/summary`, payload)
  if (summary.ok) {
    yield put(SummaryActions.setSummaryCreated(summary.payload))
    message.success(summary.payload.message)
  } else {
    message.error(summary.payload.message)
  }
  yield put(SummaryActions.setLoadingCreateSummary(false))
}

function* getSummaries({ payload }) {
  const summaries = yield Api.get(`/summary/getSummaryByModuleWorkAndUser?id=${payload.moduleWork}&userCreator=${payload.userCreator}`)
  if (summaries.ok) {
    yield put(SummaryActions.setSummaries(summaries.payload))
  } else {
    message.error("Error al obtener los informes")
  }
}

function* getSummarySearched({ payload }) {
  const summary = yield Api.get(`/summary/getFullDataSummary?id=${payload.id}&moduleWork=${payload.moduleWork}`)
  if (summary.ok) {
    yield put(SummaryActions.setSummarySearched(summary.payload))
  } else {
    message.error("Error al obtener el informe")
  }
  yield put(SummaryActions.setLoadingSummarySearched(false))
}

function* deleteSummary({ payload }) {
  const summary = yield Api.post(`/summary/deleteSummary?id=${payload}`)
  if (summary.ok) {
    message.success("Informe eliminado")
  } else {
    message.error("Error al eliminar el informe")
  }
}

function* editSummary({ payload }) {
  const summary = yield Api.post(`/summary/editSummary`, payload)
  if (summary.ok) {
    message.success(summary.payload.message)
  } else {
    message.error(summary.payload.message)
  }
  yield put(SummaryActions.setLoadingCreateSummary(false))
}

function* actionWatcher() {
  yield takeLatest(SummaryActions.getLastSummaryNumber, getLastSummaryNumber)
  yield takeLatest(SummaryActions.createSummary, createSummary)
  yield takeLatest(SummaryActions.getSummaries, getSummaries)
  yield takeLatest(SummaryActions.getSummarySearched, getSummarySearched)
  yield takeLatest(SummaryActions.deleteSummary, deleteSummary)
  yield takeLatest(SummaryActions.editSummary, editSummary)
}

export default function* SummarySaga() {
  yield all([actionWatcher()])
}