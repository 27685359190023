import { Document, Image, Page, PDFViewer, Text, View, StyleSheet } from "@react-pdf/renderer";
import blanco from '../../assets/images/blanco.png'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfInventory = ({ fullDataEquipment, data, enterprise }) => {

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            alignItems: 'center'
        },
        header: {
            border: '3px solid #000',
            marginTop: '3%',
            width: '95%',
            height: '8%',
            flexDirection: 'row'
        },
        headersub0: {
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '3px solid #000'
        },
        headersub1: {
            width: '75%',
            borderRight: '3px solid #000'
        },
        headerRow: {
            height: '50%',
            width: '100%',
            borderBottom: '3px solid #000',
            alignItems: 'center',
            justifyContent: 'center'
        },
        headerRow1: {
            height: '50%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        bigText: {
            fontSize: '32px'
        },
        text: {
            fontSize: '24px'
        },
        generalInfo: {
            border: '3px solid #000',
            marginTop: '1%',
            width: '93%',
            height: '18%',
            flexDirection: 'row'
        },
        viewImg: {
            width: '25%',
            borderRight: '3px solid #000'
        },
        viewData: {
            width: '75%',
            flexDirection: 'column'
        }

    });

    return (
        <Page size="A1" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headersub0}>
                    <Image style={{ width: '60%', height: '60%' }} src={enterprise[0].image}></Image>
                </View>
                <View style={styles.headersub1}>
                    <View style={styles.headerRow}><Text style={styles.bigText}>PROCESO GESTION ADMINISTRACIÓN DEL RIESGO</Text></View>
                    <View style={styles.headerRow1}><Text style={styles.text}>Hoja de vida de Equipos</Text></View>
                </View>
                <View style={styles.headersub0}>
                    <View style={styles.headerRow}><Text style={styles.bigText}>{" "}</Text></View>
                    <View style={styles.headerRow1}><Text style={styles.bigText}>{" "}</Text></View>
                </View>
            </View>

            <View style={{ border: '3px solid #000', marginTop: '1%', width: '93%', height: '2%', flexDirection: 'row' }}>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '16%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>CODIGO DEL SECTOR:</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '8%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>DPTO:</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>1</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>8</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '7%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>MUNICIPIO:</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>0</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>0</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>1</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '5%', backgroundColor: '#95b3d7', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>NIT:</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>8</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>2</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>8</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>0</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>0</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>0</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>3</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>8</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>6</Text>
                </View>
                <View style={{ borderRight: '3px solid #000', height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>-</Text>
                </View>
                <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.text}>1</Text>
                </View>
            </View>

            <View style={styles.generalInfo}>
                <View style={styles.viewImg}><Image style={{ width: '100%', height: '100%' }} src={{ uri: fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.image ? fullDataEquipment.biomedicEquipment.image : blanco}}></Image></View>
                <View style={styles.viewData}>
                    <View style={{ width: '100%', height: '16%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                        <View style={{ width: '20%', height: '100%', backgroundColor: '#95b3d7', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>ACTIVO EQUIPO</Text></View>
                        <View style={{ width: '30%', height: '100%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>{fullDataEquipment.biomedicEquipment &&
                            fullDataEquipment.biomedicEquipment.active ?
                            fullDataEquipment.biomedicEquipment.active + " " :
                            fullDataEquipment.licensePlate && fullDataEquipment.licensePlate + " "
                        }</Text></View>
                        <View style={{ width: '20%', height: '100%', backgroundColor: '#95b3d7', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>NOMBRE DEL EQUIPO</Text></View>
                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>{fullDataEquipment.name + " "}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '14%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>ACTIVO:</Text></View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>DD</Text></View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>MM</Text></View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>AA</Text></View>
                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>MARCA: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.brand + " "}</Text></View>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>SERIE: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.serie + " "}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '15%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>FECHA DE COMPRA</Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionDate).toLocaleDateString('es-CO', { day: 'numeric' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionDate).toLocaleDateString('es-CO', { month: 'long' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionDate).toLocaleDateString('es-CO', { year: 'numeric' }) : ''}
                            </Text>
                        </View>

                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>MODELO: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.modell}</Text></View>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>SERVICIO: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.service}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '15%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>FECHA DE INSTALACIÓN</Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.instalationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.instalationDate).toLocaleDateString('es-CO', { day: 'numeric' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.instalationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.instalationDate).toLocaleDateString('es-CO', { month: 'long' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.instalationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.instalationDate).toLocaleDateString('es-CO', { year: 'numeric' }) : ''}
                            </Text>
                        </View>

                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>REGISTRO SANITARIO: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.healthRegister + " "}</Text></View>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>AÑO FABRICACION: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.fabricationDate ? new Date(fullDataEquipment.biomedicEquipment.fabricationDate).toLocaleDateString('es-CO', { year: 'numeric' }) : ''}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '15%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>FECHA DE INICIO DE OPERACIÓN</Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.startOperationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.startOperationDate).toLocaleDateString('es-CO', { day: 'numeric' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.startOperationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.startOperationDate).toLocaleDateString('es-CO', { month: 'long' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.startOperationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.startOperationDate).toLocaleDateString('es-CO', { year: 'numeric' }) : ''}
                            </Text>
                        </View>

                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>UBICACION: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.location + " "}</Text></View>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>REPRESENTANTE: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.representative + " "}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '15%', flexDirection: 'row', borderBottom: '2px solid #000' }}>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>FECHA VENCE GARANTIA</Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.warrantyExpirationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.warrantyExpirationDate).toLocaleDateString('es-CO', { day: 'numeric' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.warrantyExpirationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.warrantyExpirationDate).toLocaleDateString('es-CO', { month: 'long' }) : ''}
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}>
                            <Text style={styles.text}>
                                {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.warrantyExpirationDate ? new Date(fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.warrantyExpirationDate).toLocaleDateString('es-CO', { year: 'numeric' }) : ''}
                            </Text>
                        </View>

                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>FABRICANTE: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maker + " "}</Text></View>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>CIUDAD: {fullDataEquipment.municipality ? fullDataEquipment.municipality.nombre + " " : " "}</Text></View>
                    </View>
                    <View style={{ width: '100%', height: '10%', flexDirection: 'row' }}>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>COSTO</Text></View>
                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.cost + " "}</Text></View>
                        <View style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #000' }}><Text style={styles.text}>PAGINA WEB: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.webPage + " "}</Text></View>
                        <View style={{ width: '20%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>PAIS: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.makerCountry + " "}</Text></View>
                    </View>
                </View>
            </View>

            <View style={{ border: '3px solid #000', marginTop: '0.1%', width: '93%', height: '17%' }}>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '16%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '20%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#95b3d7' }}><Text style={styles.text}>FORMA DE ADQUISICIÓN</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '36%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#95b3d7' }}><Text style={styles.text}>REGISTRO TÉCNICO</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '21%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#95b3d7' }}><Text style={styles.text}>FUENTES DE ALIMENTACIÓN</Text></View>
                    <View style={{ height: '100%', width: '23%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#95b3d7' }}><Text style={styles.text}>CLASIFICACIÓN DEL RIESGO</Text></View>
                </View>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '17%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>COMPRA DIRECTA</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '8%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionWay === 'Compra directa' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE VOLTAJE: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.voltageRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '17.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE PRESION: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.pressureRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '21%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>AGUA: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.powerSource === 'Agua' && <Text style={{ fontSize: '23px' }}>X</Text>}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>I BAJO</Text></View>
                    <View style={{ height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.riskClasification === 'I Bajo' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                </View>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '17%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>DONACION DE PARTICULARES</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '8%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionWay === 'Donación de Particulares' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE CORRIENTE: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.currentRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '17.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE VELOCIDAD: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.speedRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '21%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>AIRE: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.powerSource === 'Aire' && <Text style={{ fontSize: '23px' }}>X</Text>}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>IIA MODERADO</Text></View>
                    <View style={{ height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.riskClasification === 'IIA Moderado' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                </View>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '17%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>DONACIÓN DE BENEFICIENCIA</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '8%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionWay === 'Donación de Beneficiencia' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE POTENCIA: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.powerRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '17.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE HUMEDAD: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.humidityRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '21%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>VAPOR: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.powerSource === 'Vapor' && <Text style={{ fontSize: '23px' }}>X</Text>}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>IIB ALTO</Text></View>
                    <View style={{ height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.riskClasification === 'IIB Alto' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                </View>
                <View style={{ borderBottom: '3px solid #000', width: '100%', height: '17%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>ASIGNADO POR EL GOBIERNO</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '8%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionWay === 'Asignado por el gobierno' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>FRECUENCIA: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.frequency + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '17.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>RANGO DE TEMPERATURA: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.temperatureRange + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '21%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>ELECTRICIDAD: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.powerSource === 'Electricidad' && <Text style={{ fontSize: '23px' }}>X</Text>}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>III MUY ALTO</Text></View>
                    <View style={{ height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.riskClasification === 'III Muy alto' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                </View>
                <View style={{ width: '100%', height: '17%', flexDirection: 'row' }}>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '12%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>COMODATO</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '8%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.adquisitionWay === 'Comodato' && <Text style={{ fontSize: '23px' }}>X</Text>}</View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '18.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>PESO: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.weight + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '17.5%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>DIMENSIONES: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.dimensions + " "}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '21%', justifyContent: 'center', alignItems: 'center' }}><Text style={styles.text}>BATERIAS: {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.powerSource === 'Baterias' && <Text style={{ fontSize: '23px' }}>X</Text>}</Text></View>
                    <View style={{ borderRight: '3px solid #000', height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '11.5%', justifyContent: 'center', alignItems: 'center', }}></View>
                </View>
            </View>

            <View style={{ backgroundColor: '#95b3d7', width: '93%', height: '3%', border: "3px solid #000", alignItems: 'center', justifyContent: 'center', marginTop: '0.8%' }}><Text>COMPONENTES DEL EQUIPO</Text></View>
            <View style={{ width: '93%', height: '3%', border: "3px solid #000", borderTop: 0, flexDirection: 'row' }}>
                <View style={{ height: '100%', width: '28%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>NOMBRE</Text></View>
                <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MARCA</Text></View>
                <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>REFERENCIA</Text></View>
                <View style={{ height: '100%', width: '24%', justifyContent: 'center', alignItems: 'center' }}><Text>No. PARTE</Text></View>
            </View>
            {fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicComponent && fullDataEquipment.biomedicEquipment.biomedicComponent.map(d => (
                <View key={d.id} style={{ width: '93%', height: '2%', border: "3px solid #000", borderTop: 0, flexDirection: 'row' }}>
                    <View style={{ height: '100%', width: '28%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>{d.name + " "}</Text></View>
                    <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>{d.brand + " "}</Text></View>
                    <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>{d.reference + " "}</Text></View>
                    <View style={{ height: '100%', width: '24%', justifyContent: 'center', alignItems: 'center' }}><Text>{d.partNumber + " "}</Text></View>
                </View>
            ))}
            <View style={{ border: '3px solid #000', marginTop: '0.8%', width: '93%', height: '15%' }}>
                <View style={{ backgroundColor: '#95b3d7', width: '100%', height: '20%', borderBottom: "3px solid #000", alignItems: 'center', justifyContent: 'center' }}><Text>COMPONENTES DEL EQUIPO</Text></View>
                <View style={{ width: '100%', height: '20%', borderBottom: "3px solid #000", flexDirection: 'row' }}>
                    <View style={{ height: '100%', width: '15%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MANUALES</Text></View>
                    <View style={{ height: '100%', width: '15%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PLANOS</Text></View>
                    <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>USO</Text></View>
                    <View style={{ height: '100%', width: '30%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>CLASIFICACIÓN BIOMEDICA</Text></View>
                    <View style={{ height: '100%', width: '30%', justifyContent: 'center', alignItems: 'center' }}><Text>TECNOLOGÍA PREDOMINANTE</Text></View>
                </View>
                <View style={{ width: '100%', height: '20%', borderBottom: "3px solid #000", flexDirection: 'row' }}>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>OPERACIÓN</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.manual === 'Operacion' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>ELÉCTRICOS</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.plane === 'Electricos' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '7%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MEDICO</Text></View>
                    <View style={{ height: '100%', width: '3%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.use === 'Medico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>DIAGNOSTICO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicClasification === 'Diagnostico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>REHABILITACIÓN</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicClasification === 'Rehabilitacion' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>ELÉCTRICO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Eléctrico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>HIDRÁULICO</Text></View>
                    <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Hidráulico' && <Text>X</Text>}</View>
                </View>
                <View style={{ width: '100%', height: '20%', borderBottom: "3px solid #000", flexDirection: 'row' }}>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MANTENIMIENTO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.manual === 'Mantenimiento' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MECÁNICOS</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.plane === 'Mecanicos' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '7%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>BÁSICO</Text></View>
                    <View style={{ height: '100%', width: '3%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.use === 'Basico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>TRATAMIENTO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicClasification === 'Tratamiento' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PREVENCIÓN</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicClasification === 'Prevencion' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>ELECTRÓNICO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Electrónico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>NEUMÁTICO</Text></View>
                    <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Neumático' && <Text>X</Text>}</View>
                </View>
                <View style={{ width: '100%', height: '20%', borderBottom: "3px solid #000", flexDirection: 'row' }}>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>DESPIECES</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.manual === 'Despieces' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>HIDRÁULICOS</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.plane === 'Hidraulicos' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '7%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>APOYO</Text></View>
                    <View style={{ height: '100%', width: '3%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.use === 'Apoyo' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>ANÁLISIS DE LABORATORIO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicClasification === 'Analisis de laboratorio' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>OTRO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.biomedicClasification === 'Otro' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MÉCANICO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Mecánico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>VAPOR</Text></View>
                    <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Vapor' && <Text>X</Text>}</View>
                </View>
                <View style={{ width: '100%', height: '20%', flexDirection: 'row' }}>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PARTES</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.manual === 'Partes' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>NEUMÁTICOS</Text></View>
                    <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.plane === 'Neumaticos' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '7%', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '3%', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '11%', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '11%', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center', }}></View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>ELECTROMECÁNICO</Text></View>
                    <View style={{ height: '100%', width: '4%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Electromecánico' && <Text>X</Text>}</View>
                    <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>SOLAR</Text></View>
                    <View style={{ height: '100%', width: '4%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.tecnology === 'Solar' && <Text>X</Text>}</View>
                </View>
            </View>
            <View style={{ width: '93%' }}>
                <Text style={{ marginTop: '1%', textAlign: 'left', marginLeft: '5%', fontWeight: 'bold' }}>EVALUACIÓN FISICA Y FUNCIONAL</Text>
            </View>
            <View style={{ border: '3px solid #000', marginTop: '0.8%', width: '93%', height: '15%' }}>
                <View style={{ width: '100%', height: '85%', flexDirection: 'row' }}>
                    <View style={{ width: '65%', height: '100%' }}>
                        <View style={{ flexDirection: 'row', height: '20%', borderBottom: '3px solid #000' }}>
                            <View style={{ width: '34%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#95b3d7', borderRight: '3px solid #000' }}><Text>OPERACIÓN</Text></View>
                            <View style={{ width: '33%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#95b3d7', borderRight: '3px solid #000' }}><Text>MANTENIMIENTO</Text></View>
                            <View style={{ width: '33%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#95b3d7', borderRight: '3px solid #000' }}><Text>SEGURIDAD ELECTRICA</Text></View>
                        </View>
                        <View style={{ height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>FIJO (ANCLADO)</Text></View>
                            <View style={{ height: '100%', width: '6%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.operation === 'Fijo (Anclado)' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PORTATIL</Text></View>
                            <View style={{ height: '100%', width: '6%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.operation === 'Portatil' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '12%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PR</Text></View>
                            <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>CR</Text></View>
                            <View style={{ height: '100%', width: '15%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>CLASE I</Text></View>
                            <View style={{ height: '100%', width: '18%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.electricSecurity === 'Clase I' && <Text>X</Text>}</View>
                        </View>
                        <View style={{ height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>NORMAL</Text></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.operation === 'Normal' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '12%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>CONTRATADO</Text></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maintenance === 'Preventivo-Contratado' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maintenance === 'Correctivo-Contratado' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '15%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>CLASE II</Text></View>
                            <View style={{ height: '100%', width: '18%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.electricSecurity === 'Clase II' && <Text>X</Text>}</View>
                        </View>
                        <View style={{ height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>IRREGULAR</Text></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.operation === 'Irregular' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '12%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PROPIO</Text></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maintenance === 'Preventivo-Propio' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maintenance === 'Correctivo-Propio' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '15%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>CLASE IP</Text></View>
                            <View style={{ height: '100%', width: '18%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.electricSecurity === 'Clase IP' && <Text>X</Text>}</View>
                        </View>
                        <View style={{ height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                            <View style={{ height: '100%', width: '24%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>FUERA DE SERVICIO</Text></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.operation === 'Fuera de servicio' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '12%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>GARANTIA</Text></View>
                            <View style={{ height: '100%', width: '10%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maintenance === 'Preventivo-Garantia' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '11%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.maintenance === 'Correctivo-Garantia' && <Text>X</Text>}</View>
                            <View style={{ height: '100%', width: '15%' }}></View>
                            <View style={{ height: '100%', width: '18%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}></View>
                        </View>
                    </View>
                    <View style={{ width: '35%', height: '100%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                        <View style={{ width: '100%', height: '100%' }}>
                            <View style={{ width: '100%', height: '20%', borderBottom: '3px solid #000' }}></View>
                            <View style={{ width: '100%', height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                                <View style={{ height: '100%', width: '70%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>PROPIO:</Text></View>
                                <View style={{ height: '100%', width: '30%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.equipmentOwner === 'Propio' && <Text>X</Text>}</View>
                            </View>
                            <View style={{ width: '100%', height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                                <View style={{ height: '100%', width: '70%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>MEDICO:</Text></View>
                                <View style={{ height: '100%', width: '30%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.equipmentOwner === 'Medico' && <Text>X</Text>}</View>
                            </View>
                            <View style={{ width: '100%', height: '20%', borderBottom: '3px solid #000', flexDirection: 'row' }}>
                                <View style={{ height: '100%', width: '70%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>FIRMA:</Text></View>
                                <View style={{ height: '100%', width: '30%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.equipmentOwner === 'Firma' && <Text>X</Text>}</View>
                            </View>
                            <View style={{ width: '100%', height: '20%', flexDirection: 'row' }}>
                                <View style={{ height: '100%', width: '70%', borderRight: '3px solid #000', justifyContent: 'center', alignItems: 'center' }}><Text>OTRO:</Text></View>
                                <View style={{ height: '100%', width: '30%', justifyContent: 'center', alignItems: 'center' }}>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.equipmentOwner === 'Otro' && <Text>X</Text>}</View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ width: '100%', height: '15%', flexDirection: 'row' }}>
                    <View style={{ width: '22%', height: '100%', justifyContent: 'center', alignItems: 'center', borderRight: '3px solid #000' }}><Text>CAUSA DE IRREGULARIDAD:</Text></View>
                    <View style={{ width: '78%', height: '100%', justifyContent: 'center', alignItems: 'left', paddingLeft: '1%' }}><Text>{fullDataEquipment.biomedicEquipment && fullDataEquipment.biomedicEquipment.irregularCause + " "}</Text></View>
                </View>
            </View>

            <View style={{ marginTop: '0%', marginBottom: '-5%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <Image style={{ width: '30%', height: '150%' }} src={fullDataEquipment.dependency.dependencyUser[0].user.person.firm ? fullDataEquipment.dependency.dependencyUser[0].user.person.firm : blanco}></Image> */}
                <Image style={{ width: '25%', height: '100%' }} src={(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person.firm) ? fullDataEquipment.userCreator.person.firm : blanco}></Image>
            </View>

            <View style={{ marginTop: '1%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View> */}
                <View style={{ width: '30%', borderBottom: '3px solid #00' }}></View>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <Text
                        style={{ fontSize: '20px', marginTop: '0.4%' }}>
                        {
                            fullDataEquipment.dependency.dependencyUser[0].user.person.firm && fullDataEquipment.dependency.dependencyUser[0].user.person.name + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.secondName + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.lastName + " " +
                            fullDataEquipment.dependency.dependencyUser[0].user.person.secondLastName
                        }
                    </Text> */}
                <Text
                    style={{ fontSize: '20px', marginTop: '0.4%' }}>
                    {(fullDataEquipment.userCreator && fullDataEquipment.userCreator.person.firm) &&
                        fullDataEquipment.userCreator.person.name + " " +
                        fullDataEquipment.userCreator.person.secondName + " " +
                        fullDataEquipment.userCreator.person.lastName + " " +
                        fullDataEquipment.userCreator.person.secondLastName
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.position
                    }
                </Text>
            </View>
            <View style={{ marginTop: '0%', marginBottom: '-2%', width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text
                    style={{ fontSize: '20px', marginTop: '0.1%', color: 'black' }}>
                    {
                        fullDataEquipment.userCreator && fullDataEquipment.userCreator.profesion
                    }
                </Text>
            </View>
            <View style={{ width: '95%', height: '3%', justifyContent: 'space-between', flexDirection: 'row' }}>
                {/* <Text style={{ fontSize: '29px', marginTop: '0.7%' }}>JEFE DE DEPENDENCIA</Text> */}
                <Text style={{ fontSize: '24px', marginTop: '0.1%', fontWeight: 'bold' }}>AGENTE</Text>
            </View>
        </Page>
    )
}