import { Steps, Button, Radio, Select, DatePicker, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Input } from '../Input/Input'
import { CloseOutlined, PlusOutlined, CloseCircleFilled, SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentCard } from '../EquipmentCard/EquipmentCard';
import { CaseReportActions } from '../../services/caseReport/CaseReportSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { ModalSearchEquipment } from '../ModalSearchEquipment/ModalSearchEquipment';


export const FormServiceReport = ({ edit, data }) => {
    const { Step } = Steps;
    const [current, setCurrent] = React.useState(0);
    const [date, setDate] = useState(false)
    const [descriptionAsking, setDescriptionAsking] = useState('')
    const [descriptionAnswer, setDescriptionAnswer] = useState('')
    const [processDescription, setProcessDescription] = useState('')
    const [observations, setObservations] = useState('')
    /* const [agent, setAgent] = React.useState(1) */
    /* const [openAgent, setOpenAgent] = React.useState(false)
    const canvas = useRef(null) */
    const { dependenciesFromBoss, moduleWork } = useSelector(state => state.Area)
    const [serviceType, setServiceType] = React.useState(1)
    const [status, setStatus] = React.useState(1)
    const [statusEquip, setStatusEquip] = useState(1)
    const [reqRepService, setReqRepService] = React.useState(1)
    /* const [openReqRepService, setOpenReqRepService] = React.useState(false) */
    const [changeOrInstalation, setChangeOrInstalation] = React.useState(2)
/*     const [firm, setFirm] = React.useState(1)
 */    const [compInfo, setCompInfo] = React.useState([]);
    /* const [image, setImage] = useState([]) */
    const [imgToUplo, setImgToUplo] = useState([])
    const { dependencySelected } = useSelector(state => state.Area)
    const [equipSelected, setEquipSelected] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const { CaseReportCreatedId } = useSelector(state => state.CaseReport)
    const { idUser, area } = useSelector(state => state.Login)
    const { fullDataCaseReport } = useSelector(state => state.CaseReport)
    const { locations, states, equipmentsFiltered, equipmentsSearched, loadingEquipment } = useSelector(state => state.Equipment)
    const { Option } = Select

    useEffect(() => {
        dispatch(EquipmentActions.getLocations())
        dispatch(EquipmentActions.getStates())
    }, [dispatch])

    useEffect(() => {
        if (data) {
            data.caseReport && dispatch(CaseReportActions.getFullDataCaseReport({ "id": data.caseReport[0].id, "moduleWork": moduleWork[0].moduleWork.id }))
            !data.caseReport && data.id && dispatch(CaseReportActions.getFullDataCaseReport({ "id": data.id, "moduleWork": moduleWork[0].moduleWork.id }))
        }
    }, [data])

    useEffect(() => {
        if (data && fullDataCaseReport) {
            setEquipSelected({
                "name": fullDataCaseReport.equipment.biomedicEquipment.brand,
                "brand": fullDataCaseReport.equipment.biomedicEquipment.brand,
                "model": fullDataCaseReport.equipment.biomedicEquipment.modell,
                "serie": fullDataCaseReport.equipment.biomedicEquipment.serie,
                "plate": fullDataCaseReport.equipment.licensePlate,
                "id": fullDataCaseReport.equipment.id
            })
            setDate(moment(fullDataCaseReport.date))
            setServiceType(fullDataCaseReport.biomedicCaseReport.serviceType)
            setDescriptionAnswer(fullDataCaseReport.biomedicCaseReport.descriptionAnswer)
            setDescriptionAsking(fullDataCaseReport.biomedicCaseReport.descriptionAsking)
            setStatus(fullDataCaseReport.biomedicCaseReport.status)
            setProcessDescription(fullDataCaseReport.biomedicCaseReport.processDescription)
            setObservations(fullDataCaseReport.biomedicCaseReport.observations)
            setStatusEquip(fullDataCaseReport.statusEquip)
        }
    }, [data, fullDataCaseReport])

    const next = () => {
        setCurrent(current + 1);
        window.scrollTo(0, 0)
    };

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0)
    };

    const onExitSearch = () => {
        setVisibleExitSearch(false)
        dispatch(EquipmentActions.setEquipmentsFiltered(false))
    }

    const postCaseReport = () => {
        if (equipSelected && date) {
            setIsSubmitting(true)
            const dataP = {
                "serviceType": serviceType,
                "descriptionAnswer": descriptionAnswer,
                "descriptionAsking": descriptionAsking,
                "status": status,
                "processDescription": processDescription,
                "observations": observations,
                "date": date,
                "equipment": equipSelected.id,
                "statusEquip": statusEquip,
                "id": data && data.id,
                "components": compInfo,
                "userCreator": idUser,
            }
            !data && dispatch(CaseReportActions.createCaseReport(dataP));
            if (data) {
                dataP.id = fullDataCaseReport.id
            }
            data && dispatch(CaseReportActions.updateCaseReport(dataP));
        } else {
            message.warn("Debes seleccionar un equipo y una fecha")
        }
    }

    const Comp = ({ index, values }) => {
        const [exist, setExist] = useState(true);
        const [text, setText] = useState(values[0])
        const [text1, setText1] = useState(values[1])
        const [text2, setText2] = useState(values[2])
        /* const [text3, setText3] = useState(values[3]) */

        const deleteComp = () => {
            setExist(false)
            var aux = compInfo
            aux.splice(index, 1)
            setCompInfo(aux)
        }
        useEffect(() => {
            var aux = compInfo
            aux[index][0] = text
            aux[index][1] = text1
            aux[index][2] = text2
            /*  aux[index][3] = text3 */
            //verify if text is a number
            if (isNaN(text)) {
                message.warn("Debes ingresar un valor numérico")
                aux[index][0] = ""
                setText("")
            }
            text !== "" && text1 !== "" && text2 !== "" && /* text3 !== "" && */ setCompInfo(aux)
        }, [text, text1, text2, /* text3, */ index])

        return (
            exist && <div className='sparePartsDiv'>
                <Input va={text} setData={setText} label='Cantidad' labelstyle='inputLabelBordered' />
                <Input va={text1} setData={setText1} label='Nombre del repuesto' labelstyle='inputLabelBordered' />
                <Input va={text2} setData={setText2} label='N° de parte' labelstyle='inputLabelBordered' />
                {/* <Input va={text3} setData={setText3} label='Valor' labelstyle='inputLabelBordered' /> */}
                <CloseOutlined onClick={() => deleteComp()} className='closeIcon' />
            </div>
        )
    }
    const steps = [
        {
            title: 1,
            content:
                <div className='firstRep'>
                    <div>
                        <p>Fecha de Reporte</p>
                        <DatePicker style={{ width: '15vw', height: '3vw' }} value={date} onChange={d => setDate(d)} />
                    </div>
                    <p className='featuresText'>{equipSelected ? "Ha seleccionado " + equipSelected.name : "Selecciona un equipo"}</p>
                    <hr />
                    <div className='divSearchEquips'>
                        <Button className="Selectedd" onClick={() => {
                            setVisibleModal(true)
                            dispatch(EquipmentActions.setLoading(false))
                        }}> <SearchOutlined className="plus" />  Buscar Equipos</Button>
                        {visibleExitSearch && <CloseCircleFilled className="closeIcon" onClick={() => onExitSearch()} />}
                    </div>
                    <div className='div-equipment'>
                        {!data && equipmentsSearched && !equipSelected ? equipmentsSearched.map((d, i) =>
                        (

                            // <EquipmentSearched image={d.equipments.image} licensePlate={d.licensePlate}></EquipmentSearched>
                            <EquipmentCard
                                onChange={() => setEquipSelected({
                                    "name": d.name,
                                    "brand": d.biomedicEquipment.brand,
                                    "model": d.biomedicEquipment.modell,
                                    "serie": d.biomedicEquipment.serie,
                                    "plate": d.licensePlate,
                                    "location": d.biomedicEquipment.location,
                                    "moduleWork": d.moduleWork.id,
                                    "state": d.state,
                                    "id": d.id
                                })}
                                key={i}
                                img={d.biomedicEquipment.image}
                                name={d.name}
                                brand={d.biomedicEquipment.brand}
                                model={d.biomedicEquipment.modell}
                                serie={d.biomedicEquipment.serie}
                                plate={d.licensePlate}
                                location={d.biomedicEquipment.location}
                                moduleWork={d.moduleWork.id}
                                state={d.state}
                                id={d.id}
                            >
                            </EquipmentCard>
                            // name, brand, model, serie, plate, group, agent
                        ))
                            : equipSelected &&
                            <EquipmentCard
                                onChange={() => setEquipSelected(false)}
                                id={equipSelected.id}
                                img={equipSelected.image}
                                name={equipSelected.name}
                                brand={equipSelected.brand}
                                model={equipSelected.modell}
                                serie={equipSelected.serie}
                                location={equipSelected.location}
                                moduleWork={equipSelected.moduleWork}
                                state={equipSelected.state}
                            >
                            </EquipmentCard>
                        }
                    </div>

                    {/* <EquipmentCard /> */}
                    {/* <div className='firstFields'>
                        <div>
                            <p className='label1'>Fecha</p>
                            <DatePicker style={{ width: '10vw', height: '2.5vw' }} onChange={d => setDate(d)} />
                        </div>
                        <div>
                            <p className='label1'>Departamento</p>
                            <Select
                                className='select'
                                id="demo-controlled-open-select"
                                open={openDepartment}
                                onClose={() => setOpenDepartment(false)}
                                onOpen={() => setOpenDepartment(true)}
                                value={department}
                                onChange={event => setDepartment(event.target.value)}
                                color='primary'
                            >
                                <MenuItem className='item' value={1}>
                                    <em>Departamento</em>
                                </MenuItem>
                                <MenuItem value={10}>Subgerencia cientifica</MenuItem>
                                <MenuItem value={20}>Subgerencia Y</MenuItem>
                                <MenuItem value={30}>Subgerencia X</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <p className='label1'>Municipio</p>
                            <Select
                                className='select'
                                id="demo-controlled-open-select"
                                open={openMunicipality}
                                onClose={() => setOpenMunicipality(false)}
                                onOpen={() => setOpenMunicipality(true)}
                                value={municipality}
                                onChange={event => setMunicipality(event.target.value)}
                                color='primary'
                            >
                                <MenuItem className='item' value={1}>
                                    <em>Municipio</em>
                                </MenuItem>
                                <MenuItem value={10}>Subgerencia cientifica</MenuItem>
                                <MenuItem value={20}>Subgerencia Y</MenuItem>
                                <MenuItem value={30}>Subgerencia X</MenuItem>
                            </Select>
                        </div>
                        <Input heightDiv='0vh' label='Nit' labelstyle='inputLabelBordered' width='10vw' height='2.5vw' />
                        <Input heightDiv='0vh' label='N°' labelstyle='inputLabelBordered' width='10vw' height='2.5vw' />
                    </div> */}
                    {/* <p className='featuresText'>Caracteristicas Tecnicas del Equipo</p>
                    <hr />
                    <div className='dataFields'>
                        <Input label='Nombre' labelstyle='inputLabelBordered' width='10vw' height='2.5vw' />
                        <Input label='Marca' labelstyle='inputLabelBordered' width='18vw' height='2.5vw' />
                        <Input label='Modelo' labelstyle='inputLabelBordered' width='10vw' height='2.5vw' />
                        <Input label='Serie' labelstyle='inputLabelBordered' width='10vw' height='2.5vw' />
                    </div>
                    <div className='dataFields1'>
                        <Input label='Placa' labelstyle='inputLabelBordered' mr='2vw' width='10vw' height='2.5vw' />
                        <div>
                            <p className='label1'>Grupo</p>
                            <Select
                                className='select'
                                id="demo-controlled-open-select"
                                open={openGroup}
                                onClose={() => setOpenGroup(false)}
                                onOpen={() => setOpenGroup(true)}
                                value={group}
                                onChange={event => setGroup(event.target.value)}
                                color='primary'
                            >
                                <MenuItem className='item' value={1}>
                                    <em>Municipio</em>
                                </MenuItem>
                                <MenuItem value={10}>Subgerencia cientifica</MenuItem>
                                <MenuItem value={20}>Subgerencia Y</MenuItem>
                                <MenuItem value={30}>Subgerencia X</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <p className='label1'>Agente</p>
                            <Select
                                className='select'
                                id="demo-controlled-open-select"
                                open={openAgent}
                                onClose={() => setOpenAgent(false)}
                                onOpen={() => setOpenAgent(true)}
                                value={agent}
                                onChange={event => setAgent(event.target.value)}
                                color='primary'
                            >
                                <MenuItem className='item' value={1}>
                                    <em>Municipio</em>
                                </MenuItem>
                                <MenuItem value={10}>Subgerencia cientifica</MenuItem>
                                <MenuItem value={20}>Subgerencia Y</MenuItem>
                                <MenuItem value={30}>Subgerencia X</MenuItem>
                            </Select>
                        </div>
                    </div> */}
                    <p className='featuresText'>Tipo de Servicio</p>
                    <hr />
                    <Radio.Group className='radioG' onChange={d => setServiceType(d.target.value)} value={serviceType}>
                        <Radio className='radio' value={"Correctivo"}>Correctivo</Radio>
                        <Radio className='radio' value={"Preventivo"}>Preventivo</Radio>
                        <Radio className='radio' value={"Diagnóstico"}>Diagnóstico</Radio>
                        <Radio className='radio' value={"Instalación"}>Instalación</Radio>
                        <Radio className='radio' value={"Inducción"}>Inducción</Radio>
                    </Radio.Group>
                    <p className='featuresText'>Descripción del servicio</p>
                    <hr />
                    <div className='descriptionDiv'>
                        <div>
                            {/* <p className='labelServiceReq'>Usuario</p>
                            <Select
                                className='select'
                                id="demo-controlled-open-select"
                                open={openReqRepService}
                                onClose={() => setOpenReqRepService(false)}
                                onOpen={() => setOpenReqRepService(true)}
                                value={reqRepService}
                                onChange={event => setReqRepService(event.target.value)}
                                color='primary'
                            >
                                <MenuItem className='item' value={1}>
                                    <em>Usuario solicitante del servicio</em>
                                </MenuItem>
                                {users.map(d => (
                                    <MenuItem key={d.user.id} value={d.user.id}>
                                        {d.user.person.name + " " + d.user.person.lastName}
                                    </MenuItem>
                                ))}
                            </Select> */}
                            <p className='serviceDescription'>Servicio solicitado y/o reportado</p>
                            <textarea onChange={e => setDescriptionAsking(e.target.value)} value={descriptionAsking}></textarea>
                            {/* <p className='labelServiceReq'>Agente</p> */}
                            {/* <Select
                                className='select'
                                id="demo-controlled-open-select"
                                open={openAgent}
                                onClose={() => setOpenAgent(false)}
                                onOpen={() => setOpenAgent(true)}
                                value={agent}
                                onChange={event => setAgent(event.target.value)}
                                color='primary'
                            >
                                <MenuItem className='item' value={1}>
                                    <em>Agente que realizó la revisión</em>
                                </MenuItem>
                                {agents.map(d => (
                                    <MenuItem key={d.user.id} value={d.user.id}>
                                        {d.user.person.name + " " + d.user.person.lastName}
                                    </MenuItem>
                                ))}
                            </Select> */}
                            <p className='serviceDescription'>Servicio ejecutado</p>
                            <textarea onChange={e => setDescriptionAnswer(e.target.value)} value={descriptionAnswer}></textarea>
                            <Radio.Group className='radioG' onChange={d => setStatus(d.target.value)} value={status}>
                                <Radio className='radio' value={'Terminado'}>Terminado</Radio>
                                <Radio className='radio' value={'En proceso'}>En proceso</Radio>
                                <Radio className='radio' value={'Fuera De Servicio'}>Fuera De Servicio</Radio>
                                <Radio className='radio' value={'Otro'}>Otro</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </div>,
        },
        {
            title: 2,
            content:
                <div className='secondSectionRep'>
                    {!data && <p className='featuresText'>Cambio O Instalación De La Parte</p>}
                    {!data && <hr />}
                    {!data && <Radio.Group className='radioG' onChange={d => setChangeOrInstalation(d.target.value)} value={changeOrInstalation}>
                        <Radio className='radio' value={1}>Si</Radio>
                        <Radio onClick={() => setCompInfo([])} className='radio' value={2}>No</Radio>
                    </Radio.Group>}
                    {changeOrInstalation === 1 && <div>
                        <p className='featuresText'>Repuestos Instalados</p>
                        <hr />
                        <div className='newComp'>
                            <Button type="primary"
                                className='boton'
                                icon={<PlusOutlined />}
                                style={{
                                    backgroundColor: '#4E8E30',
                                    borderRadius: '10px',
                                    padding: 0
                                }}
                                onClick={() => setCompInfo([...compInfo, ['', '', ''/* , '' */]])}>
                                Añadir repuesto
                            </Button>
                        </div>
                    </div>}
                    {compInfo.map((data, i) => (
                        <Comp key={i} index={i} values={data} />
                    ))}

                    <p className='processDescription'>Descripción Del Procedimiento</p>
                    <textarea onChange={e => setProcessDescription(e.target.value)} value={processDescription}></textarea>
                    <p className='observations'>Observaciones</p>
                    <textarea onChange={e => setObservations(e.target.value)} value={observations}></textarea>

                    <p className='processDescription'>Estado final del equipo</p>
                    <Radio.Group className='radioG' onChange={d => setStatusEquip(d.target.value)} value={statusEquip}>
                        <Radio className='radio' value={'Funcional'}>Funcional</Radio>
                        <Radio className='radio' value={'No funcional'}>No funcional</Radio>
                        <Radio className='radio' value={'Dado de baja'}>Dado de baja</Radio>
                    </Radio.Group>
                    {/* <p className='featuresText'>Firma Digital</p>
                    <hr />
                    <Radio.Group className='radioG' onChange={d => setFirm(d.target.value)} value={firm}>
                        <Radio className='radio' value={1}>Si</Radio>
                        <Radio className='radio' value={2}>No</Radio>
                    </Radio.Group> */}
                </div>,
        },

    ];

    return (
        <div className='FormServiceReport'>
            <Steps current={current} >
                {steps.map(item => (
                    <Step key={item.title} title={item.title} icon={item.icon} />
                ))}
            </Steps>
            <div className="steps-content" >{steps[current].content}</div>
            <div className="steps-action" >
                {current > 0 && (
                    <Button className='boton' style={{ margin: '0 8px', backgroundColor: '#C4C4C4', borderRadius: '10px', }} onClick={() => prev()}>
                        Atras
                    </Button>
                )}
                {current < steps.length - 1 && (
                    <Button className='boton' type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', }} onClick={() => next()}>
                        Continuar
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button disabled={isSubmitting} className='boton' type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', }} onClick={() => postCaseReport()}>
                        Hecho
                    </Button>
                )}
            </div>
            <ModalSearchEquipment visibleModal={visibleModal} setVisibleModal={setVisibleModal} />
        </div>
    )
}