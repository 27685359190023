import { Modal } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { apiUrl } from '../../common/config/Environments'
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { PdfInventory } from '../PdfInventory/PdfInventory';

export const Equipment = ({ data, setEdit, setEquipData, extraOnClick, width, height }) => {
    const [visibleModal, setVisibleModal] = useState(false)
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [pdf, setPdf] = useState(false)
    const { equipAndReportPDF, fullDataEquipment, loadingFullDataEquipment, annexesPdf, loadingAnnexesPdf, loadingDeleteEquipmentAnnexe } = useSelector(state => state.Equipment)
    const [image, setImage] = useState(false)
    const { roleUser, fullUser, idUser } = useSelector(state => state.Login)
    const history = useHistory()

    const dispatch = useDispatch()
    useEffect(() => {
        fetch(`${apiUrl}/biomedicEquipment/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        pdf === 'all' && dispatch(EquipmentActions.getEquipAndReportPDF(data.id))
    }, [pdf])

    const MyDocument = () => (
        <Document>
            <PdfInventory fullDataEquipment={fullDataEquipment} data={data} enterprise={enterpriseImage} />
        </Document >
    );

    const annexesTypes = {
        "importRegistration": "Registro de importación",
        "INVIMA": "INVIMA",
        "manuals": "Manuales",
        "quickHandlingGuide": "Guía rápida de manejo",
        "dataSheet": "Hoja de datos",
        "warranty": "Garantía",
        "preventiveMaintenance": "Mantenimiento preventivo",
        "induction": "Inducción",
    }

    const ModalEquip = () => {
        const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        }
        return (
            <Modal className='ModalEquip' width={(fullDataEquipment || annexesPdf) ? '90vw' : '38vw'} visible={visibleModal} footer={null} onCancel={() => {
                setVisibleModal(false)
                dispatch(EquipmentActions.setFullDataEquipment(false))
                dispatch(EquipmentActions.setEquipAndReportPDF(false))
                dispatch(EquipmentActions.setAnnexesPdf(false))
            }}>
                {(!fullDataEquipment && !annexesPdf) && <p className="title">{data.biomedicEquipment ? data.biomedicEquipment.name : ''}</p>}
                {(!fullDataEquipment && !annexesPdf) && <div className='div'>
                    <button className='button' onClick={() => {
                        dispatch(EquipmentActions.getFullDataEquipment(data.id))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Ver Hoja de Vida"}
                    </button>

                    {fullUser.canCreate &&
                        <button className='button' onClick={() => {
                            onClickEdit(true)
                            dispatch(EquipmentActions.getFullDataEquipment(data.id))
                        }}>
                            {loadingFullDataEquipment ? <LoadingOutlined /> : "Editar Hoja de Vida"}
                        </button>}
                    {fullUser.canCreate && roleUser.id == 37 &&
                        <button className='button' onClick={() => {
                            history.push('/Traslados', { equipSelectedToTransfer: data })
                        }}>
                            {loadingFullDataEquipment ? <LoadingOutlined /> : "Trasladar"}
                        </button>}
                    {fullUser.canCreate && roleUser.id == 3 &&
                        <button className='button' onClick={() => {
                            history.push('/Traslados', { equipSelectedToTransfer: data, agent: true })
                        }}>
                            {loadingFullDataEquipment ? <LoadingOutlined /> : "Solicitar Traslado"}
                        </button>}
                    {data.equipmentAnnexes && data.equipmentAnnexes.map((d, i) => (
                        <div key={i} style={{ display: 'flex', justifyContent: 'space-around', width: '28vw' }}>
                            <button key={i} className='button1' onClick={() => {
                                dispatch(EquipmentActions.getAnnexesPdf(d.id))
                            }}>
                                {loadingAnnexesPdf ? <LoadingOutlined /> : "Ver " + annexesTypes[d.type]}
                            </button>
                            <button key={i} className='button2' onClick={() => {
                                dispatch(EquipmentActions.deleteEquipmentAnnexe(d.id))
                            }}>
                                {loadingDeleteEquipmentAnnexe ? <LoadingOutlined /> : "Eliminar " + annexesTypes[d.type]}
                            </button>
                        </div>
                    ))}
                    {/* <button className='button' onClick={() => {
                        dispatch(EquipmentActions.setFullDataEquipment(
                            {
                                "biomedicEquipment": {
                                    "active": '',
                                    "brand": '',
                                    "serie": '',
                                    "adquisitionDate": null,
                                    "modell": '',
                                    "service": '',
                                    "instalationDate": null,
                                    "healthRegister": '',
                                    "fabricationDate": null,
                                    "startOperationDate": null,
                                    "location": '',
                                    "representative": '',
                                    "warrantyExpirationDate": null,
                                    "maker": '',
                                    "cost": '',
                                    "webPage": '',
                                    "makerCountry": '',
                                    "adquisitionWay": '',
                                    'voltageRange': '',
                                    'pressureRange': '',
                                    'powerSource': '',
                                    'currentRange': '',
                                    'speedRange': '',
                                    'riskClasification': '',
                                    'powerRange': '',
                                    'humidityRange': '',
                                    'frequency': '',
                                    'temperatureRange': '',
                                    'weight': '',
                                    'dimensions': '',
                                    'biomedicComponent': [{
                                        'name': '',
                                        'brand': '',
                                        'reference': '',
                                        'partNumber': '',
                                    }],
                                    "manual": '',
                                    "plane": '',
                                    'use': '',
                                    'biomedicClasification': '',
                                    'tecnology': '',
                                    'operation': '',
                                    'electricSecurity': '',
                                    'maintenance': '',
                                    'equipmentOwner': '',
                                    'irregularCause': '',
                                },
                                "licensePlate": '',
                                "name": '',
                                "municipality": null,

                            }
                        ))
                    }}>
                        {loadingFullDataEquipment ? <LoadingOutlined /> : "Formato vacio"}
                    </button> */}
                    {/*  <button className='button' onClick={() => setPdf('all')}>Descargar </button> */}
                </div>}

                {fullDataEquipment && <PDFViewer className='pdf'>
                    <MyDocument></MyDocument>
                </PDFViewer>}
                {equipAndReportPDF &&
                    <div>
                        <PDFViewer className='pdf'>
                            <MyDocument></MyDocument>
                        </PDFViewer>
                        {equipAndReportPDF.caseReport && equipAndReportPDF.caseReport.map(d => (
                            <embed className='pdf' src={d.pdf}></embed>
                        ))}
                    </div>}
                {annexesPdf && <embed className='pdf' src={annexesPdf.pdf}></embed>}

            </Modal >
        )
    }

    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este equipo?')) {
            const dataDelete = {
                "id": data.id
            }
            dispatch(EquipmentActions.deleteEquipment(dataDelete))
        }
    }

    // Obtén una referencia a todos los elementos
    const elements = document.querySelectorAll('.dataDiv');

    elements.forEach(element => {
        // Obtén una referencia al elemento padre
        const parent = element.parentElement;

        // Calcula el tamaño de la fuente como un porcentaje del ancho del elemento padre
        const fontSize = parent.offsetWidth * 0.014; // 1.4% del ancho del padre

        // Establece el tamaño de la fuente del elemento
        element.style.fontSize = `${fontSize}px`;
    });

    return (
        <div className='Equipment' style={{ width: width, height: height }}>
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                if (extraOnClick) {
                    extraOnClick(data)
                } else {
                    setVisibleModal(true)
                }
            }}>
                <div><p className='pFontData name'>{data.name}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment ? data.biomedicEquipment.brand : ''}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment ? data.biomedicEquipment.modell : ''}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment ? data.biomedicEquipment.serie : ''}</p></div>
                <div><p className='pFontData'>{data.licensePlate}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.healthRegister}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.riskClasification}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment ? data.biomedicEquipment.location : ''}</p></div>
                {data.state === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                    : data.state === "No funcional" ? <div className='status1'><p className='pFontStatus'>{data.state}</p></div>
                        : data.state === "Dado de baja" ? <div className='status2'><p className='pFontStatus'>Baja</p></div>
                            : data.state === "Comodato devuelto" && <div className='status4'><p className='pFontStatus'>Comodato devuelto</p></div>}
            </div>
            {/* {roleUser.id !== 36 && data.userCreator.id === idUser &&  */}<div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>
            <ModalEquip />
        </div >
    )
}