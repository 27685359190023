import { Steps, Button, Radio, Select, DatePicker, message, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Input } from "../Input/Input";
import Icon, {
  CloseCircleOutlined,
  CloseCircleFilled,
  SearchOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentCard } from "../EquipmentCard/EquipmentCard";
import { CaseReportActions } from "../../services/caseReport/CaseReportSlice";
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice";
import { ModalSearchEquipment } from "../ModalSearchEquipment/ModalSearchEquipment";
import { Controller, useForm } from "react-hook-form";
import { CaseReportSystemActions } from "../../services/caseReportSystem/caseReportSystemSlice";
import Wrench from "../../assets/images/Wrench.svg";
import { PagActions } from "../../services/common/Pag/PagSlice";
import { select } from "redux-saga/effects";
import { SaveOutlined } from "@material-ui/icons";

export const FormServiceReportSystem = ({ edit, data }) => {
  const { selectOptions } = useSelector((state) => state.Pag);
  const [equipSelected, setEquipSelected] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleExitSearch, setVisibleExitSearch] = useState(false);
  const { dependenciesFromBoss, moduleWork } = useSelector(
    (state) => state.Area
  );
  const dispatch = useDispatch();
  const { loadingCreateCaseReportSystem, lastNumber, messageCreateCaseReportSystem } = useSelector(
    (state) => state.CaseReportSystem
  );
  const { idUser } = useSelector((state) => state.Login);

  const { loadingPDF, loadingCaseReport, loadingFullDataCaseReport } = useSelector(state => state.CaseReport)
  const { loadingEquipment, states, equipmentsSearched, equipsWithCaseReports, loadingEquipsWithCaseReport, quantityEquipsWithCaseReports, isFiltered, fullDataEquipment } = useSelector(state => state.Equipment)
  const [formData, setFormData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageFiltered, setCurrentPageFiltered] = useState(0);
  const { Option } = Select;
  const [componentsQuantity, setComponentsQuantity] = useState([]);
  const [extraProcessors, setExtraProcessors] = useState([]);
  const [extraDisks, setExtraDisks] = useState([]);
  const [extraRams, setExtraRams] = useState([]);
  const [statusLaptop, setStatusLaptop] = useState("");
  const [statusAllInOne, setStatusAllInOne] = useState("");
  const [statusCPU, setStatusCPU] = useState("");
  const [statusGPU, setStatusGPU] = useState("");
  const [statusMonitor, setStatusMonitor] = useState("");
  const [statusKeyboard, setStatusKeyboard] = useState("");
  const [statusMouse, setStatusMouse] = useState("");
  const [statusSignaturePad, setStatusSignaturePad] = useState("");
  const [statusWebcam, setStatusWebcam] = useState("");
  const [statusBarcodeReader, setStatusBarcodeReader] = useState("");
  const [statusFingerprintReader, setStatusFingerprintReader] = useState("");
  const [statusPowerAdapter, setStatusPowerAdapter] = useState("");
  const [statusDiadems, setStatusDiadems] = useState("");
  const [statusSource, setStatusSource] = useState("");
  const [statusProcessors, setStatusProcessors] = useState(false);
  const [statusDisks, setStatusDisks] = useState(false);
  const [statusRams, setStatusRams] = useState(false);
  const stateVars = useRef({
    statusLaptop,
    statusAllInOne,
    statusCPU,
    statusGPU,
    statusMonitor,
    statusKeyboard,
    statusMouse,
    statusSignaturePad,
    statusWebcam,
    statusBarcodeReader,
    statusFingerprintReader,
    statusPowerAdapter,
    statusDiadems,
    statusSource,
  });
  const [countComps, setCountComps] = useState(0);
  const [hasSoftware, setHasSoftware] = useState(false);
  const [visibleCtmModal, setVisibleCtmModal] = useState(true);
  const [auxSelectOptions, setAuxSelectOptions] = useState(false);
  const [optionSearched, setOptionSearched] = useState(false);
  const [optionsType, setOptionsType] = useState([
    { tipoSoftware: "Windows" },
    { tipoSoftware: "Ubuntu" },
    { tipoSoftware: "Mac OS" },
    { tipoSoftware: "Paquete Ofimatica" },
    { tipoSoftware: "Visor de PDF" },
    { tipoSoftware: "Compresor Rar-Zip" },
    { tipoSoftware: "Controladores" },
    { tipoSoftware: "Antivirus" },
    { tipoSoftware: "Navegador" },
    { tipoSoftware: "Base de datos" },
    { tipoSoftware: "Licencia" },
    { tipoSoftware: "Otro" },
  ]);

  useEffect(() => {
    dispatch(EquipmentActions.getLocations());
    dispatch(EquipmentActions.getStates());
    dispatch(PagActions.getSelectOptions());
  }, [dispatch]);

  useEffect(() => {
    if (messageCreateCaseReportSystem === "Creado sin componentes ni software" ||
      messageCreateCaseReportSystem === "Creado con software pero sin componentes" ||
      messageCreateCaseReportSystem === "Creado con componentes pero sin software" ||
      messageCreateCaseReportSystem === "Creado con componentes y software"
    ) {
      dispatch(CaseReportActions.setOption("search"))
      dispatch(CaseReportSystemActions.setMessageCreateCaseReportSystem(false))
    }
  }, [messageCreateCaseReportSystem])

  useEffect(() => {
    moduleWork && dispatch(EquipmentActions.getEquipmentsByModuleWork({ "moduleWork": moduleWork[0].moduleWork.id, "take": 8, "page": currentPage }))
    moduleWork && formData && dispatch(EquipmentActions.getEquipmentsByModuleWork({
      "moduleWork": moduleWork[0].moduleWork.id,
      "take": 20,
      "page": currentPageFiltered,
      "dependency": formData.dependency,
      "area": formData.area,
      "nameEquip": formData.nameEquip,
      "year": formData.year,
      "location": formData.location,
      "licensePlate": formData.licensePlate,
    }))
  }, [currentPage, moduleWork, dispatch, formData, currentPageFiltered])

  useEffect(() => {
    selectOptions && setAuxSelectOptions(selectOptions);
  }, [selectOptions]);

  useEffect(() => {
    if (data) {
      data.caseReport &&
        dispatch(
          CaseReportActions.getFullDataCaseReport({
            id: data.caseReport[0].id,
            moduleWork: moduleWork[0].moduleWork.id,
          })
        );
      !data.caseReport &&
        data.id &&
        dispatch(
          CaseReportActions.getFullDataCaseReport({
            id: data.id,
            moduleWork: moduleWork[0].moduleWork.id,
          })
        );
    }
  }, [data]);

  const {
    register: registerSystemReport,
    handleSubmit: handleSubmitSystemReport,
    setValue: setValueSystemReport,
    watch: watchSystemReport,
    control: controlSystemReport,
    formState: { errors: errorsSystemReport },
    trigger: triggerSystemReport,
    reset: resetSystemReport,
  } = useForm({
    defaultValues: {
      equipSelected: "",
      startDate: moment(),
      endDate: moment(),
      failReported: "",
      physicStatus: "",
      diagnostic: "",
      isTampered: "",
      whyIsTampered: "",
      testsPerformed: "",
      solutionDelivered: "",
      service: "",
      mttoType: "",
      clientObservation: "",

      brandLaptop: undefined,
      modellLaptop: undefined,
      serieLaptop: undefined,
      licensePlateLaptop: undefined,
      imageLaptop: undefined,
      imageLaptopSerial: undefined,
      statusLaptop: undefined,
      brandCPU: undefined,
      modellCPU: undefined,
      serieCPU: undefined,
      licensePlateCPU: undefined,
      imageCPU: undefined,
      imageCPUSerial: undefined,
      statusCPU: undefined,
      brandGPU: undefined,
      modellGPU: undefined,
      serieGPU: undefined,
      licensePlateGPU: undefined,
      imageGPU: undefined,
      imageGPUSerial: undefined,
      statusGPU: undefined,
      brandAllInOne: undefined,
      modellAllInOne: undefined,
      serieAllInOne: undefined,
      licensePlateAllInOne: undefined,
      imageAllInOne: undefined,
      imageAllInOneSerial: undefined,
      statusAllInOne: undefined,
      brandKeyboard: undefined,
      modellKeyboard: undefined,
      serieKeyboard: undefined,
      licensePlateKeyboard: undefined,
      imageKeyboard: undefined,
      imageKeyboardSerial: undefined,
      statusKeyboard: undefined,
      brandMouse: undefined,
      modellMouse: undefined,
      serieMouse: undefined,
      licensePlateMouse: undefined,
      imageMouse: undefined,
      imageMouseSerial: undefined,
      statusMouse: undefined,
      brandSignaturePad: undefined,
      modellSignaturePad: undefined,
      serieSignaturePad: undefined,
      licensePlateSignaturePad: undefined,
      imageSignaturePad: undefined,
      imageSignaturePadSerial: undefined,
      statusSignaturePad: undefined,
      brandWebcam: undefined,
      modellWebcam: undefined,
      serieWebcam: undefined,
      licensePlateWebcam: undefined,
      imageWebcam: undefined,
      imageWebcamSerial: undefined,
      statusWebcam: undefined,
      brandBarcodeReader: undefined,
      modellBarcodeReader: undefined,
      serieBarcodeReader: undefined,
      licensePlateBarcodeReader: undefined,
      imageBarcodeReader: undefined,
      imageBarcodeReaderSerial: undefined,
      statusBarcodeReader: undefined,
      brandPowerAdapter: undefined,
      modellPowerAdapter: undefined,
      seriePowerAdapter: undefined,
      licensePlatePowerAdapter: undefined,
      imagePowerAdapter: undefined,
      imagePowerAdapterSerial: undefined,
      statusPowerAdapter: undefined,
      brandFingerprintReader: undefined,
      modellFingerprintReader: undefined,
      serieFingerprintReader: undefined,
      licensePlateFingerprintReader: undefined,
      imageFingerprintReader: undefined,
      imageFingerprintReaderSerial: undefined,
      statusFingerprintReader: undefined,
      brandDiadems: undefined,
      modellDiadems: undefined,
      serieDiadems: undefined,
      licensePlateDiadems: undefined,
      imageDiadems: undefined,
      imageDiademsSerial: undefined,
      statusDiadems: undefined,
      brandSource: undefined,
      modellSource: undefined,
      serieSource: undefined,
      licensePlateSource: undefined,
      imageSource: undefined,
      imageSourceSerial: undefined,
      statusSource: undefined,
      number: "",
      processors: undefined,
    },
  });

  useEffect(() => {
    if (watchSystemReport("processors")) {
      let aux = [];
      watchSystemReport("processors").forEach((item, i) => {
        aux.push({
          processor: item,
          status: "Funcional",
        });
        setStatusProcessors(aux);
      });
    }
  }, [watchSystemReport("processors")]);

  useEffect(() => {
    if (fullDataEquipment) {
      setValueSystemReport(
        "brandLaptop",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandLaptop
      );
      setValueSystemReport(
        "modellLaptop",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellLaptop
      );
      setValueSystemReport(
        "serieLaptop",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieLaptop
      );
      setValueSystemReport(
        "licensePlateLaptop",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateLaptop
      );
      setValueSystemReport(
        "imageLaptop",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoLaptop
      );
      setValueSystemReport(
        "imageLaptopSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoLaptopSerial
      );
      setValueSystemReport(
        "brandCPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandCPU
      );
      setValueSystemReport(
        "modellCPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellCPU
      );
      setValueSystemReport(
        "serieCPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieCPU
      );
      setValueSystemReport(
        "licensePlateCPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateCPU
      );
      setValueSystemReport(
        "imageCPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoCPU
      );
      setValueSystemReport(
        "imageCPUSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoCPUSerial
      );
      setValueSystemReport(
        "brandGPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandGPU
      );
      setValueSystemReport(
        "modellGPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellGPU
      );
      setValueSystemReport(
        "serieGPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieGPU
      );
      setValueSystemReport(
        "licensePlateGPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateGPU
      );
      setValueSystemReport(
        "imageGPU",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoGPU
      );
      setValueSystemReport(
        "imageGPUSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoGPUSerial
      );
      setValueSystemReport(
        "brandAllInOne",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandAllInOne
      );
      setValueSystemReport(
        "modellAllInOne",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellAllInOne
      );
      setValueSystemReport(
        "serieAllInOne",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieAllInOne
      );
      setValueSystemReport(
        "licensePlateAllInOne",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateAllInOne
      );
      setValueSystemReport(
        "imageAllInOne",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoAllInOne
      );
      setValueSystemReport(
        "imageAllInOneSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoAllInOneSerial
      );
      setValueSystemReport(
        "brandMonitor",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandMonitor
      );
      setValueSystemReport(
        "modellMonitor",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellMonitor
      );
      setValueSystemReport(
        "serieMonitor",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieMonitor
      );
      setValueSystemReport(
        "licensePlateMonitor",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateMonitor
      );
      setValueSystemReport(
        "imageMonitor",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoMonitor
      );
      setValueSystemReport(
        "imageMonitorSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoMonitorSerial
      );
      setValueSystemReport(
        "brandKeyboard",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandKeyboard
      );
      setValueSystemReport(
        "modellKeyboard",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellKeyboard
      );
      setValueSystemReport(
        "serieKeyboard",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieKeyboard
      );
      setValueSystemReport(
        "licensePlateKeyboard",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateKeyboard
      );
      setValueSystemReport(
        "imageKeyboard",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoKeyboard
      );
      setValueSystemReport(
        "imageKeyboardSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoKeyboardSerial
      );
      setValueSystemReport(
        "brandMouse",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandMouse
      );
      setValueSystemReport(
        "modellMouse",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellMouse
      );
      setValueSystemReport(
        "serieMouse",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieMouse
      );
      setValueSystemReport(
        "licensePlateMouse",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateMouse
      );
      setValueSystemReport(
        "imageMouse",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoMouse
      );
      setValueSystemReport(
        "imageMouseSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoMouseSerial
      );
      setValueSystemReport(
        "brandSignaturePad",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandSignaturePad
      );
      setValueSystemReport(
        "modellSignaturePad",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellSignaturePad
      );
      setValueSystemReport(
        "serieSignaturePad",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieSignaturePad
      );
      setValueSystemReport(
        "licensePlateSignaturePad",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateSignaturePad
      );
      setValueSystemReport(
        "imageSignaturePad",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoSignaturePad
      );
      setValueSystemReport(
        "imageSignaturePadSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoSignaturePadSerial
      );
      setValueSystemReport(
        "brandWebcam",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandWebcam
      );
      setValueSystemReport(
        "modellWebcam",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellWebcam
      );
      setValueSystemReport(
        "serieWebcam",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieWebcam
      );
      setValueSystemReport(
        "licensePlateWebcam",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateWebcam
      );
      setValueSystemReport(
        "imageWebcam",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoWebcam
      );
      setValueSystemReport(
        "imageWebcamSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoWebcamSerial
      );
      setValueSystemReport(
        "brandBarcodeReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandBardcodeReader
      );
      setValueSystemReport(
        "modellBarcodeReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellBardcodeReader
      );
      setValueSystemReport(
        "serieBarcodeReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieBardcodeReader
      );
      setValueSystemReport(
        "licensePlateBarcodeReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateBardcodeReader
      );
      setValueSystemReport(
        "imageBarcodeReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoBardcodeReader
      );
      setValueSystemReport(
        "imageBarcodeReaderSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoBardcodeReaderSerial
      );
      setValueSystemReport(
        "brandPowerAdapter",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandPowerAdapter
      );
      setValueSystemReport(
        "modellPowerAdapter",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellPowerAdapter
      );
      setValueSystemReport(
        "seriePowerAdapter",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.seriePowerAdapter
      );
      setValueSystemReport(
        "licensePlatePowerAdapter",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlatePowerAdapter
      );
      setValueSystemReport(
        "imagePowerAdapter",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoPowerAdapter
      );
      setValueSystemReport(
        "imagePowerAdapterSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoPowerAdapterSerial
      );
      setValueSystemReport(
        "brandFingerprintReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandFingerprintReader
      );
      setValueSystemReport(
        "modellFingerprintReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellFingerprintReader
      );
      setValueSystemReport(
        "serieFingerprintReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieFingerprintReader
      );
      setValueSystemReport(
        "licensePlateFingerprintReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateFingerprintReader
      );
      setValueSystemReport(
        "imageFingerprintReader",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoFingerprintReader
      );
      setValueSystemReport(
        "imageFingerprintReaderSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoFingerprintReaderSerial
      );
      setValueSystemReport(
        "brandDiadems",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandDiadems
      );
      setValueSystemReport(
        "modellDiadems",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellDiadems
      );
      setValueSystemReport(
        "serieDiadems",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieDiadems
      );
      setValueSystemReport(
        "licensePlateDiadems",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateDiadems
      );
      setValueSystemReport(
        "imageDiadems",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoDiadems
      );
      setValueSystemReport(
        "imageDiademsSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoDiademsSerial
      );
      setValueSystemReport(
        "brandSource",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.brandSource
      );
      setValueSystemReport(
        "modellSource",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.modellSource
      );
      setValueSystemReport(
        "serieSource",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.serieSource
      );
      setValueSystemReport(
        "licensePlateSource",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.licensePlateSource
      );
      setValueSystemReport(
        "imageSource",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoSource
      );
      setValueSystemReport(
        "imageSourceSerial",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.photoSourceSerial
      );
      setValueSystemReport(
        "processors",
        fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.processors
      );
      let auxDisk = [];
      fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.diskComputer.map((item, i) => {
          auxDisk.push({
            id: item.id,
            brand: item.brand,
            capacity: item.capacity,
            serie: item.serie,
            status: "Funcional",
            type: item.type,
          });
        });
      setStatusDisks(auxDisk);
      let auxRams = [];
      fullDataEquipment.computersEquipment &&
        fullDataEquipment.computersEquipment.ramComputer.map((item, i) => {
          auxRams.push({
            id: item.id,
            brand: item.brand,
            capacity: item.capacity,
            serie: item.serie,
            status: "Funcional",
            type: item.type,
          });
        });
      setStatusRams(auxRams);
    }
  }, [fullDataEquipment]);

  useEffect(() => {
    dispatch(CaseReportSystemActions.getLastNumber());
  }, []);

  useEffect(() => {
    if (lastNumber.max) {
      resetSystemReport({
        ...watchSystemReport(),
        number: lastNumber.max + 1,
      });
    } else {
      resetSystemReport({
        ...watchSystemReport(),
        number: "1",
      });
    }
  }, [lastNumber]);

  const onExitSearch = () => {
    setVisibleExitSearch(false);
    dispatch(EquipmentActions.setEquipmentsFiltered(false));
  };

  useEffect(() => {
    const changedVar = Object.keys(stateVars.current).find(
      (key) => stateVars.current[key] !== eval(key)
    );
    let comp = "";
    if (changedVar && eval(changedVar) === "No Funcional") {
      comp = changedVar.replace("status", "").toUpperCase();
      if (comp === "LAPTOP") {
        comp = "PORTATIL";
      }
      if (comp === "ALLINONE") {
        comp = "EQUIPO TODO EN UNO";
      }
      if (comp === "SIGNATUREPAD") {
        comp = "PAD DE FIRMAS";
      }
      if (comp === "WEBCAM") {
        comp = "CAMARA WEB";
      }
      if (comp === "BARCODEREADER") {
        comp = "LECTOR DE CODIGO DE BARRAS";
      }
      if (comp === "FINGERPRINTREADER") {
        comp = "LECTOR DE HUELLA";
      }
      if (comp === "POWERADAPTER") {
        comp = "ADAPTADOR DE CORRIENTE";
      }
      if (comp === "DIADEMS") {
        comp = "DIADEMAS";
      }
      if (comp === "SOURCE") {
        comp = "FUENTE";
      }
      if (comp === "KEYBOARD") {
        comp = "TECLADO";
      }
      setValueSystemReport(`extraCompName${countComps}`, comp);
      setCountComps((prev) => prev + 1);
      setComponentsQuantity([
        ...componentsQuantity,
        {
          tipo: comp,
          marca: "",
          modelo: "",
          serie: "",
          placa: "",
          imagen: "",
          imagenSerie: "",
          estado: "",
        },
      ]);
    } else if (changedVar && eval(changedVar) !== "No Funcional") {
      comp = changedVar.replace("status", "").toUpperCase();
      if (comp === "LAPTOP") {
        comp = "PORTATIL";
      }
      if (comp === "ALLINONE") {
        comp = "EQUIPO TODO EN UNO";
      }
      if (comp === "SIGNATUREPAD") {
        comp = "PAD DE FIRMAS";
      }
      if (comp === "WEBCAM") {
        comp = "CAMARA WEB";
      }
      if (comp === "BARCODEREADER") {
        comp = "LECTOR DE CODIGO DE BARRAS";
      }
      if (comp === "FINGERPRINTREADER") {
        comp = "LECTOR DE HUELLA";
      }
      if (comp === "POWERADAPTER") {
        comp = "ADAPTADOR DE CORRIENTE";
      }
      if (comp === "DIADEMS") {
        comp = "DIADEMAS";
      }
      if (comp === "SOURCE") {
        comp = "FUENTE";
      }
      if (comp === "KEYBOARD") {
        comp = "TECLADO";
      }
      setComponentsQuantity(
        componentsQuantity.filter((item) => item.tipo !== comp)
      );
    }
    stateVars.current = {
      statusLaptop,
      statusAllInOne,
      statusCPU,
      statusGPU,
      statusMonitor,
      statusKeyboard,
      statusMouse,
      statusSignaturePad,
      statusWebcam,
      statusBarcodeReader,
      statusFingerprintReader,
      statusPowerAdapter,
      statusDiadems,
      statusSource,
    };
  }, [
    statusLaptop,
    statusAllInOne,
    statusCPU,
    statusGPU,
    statusMonitor,
    statusKeyboard,
    statusMouse,
    statusSignaturePad,
    statusWebcam,
    statusBarcodeReader,
    statusFingerprintReader,
    statusPowerAdapter,
    statusDiadems,
    statusSource,
  ]);

  useEffect(() => {
    if (statusProcessors) {
      const statusNoFuncional = statusProcessors.findIndex(
        (item) => item.status === "No Funcional"
      );
      const statusFuncional = statusProcessors.findIndex(
        (item) => item.status === "Funcional"
      );
      if (statusNoFuncional !== -1) {
        setExtraProcessors((prevExtraProcessors) => [
          ...prevExtraProcessors,
          {
            prevProcessor: statusProcessors[statusNoFuncional].processor,
            newProcessor: "",
          },
        ]);
      }
      if (statusFuncional !== -1) {
        setExtraProcessors(
          extraProcessors.filter(
            (item) =>
              item.prevProcessor !== statusProcessors[statusFuncional].processor
          )
        );
      }
    }
  }, [statusProcessors]);

  useEffect(() => {
    if (statusDisks) {
      const statusNoFuncional = statusDisks.findIndex(
        (item) => item.status === "No Funcional"
      );
      const statusFuncional = statusDisks.findIndex(
        (item) => item.status === "Funcional"
      );
      if (statusNoFuncional !== -1) {
        setExtraDisks((prevExtraDisks) => [
          ...prevExtraDisks,
          {
            id: statusDisks[statusNoFuncional].id,
            capacity: "",
            brand: "",
            serie: "",
            type: "",
          },
        ]);
      }
      if (statusFuncional !== -1) {
        setExtraDisks(
          extraDisks.filter(
            (item) => item.id !== statusDisks[statusFuncional].id
          )
        );
      }
    }
  }, [statusDisks]);

  useEffect(() => {
    if (statusRams) {
      const statusNoFuncional = statusRams.findIndex(
        (item) => item.status === "No Funcional"
      );
      const statusFuncional = statusRams.findIndex(
        (item) => item.status === "Funcional"
      );
      if (statusNoFuncional !== -1) {
        setExtraRams((prevExtraRams) => [
          ...prevExtraRams,
          {
            id: statusRams[statusNoFuncional].id,
            capacity: "",
            brand: "",
            serie: "",
            type: "",
          },
        ]);
      }
      if (statusFuncional !== -1) {
        setExtraRams(
          extraRams.filter((item) => item.id !== statusRams[statusFuncional].id)
        );
      }
    }
  }, [statusRams]);

  useEffect(() => {
    extraProcessors.forEach((proc, index) => {
      setValueSystemReport(`prevProcessor${index}`, proc.prevProcessor);
    });
  }, [extraProcessors, setValueSystemReport]);

  const handleAddFields = (type) => {
    const lastIndexOf = (arr, predicate) => {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (predicate(arr[i])) {
          return i;
        }
      }
      return -1;
    };
    const count = optionsType.filter((option) =>
      option.tipoSoftware.startsWith(type)
    ).length;
    const index = lastIndexOf(
      optionsType,
      (option) => option.tipoSoftware === type
    );
    const newItem = { tipoSoftware: type + count };

    if (index !== -1) {
      setOptionsType([
        ...optionsType.slice(0, index + 1),
        newItem,
        ...optionsType.slice(index + 1),
      ]);
    } else {
      setOptionsType([...optionsType, newItem]);
    }
  };

  const handleRemoveSoftware = (softwareType) => {
    setValueSystemReport(softwareType, undefined);
    if (/\d$/.test(softwareType)) {
      setOptionsType(
        optionsType.filter((option) => option.tipoSoftware !== softwareType)
      );
    }
  };

  const pageSize = 8
  const totalPages = Math.ceil(quantityEquipsWithCaseReports / pageSize);

  const onSides = (n) => {
    if (isFiltered) {
      if (currentPageFiltered < totalPages - 1 & n === 1) {
        setCurrentPageFiltered(prev => prev + n)
      }
      if (currentPageFiltered > 0 & n === -1) {
        setCurrentPageFiltered(prev => prev + n)
      }
    } else {
      if (currentPage < totalPages - 1 & n === 1) {
        setCurrentPage(prev => prev + n)
      }
      if (currentPage > 0 & n === -1) {
        setCurrentPage(prev => prev + n)
      }
    }
  }

  const generateNButtons = (N, onClick, currentPage, totalPages) =>
    [...Array(N).keys()].map(i => (
      currentPage === i ? <div className='radiobuttonSelected'
        key={`Button_${i}`}
        onClick={() => onClick(i)}
      >
        {i + 1}
      </div>
        :
        Math.abs(i - currentPage) <= 2 ?
          <div className='radiobutton'
            key={`Button_${i}`}
            onClick={() => onClick(i)}
          >
            {i + 1}
          </div>
          :
          i === totalPages - 1 ?
            <div className='radiobutton'
              key={`Button_${i}`}
              onClick={() => onClick(i)}
            >
              {i + 1}
            </div>
            :
            Math.abs(i - currentPage) === 3 &&
            <div className='radiobutton'
              key={`Button_${i}`}
              onClick={() => onClick(i)}
            >
              ...
            </div>
    ))

  return (
    <div className="FormServiceReportSystem">
      <div className="firstRep">
        {!equipSelected ? (
          <p className="featuresText">
            Selecciona la maquina a realizar el mantenimiento
          </p>
        ) : (
          <p className="featuresText">Maquina seleccionada</p>
        )}
        <div className="divSearchEquips">
          <Button
            className="Selected"
            onClick={() => {
              setVisibleModal(true);
              dispatch(EquipmentActions.setLoading(false));
            }}
          >
            <SearchOutlined className="plus" /> Buscar Equipos
          </Button>
          {visibleExitSearch && (
            <CloseCircleFilled
              className="closeIcon"
              onClick={() => onExitSearch()}
            />
          )}
        </div>
        <div className="div-equipment">
          {!data && equipmentsSearched && !equipSelected ? (
            equipmentsSearched.map((data, i) => {
              let equipmentType;

              if (data.networkingAndCommunicationsEquipment) {
                equipmentType = "networkingAndCommunicationsEquipment";
              } else if (data.peripheralsAndAccessoriesEquipment) {
                equipmentType = "peripheralsAndAccessoriesEquipment";
              } else if (data.computersEquipment) {
                equipmentType = "computersEquipment";
              }

              let brand, serie, modell, licensePlate;

              if (data.networkingAndCommunicationsEquipment) {
                brand = data.networkingAndCommunicationsEquipment.brand;
                serie = data.networkingAndCommunicationsEquipment.serie;
                modell = data.networkingAndCommunicationsEquipment.modell;
                licensePlate = data.licensePlate;
              } else if (data.peripheralsAndAccessoriesEquipment) {
                brand = data.peripheralsAndAccessoriesEquipment.brand;
                serie = data.peripheralsAndAccessoriesEquipment.serie;
                modell = data.peripheralsAndAccessoriesEquipment.modell;
                licensePlate = data.licensePlate;
              } else if (data.computersEquipment) {
                if (data.computersEquipment.brandCPU) {
                  brand = data.computersEquipment.brandCPU;
                  serie = data.computersEquipment.serieCPU;
                  modell = data.computersEquipment.modellCPU;
                  licensePlate = data.computersEquipment.licensePlateCPU;
                } else if (data.computersEquipment.brandAllInOne) {
                  brand = data.computersEquipment.brandAllInOne;
                  serie = data.computersEquipment.serieAllInOne;
                  modell = data.computersEquipment.modellAllInOne;
                  licensePlate = data.computersEquipment.licensePlateAllInOne;
                } else if (data.computersEquipment.brandLaptop) {
                  brand = data.computersEquipment.brandLaptop;
                  serie = data.computersEquipment.serieLaptop;
                  modell = data.computersEquipment.modellLaptop;
                  licensePlate = data.computersEquipment.licensePlateLaptop;
                }
              }
              return (
                <EquipmentCard
                  onChange={() => {
                    setEquipSelected({
                      name: data.name,
                      brand: brand,
                      model: modell,
                      serie: serie,
                      plate: licensePlate,
                      location: data.location.name,
                      equipmentType: equipmentType,
                      moduleWork: data.moduleWork.id,
                      state: data.state,
                      id: data.id,
                    });
                    setValueSystemReport("equipSelected", data.id);
                  }}
                  key={i}
                  name={data.name}
                  brand={brand}
                  model={modell}
                  serie={serie}
                  plate={licensePlate}
                  location={data.location.name}
                  equipmentType={equipmentType}
                  moduleWork={data.moduleWork.id}
                  state={data.state}
                  id={data.id}
                ></EquipmentCard>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "40vw",
              }}
            >
              {equipSelected && (
                <EquipmentCard
                  onChange={() => setEquipSelected(false)}
                  id={equipSelected.id}
                  /* img={equipSelected.image} */
                  name={equipSelected.name}
                  brand={equipSelected.brand}
                  model={equipSelected.modell}
                  serie={equipSelected.serie}
                  equipmentType={equipSelected.equipmentType}
                  location={equipSelected.location}
                  plate={equipSelected.plate}
                  moduleWork={equipSelected.moduleWork}
                  state={equipSelected.state}
                ></EquipmentCard>
              )}
            </div>
          )}
        </div>
        <div className='showingText'>
          Mostrando {((currentPage) * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, quantityEquipsWithCaseReports)} de {quantityEquipsWithCaseReports} elementos
        </div>
        <div className='divButtonsPages'>
          <div className='sidesButtons' onClick={() => onSides(-1)}>Anterior</div>
          {!isFiltered ?
            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
              {generateNButtons(totalPages, setCurrentPage, currentPage, totalPages)}
            </Radio.Group>
            :
            <Radio.Group className='RadioButtonGroup' buttonStyle={'solid'} defaultValue={0} size={totalPages}>
              {generateNButtons(totalPages, setCurrentPageFiltered, currentPageFiltered, totalPages)}
            </Radio.Group>
          }
          <div className='sidesButtons' onClick={() => onSides(1)}>Siguiente</div>
        </div>
        {equipSelected &&
          (equipSelected.name === "Computador de mesa" ||
            equipSelected.name === "Portatil" ||
            equipSelected.name === "Todo en uno" ||
            equipSelected.name === "Servidor") && (
            <div>
              <p className="featuresText">Descripción del servicio</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80vw",
                }}
              >
                <div style={{ position: "relative", top: "1.4vw" }}>
                  <p className="labelDate">Fecha inicio</p>
                  <DatePicker
                    value={watchSystemReport("startDate")}
                    {...registerSystemReport("startDate", {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("La fecha es requerida");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    })}
                    style={{ width: "10vw", height: "2vw" }}
                    onChange={(d) => setValueSystemReport("startDate", d)}
                  />
                  <p></p>
                  <button
                    className="buttonDeleteDate"
                    onClick={() => setValueSystemReport("startDate", undefined)}
                  >
                    Eliminar fecha
                  </button>
                  {errorsSystemReport.startDate && (
                    <p className="errorMessage">
                      {errorsSystemReport.startDate.message}
                    </p>
                  )}
                </div>
                <div style={{ position: "relative", top: "1.4vw" }}>
                  <p className="labelDate">Fecha fin</p>
                  <DatePicker
                    value={watchSystemReport("endDate")}
                    {...registerSystemReport("endDate", {})}
                    style={{ width: "10vw", height: "2vw" }}
                    onChange={(d) => setValueSystemReport("endDate", d)}
                  />
                  <p></p>
                  <button
                    className="buttonDeleteDate"
                    onClick={() => setValueSystemReport("startDate", undefined)}
                  >
                    Eliminar fecha
                  </button>
                  {errorsSystemReport.endDate && (
                    <p className="errorMessage">
                      {errorsSystemReport.endDate.message}
                    </p>
                  )}
                </div>
                <Input
                  {...registerSystemReport("nameEquip", {
                    validate: (value) => {
                      const errors = [];
                      if (!value || value === "") {
                        errors.push("El nombre del equipo es requerido");
                      }
                      return errors.length === 0 || errors.join(", ");
                    },
                  })}
                  height={"2vw"}
                  width={"10vw"}
                  fontSize={"1vw"}
                  mt={"1.4vw"}
                  va={watchSystemReport("nameEquip")}
                  setData={(e) => setValueSystemReport("nameEquip", e)}
                  labelstyle="inputLabelBordered"
                  error={
                    errorsSystemReport.nameEquip &&
                    errorsSystemReport.nameEquip.message
                  }
                  label="Nombre del Equipo"
                />
                <Input
                  {...registerSystemReport("passEquip", {})}
                  height={"2vw"}
                  width={"10vw"}
                  fontSize={"1vw"}
                  mt={"1.4vw"}
                  va={watchSystemReport("passEquip")}
                  setData={(e) => setValueSystemReport("passEquip", e)}
                  labelstyle="inputLabelBordered"
                  error={
                    errorsSystemReport.passEquip &&
                    errorsSystemReport.passEquip.message
                  }
                  label="Contraseña del equipo"
                />
                <Input
                  {...registerSystemReport("ipAddress", {
                    validate: (value) => {
                      const errors = [];
                      if (!value || value === "") {
                        errors.push("La IP es requerida");
                      }
                      return errors.length === 0 || errors.join(", ");
                    },
                  })}
                  height={"2vw"}
                  width={"10vw"}
                  fontSize={"1vw"}
                  mt={"1.4vw"}
                  va={watchSystemReport("ipAddress")}
                  setData={(e) => setValueSystemReport("ipAddress", e)}
                  labelstyle="inputLabelBordered"
                  error={
                    errorsSystemReport.ipAddress &&
                    errorsSystemReport.ipAddress.message
                  }
                  label="IP"
                />
                <Input
                  {...registerSystemReport("anyDeskNumber", {})}
                  height={"2vw"}
                  width={"10vw"}
                  fontSize={"1vw"}
                  mt={"1.4vw"}
                  va={watchSystemReport("anyDeskNumber")}
                  setData={(e) => setValueSystemReport("anyDeskNumber", e)}
                  labelstyle="inputLabelBordered"
                  error={
                    errorsSystemReport.anyDeskNumber &&
                    errorsSystemReport.anyDeskNumber.message
                  }
                  label="Numero de Anydesk"
                />
                <Input
                  {...registerSystemReport("anyDeskPass", {})}
                  height={"2vw"}
                  width={"10vw"}
                  fontSize={"1vw"}
                  mt={"1.4vw"}
                  va={watchSystemReport("anyDeskPass")}
                  setData={(e) => setValueSystemReport("anyDeskPass", e)}
                  labelstyle="inputLabelBordered"
                  error={
                    errorsSystemReport.anyDeskPass &&
                    errorsSystemReport.anyDeskPass.message
                  }
                  label="Contraseña Anydesk"
                />
              </div>
              <Input
                {...registerSystemReport("failReported", {
                  validate: (value) => {
                    const errors = [];
                    if (!value || value === "") {
                      errors.push("La falla reportada es requerida");
                    }
                    return errors.length === 0 || errors.join(", ");
                  },
                })}
                height={"5vw"}
                width={"76.5vw"}
                fontSize={"1vw"}
                mt={"1.8vw"}
                va={watchSystemReport("failReported")}
                setData={(e) => setValueSystemReport("failReported", e)}
                labelstyle="inputLabelBordered"
                error={
                  errorsSystemReport.failReported &&
                  errorsSystemReport.failReported.message
                }
                label="Falla Reportada:"
                textArea
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50vw",
                }}
              >
                <div>
                  <p className="titleSection" style={{ marginLeft: "0vw" }}>
                    Estado Físico
                  </p>
                  <div>
                    <Radio.Group
                      className="radioG"
                      {...registerSystemReport("physicStatus", {
                        validate: (value) => {
                          const errors = [];
                          if (!value || value === "") {
                            errors.push("El estado fisico es requerido");
                          }
                          return errors.length === 0 || errors.join(", ");
                        },
                      })}
                      onChange={(d) =>
                        setValueSystemReport("physicStatus", d.target.value)
                      }
                      value={watchSystemReport("physicStatus")}
                    >
                      <Radio className="radio" value={"Bueno"}>
                        Bueno
                      </Radio>
                      <Radio className="radio" value={"Malo"}>
                        Malo
                      </Radio>
                    </Radio.Group>
                    {errorsSystemReport.physicStatus && (
                      <p className="errorMessage">
                        {errorsSystemReport.physicStatus.message}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div>
                        <p className='titleSection' style={{ marginLeft: '0vw' }}>Lo están usando</p>
                        <div>
                            <Radio.Group
                                className='radioG'
                                {...registerSystemReport('isUsed', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("El uso es requerido");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValueSystemReport('isUsed', d.target.value)}
                                value={watchSystemReport('isUsed')}>
                                <Radio className='radio' value={'Si'}>Si</Radio>
                                <Radio className='radio' value={'Mo'}>No</Radio>
                            </Radio.Group>
                            {errorsSystemReport.isUsed && <p className='errorMessage'>{errorsSystemReport.isUsed.message}</p>}
                        </div>
                    </div>
                    <div>
                        <p className='titleSection' style={{ marginLeft: '0vw' }}>Funciona</p>
                        <div>
                            <Radio.Group
                                className='radioG'
                                {...registerSystemReport('isFunctional', {
                                    validate: (value) => {
                                        const errors = [];
                                        if (!value || value === '') {
                                            errors.push("Funcionalidad requerida");
                                        }
                                        return errors.length === 0 || errors.join(', ');
                                    },
                                })}
                                onChange={d => setValueSystemReport('isFunctional', d.target.value)}
                                value={watchSystemReport('isFunctional')}>
                                <Radio className='radio' value={'Si'}>Si</Radio>
                                <Radio className='radio' value={'Mo'}>No</Radio>
                            </Radio.Group>
                            {errorsSystemReport.isFunctional && <p className='errorMessage'>{errorsSystemReport.isFunctional.message}</p>}
                        </div>
                    </div> */}
              </div>
              <Input
                {...registerSystemReport("diagnostic", {
                  validate: (value) => {
                    const errors = [];
                    if (!value || value === "") {
                      errors.push("El diagnostico es requerido");
                    }
                    return errors.length === 0 || errors.join(", ");
                  },
                })}
                height={"5vw"}
                width={"76.5vw"}
                fontSize={"1vw"}
                mt={"1.4vw"}
                va={watchSystemReport("diagnostic")}
                setData={(e) => setValueSystemReport("diagnostic", e)}
                labelstyle="inputLabelBordered"
                error={
                  errorsSystemReport.diagnostic &&
                  errorsSystemReport.diagnostic.message
                }
                label="Diagnostico:"
                textArea
              />

              <p className="featuresText">
                Verifique el estado de las partes del equipo :
              </p>
              <div style={{ width: "100%" }}>
                <p className="titleSection">Caracteristicas Tecnicas</p>
                <div>
                  {equipSelected.name === "Portatil" && (
                    <div className="divInputs">
                      <Input
                        label={"Tipo"}
                        mr={"0.7vw"}
                        mt={"0.125vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        va={"PORTATIL"}
                        fontSize={"0.6vw"}
                        disabled
                      />
                      <Controller
                        name={"brandLaptop"}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Marca"
                            value={watchSystemReport("brandLaptop")}
                            disabled={true}
                            className="select"
                            style={{
                              width: "7vw",
                              height: "2vw",
                              marginTop: "0vw",
                              marginRight: "0.4vw",
                            }}
                          >
                            <Option value="Lenovo">Lenovo</Option>
                            <Option value="HP">HP</Option>
                            <Option value="Acer">Acer</Option>
                            <Option value="Apple">Apple</Option>
                            <Option value="Otras marcas de computadoras">
                              Otras marcas de computadoras
                            </Option>
                            <Option value="Alienware">Alienware</Option>
                            <Option value="Toshiba">Toshiba</Option>
                            <Option value="Dell">Dell</Option>
                            <Option value="Asus">Asus</Option>
                            <Option value="Samsung">Samsung</Option>
                            <Option value="Gateway">Gateway</Option>
                            <Option value="Sony">Sony</Option>
                            <Option value="MSI">MSI</Option>
                            <Option value="LG">LG</Option>
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`brandLaptop`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`brandLaptop`].message}
                        </p>
                      )}
                      <Input
                        {...registerSystemReport("modellLaptop", {})}
                        label={"Modelo"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        mt={"0.125vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("modellLaptop")}
                        disabled={true}
                        setData={(e) => setValueSystemReport("modellLaptop", e)}
                        error={
                          errorsSystemReport.modellLaptop &&
                          errorsSystemReport.modellLaptop.message
                        }
                      />
                      <Input
                        {...registerSystemReport("serieLaptop", {})}
                        label={"Serie"}
                        mt={"0.125vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("serieLaptop")}
                        disabled={true}
                        setData={(e) => setValueSystemReport("serieLaptop", e)}
                        error={
                          errorsSystemReport.serieLaptop &&
                          errorsSystemReport.serieLaptop.message
                        }
                      />
                      <Input
                        {...registerSystemReport("licensePlateLaptop", {})}
                        label={"Placa"}
                        mt={"0.125vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("licensePlateLaptop")}
                        disabled={true}
                        setData={(e) =>
                          setValueSystemReport("licensePlateLaptop", e)
                        }
                        error={
                          errorsSystemReport.licensePlateLaptop &&
                          errorsSystemReport.licensePlateLaptop.message
                        }
                      />
                      <div
                        style={{
                          marginTop: "-0.80vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p className="titleColumn">Foto</p>
                        {!watchSystemReport("imageLaptop") ? (
                          <input
                            {...registerSystemReport("imageLaptop", {})}
                            type="file"
                            disabled={true}
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport("imageLaptop", base64);
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport("imageLaptop")}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport("imageLaptop", undefined)
                            }
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: "-0.80vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p className="titleColumn">Foto serial</p>
                        {!watchSystemReport("imageLaptopSerial") ? (
                          <input
                            {...registerSystemReport("imageLaptopSerial", {})}
                            type="file"
                            disabled={true}
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport(
                                  "imageLaptopSerial",
                                  base64
                                );
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport("imageLaptopSerial")}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport(
                                "imageLaptopSerial",
                                undefined
                              )
                            }
                          ></img>
                        )}
                      </div>
                      <Controller
                        name={"statusLaptop"}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Estado"
                            value={watchSystemReport("statusLaptop")}
                            onChange={(e) => setStatusLaptop(e)}
                            className="select"
                            style={{
                              width: "14vw",
                              height: "2vw",
                              marginTop: "-1.4vw",
                              marginRight: "0.7vw",
                              marginBottom: "-1.2vw",
                            }}
                          >
                            <Option value="Funcional">Funcional</Option>
                            <Option value="No Funcional">No Funcional</Option>
                            {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`statusLaptop`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`statusLaptop`].message}
                        </p>
                      )}
                    </div>
                  )}
                  {equipSelected.name === "Todo en uno" && (
                    <div className="divInputs">
                      <Input
                        label={"Tipo"}
                        mr={"0.7vw"}
                        mt={"0.125vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        va={"EQUIPO TODO EN UNO INTEGRADO"}
                        fontSize={"0.6vw"}
                        disabled
                      />
                      <Controller
                        name={"brandAllInOne"}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Marca"
                            value={watchSystemReport("brandAllInOne")}
                            disabled={true}
                            className="select"
                            style={{
                              width: "7vw",
                              height: "2vw",
                              marginTop: "0vw",
                              marginRight: "0.4vw",
                            }}
                          >
                            <Option value="Lenovo">Lenovo</Option>
                            <Option value="HP">HP</Option>
                            <Option value="Acer">Acer</Option>
                            <Option value="Apple">Apple</Option>
                            <Option value="Otras marcas de computadoras">
                              Otras marcas de computadoras
                            </Option>
                            <Option value="Alienware">Alienware</Option>
                            <Option value="Toshiba">Toshiba</Option>
                            <Option value="Dell">Dell</Option>
                            <Option value="Asus">Asus</Option>
                            <Option value="Samsung">Samsung</Option>
                            <Option value="Gateway">Gateway</Option>
                            <Option value="Sony">Sony</Option>
                            <Option value="MSI">MSI</Option>
                            <Option value="LG">LG</Option>
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`brandAllInOne`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`brandAllInOne`].message}
                        </p>
                      )}

                      <Input
                        {...registerSystemReport("modellAllInOne", {})}
                        label={"Modelo"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        mt={"0.125vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("modellAllInOne")}
                        disabled={true}
                        setData={(e) =>
                          setValueSystemReport("modellAllInOne", e)
                        }
                        error={
                          errorsSystemReport.modellAllInOne &&
                          errorsSystemReport.modellAllInOne.message
                        }
                      />
                      <Input
                        {...registerSystemReport("serieAllInOne", {})}
                        label={"Serie"}
                        mt={"0.125vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("serieAllInOne")}
                        disabled={true}
                        setData={(e) =>
                          setValueSystemReport("serieAllInOne", e)
                        }
                        error={
                          errorsSystemReport.serieAllInOne &&
                          errorsSystemReport.serieAllInOne.message
                        }
                      />
                      <Input
                        {...registerSystemReport("licensePlateAllInOne", {})}
                        label={"Placa"}
                        mt={"0.125vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("licensePlateAllInOne")}
                        disabled={true}
                        setData={(e) =>
                          setValueSystemReport("licensePlateAllInOne", e)
                        }
                        error={
                          errorsSystemReport.licensePlateAllInOne &&
                          errorsSystemReport.licensePlateAllInOne.message
                        }
                      />
                      <div
                        style={{
                          marginTop: "-0.80vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p className="titleColumn">Foto</p>
                        {!watchSystemReport("imageAllInOne") ? (
                          <input
                            {...registerSystemReport("imageAllInOne", {})}
                            type="file"
                            disabled={true}
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport("imageAllInOne", base64);
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport("imageAllInOne")}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport("imageAllInOne", undefined)
                            }
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: "-0.80vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p className="titleColumn">Foto serial</p>
                        {!watchSystemReport("imageAllInOneSerial") ? (
                          <input
                            {...registerSystemReport("imageAllInOneSerial", {})}
                            type="file"
                            disabled={true}
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport(
                                  "imageAllInOneSerial",
                                  base64
                                );
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport("imageAllInOneSerial")}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport(
                                "imageAllInOneSerial",
                                undefined
                              )
                            }
                          ></img>
                        )}
                      </div>
                      <Controller
                        name={"statusAllInOne"}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Estado"
                            value={watchSystemReport("statusAllInOne")}
                            onChange={(e) => setStatusAllInOne(e)}
                            className="select"
                            style={{
                              width: "14vw",
                              height: "2vw",
                              marginTop: "-1.4vw",
                              marginRight: "0.7vw",
                              marginBottom: "-1.2vw",
                            }}
                          >
                            <Option value="Funcional">Funcional</Option>
                            <Option value="No Funcional">No Funcional</Option>
                            {/*  <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`statusAllInOne`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`statusAllInOne`].message}
                        </p>
                      )}
                    </div>
                  )}
                  {(equipSelected.name === "Computador de mesa" || equipSelected.name === "Servidor") &&
                    fullDataEquipment.computersEquipment && (
                      <div className="divInputs">
                        <Input
                          label={"Tipo"}
                          mr={"0.7vw"}
                          mt={"0.125vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          va={"CPU"}
                          fontSize={"0.6vw"}
                          disabled
                        />
                        <Controller
                          name={"brandCPU"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Marca"
                              value={watchSystemReport("brandCPU")}
                              disabled={true}
                              className="select"
                              style={{
                                width: "7vw",
                                height: "2vw",
                                marginTop: "0vw",
                                marginRight: "0.4vw",
                              }}
                            >
                              <Option value="Lenovo">Lenovo</Option>
                              <Option value="HP">HP</Option>
                              <Option value="Acer">Acer</Option>
                              <Option value="Apple">Apple</Option>
                              <Option value="Otras marcas de computadoras">
                                Otras marcas de computadoras
                              </Option>
                              <Option value="Alienware">Alienware</Option>
                              <Option value="Toshiba">Toshiba</Option>
                              <Option value="Dell">Dell</Option>
                              <Option value="Asus">Asus</Option>
                              <Option value="Samsung">Samsung</Option>
                              <Option value="Gateway">Gateway</Option>
                              <Option value="Sony">Sony</Option>
                              <Option value="MSI">MSI</Option>
                              <Option value="LG">LG</Option>
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`brandCPU`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`brandCPU`].message}
                          </p>
                        )}

                        <Input
                          {...registerSystemReport("modellCPU", {})}
                          label={"Modelo"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          mt={"0.125vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("modellCPU")}
                          disabled={true}
                          setData={(e) => setValueSystemReport("modellCPU", e)}
                          error={
                            errorsSystemReport.modellCPU &&
                            errorsSystemReport.modellCPU.message
                          }
                        />
                        <Input
                          {...registerSystemReport("serieCPU", {})}
                          label={"Serie"}
                          mt={"0.125vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("serieCPU")}
                          disabled={true}
                          setData={(e) => setValueSystemReport("serieCPU", e)}
                          error={
                            errorsSystemReport.serieCPU &&
                            errorsSystemReport.serieCPU.message
                          }
                        />
                        <Input
                          {...registerSystemReport("licensePlateCPU", {})}
                          label={"Placa"}
                          mt={"0.125vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("licensePlateCPU")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("licensePlateCPU", e)
                          }
                          error={
                            errorsSystemReport.licensePlateCPU &&
                            errorsSystemReport.licensePlateCPU.message
                          }
                        />
                        <div
                          style={{
                            marginTop: "-0.80vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className="titleColumn">Foto</p>
                          {!watchSystemReport("imageCPU") ? (
                            <input
                              {...registerSystemReport("imageCPU", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport("imageCPU", base64);
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageCPU")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport("imageCPU", undefined)
                              }
                            ></img>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "-0.80vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className="titleColumn">Foto serial</p>
                          {!watchSystemReport("imageCPUSerial") ? (
                            <input
                              {...registerSystemReport("imageCPUSerial", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport(
                                    "imageCPUSerial",
                                    base64
                                  );
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageCPUSerial")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport(
                                  "imageCPUSerial",
                                  undefined
                                )
                              }
                            ></img>
                          )}
                        </div>
                        <Controller
                          name={"statusCPU"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Estado"
                              value={watchSystemReport("statusCPU")}
                              onChange={(e) => setStatusCPU(e)}
                              className="select"
                              style={{
                                width: "14vw",
                                height: "2vw",
                                marginTop: "-1.4vw",
                                marginRight: "0.7vw",
                                marginBottom: "-1.2vw",
                              }}
                            >
                              <Option value="Funcional">Funcional</Option>
                              <Option value="No Funcional">No Funcional</Option>
                              {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`statusCPU`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`statusCPU`].message}
                          </p>
                        )}
                      </div>
                    )}
                  {(equipSelected.name === "Computador de mesa") &&
                    fullDataEquipment.computersEquipment && (
                      <div className="divInputs">
                        <Input
                          label={"Tipo"}
                          mr={"0.7vw"}
                          mt={"0.125vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          va={"GPU"}
                          fontSize={"0.6vw"}
                          disabled
                        />
                        <Controller
                          name={"brandGPU"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Marca"
                              value={watchSystemReport("brandGPU")}
                              disabled={true}
                              className="select"
                              style={{
                                width: "7vw",
                                height: "2vw",
                                marginTop: "0vw",
                                marginRight: "0.4vw",
                              }}
                            >
                              <Option value="GIGABYTE">GIGABYTE</Option>
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`brandGPU`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`brandGPU`].message}
                          </p>
                        )}

                        <Controller
                          name={"modellGPU"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Modelo"
                              value={watchSystemReport("modellGPU")}
                              disabled={true}
                              className="select"
                              style={{
                                width: "7vw",
                                height: "2vw",
                                marginTop: "0vw",
                                marginRight: "0.4vw",
                              }}
                            >
                              <Option value="GeForce RTX 3090 Ti">GeForce RTX 3090 Ti</Option>
                              <Option value="GeForce RTX 3090">GeForce RTX 3090</Option>
                              <Option value="GeForce RTX 3080 Ti">GeForce RTX 3080 Ti</Option>
                              <Option value="GeForce RTX 3080">GeForce RTX 3080</Option>
                              <Option value="GeForce RTX 3070 Ti">GeForce RTX 3070 Ti</Option>
                              <Option value="GeForce RTX 3070">GeForce RTX 3070</Option>
                              <Option value="GeForce RTX 3060 Ti">GeForce RTX 3060 Ti</Option>
                              <Option value="GeForce RTX 3060">GeForce RTX 3060</Option>
                              <Option value="GeForce RTX 3050">GeForce RTX 3050</Option>
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`modellGPU`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`modellGPU`].message}
                          </p>
                        )}
                        <Input
                          {...registerSystemReport("serieGPU", {})}
                          label={"Serie"}
                          mt={"0.125vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("serieGPU")}
                          disabled={true}
                          setData={(e) => setValueSystemReport("serieGPU", e)}
                          error={
                            errorsSystemReport.serieGPU &&
                            errorsSystemReport.serieGPU.message
                          }
                        />
                        <Input
                          {...registerSystemReport("licensePlateGPU", {})}
                          label={"Placa"}
                          mt={"0.125vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("licensePlateGPU")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("licensePlateGPU", e)
                          }
                          error={
                            errorsSystemReport.licensePlateGPU &&
                            errorsSystemReport.licensePlateGPU.message
                          }
                        />
                        <div
                          style={{
                            marginTop: "-0.80vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className="titleColumn">Foto</p>
                          {!watchSystemReport("imageGPU") ? (
                            <input
                              {...registerSystemReport("imageGPU", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport("imageGPU", base64);
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageGPU")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport("imageGPU", undefined)
                              }
                            ></img>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "-0.80vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className="titleColumn">Foto serial</p>
                          {!watchSystemReport("imageGPUSerial") ? (
                            <input
                              {...registerSystemReport("imageGPUSerial", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport(
                                    "imageGPUSerial",
                                    base64
                                  );
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageGPUSerial")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport(
                                  "imageGPUSerial",
                                  undefined
                                )
                              }
                            ></img>
                          )}
                        </div>
                        <Controller
                          name={"statusGPU"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Estado"
                              value={watchSystemReport("statusGPU")}
                              onChange={(e) => setStatusGPU(e)}
                              className="select"
                              style={{
                                width: "14vw",
                                height: "2vw",
                                marginTop: "-1.4vw",
                                marginRight: "0.7vw",
                                marginBottom: "-1.2vw",
                              }}
                            >
                              <Option value="Funcional">Funcional</Option>
                              <Option value="No Funcional">No Funcional</Option>
                              {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`statusGPU`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`statusGPU`].message}
                          </p>
                        )}
                      </div>
                    )}
                  {(equipSelected.name === "Computador de mesa" || equipSelected.name === "Servidor") && (
                    <div className="divInputs">
                      <Input
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        va={"MONITOR"}
                        fontSize={"0.6vw"}
                        disabled
                      />
                      <Controller
                        name={"brandMonitor"}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Marca"
                            value={watchSystemReport("brandMonitor")}
                            disabled={true}
                            className="select"
                            style={{
                              width: "7vw",
                              height: "2vw",
                              marginTop: "-2.2vw",
                              marginRight: "0.4vw",
                            }}
                          >
                            <Option value="Lenovo">Lenovo</Option>
                            <Option value="HP">HP</Option>
                            <Option value="Acer">Acer</Option>
                            <Option value="Apple">Apple</Option>
                            <Option value="Otras marcas de computadoras">
                              Otras marcas de computadoras
                            </Option>
                            <Option value="Alienware">Alienware</Option>
                            <Option value="Toshiba">Toshiba</Option>
                            <Option value="Dell">Dell</Option>
                            <Option value="Asus">Asus</Option>
                            <Option value="Samsung">Samsung</Option>
                            <Option value="Gateway">Gateway</Option>
                            <Option value="Sony">Sony</Option>
                            <Option value="MSI">MSI</Option>
                            <Option value="LG">LG</Option>
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`brandMonitor`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`brandMonitor`].message}
                        </p>
                      )}

                      <Input
                        {...registerSystemReport("modellMonitor", {})}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("modellMonitor")}
                        disabled={true}
                        setData={(e) =>
                          setValueSystemReport("modellMonitor", e)
                        }
                        error={
                          errorsSystemReport.modellMonitor &&
                          errorsSystemReport.modellMonitor.message
                        }
                      />
                      <Input
                        {...registerSystemReport("serieMonitor", {})}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("serieMonitor")}
                        disabled={true}
                        setData={(e) => setValueSystemReport("serieMonitor", e)}
                        error={
                          errorsSystemReport.serieMonitor &&
                          errorsSystemReport.serieMonitor.message
                        }
                      />
                      <Input
                        {...registerSystemReport("licensePlateMonitor", {})}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport("licensePlateMonitor")}
                        disabled={true}
                        setData={(e) =>
                          setValueSystemReport("licensePlateMonitor", e)
                        }
                        error={
                          errorsSystemReport.licensePlateMonitor &&
                          errorsSystemReport.licensePlateMonitor.message
                        }
                      />
                      <div
                        style={{
                          marginTop: "-1.5vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {!watchSystemReport("imageMonitor") ? (
                          <input
                            {...registerSystemReport("imageMonitor", {})}
                            type="file"
                            disabled={true}
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport("imageMonitor", base64);
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport("imageMonitor")}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport("imageMonitor", undefined)
                            }
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: "-1.5vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {!watchSystemReport("imageMonitorSerial") ? (
                          <input
                            {...registerSystemReport("imageMonitorSerial", {})}
                            type="file"
                            disabled={true}
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport(
                                  "imageMonitorSerial",
                                  base64
                                );
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport("imageMonitorSerial")}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport(
                                "imageMonitorSerial",
                                undefined
                              )
                            }
                          ></img>
                        )}
                      </div>
                      <Controller
                        name={"statusMonitor"}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Estado"
                            value={watchSystemReport("statusMonitor")}
                            onChange={(e) => setStatusMonitor(e)}
                            style={{
                              width: "14vw",
                              height: "2vw",
                              marginTop: "-1.7vw",
                              marginRight: "0.7vw",
                            }}
                          >
                            <Option value="Funcional">Funcional</Option>
                            <Option value="No Funcional">No Funcional</Option>
                            {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`statusMonitor`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`statusMonitor`].message}
                        </p>
                      )}
                    </div>
                  )}
                  {(equipSelected.name === "Todo en uno" ||
                    equipSelected.name === "Computador de mesa" ||
                    equipSelected.name === "Servidor") && (
                      <div className="divInputs">
                        <Input
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          va={"TECLADO"}
                          fontSize={"0.6vw"}
                          disabled
                        />
                        <Controller
                          name={"brandKeyboard"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Marca"
                              value={watchSystemReport("brandKeyboard")}
                              disabled={true}
                              className="select"
                              style={{
                                width: "7vw",
                                height: "2vw",
                                marginTop: "-2.2vw",
                                marginRight: "0.4vw",
                              }}
                            >
                              <Option value="Lenovo">Lenovo</Option>
                              <Option value="HP">HP</Option>
                              <Option value="Acer">Acer</Option>
                              <Option value="Apple">Apple</Option>
                              <Option value="Otras marcas de computadoras">
                                Otras marcas de computadoras
                              </Option>
                              <Option value="Alienware">Alienware</Option>
                              <Option value="Toshiba">Toshiba</Option>
                              <Option value="Dell">Dell</Option>
                              <Option value="Asus">Asus</Option>
                              <Option value="Samsung">Samsung</Option>
                              <Option value="Gateway">Gateway</Option>
                              <Option value="Sony">Sony</Option>
                              <Option value="MSI">MSI</Option>
                              <Option value="LG">LG</Option>
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`brandKeyboard`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`brandKeyboard`].message}
                          </p>
                        )}

                        <Input
                          {...registerSystemReport("modellKeyboard", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("modellKeyboard")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("modellKeyboard", e)
                          }
                          error={
                            errorsSystemReport.modellKeyboard &&
                            errorsSystemReport.modellKeyboard.message
                          }
                        />
                        <Input
                          {...registerSystemReport("serieKeyboard", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("serieKeyboard")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("serieKeyboard", e)
                          }
                          error={
                            errorsSystemReport.serieKeyboard &&
                            errorsSystemReport.serieKeyboard.message
                          }
                        />
                        <Input
                          {...registerSystemReport("licensePlateKeyboard", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("licensePlateKeyboard")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("licensePlateKeyboard", e)
                          }
                          error={
                            errorsSystemReport.licensePlateKeyboard &&
                            errorsSystemReport.licensePlateKeyboard.message
                          }
                        />
                        <div
                          style={{
                            marginTop: "-1.5vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!watchSystemReport("imageKeyboard") ? (
                            <input
                              {...registerSystemReport("imageKeyboard", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport("imageKeyboard", base64);
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageKeyboard")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport("imageKeyboard", undefined)
                              }
                            ></img>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "-1.5vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!watchSystemReport("imageKeyboardSerial") ? (
                            <input
                              {...registerSystemReport("imageKeyboardSerial", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport(
                                    "imageKeyboardSerial",
                                    base64
                                  );
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageKeyboardSerial")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport(
                                  "imageKeyboardSerial",
                                  undefined
                                )
                              }
                            ></img>
                          )}
                        </div>
                        <Controller
                          name={"statusKeyboard"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Estado"
                              value={watchSystemReport("statusKeyboard")}
                              onChange={(e) => setStatusKeyboard(e)}
                              className="select"
                              style={{
                                width: "14vw",
                                height: "2vw",
                                marginTop: "-1.4vw",
                                marginRight: "0.7vw",
                              }}
                            >
                              <Option value="Funcional">Funcional</Option>
                              <Option value="No Funcional">No Funcional</Option>
                              {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`statusKeyboard`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`statusKeyboard`].message}
                          </p>
                        )}
                      </div>
                    )}
                  {(equipSelected.name === "Todo en uno" ||
                    equipSelected.name === "Computador de mesa" ||
                    equipSelected.name === "Servidor") && (
                      <div className="divInputs">
                        <Input
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          va={"MOUSE"}
                          fontSize={"0.6vw"}
                          disabled
                        />
                        <Controller
                          name={"brandMouse"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Marca"
                              value={watchSystemReport("brandMouse")}
                              disabled={true}
                              className="select"
                              style={{
                                width: "7vw",
                                height: "2vw",
                                marginTop: "-2.2vw",
                                marginRight: "0.4vw",
                              }}
                            >
                              <Option value="Lenovo">Lenovo</Option>
                              <Option value="HP">HP</Option>
                              <Option value="Acer">Acer</Option>
                              <Option value="Apple">Apple</Option>
                              <Option value="Otras marcas de computadoras">
                                Otras marcas de computadoras
                              </Option>
                              <Option value="Alienware">Alienware</Option>
                              <Option value="Toshiba">Toshiba</Option>
                              <Option value="Dell">Dell</Option>
                              <Option value="Asus">Asus</Option>
                              <Option value="Samsung">Samsung</Option>
                              <Option value="Gateway">Gateway</Option>
                              <Option value="Sony">Sony</Option>
                              <Option value="MSI">MSI</Option>
                              <Option value="LG">LG</Option>
                              <Option value="Genius">Genius</Option>
                              <Option value="REDRAGON">REDRAGON</Option>
                              <Option value="MAXELL">MAXELL</Option>
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`brandMouse`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`brandMouse`].message}
                          </p>
                        )}

                        <Input
                          {...registerSystemReport("modellMouse", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("modellMouse")}
                          disabled={true}
                          setData={(e) => setValueSystemReport("modellMouse", e)}
                          error={
                            errorsSystemReport.modellMouse &&
                            errorsSystemReport.modellMouse.message
                          }
                        />
                        <Input
                          {...registerSystemReport("serieMouse", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("serieMouse")}
                          disabled={true}
                          setData={(e) => setValueSystemReport("serieMouse", e)}
                          error={
                            errorsSystemReport.serieMouse &&
                            errorsSystemReport.serieMouse.message
                          }
                        />
                        <Input
                          {...registerSystemReport("licensePlateMouse", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("licensePlateMouse")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("licensePlateMouse", e)
                          }
                          error={
                            errorsSystemReport.licensePlateMouse &&
                            errorsSystemReport.licensePlateMouse.message
                          }
                        />
                        <div
                          style={{
                            marginTop: "-1.5vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!watchSystemReport("imageMouse") ? (
                            <input
                              {...registerSystemReport("imageMouse", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport("imageMouse", base64);
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageMouse")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport("imageMouse", undefined)
                              }
                            ></img>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "-1.5vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!watchSystemReport("imageMouseSerial") ? (
                            <input
                              {...registerSystemReport("imageMouseSerial", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport(
                                    "imageMouseSerial",
                                    base64
                                  );
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imageMouseSerial")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport(
                                  "imageMouseSerial",
                                  undefined
                                )
                              }
                            ></img>
                          )}
                        </div>
                        <Controller
                          name={"statusMouse"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Estado"
                              value={watchSystemReport("statusMouse")}
                              onChange={(e) => setStatusMouse(e)}
                              className="select"
                              style={{
                                width: "14vw",
                                height: "2vw",
                                marginTop: "-1.4vw",
                                marginRight: "0.7vw",
                              }}
                            >
                              <Option value="Funcional">Funcional</Option>
                              <Option value="No Funcional">No Funcional</Option>
                              {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`statusMouse`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`statusMouse`].message}
                          </p>
                        )}
                      </div>
                    )}
                  <div className="divInputs">
                    <Input
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      va={"PAD DE FIRMAS"}
                      fontSize={"0.6vw"}
                      disabled
                    />
                    <Controller
                      name={"brandSignaturePad"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Marca"
                          value={watchSystemReport("brandSignaturePad")}
                          disabled={true}
                          className="select"
                          style={{
                            width: "7vw",
                            height: "2vw",
                            marginTop: "-2.2vw",
                            marginRight: "0.4vw",
                          }}
                        >
                          <Option value="Lenovo">Lenovo</Option>
                          <Option value="HP">HP</Option>
                          <Option value="Acer">Acer</Option>
                          <Option value="Apple">Apple</Option>
                          <Option value="Otras marcas de computadoras">
                            Otras marcas de computadoras
                          </Option>
                          <Option value="Alienware">Alienware</Option>
                          <Option value="Toshiba">Toshiba</Option>
                          <Option value="Dell">Dell</Option>
                          <Option value="Asus">Asus</Option>
                          <Option value="Samsung">Samsung</Option>
                          <Option value="Gateway">Gateway</Option>
                          <Option value="Sony">Sony</Option>
                          <Option value="MSI">MSI</Option>
                          <Option value="LG">LG</Option>
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`brandSignaturePad`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`brandSignaturePad`].message}
                      </p>
                    )}

                    <Input
                      {...registerSystemReport("modellSignaturePad", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("modellSignaturePad")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("modellSignaturePad", e)
                      }
                    />
                    <Input
                      {...registerSystemReport("serieSignaturePad", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("serieSignaturePad")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("serieSignaturePad", e)
                      }
                    />
                    <Input
                      {...registerSystemReport("licensePlateSignaturePad", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("licensePlateSignaturePad")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("licensePlateSignaturePad", e)
                      }
                    />
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageSignaturePad") ? (
                        <input
                          {...registerSystemReport("imageSignaturePad", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport("imageSignaturePad", base64);
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageSignaturePad")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport("imageSignaturePad", undefined)
                          }
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageSignaturePadSerial") ? (
                        <input
                          {...registerSystemReport(
                            "imageSignaturePadSerial",
                            {}
                          )}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport(
                                "imageSignaturePadSerial",
                                base64
                              );
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageSignaturePadSerial")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport(
                              "imageSignaturePadSerial",
                              undefined
                            )
                          }
                        ></img>
                      )}
                    </div>
                    <Controller
                      name={"statusSignaturePad"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Estado"
                          value={watchSystemReport("statusSignaturePad")}
                          onChange={(e) => setStatusSignaturePad(e)}
                          className="select"
                          style={{
                            width: "14vw",
                            height: "2vw",
                            marginTop: "-1.4vw",
                            marginRight: "0.7vw",
                          }}
                        >
                          <Option value="Funcional">Funcional</Option>
                          <Option value="No Funcional">No Funcional</Option>
                          {/* <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option> */}
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`statusSignaturePad`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`statusSignaturePad`].message}
                      </p>
                    )}
                  </div>
                  <div className="divInputs">
                    <Input
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      va={"CAMARA WEB"}
                      fontSize={"0.6vw"}
                      disabled
                    />
                    <Controller
                      name={"brandWebcam"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Marca"
                          value={watchSystemReport("brandWebcam")}
                          disabled={true}
                          className="select"
                          style={{
                            width: "7vw",
                            height: "2vw",
                            marginTop: "-2.2vw",
                            marginRight: "0.4vw",
                          }}
                        >
                          <Option value="Lenovo">Lenovo</Option>
                          <Option value="HP">HP</Option>
                          <Option value="Acer">Acer</Option>
                          <Option value="Apple">Apple</Option>
                          <Option value="Otras marcas de computadoras">
                            Otras marcas de computadoras
                          </Option>
                          <Option value="Alienware">Alienware</Option>
                          <Option value="Toshiba">Toshiba</Option>
                          <Option value="Dell">Dell</Option>
                          <Option value="Asus">Asus</Option>
                          <Option value="Samsung">Samsung</Option>
                          <Option value="Gateway">Gateway</Option>
                          <Option value="Sony">Sony</Option>
                          <Option value="MSI">MSI</Option>
                          <Option value="LG">LG</Option>
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`brandWebcam`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`brandWebcam`].message}
                      </p>
                    )}

                    <Input
                      {...registerSystemReport("modellWebcam", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("modellWebcam")}
                      disabled={true}
                      setData={(e) => setValueSystemReport("modellWebcam", e)}
                    />
                    <Input
                      {...registerSystemReport("serieWebcam", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("serieWebcam")}
                      disabled={true}
                      setData={(e) => setValueSystemReport("serieWebcam", e)}
                    />
                    <Input
                      {...registerSystemReport("licensePlateWebcam", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("licensePlateWebcam")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("licensePlateWebcam", e)
                      }
                    />
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageWebcam") ? (
                        <input
                          {...registerSystemReport("imageWebcam", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport("imageWebcam", base64);
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageWebcam")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport("imageWebcam", undefined)
                          }
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageWebcamSerial") ? (
                        <input
                          {...registerSystemReport("imageWebcamSerial", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport("imageWebcamSerial", base64);
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageWebcamSerial")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport("imageWebcamSerial", undefined)
                          }
                        ></img>
                      )}
                    </div>
                    <Controller
                      name={"statusWebcam"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Estado"
                          value={watchSystemReport("statusWebcam")}
                          onChange={(e) => setStatusWebcam(e)}
                          className="select"
                          style={{
                            width: "14vw",
                            height: "2vw",
                            marginTop: "-1.4vw",
                            marginRight: "0.7vw",
                          }}
                        >
                          <Option value="Funcional">Funcional</Option>
                          <Option value="No Funcional">No Funcional</Option>
                          {/* <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option> */}
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`statusWebcam`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`statusWebcam`].message}
                      </p>
                    )}
                  </div>
                  <div className="divInputs">
                    <Input
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      va={"LECTOR DE CODIGO DE BARRAS"}
                      fontSize={"0.6vw"}
                      disabled
                    />
                    <Controller
                      name={"brandBarcodeReader"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Marca"
                          value={watchSystemReport("brandBarcodeReader")}
                          disabled={true}
                          className="select"
                          style={{
                            width: "7vw",
                            height: "2vw",
                            marginTop: "-2.2vw",
                            marginRight: "0.4vw",
                          }}
                        >
                          <Option value="Lenovo">Lenovo</Option>
                          <Option value="HP">HP</Option>
                          <Option value="Acer">Acer</Option>
                          <Option value="Apple">Apple</Option>
                          <Option value="Otras marcas de computadoras">
                            Otras marcas de computadoras
                          </Option>
                          <Option value="Alienware">Alienware</Option>
                          <Option value="Toshiba">Toshiba</Option>
                          <Option value="Dell">Dell</Option>
                          <Option value="Asus">Asus</Option>
                          <Option value="Samsung">Samsung</Option>
                          <Option value="Gateway">Gateway</Option>
                          <Option value="Sony">Sony</Option>
                          <Option value="MSI">MSI</Option>
                          <Option value="LG">LG</Option>
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`brandBarcodeReader`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`brandBarcodeReader`].message}
                      </p>
                    )}

                    <Input
                      {...registerSystemReport("modellBarcodeReader", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("modellBarcodeReader")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("modellBarcodeReader", e)
                      }
                    />
                    <Input
                      {...registerSystemReport("serieBarcodeReader", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("serieBarcodeReader")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("serieBarcodeReader", e)
                      }
                    />
                    <Input
                      {...registerSystemReport("licensePlateBarcodeReader", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("licensePlateBarcodeReader")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("licensePlateBarcodeReader", e)
                      }
                    />
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageBarcodeReader") ? (
                        <input
                          {...registerSystemReport("imageBarcodeReader", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport(
                                "imageBarcodeReader",
                                base64
                              );
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageBarcodeReader")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport(
                              "imageBarcodeReader",
                              undefined
                            )
                          }
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageBarcodeReaderSerial") ? (
                        <input
                          {...registerSystemReport(
                            "imageBarcodeReaderSerial",
                            {}
                          )}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport(
                                "imageBarcodeReaderSerial",
                                base64
                              );
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageBarcodeReaderSerial")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport(
                              "imageBarcodeReaderSerial",
                              undefined
                            )
                          }
                        ></img>
                      )}
                    </div>
                    <Controller
                      name={"statusBarcodeReader"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Estado"
                          value={watchSystemReport("statusBarcodeReader")}
                          onChange={(e) => setStatusBarcodeReader(e)}
                          className="select"
                          style={{
                            width: "14vw",
                            height: "2vw",
                            marginTop: "-1.4vw",
                            marginRight: "0.7vw",
                          }}
                        >
                          <Option value="Funcional">Funcional</Option>
                          <Option value="No Funcional">No Funcional</Option>
                          {/* <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option> */}
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`statusBarcodeReader`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`statusBarcodeReader`].message}
                      </p>
                    )}
                  </div>
                  {(equipSelected.name === "Todo en uno" ||
                    equipSelected.name === "Portatil") && (
                      <div className="divInputs">
                        <Input
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          va={"ADAPTADOR DE CORRIENTE"}
                          fontSize={"0.6vw"}
                          disabled
                        />
                        <Controller
                          name={"brandPowerAdapter"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Marca"
                              value={watchSystemReport("brandPowerAdapter")}
                              disabled={true}
                              className="select"
                              style={{
                                width: "7vw",
                                height: "2vw",
                                marginTop: "-2.2vw",
                                marginRight: "0.4vw",
                              }}
                            >
                              <Option value="Lenovo">Lenovo</Option>
                              <Option value="HP">HP</Option>
                              <Option value="Acer">Acer</Option>
                              <Option value="Apple">Apple</Option>
                              <Option value="Otras marcas de computadoras">
                                Otras marcas de computadoras
                              </Option>
                              <Option value="Alienware">Alienware</Option>
                              <Option value="Toshiba">Toshiba</Option>
                              <Option value="Dell">Dell</Option>
                              <Option value="Asus">Asus</Option>
                              <Option value="Samsung">Samsung</Option>
                              <Option value="Gateway">Gateway</Option>
                              <Option value="Sony">Sony</Option>
                              <Option value="MSI">MSI</Option>
                              <Option value="LG">LG</Option>
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`brandPowerAdapter`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`brandPowerAdapter`].message}
                          </p>
                        )}

                        <Input
                          {...registerSystemReport("modellPowerAdapter", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("modellPowerAdapter")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("modellPowerAdapter", e)
                          }
                          error={
                            errorsSystemReport.modellPowerAdapter &&
                            errorsSystemReport.modellPowerAdapter.message
                          }
                        />
                        <Input
                          {...registerSystemReport("seriePowerAdapter", {})}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("seriePowerAdapter")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("seriePowerAdapter", e)
                          }
                          error={
                            errorsSystemReport.seriePowerAdapter &&
                            errorsSystemReport.seriePowerAdapter.message
                          }
                        />
                        <Input
                          {...registerSystemReport(
                            "licensePlatePowerAdapter",
                            {}
                          )}
                          label={" "}
                          mt={"-1.4vw"}
                          mr={"0.7vw"}
                          width={"7vw"}
                          height={"2vw"}
                          labelstyle="inputLabelBordered"
                          fontSize={"1vw"}
                          va={watchSystemReport("licensePlatePowerAdapter")}
                          disabled={true}
                          setData={(e) =>
                            setValueSystemReport("licensePlatePowerAdapter", e)
                          }
                          error={
                            errorsSystemReport.licensePlatePowerAdapter &&
                            errorsSystemReport.licensePlatePowerAdapter.message
                          }
                        />
                        <div
                          style={{
                            marginTop: "-1.5vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!watchSystemReport("imagePowerAdapter") ? (
                            <input
                              {...registerSystemReport("imagePowerAdapter", {})}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport(
                                    "imagePowerAdapter",
                                    base64
                                  );
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imagePowerAdapter")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport(
                                  "imagePowerAdapter",
                                  undefined
                                )
                              }
                            ></img>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "-1.5vw",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!watchSystemReport("imagePowerAdapterSerial") ? (
                            <input
                              {...registerSystemReport(
                                "imagePowerAdapterSerial",
                                {}
                              )}
                              type="file"
                              disabled={true}
                              accept="image/*"
                              className="inputPhoto"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                  const base64 = reader.result;
                                  setValueSystemReport(
                                    "imagePowerAdapterSerial",
                                    base64
                                  );
                                });
                                reader.readAsDataURL(file);
                              }}
                            />
                          ) : (
                            <img
                              src={watchSystemReport("imagePowerAdapterSerial")}
                              className="inputPhoto"
                              onClick={() =>
                                setValueSystemReport(
                                  "imagePowerAdapterSerial",
                                  undefined
                                )
                              }
                            ></img>
                          )}
                        </div>
                        <Controller
                          name={"statusPowerAdapter"}
                          control={controlSystemReport}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Estado"
                              value={watchSystemReport("statusPowerAdapter")}
                              onChange={(e) => setStatusPowerAdapter(e)}
                              className="select"
                              style={{
                                width: "14vw",
                                height: "2vw",
                                marginTop: "-1.4vw",
                                marginRight: "0.7vw",
                              }}
                            >
                              <Option value="Funcional">Funcional</Option>
                              <Option value="No Funcional">No Funcional</Option>
                              {/* <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option> */}
                            </Select>
                          )}
                        />
                        {errorsSystemReport[`statusPowerAdapter`] && (
                          <p className="errorMessage">
                            {errorsSystemReport[`statusPowerAdapter`].message}
                          </p>
                        )}
                      </div>
                    )}
                  <div className="divInputs">
                    <Input
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      va={"LECTOR DE HUELLA"}
                      fontSize={"0.6vw"}
                      disabled
                    />
                    <Controller
                      name={"brandFingerprintReader"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Marca"
                          value={watchSystemReport("brandFingerprintReader")}
                          disabled={true}
                          className="select"
                          style={{
                            width: "7vw",
                            height: "2vw",
                            marginTop: "-2.2vw",
                            marginRight: "0.4vw",
                          }}
                        >
                          <Option value="Lenovo">Lenovo</Option>
                          <Option value="HP">HP</Option>
                          <Option value="Acer">Acer</Option>
                          <Option value="Apple">Apple</Option>
                          <Option value="Otras marcas de computadoras">
                            Otras marcas de computadoras
                          </Option>
                          <Option value="Alienware">Alienware</Option>
                          <Option value="Toshiba">Toshiba</Option>
                          <Option value="Dell">Dell</Option>
                          <Option value="Asus">Asus</Option>
                          <Option value="Samsung">Samsung</Option>
                          <Option value="Gateway">Gateway</Option>
                          <Option value="Sony">Sony</Option>
                          <Option value="MSI">MSI</Option>
                          <Option value="LG">LG</Option>
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`brandFingerprintReader`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`brandFingerprintReader`].message}
                      </p>
                    )}

                    <Input
                      {...registerSystemReport("modellFingerprintReader", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("modellFingerprintReader")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("modellFingerprintReader", e)
                      }
                    />
                    <Input
                      {...registerSystemReport("serieFingerprintReader", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("serieFingerprintReader")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("serieFingerprintReader", e)
                      }
                    />
                    <Input
                      {...registerSystemReport(
                        "licensePlateFingerprintReader",
                        {}
                      )}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("licensePlateFingerprintReader")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("licensePlateFingerprintReader", e)
                      }
                    />
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageFingerprintReader") ? (
                        <input
                          {...registerSystemReport(
                            "imageFingerprintReader",
                            {}
                          )}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport(
                                "imageFingerprintReader",
                                base64
                              );
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageFingerprintReader")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport(
                              "imageFingerprintReader",
                              undefined
                            )
                          }
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageFingerprintReaderSerial") ? (
                        <input
                          {...registerSystemReport(
                            "imageFingerprintReaderSerial",
                            {}
                          )}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport(
                                "imageFingerprintReaderSerial",
                                base64
                              );
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport(
                            "imageFingerprintReaderSerial"
                          )}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport(
                              "imageFingerprintReaderSerial",
                              undefined
                            )
                          }
                        ></img>
                      )}
                    </div>
                    <Controller
                      name={"statusFingerprintReader"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Estado"
                          value={watchSystemReport("statusFingerprintReader")}
                          onChange={(e) => setStatusFingerprintReader(e)}
                          className="select"
                          style={{
                            width: "14vw",
                            height: "2vw",
                            marginTop: "-1.4vw",
                            marginRight: "0.7vw",
                          }}
                        >
                          <Option value="Funcional">Funcional</Option>
                          <Option value="No Funcional">No Funcional</Option>
                          {/* <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option> */}
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`statusFingerprintReader`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`statusFingerprintReader`].message}
                      </p>
                    )}
                  </div>
                  <div className="divInputs">
                    <Input
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      va={"DIADEMAS"}
                      fontSize={"0.6vw"}
                      disabled
                    />
                    <Controller
                      name={"brandDiadems"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Marca"
                          value={watchSystemReport("brandDiadems")}
                          disabled={true}
                          className="select"
                          style={{
                            width: "7vw",
                            height: "2vw",
                            marginTop: "-2.2vw",
                            marginRight: "0.4vw",
                          }}
                        >
                          <Option value="Lenovo">Lenovo</Option>
                          <Option value="HP">HP</Option>
                          <Option value="Acer">Acer</Option>
                          <Option value="Apple">Apple</Option>
                          <Option value="Otras marcas de computadoras">
                            Otras marcas de computadoras
                          </Option>
                          <Option value="Alienware">Alienware</Option>
                          <Option value="Toshiba">Toshiba</Option>
                          <Option value="Dell">Dell</Option>
                          <Option value="Asus">Asus</Option>
                          <Option value="Samsung">Samsung</Option>
                          <Option value="Gateway">Gateway</Option>
                          <Option value="Sony">Sony</Option>
                          <Option value="MSI">MSI</Option>
                          <Option value="LG">LG</Option>
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`brandDiadems`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`brandDiadems`].message}
                      </p>
                    )}

                    <Input
                      {...registerSystemReport("modellDiadems", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("modellDiadems")}
                      disabled={true}
                      setData={(e) => setValueSystemReport("modellDiadems", e)}
                    />
                    <Input
                      {...registerSystemReport("serieDiadems", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("serieDiadems")}
                      disabled={true}
                      setData={(e) => setValueSystemReport("serieDiadems", e)}
                    />
                    <Input
                      {...registerSystemReport("licensePlateDiadems", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("licensePlateDiadems")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("licensePlateDiadems", e)
                      }
                    />
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageDiadems") ? (
                        <input
                          {...registerSystemReport("imageDiadems", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport("imageDiadems", base64);
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageDiadems")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport("imageDiadems", undefined)
                          }
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageDiademsSerial") ? (
                        <input
                          {...registerSystemReport("imageDiademsSerial", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport(
                                "imageDiademsSerial",
                                base64
                              );
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageDiademsSerial")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport(
                              "imageDiademsSerial",
                              undefined
                            )
                          }
                        ></img>
                      )}
                    </div>
                    <Controller
                      name={"statusDiadems"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Estado"
                          value={watchSystemReport("statusDiadems")}
                          onChange={(e) => setStatusDiadems(e)}
                          className="select"
                          style={{
                            width: "14vw",
                            height: "2vw",
                            marginTop: "-1.4vw",
                            marginRight: "0.7vw",
                          }}
                        >
                          <Option value="Funcional">Funcional</Option>
                          <Option value="No Funcional">No Funcional</Option>
                          {/* <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option> */}
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`statusDiadems`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`statusDiadems`].message}
                      </p>
                    )}
                  </div>
                  <div className="divInputs">
                    <Input
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      va={"FUENTE"}
                      fontSize={"0.6vw"}
                      disabled
                    />
                    <Controller
                      name={"brandSource"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Marca"
                          value={watchSystemReport("brandSource")}
                          disabled={true}
                          className="select"
                          style={{
                            width: "7vw",
                            height: "2vw",
                            marginTop: "-2.2vw",
                            marginRight: "0.4vw",
                          }}
                        >
                          <Option value="Lenovo">Lenovo</Option>
                          <Option value="HP">HP</Option>
                          <Option value="Acer">Acer</Option>
                          <Option value="Apple">Apple</Option>
                          <Option value="Otras marcas de computadoras">
                            Otras marcas de computadoras
                          </Option>
                          <Option value="Alienware">Alienware</Option>
                          <Option value="Toshiba">Toshiba</Option>
                          <Option value="Dell">Dell</Option>
                          <Option value="Asus">Asus</Option>
                          <Option value="Samsung">Samsung</Option>
                          <Option value="Gateway">Gateway</Option>
                          <Option value="Sony">Sony</Option>
                          <Option value="MSI">MSI</Option>
                          <Option value="LG">LG</Option>
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`brandSource`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`brandSource`].message}
                      </p>
                    )}

                    <Input
                      {...registerSystemReport("modellSource", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("modellSource")}
                      disabled={true}
                      setData={(e) => setValueSystemReport("modellSource", e)}
                    />
                    <Input
                      {...registerSystemReport("serieSource", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("serieSource")}
                      disabled={true}
                      setData={(e) => setValueSystemReport("serieSource", e)}
                    />
                    <Input
                      {...registerSystemReport("licensePlateSource", {})}
                      label={" "}
                      mt={"-1.4vw"}
                      mr={"0.7vw"}
                      width={"7vw"}
                      height={"2vw"}
                      labelstyle="inputLabelBordered"
                      fontSize={"1vw"}
                      va={watchSystemReport("licensePlateSource")}
                      disabled={true}
                      setData={(e) =>
                        setValueSystemReport("licensePlateSource", e)
                      }
                    />
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageSource") ? (
                        <input
                          {...registerSystemReport("imageSource", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport("imageSource", base64);
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageSource")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport("imageSource", undefined)
                          }
                        ></img>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "-1.5vw",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!watchSystemReport("imageSourceSerial") ? (
                        <input
                          {...registerSystemReport("imageSourceSerial", {})}
                          type="file"
                          disabled={true}
                          accept="image/*"
                          className="inputPhoto"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", () => {
                              const base64 = reader.result;
                              setValueSystemReport("imageSourceSerial", base64);
                            });
                            reader.readAsDataURL(file);
                          }}
                        />
                      ) : (
                        <img
                          src={watchSystemReport("imageSourceSerial")}
                          className="inputPhoto"
                          onClick={() =>
                            setValueSystemReport("imageSourceSerial", undefined)
                          }
                        ></img>
                      )}
                    </div>
                    <Controller
                      name={"statusSource"}
                      control={controlSystemReport}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Estado"
                          value={watchSystemReport("statusSource")}
                          onChange={(e) => setStatusSource(e)}
                          className="select"
                          style={{
                            width: "14vw",
                            height: "2vw",
                            marginTop: "-1.4vw",
                            marginRight: "0.7vw",
                          }}
                        >
                          <Option value="Funcional">Funcional</Option>
                          <Option value="No Funcional">No Funcional</Option>
                          {/* <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option> */}
                        </Select>
                      )}
                    />
                    {errorsSystemReport[`statusSource`] && (
                      <p className="errorMessage">
                        {errorsSystemReport[`statusSource`].message}
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="titleSection">Procesadores</p>
                    {watchSystemReport("processors") &&
                      watchSystemReport("processors").map(
                        (processor, index) => (
                          <div key={index} className="divInputs">
                            <Input
                              {...registerSystemReport(`processor${index}`, {})}
                              label={" "}
                              mt={"0vw"}
                              mr={"0.7vw"}
                              width={"7vw"}
                              height={"2vw"}
                              labelstyle="inputLabelBordered"
                              va={processor}
                              fontSize={"0.6vw"}
                              disabled
                            />
                            <Controller
                              name={`processorStatus${index}`}
                              control={controlSystemReport}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  placeholder="Estado"
                                  value={watchSystemReport(
                                    `processorStatus${index}`
                                  )}
                                  onChange={(e) => {
                                    const indexProcessor =
                                      statusProcessors.findIndex(
                                        (p) => p.processor === processor
                                      );
                                    const newStatusProcessors = [
                                      ...statusProcessors,
                                    ];
                                    newStatusProcessors[indexProcessor].status =
                                      e;
                                    setStatusProcessors(newStatusProcessors);
                                  }}
                                  className="select"
                                  style={{
                                    width: "14vw",
                                    height: "2vw",
                                    marginTop: "-1.4vw",
                                    marginRight: "0.7vw",
                                  }}
                                >
                                  <Option value="Funcional">Funcional</Option>
                                  <Option value="No Funcional">
                                    No Funcional
                                  </Option>
                                  {/* <Option value="Dado de baja">Dado de baja</Option>
                                                        <Option value="No operativo">No operativo</Option> */}
                                </Select>
                              )}
                            />
                          </div>
                        )
                      )}
                  </div>
                  <div>
                    <p className="titleSection">Discos duros</p>
                    {statusDisks &&
                      statusDisks.map((disk, index) => (
                        <div key={index} className="divInputs">
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={disk.capacity}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={disk.brand}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={disk.serie}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={disk.type}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Controller
                            name={`diskStatus${disk.id}`}
                            control={controlSystemReport}
                            render={({ field }) => (
                              <Select
                                {...field}
                                placeholder="Estado"
                                value={watchSystemReport(
                                  `diskStatus${disk.id}`
                                )}
                                onChange={(e) => {
                                  const indexDisk = statusDisks.findIndex(
                                    (d) => d.id === disk.id
                                  );
                                  const newStatusDisk = [...statusDisks];
                                  newStatusDisk[indexDisk].status = e;
                                  setStatusDisks(newStatusDisk);
                                  setValueSystemReport(
                                    `diskStatus${disk.id}`,
                                    e
                                  );
                                }}
                                className="select"
                                style={{
                                  width: "14vw",
                                  height: "2vw",
                                  marginTop: "-1.4vw",
                                  marginRight: "0.7vw",
                                }}
                              >
                                <Option value="Funcional">Funcional</Option>
                                <Option value="No Funcional">
                                  No Funcional
                                </Option>
                                {/* <Option value="Dado de baja">Dado de baja</Option>
                                                        <Option value="No operativo">No operativo</Option> */}
                              </Select>
                            )}
                          />
                        </div>
                      ))}
                  </div>
                  <div>
                    <p className="titleSection">Memorias RAM</p>
                    {statusRams &&
                      statusRams.map((ram, index) => (
                        <div key={index} className="divInputs">
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={ram.capacity}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={ram.brand}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={ram.serie}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Input
                            label={" "}
                            mt={"0vw"}
                            mr={"0.7vw"}
                            width={"7vw"}
                            height={"2vw"}
                            labelstyle="inputLabelBordered"
                            va={ram.type}
                            fontSize={"0.6vw"}
                            disabled
                          />
                          <Controller
                            name={`ramsStatus${ram.id}`}
                            control={controlSystemReport}
                            render={({ field }) => (
                              <Select
                                {...field}
                                placeholder="Estado"
                                value={watchSystemReport(`ramsStatus${ram.id}`)}
                                onChange={(e) => {
                                  const indexRams = statusRams.findIndex(
                                    (d) => d.id === ram.id
                                  );
                                  const newStatusRams = [...statusRams];
                                  newStatusRams[indexRams].status = e;
                                  setStatusRams(newStatusRams);
                                  setValueSystemReport(
                                    `ramsStatus${ram.id}`,
                                    e
                                  );
                                }}
                                className="select"
                                style={{
                                  width: "14vw",
                                  height: "2vw",
                                  marginTop: "-1.4vw",
                                  marginRight: "0.7vw",
                                }}
                              >
                                <Option value="Funcional">Funcional</Option>
                                <Option value="No Funcional">
                                  No Funcional
                                </Option>
                                {/* <Option value="Dado de baja">Dado de baja</Option>
                                                        <Option value="No operativo">No operativo</Option> */}
                              </Select>
                            )}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <p className="featuresText">
                  Repuestos Utilizados en el Mantenimiento
                </p>
                <div
                  style={{
                    display: "flex",
                    width: "76vw",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    className="buttonAddComp"
                    onClick={() =>
                      setComponentsQuantity([
                        ...componentsQuantity,
                        {
                          tipo: "",
                          marca: "",
                          modelo: "",
                          serie: "",
                          placa: "",
                          imagen: "",
                          imagenSerie: "",
                          estado: "",
                        },
                      ])
                    }
                  >
                    <PlusCircleOutlined
                      style={{ color: "white", fontSize: "1.4vw" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "62vw",
                    marginBottom: "1.3vw",
                  }}
                >
                  <p className="titleSection">Tipo</p>
                  <p className="titleSection">Marca</p>
                  <p className="titleSection">Modelo</p>
                  <p className="titleSection">Serie</p>
                  <p className="titleSection">Placa</p>
                  <p className="titleSection">Imagen</p>
                  <p className="titleSection">Imagen Serie</p>
                  <p className="titleSection">Estado</p>
                </div>
                {componentsQuantity.map((component, index) => {
                  return (
                    <div key={index} className="divInputs">
                      <Input
                        {...registerSystemReport(`extraCompName${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("El nombre es requerido");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        onChange={(e) => {
                          setValueSystemReport(
                            `extraCompName${index}`,
                            e.target.value
                          );
                        }}
                        va={
                          watchSystemReport(`extraCompName${index}`) === ""
                            ? component.tipo
                            : watchSystemReport(`extraCompName${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`extraCompName${index}`, e)
                        }
                        error={
                          errorsSystemReport[`extraCompName${index}`] &&
                          errorsSystemReport[`extraCompName${index}`].message
                        }
                      />
                      <Controller
                        name={`extraCompBrand${index}`}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Marca"
                            value={watchSystemReport(`extraCompBrand${index}`)}
                            className="select"
                            style={{
                              width: "7vw",
                              height: "2vw",
                              marginTop: "-2.4vw",
                              marginRight: "0.4vw",
                            }}
                          >
                            <Option value="Lenovo">Lenovo</Option>
                            <Option value="HP">HP</Option>
                            <Option value="Acer">Acer</Option>
                            <Option value="Apple">Apple</Option>
                            <Option value="Otras marcas de computadoras">
                              Otras marcas de computadoras
                            </Option>
                            <Option value="Alienware">Alienware</Option>
                            <Option value="Toshiba">Toshiba</Option>
                            <Option value="Dell">Dell</Option>
                            <Option value="Asus">Asus</Option>
                            <Option value="Samsung">Samsung</Option>
                            <Option value="Gateway">Gateway</Option>
                            <Option value="Sony">Sony</Option>
                            <Option value="MSI">MSI</Option>
                            <Option value="LG">LG</Option>
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`extraCompBrand${index}`] && (
                        <p className="errorMessage">
                          {errorsSystemReport[`extraCompBrand${index}`].message}
                        </p>
                      )}

                      <Input
                        {...registerSystemReport(`extraCompModell${index}`, {})}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport(`extraCompModell${index}`)}
                        setData={(e) =>
                          setValueSystemReport(`extraCompModell${index}`, e)
                        }
                      />
                      <Input
                        {...registerSystemReport(`extraCompSerie${index}`, {})}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport(`extraCompSerie${index}`)}
                        setData={(e) =>
                          setValueSystemReport(`extraCompSerie${index}`, e)
                        }
                      />
                      <Input
                        {...registerSystemReport(
                          `extraCompLicensePlate${index}`,
                          {}
                        )}
                        label={" "}
                        mt={"-1.4vw"}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"1vw"}
                        va={watchSystemReport(`extraCompLicensePlate${index}`)}
                        setData={(e) =>
                          setValueSystemReport(
                            `extraCompLicensePlate${index}`,
                            e
                          )
                        }
                      />
                      <div
                        style={{
                          marginTop: "-1.5vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {!watchSystemReport(`extraCompImage${index}`) ? (
                          <input
                            {...registerSystemReport(
                              `extraCompImage${index}`,
                              {}
                            )}
                            type="file"
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport(
                                  `extraCompImage${index}`,
                                  base64
                                );
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport(`extraCompImage${index}`)}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport(
                                `extraCompImage${index}`,
                                undefined
                              )
                            }
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: "-1.5vw",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {!watchSystemReport(`extraCompImageSerial${index}`) ? (
                          <input
                            {...registerSystemReport(
                              `extraCompImageSerial${index}`,
                              {}
                            )}
                            type="file"
                            accept="image/*"
                            className="inputPhoto"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", () => {
                                const base64 = reader.result;
                                setValueSystemReport(
                                  `extraCompImageSerial${index}`,
                                  base64
                                );
                              });
                              reader.readAsDataURL(file);
                            }}
                          />
                        ) : (
                          <img
                            src={watchSystemReport(
                              `extraCompImageSerial${index}`
                            )}
                            className="inputPhoto"
                            onClick={() =>
                              setValueSystemReport(
                                `extraCompImageSerial${index}`,
                                undefined
                              )
                            }
                          ></img>
                        )}
                      </div>
                      <Controller
                        name={`extraCompStatus${index}`}
                        rules={{
                          required: "La dependencia es requerida",
                        }}
                        control={controlSystemReport}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Estado"
                            value={watchSystemReport(`extraCompStatus${index}`)}
                            className="select"
                            style={{
                              width: "14vw",
                              height: "2vw",
                              marginTop: "-1.4vw",
                              marginRight: "0.7vw",
                            }}
                          >
                            <Option value="Funcional">Funcional</Option>
                            {/* <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>
                                                     */}
                          </Select>
                        )}
                      />
                      {errorsSystemReport[`extraCompStatus${index}`] && (
                        <p className="errorMessage">
                          {
                            errorsSystemReport[`extraCompStatus${index}`]
                              .message
                          }
                        </p>
                      )}
                    </div>
                  );
                })}
                {extraProcessors.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "15vw",
                      marginBottom: "0.5vw",
                      marginTop: "1vw",
                    }}
                  >
                    <p className="titleSection">Antiguo procesador</p>
                    <p className="titleSection">Nuevo procesador</p>
                  </div>
                )}
                {extraProcessors.map((proc, index) => {
                  return (
                    <div key={index} className="divInputs">
                      <Input
                        {...registerSystemReport(`prevProcessor${index}`, {})}
                        label={" "}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={proc.prevProcessor}
                        setData={(e) =>
                          setValueSystemReport(`prevProcessor${index}`, e)
                        }
                        error={
                          errorsSystemReport[`prevProcessor${index}`] &&
                          errorsSystemReport[`prevProcessor${index}`].message
                        }
                        disabled
                      />
                      <Input
                        {...registerSystemReport(`newProcessor${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("El nombre es requerido");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"7vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newProcessor${index}`) === ""
                            ? proc.newProcessor
                            : watchSystemReport(`newProcessor${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newProcessor${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newProcessor${index}`] &&
                          errorsSystemReport[`newProcessor${index}`].message
                        }
                      />
                    </div>
                  );
                })}
                {extraDisks.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "30vw",
                      marginBottom: "0.5vw",
                      marginTop: "1vw",
                    }}
                  >
                    <p className="titleSection">Capacidad disco</p>
                    <p
                      className="titleSection"
                      style={{ marginRight: "1.8vw" }}
                    >
                      Marca disco
                    </p>
                    <p
                      className="titleSection"
                      style={{ marginRight: "1.8vw" }}
                    >
                      Serie disco
                    </p>
                    <p className="titleSection">Tipo disco</p>
                  </div>
                )}
                {extraDisks.map((disk, index) => {
                  return (
                    <div key={index} className="divInputs">
                      <Input
                        {...registerSystemReport(`newDiskCapacity${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("La capacidad es requerida");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newDiskCapacity${index}`) === ""
                            ? ""
                            : watchSystemReport(`newDiskCapacity${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newDiskCapacity${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newDiskCapacity${index}`] &&
                          errorsSystemReport[`newDiskCapacity${index}`].message
                        }
                      />
                      <Input
                        {...registerSystemReport(`newDiskBrand${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("La marca es requerida");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newDiskBrand${index}`) === ""
                            ? ""
                            : watchSystemReport(`newDiskBrand${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newDiskBrand${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newDiskBrand${index}`] &&
                          errorsSystemReport[`newDiskBrand${index}`].message
                        }
                      />
                      <Input
                        {...registerSystemReport(`newDiskSerie${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("La serie es requerida");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newDiskSerie${index}`) === ""
                            ? ""
                            : watchSystemReport(`newDiskSerie${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newDiskSerie${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newDiskSerie${index}`] &&
                          errorsSystemReport[`newDiskSerie${index}`].message
                        }
                      />
                      <Input
                        {...registerSystemReport(`newDiskType${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("El tipo es requerido");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newDiskType${index}`) === ""
                            ? ""
                            : watchSystemReport(`newDiskType${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newDiskType${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newDiskType${index}`] &&
                          errorsSystemReport[`newDiskType${index}`].message
                        }
                      />
                    </div>
                  );
                })}
                {extraRams.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "30vw",
                      marginBottom: "0.5vw",
                      marginTop: "1vw",
                    }}
                  >
                    <p className="titleSection">Capacidad ram</p>
                    <p
                      className="titleSection"
                      style={{ marginRight: "1.8vw" }}
                    >
                      Marca ram
                    </p>
                    <p
                      className="titleSection"
                      style={{ marginRight: "1.8vw" }}
                    >
                      Serie ram
                    </p>
                    <p className="titleSection">Tipo ram</p>
                  </div>
                )}
                {extraRams.map((disk, index) => {
                  return (
                    <div key={index} className="divInputs">
                      <Input
                        {...registerSystemReport(`newRamCapacity${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("La capacidad es requerida");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newRamCapacity${index}`) === ""
                            ? ""
                            : watchSystemReport(`newRamCapacity${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newRamCapacity${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newRamCapacity${index}`] &&
                          errorsSystemReport[`newRamCapacity${index}`].message
                        }
                      />
                      <Input
                        {...registerSystemReport(`newRamBrand${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("La marca es requerida");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newRamBrand${index}`) === ""
                            ? ""
                            : watchSystemReport(`newRamBrand${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newRamBrand${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newRamBrand${index}`] &&
                          errorsSystemReport[`newRamBrand${index}`].message
                        }
                      />
                      <Input
                        {...registerSystemReport(`newRamSerie${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("La serie es requerida");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newRamSerie${index}`) === ""
                            ? ""
                            : watchSystemReport(`newRamSerie${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newRamSerie${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newRamSerie${index}`] &&
                          errorsSystemReport[`newRamSerie${index}`].message
                        }
                      />
                      <Input
                        {...registerSystemReport(`newRamType${index}`, {
                          validate: (value) => {
                            const errors = [];
                            if (!value || value === "") {
                              errors.push("El tipo es requerido");
                            }
                            return errors.length === 0 || errors.join(", ");
                          },
                        })}
                        label={" "}
                        mr={"0.7vw"}
                        width={"8vw"}
                        height={"2vw"}
                        labelstyle="inputLabelBordered"
                        fontSize={"0.6vw"}
                        va={
                          watchSystemReport(`newRamType${index}`) === ""
                            ? ""
                            : watchSystemReport(`newRamType${index}`)
                        }
                        setData={(e) =>
                          setValueSystemReport(`newRamType${index}`, e)
                        }
                        error={
                          errorsSystemReport[`newRamType${index}`] &&
                          errorsSystemReport[`newRamType${index}`].message
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <div>
                <p className="titleSection" style={{ marginLeft: "0vw" }}>
                  ¿Se evidencia manipulación?
                </p>
                <div>
                  <Radio.Group
                    className="radioG"
                    {...registerSystemReport("isTampered", {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("La manipulación es requerida");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    })}
                    onChange={(d) =>
                      setValueSystemReport("isTampered", d.target.value)
                    }
                    value={watchSystemReport("isTampered")}
                    style={{ width: "10vw" }}
                  >
                    <Radio className="radio" value={"Si"}>
                      Si
                    </Radio>
                    <Radio className="radio" value={"No"}>
                      No
                    </Radio>
                  </Radio.Group>
                  {errorsSystemReport.isTampered && (
                    <p className="errorMessage">
                      {errorsSystemReport.isTampered.message}
                    </p>
                  )}
                </div>
              </div>
              {watchSystemReport("isTampered") === "Si" && (
                <Input
                  {...registerSystemReport("whyIsTampered", {})}
                  height={"5vw"}
                  width={"76.5vw"}
                  fontSize={"1vw"}
                  mt={"1.4vw"}
                  va={watchSystemReport("whyIsTampered")}
                  setData={(e) => setValueSystemReport("whyIsTampered", e)}
                  labelstyle="inputLabelBordered"
                  error={
                    errorsSystemReport.whyIsTampered &&
                    errorsSystemReport.whyIsTampered.message
                  }
                  label="¿Por qué?"
                  textArea
                />
              )}
              <Input
                {...registerSystemReport("testsPerformed", {
                  validate: (value) => {
                    const errors = [];
                    if (!value || value === "") {
                      errors.push("Las pruebas realizadas es requerido");
                    }
                    return errors.length === 0 || errors.join(", ");
                  },
                })}
                height={"5vw"}
                width={"76.5vw"}
                fontSize={"1vw"}
                mt={"1.4vw"}
                va={watchSystemReport("testsPerformed")}
                setData={(e) => setValueSystemReport("testsPerformed", e)}
                labelstyle="inputLabelBordered"
                error={
                  errorsSystemReport.testsPerformed &&
                  errorsSystemReport.testsPerformed.message
                }
                label="Pruebas realizadas"
                textArea
              />
              <Input
                {...registerSystemReport("solutionDelivered", {
                  validate: (value) => {
                    const errors = [];
                    if (!value || value === "") {
                      errors.push("El diagnostico es requerido");
                    }
                    return errors.length === 0 || errors.join(", ");
                  },
                })}
                height={"5vw"}
                width={"76.5vw"}
                fontSize={"1vw"}
                mt={"1.4vw"}
                va={watchSystemReport("solutionDelivered")}
                setData={(e) => setValueSystemReport("solutionDelivered", e)}
                labelstyle="inputLabelBordered"
                error={
                  errorsSystemReport.solutionDelivered &&
                  errorsSystemReport.solutionDelivered.message
                }
                label="Solución Entregada: Nota: Antes de cualquier cambio en las configuraciones o reset de maquina contactar al coordinador del área de sistemas"
                textArea
              />
              <div>
                <p className="titleSection" style={{ marginLeft: "0vw" }}>
                  Software instalado
                </p>
                <div>
                  <Radio.Group
                    className="radioG"
                    onChange={(d) => setHasSoftware(d.target.value)}
                    value={hasSoftware}
                    style={{ width: "10vw", marginBottom: "1.5vw" }}
                  >
                    <Radio className="radio" value={true}>
                      Si
                    </Radio>
                    <Radio className="radio" value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              {hasSoftware && (
                <div>
                  <div
                    className="buttonAddComp"
                    onClick={() => setVisibleCtmModal(true)}
                  >
                    <PlusCircleOutlined
                      style={{ color: "white", fontSize: "1.4vw" }}
                    />
                  </div>
                  <div className="softwareList">
                    {
                      Object.entries(
                        Object.entries(watchSystemReport())
                          .filter(
                            ([key, value]) =>
                              auxSelectOptions.some((option) => key.startsWith(option.name)) &&
                              value !== undefined &&
                              value !== ""
                          )
                          .reduce((groups, [key, value]) => {
                            const groupName = auxSelectOptions.find((option) =>
                              key.startsWith(option.name)
                            ).name;

                            if (!groups[groupName]) {
                              groups[groupName] = [];
                            }

                            groups[groupName].push({ key, value });

                            return groups;
                          }, {})
                      ).map(([groupName, items], index) => (
                        <div key={index}>
                          <p className="softwareType">{groupName}</p>
                          {items.map(({ key, value }, index) => (
                            <div key={index} className="software">
                              <p className="softwareType">{key}</p>
                              {!key.endsWith("Validity") ? (
                                <input
                                  className="softwareInput"
                                  value={value}
                                  disabled
                                ></input>
                              ) : (
                                <DatePicker
                                  value={value}
                                  className="softwareInput"
                                  disabled
                                ></DatePicker>
                              )}
                              <CloseCircleOutlined
                                className="softwareIcon"
                                onClick={() => handleRemoveSoftware(key)}
                              />
                            </div>
                          ))}
                        </div>
                      ))
                    }
                  </div>
                  <CtmModal
                    visibleModal={visibleCtmModal}
                    setVisibleModal={setVisibleCtmModal}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#02a176",
                        width: "60vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "#20744A",
                          width: "100%",
                          paddingRight: "1vw",
                          paddingLeft: "1vw",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1.2vw",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Seleccione Software Instalado
                        </p>
                        <CloseCircleOutlined
                          onClick={() => setVisibleCtmModal(false)}
                          style={{
                            color: "white",
                            fontSize: "1.5vw",
                            position: "relative",
                            top: "0.2vw",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {optionsType.map((op) => (
                          <div className="selectSoftware">
                            <div className="itemSoftware">
                              <p className="name">{op.tipoSoftware}</p>
                              <Controller
                                name={op.tipoSoftware}
                                control={controlSystemReport}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    showSearch
                                    className="select"
                                    placeholder="Si no está la opción, la crea y luego la selecciona"
                                    optionFilterProp="children"
                                    onChange={(value) =>
                                      setValueSystemReport(
                                        op.tipoSoftware,
                                        value
                                      )
                                    }
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSearch={(value) => {
                                      if (value === "") {
                                        setAuxSelectOptions((prevOptions) => [
                                          ...prevOptions,
                                          {
                                            id: auxSelectOptions.length + 1,
                                            name: op.tipoSoftware,
                                            option: optionSearched,
                                          },
                                        ]);
                                      } else {
                                        setOptionSearched(value);
                                      }
                                    }}
                                  >
                                    {auxSelectOptions &&
                                      auxSelectOptions
                                        .filter((option) =>
                                          op.tipoSoftware.startsWith(
                                            option.name
                                          )
                                        )
                                        .map((option, index) => (
                                          <Option value={option.option}>
                                            {option.option}
                                          </Option>
                                        ))}
                                  </Select>
                                )}
                              />
                              {!op.tipoSoftware.match(/\d$/) && (
                                <PlusCircleOutlined
                                  className="icon"
                                  onClick={() =>
                                    handleAddFields(op.tipoSoftware)
                                  }
                                />
                              )}
                            </div>
                            {errorsSystemReport[op.tipoSoftware] && (
                              <p className="errorMessage">
                                {errorsSystemReport[op.tipoSoftware].message}
                              </p>
                            )}
                            <div className="itemSoftware">
                              <p className="name">Versión</p>
                              <Input
                                {...registerSystemReport(
                                  `${op.tipoSoftware}Version`,
                                  {}
                                )}
                                label={" "}
                                mr={"0.7vw"}
                                width={"15vw"}
                                height={"2.5vw"}
                                labelstyle="inputLabelBordered"
                                fontSize={"1vw"}
                                va={watchSystemReport(
                                  `${op.tipoSoftware}Version`
                                )}
                                setData={(e) =>
                                  setValueSystemReport(
                                    `${op.tipoSoftware}Version`,
                                    e
                                  )
                                }
                                error={
                                  errorsSystemReport[
                                  `${op.tipoSoftware}Version`
                                  ] &&
                                  errorsSystemReport[
                                    `${op.tipoSoftware}Version`
                                  ].message
                                }
                              />
                            </div>
                            <div className="itemSoftware">
                              <p className="name">Serial</p>
                              <Input
                                {...registerSystemReport(
                                  `${op.tipoSoftware}Serial`,
                                  {}
                                )}
                                label={" "}
                                mr={"0.7vw"}
                                width={"15vw"}
                                height={"2.5vw"}
                                labelstyle="inputLabelBordered"
                                fontSize={"1vw"}
                                va={watchSystemReport(
                                  `${op.tipoSoftware}Serial`
                                )}
                                setData={(e) =>
                                  setValueSystemReport(
                                    `${op.tipoSoftware}Serial`,
                                    e
                                  )
                                }
                                error={
                                  errorsSystemReport[
                                  `${op.tipoSoftware}Serial`
                                  ] &&
                                  errorsSystemReport[`${op.tipoSoftware}Serial`]
                                    .message
                                }
                              />
                            </div>
                            <div className="itemSoftware">
                              <p className="name">Vigencia</p>
                              <DatePicker
                                value={watchSystemReport(
                                  `${op.tipoSoftware}Validity`
                                )}
                                {...registerSystemReport(
                                  `${op.tipoSoftware}Validity`,
                                  {}
                                )}
                                style={{ width: "15vw", height: "2.5vw" }}
                                onChange={(d) =>
                                  setValueSystemReport(
                                    `${op.tipoSoftware}Validity`,
                                    d
                                  )
                                }
                              />
                              {errorsSystemReport[
                                `${op.tipoSoftware}Validity`
                              ] && (
                                  <p className="errorMessage">
                                    {
                                      errorsSystemReport[
                                        `${op.tipoSoftware}Validity`
                                      ].message
                                    }
                                  </p>
                                )}
                            </div>
                            <div className="itemSoftware">
                              <p className="name">Tipo de licencia</p>
                              <Controller
                                name={op.tipoSoftware + "LicenseType"}
                                control={controlSystemReport}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    className="select"
                                    optionFilterProp="children"
                                    onChange={(value) =>
                                      setValueSystemReport(
                                        op.tipoSoftware + "LicenseType",
                                        value
                                      )
                                    }
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    <Option
                                      value={
                                        "Licencia de software de propietario"
                                      }
                                    >
                                      Licencia de software de propietario
                                    </Option>
                                    <Option
                                      value={
                                        "Licencia de software libre"
                                      }
                                    >
                                      Licencia de software libre
                                    </Option>
                                    <Option
                                      value={
                                        "Licencia de software de codigo abierto"
                                      }
                                    >
                                      Licencia de software de codigo abierto
                                    </Option>
                                    <Option
                                      value={
                                        "Licencia de software de dominio publico"
                                      }
                                    >
                                      Licencia de software de dominio publico
                                    </Option>
                                  </Select>
                                )}
                              />
                            </div>
                            {errorsSystemReport[
                              op.tipoSoftware + "LicenseType"
                            ] && (
                                <p className="errorMessage">
                                  {
                                    errorsSystemReport[
                                      op.tipoSoftware + "LicenseType"
                                    ].message
                                  }
                                </p>
                              )}
                          </div>
                        ))}
                      </div>

                      <div
                        className="buttonSave"
                        onClick={() => setVisibleCtmModal(false)}
                      >
                        <SaveOutlined
                          style={{ fontSize: "2vw", color: "white" }}
                        ></SaveOutlined>
                        <p
                          style={{
                            fontSize: "1.1vw",
                            marginTop: "1vw",
                            marginLeft: "1vw",
                            color: "white",
                          }}
                        >
                          Aplicar
                        </p>
                      </div>
                    </div>
                  </CtmModal>
                </div>
              )}
              <div>
                <p className="titleSection" style={{ marginLeft: "0vw" }}>
                  Servicio
                </p>
                <div>
                  <Radio.Group
                    className="radioG"
                    {...registerSystemReport("service", {
                      validate: (value) => {
                        const errors = [];
                        if (!value || value === "") {
                          errors.push("El servicio es requerido");
                        }
                        return errors.length === 0 || errors.join(", ");
                      },
                    })}
                    onChange={(d) =>
                      setValueSystemReport("service", d.target.value)
                    }
                    value={watchSystemReport("service")}
                    style={{ width: "10vw" }}
                  >
                    <Radio className="radio" value={"Correctivo"}>
                      Correctivo
                    </Radio>
                    <Radio className="radio" value={"Preventivo"}>
                      Preventivo
                    </Radio>
                  </Radio.Group>
                  {errorsSystemReport.service && (
                    <p className="errorMessage">
                      {errorsSystemReport.service.message}
                    </p>
                  )}
                </div>
              </div>
              <Input
                {...registerSystemReport("clientObservation", {})}
                height={"5vw"}
                width={"76.5vw"}
                fontSize={"1vw"}
                mt={"1.4vw"}
                va={watchSystemReport("clientObservation")}
                setData={(e) => setValueSystemReport("clientObservation", e)}
                labelstyle="inputLabelBordered"
                error={
                  errorsSystemReport.clientObservation &&
                  errorsSystemReport.clientObservation.message
                }
                label="Observación del cliente"
                textArea
              />
              <p className="titleSection">Estado del equipo</p>
              <div style={{ display: "flex" }}>
                <Radio.Group
                  className="radioGroup"
                  {...registerSystemReport("statusEquip", {
                    validate: (value) => {
                      const errors = [];
                      if (!value || value === "") {
                        errors.push("Es requerido elegir una opcion");
                      }
                      return errors.length === 0 || errors.join(", ");
                    },
                  })}
                  style={{ marginLeft: "2vw" }}
                  value={watchSystemReport("statusEquip")}
                  onChange={(e) =>
                    setValueSystemReport("statusEquip", e.target.value)
                  }
                >
                  <Radio value={"Funcional"}>Funcional</Radio>
                  <Radio value={"No funcional"}>No funcional</Radio>
                  <Radio value={"Dado de baja"}>Dado de baja</Radio>
                </Radio.Group>
                {errorsSystemReport.statusEquip && (
                  <p className="errorMessage">
                    {errorsSystemReport.statusEquip.message}
                  </p>
                )}
              </div>
              <button
                className="save"
                onClick={() =>
                  handleSubmitSystemReport((dataSubmit) => {
                    if (dataSubmit.equipSelected === "") {
                      message.warn("Debes seleccionar una maquina");
                    } else {
                      dataSubmit.userCreator = idUser;
                      dispatch(
                        CaseReportSystemActions.createCaseReportSystem(
                          dataSubmit
                        )
                      );
                    }
                  })()
                }
              >
                {loadingCreateCaseReportSystem ? (
                  <LoadingOutlined />
                ) : (
                  "Guardar"
                )}
              </button>
            </div>
          )}
      </div>
      <ModalSearchEquipment
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
      /* setFormData={setFormData} */
      />
    </div>
  );
};

const CtmModal = ({ visibleModal, setVisibleModal, handleOk, children }) => {
  return (
    <Modal
      visible={visibleModal}
      onOk={handleOk}
      footer={false}
      closable={false}
      bodyStyle={{ padding: 0, borderRadius: "10px" }}
      style={{ borderRadius: "10px" }}
      className="CtmModalSystemReport"
    >
      {children}
    </Modal>
  );
};
