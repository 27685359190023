import { Steps, Button, Radio, message, AutoComplete } from 'antd';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Input } from '../Input/Input'
import { PlusCircleOutlined, MinusCircleOutlined, ClearOutlined, LoadingOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { useForm, Controller } from "react-hook-form"
import { apiUrl } from '../../common/config/Environments'
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import { EquipmentSystemActions } from '../../services/EquipmentSystem/EquipmentSystemSlice';

export const FormEquipmentSystem = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { loadingCreateEquipmentSystem, EquipmentSystemCreatedId, } = useSelector(state => state.EquipmentSystem)
    const { fullDataEquipment } = useSelector(state => state.Equipment)
    const [processors, setProcessors] = useState([{ id: '1p' }])
    const [disks, setDisks] = useState([{ id: '1d' }])
    const [rams, setRams] = useState([{ id: '1r' }])


    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        if (EquipmentSystemCreatedId) {
            setOption("Search")
            dispatch(EquipmentSystemActions.setEquipmentSystemCreatedId(false))
        }
    }, [EquipmentSystemCreatedId])

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const defaultValues = useMemo(() => {
        const processors = edit && fullDataEquipment.computersEquipment?.processors
            ? fullDataEquipment.computersEquipment.processors.reduce((acc, processor, index) => {
                acc[`processors${index + 1}p`] = processor;
                return acc;
            }, {})
            : {};

        const disks = edit && fullDataEquipment.computersEquipment?.diskComputer.reduce((acc, disk, index) => {
            acc[`idDisk${index + 1}d`] = disk.id;
            acc[`diskType${index + 1}d`] = disk.type;
            acc[`diskCapacity${index + 1}d`] = disk.capacity;
            acc[`diskBrand${index + 1}d`] = disk.brand;
            acc[`diskSerie${index + 1}d`] = disk.serie;
            return acc;
        }, {});
        const rams = edit && fullDataEquipment.computersEquipment?.ramComputer.reduce((acc, ram, index) => {
            acc[`idRam${index + 1}r`] = ram.id;
            acc[`ramType${index + 1}r`] = ram.type;
            acc[`ramCapacity${index + 1}r`] = ram.capacity;
            acc[`ramBrand${index + 1}r`] = ram.brand;
            acc[`ramSerie${index + 1}r`] = ram.serie;
            return acc;
        }, {});

        return {
            dependency: edit ? fullDataEquipment.dependency.id : undefined,
            location: edit ? fullDataEquipment.location.name : undefined,
            headquarter: edit ? (fullDataEquipment.headquarter ? fullDataEquipment.headquarter.id : null) : undefined,
            image: edit ? (fullDataEquipment.computersEquipment?.image
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.image
                || fullDataEquipment.networkingAndCommunicationsEquipment?.image) : undefined,

            startWarranty: edit ? (fullDataEquipment.computersEquipment?.startWarranty
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.startWarranty
                || fullDataEquipment.networkingAndCommunicationsEquipment?.startWarranty
            ) ? moment.utc(fullDataEquipment.computersEquipment.startWarranty) : null : undefined,

            endWarranty: edit ? (fullDataEquipment.computersEquipment?.endWarranty
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.endWarranty
                || fullDataEquipment.networkingAndCommunicationsEquipment?.endWarranty
            ) ? moment.utc(fullDataEquipment.computersEquipment.endWarranty) : null : undefined,

            ipAddress: edit
                ? (fullDataEquipment.computersEquipment?.ipAddress
                    || fullDataEquipment.peripheralsAndAccessoriesEquipment?.ipAddress
                    || fullDataEquipment.networkingAndCommunicationsEquipment?.ipAddress)
                : "",
            macAddress: edit ? (fullDataEquipment.computersEquipment?.macAddress
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.macAddress
                || fullDataEquipment.networkingAndCommunicationsEquipment?.macAddress
            ) : "",
            brand: edit ? (fullDataEquipment.computersEquipment?.brand
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.brand
                || fullDataEquipment.networkingAndCommunicationsEquipment?.brand
            ) : "",
            modell: edit ? (fullDataEquipment.computersEquipment?.modell
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.modell
                || fullDataEquipment.networkingAndCommunicationsEquipment?.modell
            ) : "",
            serie: edit ? (fullDataEquipment.computersEquipment?.serie
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.serie
                || fullDataEquipment.networkingAndCommunicationsEquipment?.serie
            ) : "",
            licensePlate: edit ? fullDataEquipment.licensePlate : "",
            adquisitionWay: edit ? fullDataEquipment.computersEquipment?.adquisitionWay : "",
            SO: edit ? fullDataEquipment.computersEquipment?.SO : "",
            version: edit ? fullDataEquipment.computersEquipment?.version : "",
            nameVersion: edit ? fullDataEquipment.computersEquipment?.nameVersion : "",
            numberVersion: edit ? fullDataEquipment.computersEquipment?.numberVersion : "",
            compilationDate: edit ? fullDataEquipment.computersEquipment?.compilationDate : "",
            supplier: edit ? fullDataEquipment.computersEquipment?.supplier : "",
            supplierPhone: edit ? fullDataEquipment.computersEquipment?.supplierPhone : "",
            state: undefined,
            validity: "",
            lastMtto: "",
            novelty: "",
            clientObservation: "",
            equipType: edit ? (fullDataEquipment.computersEquipment?.equipType 
                || fullDataEquipment.peripheralsAndAccessoriesEquipment?.equipType
                || fullDataEquipment.networkingAndCommunicationsEquipment?.equipType
            ) : undefined,

            printerType: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.printerType : undefined,
            observation: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.observation : undefined,
            connection: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.connection : undefined,
            isInstalationShared: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.isInstalationShared : undefined,
            batteryNumber: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.batteryNumber : undefined,
            backupTime: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.backupTime : undefined,
            load: edit ? fullDataEquipment.peripheralsAndAccessoriesEquipment?.load : undefined,
            OS: edit ? fullDataEquipment.computersEquipment?.SO : undefined,
            version: edit ? fullDataEquipment.computersEquipment?.version : undefined,
            nameVersion: edit ? fullDataEquipment.computersEquipment?.nameVersion : undefined,
            numberVersion: edit ? fullDataEquipment.computersEquipment?.numberVersion : undefined,
            compilation: edit ? fullDataEquipment.computersEquipment?.compilation : undefined,

            ...processors,
            ...disks,
            ...rams,

            observations: edit ? fullDataEquipment.computersEquipment?.observations : undefined,
            brandLaptop: edit ? fullDataEquipment.computersEquipment?.brandLaptop : undefined,
            modellLaptop: edit ? fullDataEquipment.computersEquipment?.modellLaptop : undefined,
            serieLaptop: edit ? fullDataEquipment.computersEquipment?.serieLaptop : undefined,
            licensePlateLaptop: edit ? fullDataEquipment.computersEquipment?.licensePlateLaptop : undefined,
            imageLaptop: edit ? fullDataEquipment.computersEquipment?.photoLaptop : undefined,
            imageLaptopSerial: edit ? fullDataEquipment.computersEquipment?.photoLaptopSerial : undefined,
            statusLaptop: edit ? fullDataEquipment.computersEquipment?.statusLaptop : undefined,
            validityLaptop: edit ? fullDataEquipment.computersEquipment?.validityLaptop : undefined,
            lastMttoLaptop: edit ? fullDataEquipment.computersEquipment?.lastMttoLaptop : undefined,
            noveltyLaptop: edit ? fullDataEquipment.computersEquipment?.noveltyLaptop : undefined,
            brandAllInOne: edit ? fullDataEquipment.computersEquipment?.brandAllInOne : undefined,
            modellAllInOne: edit ? fullDataEquipment.computersEquipment?.modellAllInOne : undefined,
            serieAllInOne: edit ? fullDataEquipment.computersEquipment?.serieAllInOne : undefined,
            licensePlateAllInOne: edit ? fullDataEquipment.computersEquipment?.licensePlateAllInOne : undefined,
            imageAllInOne: edit ? fullDataEquipment.computersEquipment?.photoAllInOne : undefined,
            imageAllInOneSerial: edit ? fullDataEquipment.computersEquipment?.photoAllInOneSerial : undefined,
            statusAllInOne: edit ? fullDataEquipment.computersEquipment?.statusAllInOne : undefined,
            validityAllInOne: edit ? fullDataEquipment.computersEquipment?.validityAllInOne : undefined,
            lastMttoAllInOne: edit ? fullDataEquipment.computersEquipment?.lastMttoAllInOne : undefined,
            noveltyAllInOne: edit ? fullDataEquipment.computersEquipment?.noveltyAllInOne : undefined,
            brandCPU: edit ? fullDataEquipment.computersEquipment?.brandCPU : undefined,
            modellCPU: edit ? fullDataEquipment.computersEquipment?.modellCPU : undefined,
            serieCPU: edit ? fullDataEquipment.computersEquipment?.serieCPU : undefined,
            licensePlateCPU: edit ? fullDataEquipment.computersEquipment?.licensePlateCPU : undefined,
            imageCPU: edit ? fullDataEquipment.computersEquipment?.photoCPU : undefined,
            imageCPUSerial: edit ? fullDataEquipment.computersEquipment?.photoCPUSerial : undefined,
            statusCPU: edit ? fullDataEquipment.computersEquipment?.statusCPU : undefined,
            validityCPU: edit ? fullDataEquipment.computersEquipment?.validityCPU : undefined,
            lastMttoCPU: edit ? fullDataEquipment.computersEquipment?.lastMttoCPU : undefined,
            noveltyCPU: edit ? fullDataEquipment.computersEquipment?.noveltyCPU : undefined,
            brandGPU: edit ? fullDataEquipment.computersEquipment?.brandGPU : undefined,
            modellGPU: edit ? fullDataEquipment.computersEquipment?.modellGPU : undefined,
            serieGPU: edit ? fullDataEquipment.computersEquipment?.serieGPU : undefined,
            licensePlateGPU: edit ? fullDataEquipment.computersEquipment?.licensePlateGPU : undefined,
            imageGPU: edit ? fullDataEquipment.computersEquipment?.photoGPU : undefined,
            imageGPUSerial: edit ? fullDataEquipment.computersEquipment?.photoGPUSerial : undefined,
            statusGPU: edit ? fullDataEquipment.computersEquipment?.statusGPU : undefined,
            validityGPU: edit ? fullDataEquipment.computersEquipment?.validityGPU : undefined,
            lastMttoGPU: edit ? fullDataEquipment.computersEquipment?.lastMttoGPU : undefined,
            noveltyGPU: edit ? fullDataEquipment.computersEquipment?.noveltyGPU : undefined,
            brandMonitor: edit ? fullDataEquipment.computersEquipment?.brandMonitor : undefined,
            modellMonitor: edit ? fullDataEquipment.computersEquipment?.modellMonitor : undefined,
            serieMonitor: edit ? fullDataEquipment.computersEquipment?.serieMonitor : undefined,
            licensePlateMonitor: edit ? fullDataEquipment.computersEquipment?.licensePlateMonitor : undefined,
            imageMonitor: edit ? fullDataEquipment.computersEquipment?.photoMonitor : undefined,
            imageMonitorSerial: edit ? fullDataEquipment.computersEquipment?.photoMonitorSerial : undefined,
            statusMonitor: edit ? fullDataEquipment.computersEquipment?.statusMonitor : undefined,
            validityMonitor: edit ? fullDataEquipment.computersEquipment?.validityMonitor : undefined,
            lastMttoMonitor: edit ? fullDataEquipment.computersEquipment?.lastMttoMonitor : undefined,
            noveltyMonitor: edit ? fullDataEquipment.computersEquipment?.noveltyMonitor : undefined,
            brandKeyboard: edit ? fullDataEquipment.computersEquipment?.brandKeyboard : undefined,
            modellKeyboard: edit ? fullDataEquipment.computersEquipment?.modellKeyboard : undefined,
            serieKeyboard: edit ? fullDataEquipment.computersEquipment?.serieKeyboard : undefined,
            licensePlateKeyboard: edit ? fullDataEquipment.computersEquipment?.licensePlateKeyboard : undefined,
            imageKeyboard: edit ? fullDataEquipment.computersEquipment?.photoKeyboard : undefined,
            imageKeyboardSerial: edit ? fullDataEquipment.computersEquipment?.photoKeyboardSerial : undefined,
            statusKeyboard: edit ? fullDataEquipment.computersEquipment?.statusKeyboard : undefined,
            validityKeyboard: edit ? fullDataEquipment.computersEquipment?.validityKeyboard : undefined,
            lastMttoKeyboard: edit ? fullDataEquipment.computersEquipment?.lastMttoKeyboard : undefined,
            noveltyKeyboard: edit ? fullDataEquipment.computersEquipment?.noveltyKeyboard : undefined,
            brandMouse: edit ? fullDataEquipment.computersEquipment?.brandMouse : undefined,
            modellMouse: edit ? fullDataEquipment.computersEquipment?.modellMouse : undefined,
            serieMouse: edit ? fullDataEquipment.computersEquipment?.serieMouse : undefined,
            licensePlateMouse: edit ? fullDataEquipment.computersEquipment?.licensePlateMouse : undefined,
            imageMouse: edit ? fullDataEquipment.computersEquipment?.photoMouse : undefined,
            imageMouseSerial: edit ? fullDataEquipment.computersEquipment?.photoMouseSerial : undefined,
            statusMouse: edit ? fullDataEquipment.computersEquipment?.statusMouse : undefined,
            validityMouse: edit ? fullDataEquipment.computersEquipment?.validityMouse : undefined,
            lastMttoMouse: edit ? fullDataEquipment.computersEquipment?.lastMttoMouse : undefined,
            noveltyMouse: edit ? fullDataEquipment.computersEquipment?.noveltyMouse : undefined,
            brandSignaturePad: edit ? fullDataEquipment.computersEquipment?.brandSignaturePad : undefined,
            modellSignaturePad: edit ? fullDataEquipment.computersEquipment?.modellSignaturePad : undefined,
            serieSignaturePad: edit ? fullDataEquipment.computersEquipment?.serieSignaturePad : undefined,
            licensePlateSignaturePad: edit ? fullDataEquipment.computersEquipment?.licensePlateSignaturePad : undefined,
            imageSignaturePad: edit ? fullDataEquipment.computersEquipment?.photoSignaturePad : undefined,
            imageSignaturePadSerial: edit ? fullDataEquipment.computersEquipment?.photoSignaturePadSerial : undefined,
            statusSignaturePad: edit ? fullDataEquipment.computersEquipment?.statusSignaturePad : undefined,
            validitySignaturePad: edit ? fullDataEquipment.computersEquipment?.validitySignaturePad : undefined,
            lastMttoSignaturePad: edit ? fullDataEquipment.computersEquipment?.lastMttoSignaturePad : undefined,
            noveltySignaturePad: edit ? fullDataEquipment.computersEquipment?.noveltySignaturePad : undefined,
            brandWebcam: edit ? fullDataEquipment.computersEquipment?.brandWebcam : undefined,
            modellWebcam: edit ? fullDataEquipment.computersEquipment?.modellWebcam : undefined,
            serieWebcam: edit ? fullDataEquipment.computersEquipment?.serieWebcam : undefined,
            licensePlateWebcam: edit ? fullDataEquipment.computersEquipment?.licensePlateWebcam : undefined,
            imageWebcam: edit ? fullDataEquipment.computersEquipment?.photoWebcam : undefined,
            imageWebcamSerial: edit ? fullDataEquipment.computersEquipment?.photoWebcamSerial : undefined,
            statusWebcam: edit ? fullDataEquipment.computersEquipment?.statusWebcam : undefined,
            validityWebcam: edit ? fullDataEquipment.computersEquipment?.validityWebcam : undefined,
            lastMttoWebcam: edit ? fullDataEquipment.computersEquipment?.lastMttoWebcam : undefined,
            noveltyWebcam: edit ? fullDataEquipment.computersEquipment?.noveltyWebcam : undefined,
            brandBarcodeReader: edit ? fullDataEquipment.computersEquipment?.brandBardcodeReader : undefined,
            modellBardcodeReader: edit ? fullDataEquipment.computersEquipment?.modellBardcodeReader : undefined,
            serieBardcodeReader: edit ? fullDataEquipment.computersEquipment?.serieBardcodeReader : undefined,
            licensePlateBardcodeReader: edit ? fullDataEquipment.computersEquipment?.licensePlateBardcodeReader : undefined,
            imageBardcodeReader: edit ? fullDataEquipment.computersEquipment?.photoBardcodeReader : undefined,
            imageBardcodeReaderSerial: edit ? fullDataEquipment.computersEquipment?.photoBardcodeReaderSerial : undefined,
            statusBardcodeReader: edit ? fullDataEquipment.computersEquipment?.statusBardcodeReader : undefined,
            validityBardcodeReader: edit ? fullDataEquipment.computersEquipment?.validityBardcodeReader : undefined,
            lastMttoBardcodeReader: edit ? fullDataEquipment.computersEquipment?.lastMttoBardcodeReader : undefined,
            noveltyBardcodeReader: edit ? fullDataEquipment.computersEquipment?.noveltyBardcodeReader : undefined,
            brandPowerAdapter: edit ? fullDataEquipment.computersEquipment?.brandPowerAdapter : undefined,
            modellPowerAdapter: edit ? fullDataEquipment.computersEquipment?.modellPowerAdapter : undefined,
            seriePowerAdapter: edit ? fullDataEquipment.computersEquipment?.seriePowerAdapter : undefined,
            licensePlatePowerAdapter: edit ? fullDataEquipment.computersEquipment?.licensePlatePowerAdapter : undefined,
            imagePowerAdapter: edit ? fullDataEquipment.computersEquipment?.photoPowerAdapter : undefined,
            imagePowerAdapterSerial: edit ? fullDataEquipment.computersEquipment?.photoPowerAdapterSerial : undefined,
            statusPowerAdapter: edit ? fullDataEquipment.computersEquipment?.statusPowerAdapter : undefined,
            validityPowerAdapter: edit ? fullDataEquipment.computersEquipment?.validityPowerAdapter : undefined,
            lastMttoPowerAdapter: edit ? fullDataEquipment.computersEquipment?.lastMttoPowerAdapter : undefined,
            noveltyPowerAdapter: edit ? fullDataEquipment.computersEquipment?.noveltyPowerAdapter : undefined,
            brandFingerprintReader: edit ? fullDataEquipment.computersEquipment?.brandFingerprintReader : undefined,
            modellFingerprintReader: edit ? fullDataEquipment.computersEquipment?.modellFingerprintReader : undefined,
            serieFingerprintReader: edit ? fullDataEquipment.computersEquipment?.serieFingerprintReader : undefined,
            licensePlateFingerprintReader: edit ? fullDataEquipment.computersEquipment?.licensePlateFingerprintReader : undefined,
            imageFingerprintReader: edit ? fullDataEquipment.computersEquipment?.photoFingerprintReader : undefined,
            imageFingerprintReaderSerial: edit ? fullDataEquipment.computersEquipment?.photoFingerprintReaderSerial : undefined,
            statusFingerprintReader: edit ? fullDataEquipment.computersEquipment?.statusFingerprintReader : undefined,
            validityFingerprintReader: edit ? fullDataEquipment.computersEquipment?.validityFingerprintReader : undefined,
            lastMttoFingerprintReader: edit ? fullDataEquipment.computersEquipment?.lastMttoFingerprintReader : undefined,
            noveltyFingerprintReader: edit ? fullDataEquipment.computersEquipment?.noveltyFingerprintReader : undefined,
            brandDiadems: edit ? fullDataEquipment.computersEquipment?.brandDiadems : undefined,
            modellDiadems: edit ? fullDataEquipment.computersEquipment?.modellDiadems : undefined,
            serieDiadems: edit ? fullDataEquipment.computersEquipment?.serieDiadems : undefined,
            licensePlateDiadems: edit ? fullDataEquipment.computersEquipment?.licensePlateDiadems : undefined,
            imageDiadems: edit ? fullDataEquipment.computersEquipment?.photoDiadems : undefined,
            imageDiademsSerial: edit ? fullDataEquipment.computersEquipment?.photoDiademsSerial : undefined,
            statusDiadems: edit ? fullDataEquipment.computersEquipment?.statusDiadems : undefined,
            validityDiadems: edit ? fullDataEquipment.computersEquipment?.validityDiadems : undefined,
            lastMttoDiadems: edit ? fullDataEquipment.computersEquipment?.lastMttoDiadems : undefined,
            noveltyDiadems: edit ? fullDataEquipment.computersEquipment?.noveltyDiadems : undefined,
            brandSource: edit ? fullDataEquipment.computersEquipment?.brandSource : undefined,
            modellSource: edit ? fullDataEquipment.computersEquipment?.modellSource : undefined,
            serieSource: edit ? fullDataEquipment.computersEquipment?.serieSource : undefined,
            licensePlateSource: edit ? fullDataEquipment.computersEquipment?.licensePlateSource : undefined,
            imageSource: edit ? fullDataEquipment.computersEquipment?.photoSource : undefined,
            imageSourceSerial: edit ? fullDataEquipment.computersEquipment?.photoSourceSerial : undefined,
            statusSource: edit ? fullDataEquipment.computersEquipment?.statusSource : undefined,
            validitySource: edit ? fullDataEquipment.computersEquipment?.validitySource : undefined,
            lastMttoSource: edit ? fullDataEquipment.computersEquipment?.lastMttoSource : undefined,
            noveltySource: edit ? fullDataEquipment.computersEquipment?.noveltySource : undefined,
        };
    }, [edit, fullDataEquipment]);

    const {
        register: registerSystem,
        handleSubmit: handleSubmitSystem,
        setValue: setValueSystem,
        watch: watchSystem,
        control: controlSystem,
        formState: { errors: errorsSystem },
        trigger: triggerSystem,
        reset: resetSystem
    } = useForm({
        defaultValues
    })

    const [areaSelected, setAreaSelected] = useState(fullDataEquipment && fullDataEquipment.dependency.area.id)
    const [Dep, setDep] = useState(false);
    const [compInfo, setCompInfo] = useState([])
    const canvas = useRef(null)
    const canvas1 = useRef(null)
    const [image, setImage] = useState(photo)

    useEffect(() => {
        if (edit && fullDataEquipment.computersEquipment) {
            for (let i = 1; i < fullDataEquipment.computersEquipment.diskComputer.length; i++) {
                setDisks(prevDisks => [...prevDisks, { id: (i + 1) + "d" }])
            }
            for (let i = 1; i < fullDataEquipment.computersEquipment.ramComputer.length; i++) {
                setRams(prevRams => [...prevRams, { id: (i + 1) + "r" }])
            }
        }
    }, [edit])

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watchSystem('dependency')) {
            dispatch(LoginActions.getBoss(watchSystem('dependency')))
        }
    }, [watchSystem('dependency')])

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        if (canvas.current) {
            if (!edit) {
                var ctx = canvas.current.getContext('2d')
                var img = new Image()

                canvas.current.width = 350
                canvas.current.height = 280

                img.onload = function () {
                    ctx.drawImage(img, 0, 0, 350, 280)
                }
                var dataImg;
                setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
                img.src = photo
            } else {
                setImage(fullDataEquipment.computersEquipment?.image
                    || fullDataEquipment.peripheralsAndAccessoriesEquipment?.image
                    || fullDataEquipment.networkingAndCommunicationsEquipment?.image
                );

                var ctx = canvas.current.getContext('2d')
                var img = new Image()

                canvas.current.width = 350
                canvas.current.height = 280

                img.onload = function () {
                    ctx.drawImage(img, 0, 0, 350, 280)
                }
                var dataImg;
                setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
                img.src = fullDataEquipment.computersEquipment?.image 
                    || fullDataEquipment.peripheralsAndAccessoriesEquipment?.image
                    || fullDataEquipment.networkingAndCommunicationsEquipment?.image

                setTimeout(function () { setValueSystem("image", dataImg) }, 1000)
            }
        }
    }, [canvas, watchSystem('equipType')])

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueSystem("image", dataImg) }, 1000)
    };

    useEffect(() => {
        if (canvas1.current) {
            var ctx = canvas1.current.getContext('2d')
            var img = new Image()

            canvas1.current.width = 350
            canvas1.current.height = 280

            img.onload = function () {
                ctx.drawImage(img, 0, 0, 350, 280)
            }
            var dataImg;
            setTimeout(function () { dataImg = canvas1.current.toDataURL() }, 1000)
            img.src = photo
        }
    }, [canvas, watchSystem('equipType')])

    const uploadImage1 = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas1.current.getContext('2d')
        var img = new Image()

        canvas1.current.width = 350
        canvas1.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas1.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueSystem("imageSerial", dataImg) }, 1000)
    };

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    const SOVersions = {
        "Windows": ["XP", "Vista", "7", "8", "8.1", "10", "11"],
        "Windows Server": ["2000", "2003", "2008", "2012", "2016", "2019", "2022"],
    }

    return (
        <div className='FormEquipmentSystem'>
            <p className='titleSection'>Hoja de vida</p>
            <div className='initialSelects'>
                <Controller
                    name="headquarter"
                    control={controlSystem}
                    disabled={edit}
                    render={({ field }) => (
                        <Select
                            {...field}
                            placeholder='Sede'
                            value={watchSystem('headquarter')}
                            className="select"
                        >
                            <Option value={null}>Sede principal</Option>
                            {headquarters && headquarters.map(d => (
                                <Option key={d.id} value={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    )}
                />
                {errorsSystem.headquarter && <p className='errorMessage'>{errorsSystem.headquarter.message}</p>}
                <Select
                    className="select"
                    placeholder='Area'
                    disabled={edit}
                    onChange={e => {
                        setAreaSelected(e)
                        setValueSystem('dependency', undefined)
                    }}
                >
                    {dependencys && areas.map(d => (
                        <Option key={d.id} value={d.id} >{d.name}</Option>
                    ))}
                </Select>
                {areaSelected &&
                    <Controller
                        name="dependency"
                        rules={{
                            required: "La dependencia es requerida",
                        }}
                        control={controlSystem}
                        render={({ field }) => (
                            <Select
                                {...field}
                                placeholder='Dependencia'
                                value={watchSystem('dependency')}
                                className="select"
                            >
                                {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        )}
                    />
                }
                {errorsSystem.dependency && <p className='errorMessage'>{errorsSystem.dependency.message}</p>}
                {
                    boss &&
                    <Select
                        value="valor"
                        disabled
                        className='select'
                    >
                        <Option value="valor">
                            {boss !== null
                                ?
                                boss.name + " " +
                                boss.secondName + " " +
                                boss.lastName + " " +
                                boss.secondLastName
                                :
                                "No hay jefe de dependencia"
                            }
                        </Option>
                    </Select>
                }
                <Controller
                    name="location"
                    control={controlSystem}
                    rules={{
                        required: "La ubicación es requerida",
                    }}
                    render={({ field }) => (
                        <AutoComplete
                            {...field}
                            value={watchSystem('location')}
                            placeholder='Ubicación'
                            className="select"
                            style={{ width: '12vw' }}
                            options={locations && locations.map((location) => ({
                                value: location.name,
                                label: location.name,
                            }))}
                            filterOption={(inputValue, option) =>
                                option ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                            }
                        />
                    )}
                />
                {errorsSystem.location && <p className='errorMessage'>{errorsSystem.location.message}</p>}
            </div>

            {watchSystem('dependency') !== undefined && watchSystem('headquarter') !== undefined && watchSystem('location') !== undefined &&
                <div>
                    <p className='titleSection'>Seleccione la Maquina a Realizar Inventario</p>
                    <Select
                        {...registerSystem('equipType', {
                        })}
                        className="selectEquips"
                        placeholder='Tipo de Maquina'
                        value={watchSystem('equipType')}
                        onChange={e => {
                            setValueSystem("equipType", e)
                        }}
                        disabled={edit}
                    >
                        <Option value={"Cableado Estructurado"}>Cableado Estructurado</Option>
                        <Option value={"Computador de mesa"}>Computador de mesa</Option>
                        <Option value={"Escaner"}>Escaner</Option>
                        <Option value={"Impresora"}>Impresora</Option>
                        <Option value={"Plotter"}>Plotter</Option>
                        <Option value={"Portatil"}>Portatil</Option>
                        <Option value={"Rack"}>Rack</Option>
                        <Option value={"Router"}>Router</Option>
                        <Option value={"Switch"}>Switch</Option>
                        <Option value={"Servidor"}>Servidor</Option>
                        <Option value={"Telefono"}>Telefono</Option>
                        <Option value={"Todo en uno"}>Todo en uno</Option>
                        <Option value={"UPS"}>UPS</Option>
                        <Option value={"Video Beam"}>Video Beam</Option>
                    </Select>
                </div>
            }
            {
                watchSystem('equipType') &&
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='titleSection' style={{ alignSelf: 'flex-start', width: '100%' }}>Imagen del Equipo</p>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Controller
                                name="image"
                                control={controlSystem}
                                rules={{
                                    required: "La imagen es requerida",
                                }}
                                render={({ field }) => (
                                    <div className='imageDiv'>
                                        <canvas ref={canvas} className='canvas'> </canvas>
                                        <p>Imagen</p>
                                        <div className='imageButtons'>
                                            <Button
                                                onClick={() => {
                                                    setImage(photo)
                                                    setValueSystem('image', undefined)
                                                }}
                                                style={{
                                                    backgroundColor: '#ffff',
                                                    color: '#C4C4C4',
                                                    borderRadius: '10px',
                                                    fontSize: '1vw'
                                                }}
                                            >
                                                Descartar
                                            </Button>
                                            <label className="custom-file-upload">
                                                <input
                                                    accept="image/png, image/jpeg"
                                                    onClick={(event) => { event.target.value = null }}
                                                    onChange={e => {
                                                        uploadImage(e);
                                                    }}
                                                    type='file'
                                                ></input>
                                                <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                            </label>
                                        </div>
                                    </div>
                                )}
                            />
                            {(watchSystem('equipType') !== 'Portatil' && watchSystem('equipType') !== 'Todo en uno' && watchSystem('equipType') !== 'Computador de mesa' && watchSystem('equipType') !== 'Servidor') &&
                                <Controller
                                    name="imageSerial"
                                    control={controlSystem}
                                    render={({ field }) => (
                                        <div className='imageDiv'>
                                            <canvas ref={canvas1} className='canvas'> </canvas>
                                            <p>Imagen del serial</p>

                                            <div className='imageButtons'>
                                                <Button
                                                    onClick={() => {
                                                        setImage(photo)
                                                        setValueSystem('imageSerial', undefined)
                                                    }}
                                                    style={{
                                                        backgroundColor: '#ffff',
                                                        color: '#C4C4C4',
                                                        borderRadius: '10px',

                                                        fontSize: '1vw'
                                                    }}
                                                >
                                                    Descartar
                                                </Button>
                                                <label className="custom-file-upload">
                                                    <input
                                                        accept="image/png, image/jpeg"
                                                        onClick={(event) => { event.target.value = null }}
                                                        onChange={e => {
                                                            uploadImage1(e);
                                                        }}
                                                        type='file'
                                                    ></input>
                                                    <i className="fa fa-cloud-upload"></i> Adjuntar imagen
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                            }
                        </div>
                        {errorsSystem.image && <p className='errorMessage'>{errorsSystem.image.message}</p>}
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '1vw', flexWrap: 'wrap' }}>
                                <div>
                                    <p className='labelDate'>Inicio Garantia</p>
                                    <DatePicker
                                        value={watchSystem("startWarranty")}
                                        {...registerSystem('startWarranty', {
                                        })}
                                        style={{ width: '14vw', height: '2vw', marginRight: '4vw' }}
                                        onChange={d => setValueSystem("startWarranty", d)}
                                    />
                                    <p></p>
                                    <button className="buttonDeleteDate" onClick={() => setValueSystem("startWarranty", undefined)}>Eliminar fecha</button>
                                    {errorsSystem.startWarranty && <p className='errorMessage'>{errorsSystem.startWarranty.message}</p>}
                                </div>
                                <div>
                                    <p className='labelDate'>Fin Garantia</p>
                                    <DatePicker
                                        value={watchSystem("endWarranty")}
                                        {...registerSystem('endWarranty', {
                                        })}
                                        style={{ width: '14vw', height: '2vw', marginRight: '4vw' }}
                                        onChange={d => setValueSystem("endWarranty", d)}
                                    />
                                    <p></p>
                                    <button className="buttonDeleteDate" onClick={() => setValueSystem("endWarranty", undefined)}>Eliminar fecha</button>
                                    {errorsSystem.endWarranty && <p className='errorMessage'>{errorsSystem.endWarranty.message}</p>}
                                </div>
                                <Input
                                    {...registerSystem('ipAddress', {
                                    })}
                                    height={'2vw'}
                                    width={'14vw'}
                                    mt={'0.125vw'}
                                    mr={'4vw'}
                                    va={watchSystem('ipAddress')}
                                    setData={e => setValueSystem('ipAddress', e)}
                                    label='Dirección IP'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.ipAddress && errorsSystem.ipAddress.message}
                                />
                                <Input
                                    {...registerSystem('macAddress', {
                                    })}
                                    height={'2vw'}
                                    width={'14vw'}
                                    mt={'0.125vw'}
                                    va={watchSystem('macAddress')}
                                    setData={e => setValueSystem('macAddress', e)}
                                    label='Dirección MAC'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.macAddress && errorsSystem.macAddress.message}
                                />
                            </div>
                            {(watchSystem('equipType') === 'Cableado Estructurado' || watchSystem('equipType') === 'Rack' || watchSystem('equipType') === 'Switch' || watchSystem('equipType') === 'Telefono' || watchSystem('equipType') === 'Router') &&
                                <div>
                                    <p className='titleSection' style={{ marginLeft: '1vw' }}>Caracteristicas Tecnicas</p>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '1vw', width: '100%', justifyContent: 'space-between' }}>
                                        <Input
                                            {...registerSystem('brand', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El marca es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mr={'1vw'}
                                            width={'7.96vw'}
                                            mt={'0.125vw'}
                                            va={watchSystem('brand')}
                                            setData={e => setValueSystem('brand', e)}
                                            error={errorsSystem.brand && errorsSystem.brand.message}
                                            label='Marca'
                                            labelstyle='inputLabelBordered'
                                        />
                                        <Input
                                            {...registerSystem('modell', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El modelo es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mr={'1vw'}
                                            width={'7.96vw'}
                                            mt={'0.125vw'}
                                            va={watchSystem('modell')}
                                            setData={e => setValueSystem('modell', e)}
                                            label='Modelo'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.modell && errorsSystem.modell.message}
                                        />
                                        <Input
                                            {...registerSystem('serie', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La serie es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mr={'1vw'}
                                            width={'7.96vw'}
                                            mt={'0.125vw'}
                                            va={watchSystem('serie')}
                                            setData={e => setValueSystem('serie', e)}
                                            label='Serie'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.serie && errorsSystem.serie.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlate', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La placa es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mr={'1vw'}
                                            mt={'0.125vw'}
                                            width={'7.96vw'}
                                            va={watchSystem('licensePlate')}
                                            setData={e => setValueSystem('licensePlate', e)}
                                            label='Placa'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.licensePlate && errorsSystem.licensePlate.message}
                                        />
                                        <Controller
                                            name={"state"}
                                            rules={{
                                                required: "El estado es requerido",
                                            }}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("state")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '1.4vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`state`] && <p className='errorMessage'>{errorsSystem[`state`].message}</p>}
                                        <Input
                                            {...registerSystem('novelty', {
                                            })}
                                            height={'2vw'}
                                            mr={'1vw'}
                                            mt={'0.125vw'}
                                            width={'46vw'}
                                            fontSize={'1vw'}
                                            va={watchSystem('novelty')}
                                            setData={e => setValueSystem('novelty', e)}
                                            label='Novedad'
                                            labelstyle='inputLabelBordered'
                                            textArea
                                            error={errorsSystem.novelty && errorsSystem.novelty.message}
                                        />

                                    </div>
                                </div>}
                            {(watchSystem('equipType') === 'Portatil' || watchSystem('equipType') === 'Todo en uno' || watchSystem('equipType') === 'Computador de mesa' || watchSystem('equipType') === 'Servidor') &&
                                <div>
                                    <div style={{ width: '100%', display: 'flex', marginLeft: '1vw', marginTop: '1vw', gap: '1vw' }}>
                                        <Controller
                                            name={"OS"}
                                            rules={{
                                                required: "El SO es requerido",
                                            }}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Sistema Operativo'
                                                    value={watchSystem("OS")}
                                                    className="select"
                                                    style={{ width: '11vw', height: '2vw', marginTop: '1.4vw' }}
                                                >
                                                    <Option value="Windows">Windows</Option>
                                                    <Option value="Windows Server">Windows Server</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`OS`] && <p className='errorMessage'>{errorsSystem[`OS`].message}</p>}
                                        <Controller
                                            name={"version"}
                                            rules={{
                                                required: "La versión es requerida",
                                            }}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Versión'
                                                    value={watchSystem("version")}
                                                    className="select"
                                                    style={{ width: '11vw', height: '2vw', marginTop: '1.4vw' }}
                                                >
                                                    {SOVersions[watchSystem("OS")] && SOVersions[watchSystem("OS")].map(d => (
                                                        <Option key={d} value={d}>{d}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`version`] && <p className='errorMessage'>{errorsSystem[`version`].message}</p>}
                                        <Input
                                            {...registerSystem('nameVersion', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El nombre version es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mt={'0.125vw'}
                                            width={'11vw'}
                                            va={watchSystem('nameVersion')}
                                            setData={e => setValueSystem('nameVersion', e)}
                                            label='Nombre Versión'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.nameVersion && errorsSystem.nameVersion.message}
                                        />
                                        <Input
                                            {...registerSystem('numberVersion', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El numero version es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mt={'0.125vw'}
                                            width={'11vw'}
                                            va={watchSystem('numberVersion')}
                                            setData={e => setValueSystem('numberVersion', e)}
                                            label='Numero versión'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.numberVersion && errorsSystem.numberVersion.message}
                                        />
                                        <Input
                                            {...registerSystem('compilation', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("Compilacion es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mt={'0.125vw'}
                                            width={'11vw'}
                                            va={watchSystem('compilation')}
                                            setData={e => setValueSystem('compilation', e)}
                                            label='Compilación'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.compilation && errorsSystem.compilation.message}
                                        />
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', marginLeft: '1vw', marginTop: '1vw', gap: '0.5vw' }}>
                                        <Input
                                            {...registerSystem('supplier', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El proveedor es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mt={'0.125vw'}
                                            width={'14vw'}
                                            va={watchSystem('supplier')}
                                            setData={e => setValueSystem('supplier', e)}
                                            label='Proveedor'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.supplier && errorsSystem.supplier.message}
                                        />
                                        <Input
                                            {...registerSystem('supplierPhone', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El telefono del proveedor es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            height={'2vw'}
                                            mt={'0.125vw'}
                                            width={'14vw'}
                                            va={watchSystem('supplierPhone')}
                                            setData={e => setValueSystem('supplierPhone', e)}
                                            label='Telefono Proveedor'
                                            labelstyle='inputLabelBordered'
                                            error={errorsSystem.supplierPhone && errorsSystem.supplierPhone.message}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ marginLeft: '1vw', fontFamily: 'Lato', color: '#595959', fontSize: '1vw' }}>Forma de adquisición</p>
                                        <div>
                                            <Radio.Group
                                                className='radioG'
                                                {...registerSystem('adquisitionWay', {
                                                    validate: (value) => {
                                                        const errors = [];
                                                        if (!value || value === '') {
                                                            errors.push("La forma de adquisición es requerido");
                                                        }
                                                        return errors.length === 0 || errors.join(', ');
                                                    },
                                                })}
                                                onChange={d => setValueSystem('adquisitionWay', d.target.value)}
                                                value={watchSystem('adquisitionWay')}>
                                                <Radio className='radio' value={'Compra Directa'}>Compra Directa</Radio>
                                                <Radio className='radio' value={'Comodato'}>Comodato</Radio>
                                                <Radio className='radio' value={'Donación de Particulares'}>Donación de Particulares</Radio>
                                                <Radio className='radio' value={'Donado por el gobierno'}>Donado por el gobierno</Radio>
                                            </Radio.Group>
                                            {errorsSystem.adquisitionWay && <p className='errorMessage'>{errorsSystem.adquisitionWay.message}</p>}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: '1vw' }}>
                                        {!edit &&
                                            <div>
                                                {processors.map((d, i) => (
                                                    <div key={i} style={{ display: 'flex' }}>
                                                        <Input
                                                            {...registerSystem(`processors${d.id}`, {
                                                                validate: (value) => {
                                                                    const errors = [];
                                                                    if (!value || value === '') {
                                                                        errors.push("El procesador es requerido");
                                                                    }
                                                                    return errors.length === 0 || errors.join(', ');
                                                                },
                                                            })}
                                                            key={d.id}
                                                            height={'2vw'}
                                                            mr={'1vw'}
                                                            mt={'0.125vw'}
                                                            width={'15vw'}
                                                            va={watchSystem(`processors${d.id}`)}
                                                            setData={e => setValueSystem(`processors${d.id}`, e)}
                                                            label='Procesador'
                                                            labelstyle='inputLabelBordered'
                                                            error={errorsSystem[`processors${d.id}`] && errorsSystem[`processors${d.id}`].message}
                                                        />
                                                        <MinusCircleOutlined style={{ color: '#017C5B', fontSize: '2.3vw', marginTop: '3vh' }} onClick={() => {
                                                            if (processors.length > 1) {
                                                                setProcessors(processors.filter(item => item.id !== d.id));
                                                            } else {
                                                                message.warn('No se puede eliminar el único procesador');
                                                            }
                                                        }} />
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {!edit && <PlusCircleOutlined style={{ color: '#017C5B', fontSize: '2.3vw', marginTop: '3vh', marginLeft: '1vw' }} onClick={() => setProcessors([...processors, { "id": processors.length + 1 + "p" }])} />}
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: '1vw' }}>
                                        <div>
                                            {disks.map((d, i) => (
                                                <div key={d.id} style={{ display: 'flex' }}>
                                                    <Controller
                                                        name={`diskType${d.id}`}
                                                        rules={{
                                                            required: "El tipo de disco es requerido",
                                                        }}
                                                        control={controlSystem}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                placeholder='Tipo de disco'
                                                                value={watchSystem(`diskType${d.id}`)}
                                                                className="select"
                                                                style={{ width: '10vw', height: '2vw', marginTop: '1.15vw', marginRight: '1vw' }}
                                                            >
                                                                <Option value="HDD 2,5">HDD 2,5</Option>
                                                                <Option value="HDD 3,5">HDD 3,5</Option>
                                                                <Option value="SSD 2,5">SSD 2,5</Option>
                                                                <Option value="M2 NVME">M2 NVME</Option>
                                                                <Option value="M2 SATA III">M2 SATA III</Option>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errorsSystem[`diskType${d.id}`] && <p className='errorMessage'>{errorsSystem[`diskType${d.id}`].message}</p>}

                                                    <Controller
                                                        name={`diskCapacity${d.id}`}
                                                        rules={{
                                                            required: "La capacidad del disco es requerida",
                                                        }}
                                                        control={controlSystem}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                placeholder='Capacidad de disco'
                                                                value={watchSystem(`diskCapacity${d.id}`)}
                                                                className="select"
                                                                style={{ width: '10vw', height: '2vw', marginTop: '1.15vw', marginRight: '1vw' }}
                                                            >
                                                                <Option value="120 GB">120 GB</Option>
                                                                <Option value="150 GB">150 GB</Option>
                                                                <Option value="160 GB">160 GB</Option>
                                                                <Option value="240 GB">240 GB</Option>
                                                                <Option value="250 GB">250 GB</Option>
                                                                <Option value="300 GB">300 GB</Option>
                                                                <Option value="320 GB">320 GB</Option>
                                                                <Option value="400 GB">400 GB</Option>
                                                                <Option value="480 GB">480 GB</Option>
                                                                <Option value="500 GB">500 GB</Option>
                                                                <Option value="1000 GB (1TB)">1000 GB (1TB)</Option>
                                                                <Option value="1500 GB (1,5 TB)">1500 GB (1,5 TB)</Option>
                                                                <Option value="1800 GB (1,8 TB)">1800 GB (1,8 TB)</Option>
                                                                <Option value="2000 GB (2TB)">2000 GB (2TB)</Option>
                                                                <Option value="3000 GB (3TB)">3000 GB (3TB)</Option>
                                                                <Option value="4000 GB (4TB)">4000 GB (4TB)</Option>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errorsSystem[`diskCapacity${d.id}`] && <p className='errorMessage'>{errorsSystem[`diskCapacity${d.id}`].message}</p>}

                                                    <Controller
                                                        name={`diskBrand${d.id}`}
                                                        rules={{
                                                            required: "La marca del disco es requerida",
                                                        }}
                                                        control={controlSystem}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                placeholder='Marca de disco'
                                                                value={watchSystem(`diskBrand${d.id}`)}
                                                                className="select"
                                                                style={{ width: '10vw', height: '2vw', marginTop: '1.15vw', marginRight: '1vw' }}
                                                            >
                                                                <Option value="Adata">Adata</Option>
                                                                <Option value="Samsung">Samsung</Option>
                                                                <Option value="Western Digital (WD)">Western Digital (WD)</Option>
                                                                <Option value="Kingston">Kingston</Option>
                                                                <Option value="Seagate">Seagate</Option>
                                                                <Option value="Sandisk">Sandisk</Option>
                                                                <Option value="Crucial">Crucial</Option>
                                                                <Option value="Corsair">Corsair</Option>
                                                                <Option value="Hikvision">Hikvision</Option>
                                                                <Option value="Intel">Intel</Option>
                                                                <Option value="Toshiba">Toshiba</Option>
                                                                <Option value="Hitachi">Hitachi</Option>
                                                                <Option value="No Aplica">No Aplica</Option>
                                                                <Option value="No se Visualiza">No se Visualiza</Option>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errorsSystem[`diskBrand${d.id}`] && <p className='errorMessage'>{errorsSystem[`diskBrand${d.id}`].message}</p>}

                                                    <Input
                                                        {...registerSystem(`diskSerie${d.id}`, {
                                                            validate: (value) => {
                                                                const errors = [];
                                                                if (!value || value === '') {
                                                                    errors.push("La serie del disco es requerida");
                                                                }
                                                                return errors.length === 0 || errors.join(', ');
                                                            },
                                                        })}
                                                        height={'2vw'}
                                                        mr={'1vw'}
                                                        mt={'0.125vw'}
                                                        width={'12vw'}
                                                        va={watchSystem(`diskSerie${d.id}`)}
                                                        setData={e => setValueSystem(`diskSerie${d.id}`, e)}
                                                        label='Serie'
                                                        labelstyle='inputLabelBordered'
                                                        error={errorsSystem[`diskSerie${d.id}`] && errorsSystem[`diskSerie${d.id}`].message}
                                                    />
                                                    <MinusCircleOutlined style={{ color: '#017C5B', fontSize: '2.3vw', marginTop: '3vh' }} onClick={() => {
                                                        if (disks.length > 1) {
                                                            setDisks(disks.filter(item => item.id !== d.id));
                                                        } else {
                                                            message.warn('No se puede eliminar el único disco');
                                                        }
                                                    }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {!edit && <PlusCircleOutlined style={{ color: '#017C5B', fontSize: '2.3vw', marginTop: '3vh', marginLeft: '1vw' }} onClick={() => setDisks([...disks, { "id": disks.length + 1 + "d" }])} />}
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: '1vw' }}>
                                        <div>
                                            {rams.map((d, i) => (
                                                <div key={d.id} style={{ display: 'flex' }}>
                                                    <Controller
                                                        name={`ramType${d.id}`}
                                                        rules={{
                                                            required: "El tipo de RAM es requerido",
                                                        }}
                                                        control={controlSystem}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                placeholder='Tipo de RAM'
                                                                value={watchSystem(`ramType${d.id}`)}
                                                                className="select"
                                                                style={{ width: '10vw', height: '2vw', marginTop: '1.15vw', marginRight: '1vw' }}
                                                            >
                                                                <Option value="DDR2">DDR2</Option>
                                                                <Option value="DDR3">DDR3</Option>
                                                                <Option value="DDR4">DDR4</Option>
                                                                <Option value="DDR5">DDR5</Option>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errorsSystem[`ramType${d.id}`] && <p className='errorMessage'>{errorsSystem[`ramType${d.id}`].message}</p>}

                                                    <Controller
                                                        name={`ramCapacity${d.id}`}
                                                        rules={{
                                                            required: "La capacidad de RAM es requerida",
                                                        }}
                                                        control={controlSystem}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                placeholder='Capacidad de RAM'
                                                                value={watchSystem(`ramCapacity${d.id}`)}
                                                                className="select"
                                                                style={{ width: '10vw', height: '2vw', marginTop: '1.15vw', marginRight: '1vw' }}
                                                            >
                                                                <Option value="2 GB">2 GB</Option>
                                                                <Option value="4 GB">4 GB</Option>
                                                                <Option value="8 GB">8 GB</Option>
                                                                <Option value="16 GB">16 GB</Option>
                                                                <Option value="32 GB">32 GB</Option>
                                                                <Option value="64 GB">64 GB</Option>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errorsSystem[`ramCapacity${d.id}`] && <p className='errorMessage'>{errorsSystem[`ramCapacity${d.id}`].message}</p>}

                                                    <Controller
                                                        name={`ramBrand${d.id}`}
                                                        rules={{
                                                            required: "La marca de RAM es requerida",
                                                        }}
                                                        control={controlSystem}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                placeholder='Marca de RAM'
                                                                value={watchSystem(`ramBrand${d.id}`)}
                                                                className="select"
                                                                style={{ width: '10vw', height: '2vw', marginTop: '1.15vw', marginRight: '1vw' }}
                                                            >
                                                                <Option value="G-Skill">G-Skill</Option>
                                                                <Option value="Corsair">Corsair</Option>
                                                                <Option value="Kingston">Kingston</Option>
                                                                <Option value="Patriot">Patriot</Option>
                                                                <Option value="Crucial">Crucial</Option>
                                                                <Option value="Corsair Vengeance LED">Corsair Vengeance LED</Option>
                                                                <Option value="G.Skill Trident Z RGB">G.Skill Trident Z RGB</Option>
                                                                <Option value="Kingston HyperX Predator">Kingston HyperX Predator</Option>
                                                                <Option value="Kingston HyperX Fury">Kingston HyperX Fury</Option>
                                                                <Option value="Corsair Dominator Platinum RGB">Corsair Dominator Platinum RGB</Option>
                                                                <Option value="HyperX Fury RGB 3733MHz">HyperX Fury RGB 3733MHz</Option>
                                                                <Option value="G.Skill Trident Z RGB DC">G.Skill Trident Z RGB DC</Option>
                                                                <Option value="Adata Spectrix D80">Adata Spectrix D80</Option>
                                                                <Option value="G.Skill TridentZ Royal">G.Skill TridentZ Royal</Option>
                                                                <Option value="Corsair Vengeance LPX">Corsair Vengeance LPX</Option>
                                                                <Option value="G.Skill Mac RAM">G.Skill Mac RAM</Option>
                                                                <Option value="Crucial Ballistix Sport">Crucial Ballistix Sport</Option>
                                                                <Option value="Intel">Intel</Option>
                                                                <Option value="Toshiba">Toshiba</Option>
                                                                <Option value="Hitachi">Hitachi</Option>
                                                                <Option value="No Aplica">No Aplica</Option>
                                                                <Option value="No se Visualiza">No se Visualiza</Option>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errorsSystem[`ramBrand${d.id}`] && <p className='errorMessage'>{errorsSystem[`ramBrand${d.id}`].message}</p>}

                                                    <Input
                                                        {...registerSystem(`ramSerie${d.id}`, {
                                                            validate: (value) => {
                                                                const errors = [];
                                                                if (!value || value === '') {
                                                                    errors.push("La serie del disco es requerida");
                                                                }
                                                                return errors.length === 0 || errors.join(', ');
                                                            },
                                                        })}
                                                        height={'2vw'}
                                                        mr={'1vw'}
                                                        mt={'0.125vw'}
                                                        width={'12vw'}
                                                        va={watchSystem(`ramSerie${d.id}`)}
                                                        setData={e => setValueSystem(`ramSerie${d.id}`, e)}
                                                        label='Serie'
                                                        labelstyle='inputLabelBordered'
                                                        error={errorsSystem[`ramSerie${d.id}`] && errorsSystem[`ramSerie${d.id}`].message}
                                                    />
                                                    <MinusCircleOutlined style={{ color: '#017C5B', fontSize: '2.3vw', marginTop: '3vh' }} onClick={() => {
                                                        if (rams.length > 1) {
                                                            setRams(rams.filter(item => item.id !== d.id));
                                                        } else {
                                                            message.warn('No se puede eliminar el único disco');
                                                        }
                                                    }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {!edit && <PlusCircleOutlined style={{ color: '#017C5B', fontSize: '2.3vw', marginTop: '3vh', marginLeft: '1vw' }} onClick={() => setRams([...rams, { "id": rams.length + 1 + "d" }])} />}
                                    </div>
                                </div>
                            }
                            {watchSystem('equipType') === 'Impresora' &&
                                <div>
                                    <p className='titleSection' style={{ marginLeft: '1vw' }}>Tipo de equipo</p>
                                    <div>
                                        <Radio.Group
                                            className='radioG'
                                            {...registerSystem('printerType', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El tipo de equipo es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            onChange={d => setValueSystem('printerType', d.target.value)}
                                            value={watchSystem('printerType')}>
                                            <Radio className='radio' value={'Laser'}>Laser</Radio>
                                            <Radio className='radio' value={'Matriz de punto'}>Matriz de punto</Radio>
                                            <Radio className='radio' value={'Inyección'}>Inyección</Radio>
                                            <Radio className='radio' value={'A color'}>A color</Radio>
                                            <Radio className='radio' value={'Termica'}>Termica</Radio>
                                        </Radio.Group>
                                        {errorsSystem.printerType && <p className='errorMessage'>{errorsSystem.printerType.message}</p>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(watchSystem('equipType') === 'Portatil' || watchSystem('equipType') === 'Todo en uno' || watchSystem('equipType') === 'Computador de mesa' || watchSystem('equipType') === 'Servidor') &&
                        <div style={{ width: '100%' }}>
                            <p className='titleSection'>Caracteristicas Tecnicas</p>
                            <div>
                                {watchSystem('equipType') === 'Portatil' &&
                                    <div className='divInputs'>
                                        <Input
                                            label={"Tipo"}
                                            mr={"0.7vw"}
                                            mt={'0.125vw'}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"PORTATIL"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandLaptop"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandLaptop")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '0.3vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandLaptop`] && <p className='errorMessage'>{errorsSystem[`brandLaptop`].message}</p>}

                                        <Input
                                            {...registerSystem('modellLaptop', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El modelo es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Modelo"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            mt={'0.125vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellLaptop')}
                                            setData={e => setValueSystem('modellLaptop', e)}
                                            error={errorsSystem.modellLaptop && errorsSystem.modellLaptop.message}
                                        />
                                        <Input
                                            {...registerSystem('serieLaptop', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La serie es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Serie"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieLaptop')}
                                            setData={e => setValueSystem('serieLaptop', e)}
                                            error={errorsSystem.serieLaptop && errorsSystem.serieLaptop.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateLaptop', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La placa es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Placa"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateLaptop')}
                                            setData={e => setValueSystem('licensePlateLaptop', e)}
                                            error={errorsSystem.licensePlateLaptop && errorsSystem.licensePlateLaptop.message}
                                        />
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <p className='titleColumn'>Foto</p>
                                            {!watchSystem('imageLaptop') ?
                                                <input
                                                    {...registerSystem('imageLaptop', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageLaptop', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageLaptop")} className='inputPhoto' onClick={() => setValueSystem('imageLaptop', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <p className='titleColumn'>Foto serial</p>
                                            {!watchSystem('imageLaptopSerial') ?
                                                <input
                                                    {...registerSystem('imageLaptopSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageLaptopSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageLaptopSerial")} className='inputPhoto' onClick={() => setValueSystem('imageLaptopSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusLaptop"}
                                            rules={{
                                                required: "El estado es requerido",
                                            }}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusLaptop")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-0.7vw', marginRight: '0.7vw', marginBottom: '-1.2vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusLaptop`] && <p className='errorMessage'>{errorsSystem[`statusLaptop`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyLaptop', {

                                            })}
                                            label={"Novedad"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyLaptop')}
                                            setData={e => setValueSystem('noveltyLaptop', e)}
                                            error={errorsSystem.noveltyLaptop && errorsSystem.noveltyLaptop.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandLaptop', null)
                                            setValueSystem('modellLaptop', null)
                                            setValueSystem('serieLaptop', null)
                                            setValueSystem('licensePlateLaptop', null)
                                            setValueSystem('imageLaptop', null)
                                            setValueSystem('imageLaptopSerial', null)
                                            setValueSystem('statusLaptop', null)
                                            setValueSystem('noveltyLaptop', null)
                                        }} />
                                    </div>}
                                {watchSystem('equipType') === 'Todo en uno' &&
                                    <div className='divInputs'>
                                        <Input
                                            label={"Tipo"}
                                            mr={"0.7vw"}
                                            mt={'0.125vw'}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"EQUIPO TODO EN UNO INTEGRADO"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandAllInOne"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandAllInOne")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '0.3vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandAllInOne`] && <p className='errorMessage'>{errorsSystem[`brandAllInOne`].message}</p>}

                                        <Input
                                            {...registerSystem('modellAllInOne', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El modelo es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Modelo"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            mt={'0.125vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellAllInOne')}
                                            setData={e => setValueSystem('modellAllInOne', e)}
                                            error={errorsSystem.modellAllInOne && errorsSystem.modellAllInOne.message}
                                        />
                                        <Input
                                            {...registerSystem('serieAllInOne', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La serie es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Serie"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieAllInOne')}
                                            setData={e => setValueSystem('serieAllInOne', e)}
                                            error={errorsSystem.serieAllInOne && errorsSystem.serieAllInOne.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateAllInOne', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La placa es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Placa"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateAllInOne')}
                                            setData={e => setValueSystem('licensePlateAllInOne', e)}
                                            error={errorsSystem.licensePlateAllInOne && errorsSystem.licensePlateAllInOne.message}
                                        />
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <p className='titleColumn'>Foto</p>
                                            {!watchSystem('imageAllInOne') ?
                                                <input
                                                    {...registerSystem('imageAllInOne', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageAllInOne', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageAllInOne")} className='inputPhoto' onClick={() => setValueSystem('imageAllInOne', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <p className='titleColumn'>Foto serial</p>
                                            {!watchSystem('imageAllInOneSerial') ?
                                                <input
                                                    {...registerSystem('imageAllInOneSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageAllInOneSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageAllInOneSerial")} className='inputPhoto' onClick={() => setValueSystem('imageAllInOneSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusAllInOne"}
                                            rules={{
                                                required: "El estado es requerido",
                                            }}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusAllInOne")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-0.7vw', marginRight: '0.7vw', marginBottom: '-1.2vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusAllInOne`] && <p className='errorMessage'>{errorsSystem[`statusAllInOne`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyAllInOne', {

                                            })}
                                            label={"Novedad"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyAllInOne')}
                                            setData={e => setValueSystem('noveltyAllInOne', e)}
                                            error={errorsSystem.noveltyAllInOne && errorsSystem.noveltyAllInOne.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandAllInOne', null)
                                            setValueSystem('modellAllInOne', null)
                                            setValueSystem('serieAllInOne', null)
                                            setValueSystem('licensePlateAllInOne', null)
                                            setValueSystem('imageAllInOne', null)
                                            setValueSystem('imageAllInOneSerial', null)
                                            setValueSystem('statusAllInOne', null)
                                            setValueSystem('noveltyAllInOne', null)
                                        }} />
                                    </div>}
                                {(watchSystem('equipType') === 'Computador de mesa' || watchSystem('equipType') === 'Servidor') &&
                                    <div className='divInputs'>
                                        <Input
                                            label={"Tipo"}
                                            mr={"0.7vw"}
                                            mt={'0.125vw'}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"CPU"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandCPU"}
                                            rules={
                                                {
                                                    required: "La marca es requerida",
                                                }
                                            }
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandCPU")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '0.3vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                    <Option value="Janus">Janus</Option>
                                                    <Option value="T-DAGGER">T-DAGGER</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandCPU`] && <p className='errorMessage'>{errorsSystem[`brandCPU`].message}</p>}

                                        <Input
                                            {...registerSystem('modellCPU', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El modelo es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Modelo"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            mt={'0.125vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellCPU')}
                                            setData={e => setValueSystem('modellCPU', e)}
                                            error={errorsSystem.modellCPU && errorsSystem.modellCPU.message}
                                        />
                                        <Input
                                            {...registerSystem('serieCPU', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La serie es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Serie"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieCPU')}
                                            setData={e => setValueSystem('serieCPU', e)}
                                            error={errorsSystem.serieCPU && errorsSystem.serieCPU.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateCPU', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La placa es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={"Placa"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateCPU')}
                                            setData={e => setValueSystem('licensePlateCPU', e)}
                                            error={errorsSystem.licensePlateCPU && errorsSystem.licensePlateCPU.message}
                                        />
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <p className='titleColumn'>Foto</p>
                                            {!watchSystem('imageCPU') ?
                                                <input
                                                    {...registerSystem('imageCPU', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageCPU', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageCPU")} className='inputPhoto' onClick={() => setValueSystem('imageCPU', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <p className='titleColumn'>Foto serial</p>
                                            {!watchSystem('imageCPUSerial') ?
                                                <input
                                                    {...registerSystem('imageCPUSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageCPUSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageCPUSerial")} className='inputPhoto' onClick={() => setValueSystem('imageCPUSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusCPU"}
                                            rules={{
                                                required: "El estado es requerido",
                                            }}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusCPU")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-0.7vw', marginRight: '0.7vw', marginBottom: '-1.2vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusCPU`] && <p className='errorMessage'>{errorsSystem[`statusCPU`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyCPU', {

                                            })}
                                            label={"Novedad"}
                                            mt={'0.125vw'}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyCPU')}
                                            setData={e => setValueSystem('noveltyCPU', e)}
                                            error={errorsSystem.noveltyCPU && errorsSystem.noveltyCPU.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandCPU', null)
                                            setValueSystem('modellCPU', null)
                                            setValueSystem('serieCPU', null)
                                            setValueSystem('licensePlateCPU', null)
                                            setValueSystem('imageCPU', null)
                                            setValueSystem('imageCPUSerial', null)
                                            setValueSystem('statusCPU', null)
                                            setValueSystem('noveltyCPU', null)
                                        }} />
                                    </div>}
                                {(watchSystem('equipType') === 'Computador de mesa') &&
                                    <div className='divInputs'>
                                        <Input
                                            mr={"0.7vw"}
                                            mt={"-1.4vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"GPU"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandGPU"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandGPU")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="GIGABYTE">GIGABYTE</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandGPU`] && <p className='errorMessage'>{errorsSystem[`brandGPU`].message}</p>}

                                        <Controller
                                            name={"modellGPU"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder="Modelo"
                                                    value={watchSystem("modellGPU")}
                                                    className="select"
                                                    style={{
                                                        width: "7vw",
                                                        height: "2vw",
                                                        marginTop: "-1.4vw",
                                                        marginRight: "0.4vw",
                                                    }}
                                                >
                                                    <Option value="GeForce RTX 3090 Ti">GeForce RTX 3090 Ti</Option>
                                                    <Option value="GeForce RTX 3090">GeForce RTX 3090</Option>
                                                    <Option value="GeForce RTX 3080 Ti">GeForce RTX 3080 Ti</Option>
                                                    <Option value="GeForce RTX 3080">GeForce RTX 3080</Option>
                                                    <Option value="GeForce RTX 3070 Ti">GeForce RTX 3070 Ti</Option>
                                                    <Option value="GeForce RTX 3070">GeForce RTX 3070</Option>
                                                    <Option value="GeForce RTX 3060 Ti">GeForce RTX 3060 Ti</Option>
                                                    <Option value="GeForce RTX 3060">GeForce RTX 3060</Option>
                                                    <Option value="GeForce RTX 3050">GeForce RTX 3050</Option>
                                                </Select>
                                            )}
                                        />
                                        <Input
                                            {...registerSystem('serieGPU', {
                                            })}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieGPU')}
                                            setData={e => setValueSystem('serieGPU', e)}
                                            error={errorsSystem.serieGPU && errorsSystem.serieGPU.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateGPU', {
                                            })}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateGPU')}
                                            setData={e => setValueSystem('licensePlateGPU', e)}
                                            error={errorsSystem.licensePlateGPU && errorsSystem.licensePlateGPU.message}
                                        />
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageGPU') ?
                                                <input
                                                    {...registerSystem('imageGPU', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageGPU', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageGPU")} className='inputPhoto' onClick={() => setValueSystem('imageGPU', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-0.80vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageGPUSerial') ?
                                                <input
                                                    {...registerSystem('imageGPUSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageGPUSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageGPUSerial")} className='inputPhoto' onClick={() => setValueSystem('imageGPUSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusGPU"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusGPU")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-2.4vw', marginRight: '0.7vw', marginBottom: '-1.2vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusGPU`] && <p className='errorMessage'>{errorsSystem[`statusGPU`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyGPU', {

                                            })}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyGPU')}
                                            setData={e => setValueSystem('noveltyGPU', e)}
                                            error={errorsSystem.noveltyGPU && errorsSystem.noveltyGPU.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandGPU', null)
                                            setValueSystem('modellGPU', null)
                                            setValueSystem('serieGPU', null)
                                            setValueSystem('licensePlateGPU', null)
                                            setValueSystem('imageGPU', null)
                                            setValueSystem('imageGPUSerial', null)
                                            setValueSystem('statusGPU', null)
                                            setValueSystem('noveltyGPU', null)
                                        }} />
                                    </div>}
                                {(watchSystem('equipType') === 'Computador de mesa' || watchSystem('equipType') === 'Servidor') &&
                                    <div className='divInputs'>
                                        <Input
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"MONITOR"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandMonitor"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandMonitor")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandMonitor`] && <p className='errorMessage'>{errorsSystem[`brandMonitor`].message}</p>}

                                        <Input
                                            {...registerSystem('modellMonitor', {
                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellMonitor')}
                                            setData={e => setValueSystem('modellMonitor', e)}
                                            error={errorsSystem.modellMonitor && errorsSystem.modellMonitor.message}
                                        />
                                        <Input
                                            {...registerSystem('serieMonitor', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieMonitor')}
                                            setData={e => setValueSystem('serieMonitor', e)}
                                            error={errorsSystem.serieMonitor && errorsSystem.serieMonitor.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateMonitor', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateMonitor')}
                                            setData={e => setValueSystem('licensePlateMonitor', e)}
                                            error={errorsSystem.licensePlateMonitor && errorsSystem.licensePlateMonitor.message}
                                        />
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageMonitor') ?
                                                <input
                                                    {...registerSystem('imageMonitor', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageMonitor', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageMonitor")} className='inputPhoto' onClick={() => setValueSystem('imageMonitor', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageMonitorSerial') ?
                                                <input
                                                    {...registerSystem('imageMonitorSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageMonitorSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageMonitorSerial")} className='inputPhoto' onClick={() => setValueSystem('imageMonitorSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusMonitor"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusMonitor")}
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.7vw', marginRight: '0.7vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusMonitor`] && <p className='errorMessage'>{errorsSystem[`statusMonitor`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyMonitor', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyMonitor')}
                                            setData={e => setValueSystem('noveltyMonitor', e)}
                                            error={errorsSystem.noveltyMonitor && errorsSystem.noveltyMonitor.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandMonitor', null)
                                            setValueSystem('modellMonitor', null)
                                            setValueSystem('serieMonitor', null)
                                            setValueSystem('licensePlateMonitor', null)
                                            setValueSystem('imageMonitor', null)
                                            setValueSystem('imageMonitorSerial', null)
                                            setValueSystem('statusMonitor', null)
                                            setValueSystem('noveltyMonitor', null)
                                        }} />
                                    </div>}
                                {(watchSystem('equipType') === 'Todo en uno' || watchSystem('equipType') === 'Computador de mesa' || watchSystem('equipType') === 'Servidor') &&
                                    <div className='divInputs'>
                                        <Input
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"TECLADO"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandKeyboard"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandKeyboard")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandKeyboard`] && <p className='errorMessage'>{errorsSystem[`brandKeyboard`].message}</p>}

                                        <Input
                                            {...registerSystem('modellKeyboard', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellKeyboard')}
                                            setData={e => setValueSystem('modellKeyboard', e)}
                                            error={errorsSystem.modellKeyboard && errorsSystem.modellKeyboard.message}
                                        />
                                        <Input
                                            {...registerSystem('serieKeyboard', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieKeyboard')}
                                            setData={e => setValueSystem('serieKeyboard', e)}
                                            error={errorsSystem.serieKeyboard && errorsSystem.serieKeyboard.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateKeyboard', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateKeyboard')}
                                            setData={e => setValueSystem('licensePlateKeyboard', e)}
                                            error={errorsSystem.licensePlateKeyboard && errorsSystem.licensePlateKeyboard.message}
                                        />
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageKeyboard') ?
                                                <input
                                                    {...registerSystem('imageKeyboard', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageKeyboard', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageKeyboard")} className='inputPhoto' onClick={() => setValueSystem('imageKeyboard', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageKeyboardSerial') ?
                                                <input
                                                    {...registerSystem('imageKeyboardSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageKeyboardSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageKeyboardSerial")} className='inputPhoto' onClick={() => setValueSystem('imageKeyboardSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusKeyboard"}

                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusKeyboard")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusKeyboard`] && <p className='errorMessage'>{errorsSystem[`statusKeyboard`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyKeyboard', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyKeyboard')}
                                            setData={e => setValueSystem('noveltyKeyboard', e)}
                                            error={errorsSystem.noveltyKeyboard && errorsSystem.noveltyKeyboard.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandKeyboard', null)
                                            setValueSystem('modellKeyboard', null)
                                            setValueSystem('serieKeyboard', null)
                                            setValueSystem('licensePlateKeyboard', null)
                                            setValueSystem('imageKeyboard', null)
                                            setValueSystem('imageKeyboardSerial', null)
                                            setValueSystem('statusKeyboard', null)
                                            setValueSystem('noveltyKeyboard', null)
                                        }} />
                                    </div>}
                                {(watchSystem('equipType') === 'Todo en uno' || watchSystem('equipType') === 'Computador de mesa' || watchSystem('equipType') === 'Servidor') &&
                                    <div className='divInputs'>
                                        <Input
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"MOUSE"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandMouse"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandMouse")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                    <Option value="REDRAGON">REDRAGON</Option>
                                                    <Option value="MAXELL">MAXELL</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandMouse`] && <p className='errorMessage'>{errorsSystem[`brandMouse`].message}</p>}

                                        <Input
                                            {...registerSystem('modellMouse', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellMouse')}
                                            setData={e => setValueSystem('modellMouse', e)}
                                            error={errorsSystem.modellMouse && errorsSystem.modellMouse.message}
                                        />
                                        <Input
                                            {...registerSystem('serieMouse', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('serieMouse')}
                                            setData={e => setValueSystem('serieMouse', e)}
                                            error={errorsSystem.serieMouse && errorsSystem.serieMouse.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlateMouse', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlateMouse')}
                                            setData={e => setValueSystem('licensePlateMouse', e)}
                                            error={errorsSystem.licensePlateMouse && errorsSystem.licensePlateMouse.message}
                                        />
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageMouse') ?
                                                <input
                                                    {...registerSystem('imageMouse', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageMouse', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageMouse")} className='inputPhoto' onClick={() => setValueSystem('imageMouse', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imageMouseSerial') ?
                                                <input
                                                    {...registerSystem('imageMouseSerial', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imageMouseSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imageMouseSerial")} className='inputPhoto' onClick={() => setValueSystem('imageMouseSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusMouse"}

                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusMouse")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusMouse`] && <p className='errorMessage'>{errorsSystem[`statusMouse`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyMouse', {

                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyMouse')}
                                            setData={e => setValueSystem('noveltyMouse', e)}
                                            error={errorsSystem.noveltyMouse && errorsSystem.noveltyMouse.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandMouse', null)
                                            setValueSystem('modellMouse', null)
                                            setValueSystem('serieMouse', null)
                                            setValueSystem('licensePlateMouse', null)
                                            setValueSystem('imageMouse', null)
                                            setValueSystem('imageMouseSerial', null)
                                            setValueSystem('statusMouse', null)
                                            setValueSystem('noveltyMouse', null)
                                        }} />
                                    </div>}
                                <div className='divInputs'>
                                    <Input
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        va={"PAD DE FIRMAS"}
                                        fontSize={'0.6vw'}
                                        disabled
                                    />
                                    <Controller
                                        name={"brandSignaturePad"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Marca'
                                                value={watchSystem("brandSignaturePad")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                            >
                                                <Option value="Lenovo">Lenovo</Option>
                                                <Option value="HP">HP</Option>
                                                <Option value="Acer">Acer</Option>
                                                <Option value="Apple">Apple</Option>
                                                <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                <Option value="Alienware">Alienware</Option>
                                                <Option value="Toshiba">Toshiba</Option>
                                                <Option value="Hitachi">Hitachi</Option>
                                                <Option value="Dell">Dell</Option>
                                                <Option value="Asus">Asus</Option>
                                                <Option value="Samsung">Samsung</Option>
                                                <Option value="Gateway">Gateway</Option>
                                                <Option value="Sony">Sony</Option>
                                                <Option value="MSI">MSI</Option>
                                                <Option value="LG">LG</Option>
                                                <Option value="Genius">Genius</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`brandSignaturePad`] && <p className='errorMessage'>{errorsSystem[`brandSignaturePad`].message}</p>}

                                    <Input
                                        {...registerSystem('modellSignaturePad', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('modellSignaturePad')}
                                        setData={e => setValueSystem('modellSignaturePad', e)}
                                    />
                                    <Input
                                        {...registerSystem('serieSignaturePad', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('serieSignaturePad')}
                                        setData={e => setValueSystem('serieSignaturePad', e)}
                                    />
                                    <Input
                                        {...registerSystem('licensePlateSignaturePad', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('licensePlateSignaturePad')}
                                        setData={e => setValueSystem('licensePlateSignaturePad', e)}
                                    />
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageSignaturePad') ?
                                            <input
                                                {...registerSystem('imageSignaturePad', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageSignaturePad', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageSignaturePad")} className='inputPhoto' onClick={() => setValueSystem('imageSignaturePad', undefined)}></img>
                                        }
                                    </div>
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageSignaturePadSerial') ?
                                            <input
                                                {...registerSystem('imageSignaturePadSerial', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageSignaturePadSerial', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageSignaturePadSerial")} className='inputPhoto' onClick={() => setValueSystem('imageSignaturePadSerial', undefined)}></img>
                                        }
                                    </div>
                                    <Controller
                                        name={"statusSignaturePad"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Estado'
                                                value={watchSystem("statusSignaturePad")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                            >
                                                <Option value="Funcional">Funcional</Option>
                                                <Option value="No Funcional">No Funcional</Option>
                                                <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option>

                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`statusSignaturePad`] && <p className='errorMessage'>{errorsSystem[`statusSignaturePad`].message}</p>}
                                    <Input
                                        {...registerSystem('noveltySignaturePad', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'15vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('noveltySignaturePad')}
                                        setData={e => setValueSystem('noveltySignaturePad', e)}
                                    />
                                    <ClearOutlined className="iconBrush" onClick={() => {
                                        setValueSystem('brandSignaturePad', null)
                                        setValueSystem('modellSignaturePad', null)
                                        setValueSystem('serieSignaturePad', null)
                                        setValueSystem('licensePlateSignaturePad', null)
                                        setValueSystem('imageSignaturePad', null)
                                        setValueSystem('imageSignaturePadSerial', null)
                                        setValueSystem('statusSignaturePad', null)
                                        setValueSystem('noveltySignaturePad', null)
                                    }} />
                                </div>
                                <div className='divInputs'>
                                    <Input
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        va={"CAMARA WEB"}
                                        fontSize={'0.6vw'}
                                        disabled
                                    />
                                    <Controller
                                        name={"brandWebcam"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Marca'
                                                value={watchSystem("brandWebcam")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                            >
                                                <Option value="Lenovo">Lenovo</Option>
                                                <Option value="HP">HP</Option>
                                                <Option value="Acer">Acer</Option>
                                                <Option value="Apple">Apple</Option>
                                                <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                <Option value="Alienware">Alienware</Option>
                                                <Option value="Toshiba">Toshiba</Option>
                                                <Option value="Hitachi">Hitachi</Option>
                                                <Option value="Dell">Dell</Option>
                                                <Option value="Asus">Asus</Option>
                                                <Option value="Samsung">Samsung</Option>
                                                <Option value="Gateway">Gateway</Option>
                                                <Option value="Sony">Sony</Option>
                                                <Option value="MSI">MSI</Option>
                                                <Option value="LG">LG</Option>
                                                <Option value="Genius">Genius</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`brandWebcam`] && <p className='errorMessage'>{errorsSystem[`brandWebcam`].message}</p>}

                                    <Input
                                        {...registerSystem('modellWebcam', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('modellWebcam')}
                                        setData={e => setValueSystem('modellWebcam', e)}
                                    />
                                    <Input
                                        {...registerSystem('serieWebcam', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('serieWebcam')}
                                        setData={e => setValueSystem('serieWebcam', e)}
                                    />
                                    <Input
                                        {...registerSystem('licensePlateWebcam', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('licensePlateWebcam')}
                                        setData={e => setValueSystem('licensePlateWebcam', e)}
                                    />
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageWebcam') ?
                                            <input
                                                {...registerSystem('imageWebcam', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageWebcam', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageWebcam")} className='inputPhoto' onClick={() => setValueSystem('imageWebcam', undefined)}></img>
                                        }
                                    </div>
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageWebcamSerial') ?
                                            <input
                                                {...registerSystem('imageWebcamSerial', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageWebcamSerial', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageWebcamSerial")} className='inputPhoto' onClick={() => setValueSystem('imageWebcamSerial', undefined)}></img>
                                        }
                                    </div>
                                    <Controller
                                        name={"statusWebcam"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Estado'
                                                value={watchSystem("statusWebcam")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                            >
                                                <Option value="Funcional">Funcional</Option>
                                                <Option value="No Funcional">No Funcional</Option>
                                                <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option>

                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`statusWebcam`] && <p className='errorMessage'>{errorsSystem[`statusWebcam`].message}</p>}
                                    <Input
                                        {...registerSystem('noveltyWebcam', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'15vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('noveltyWebcam')}
                                        setData={e => setValueSystem('noveltyWebcam', e)}
                                    />
                                    <ClearOutlined className="iconBrush" onClick={() => {
                                        setValueSystem('brandWebcam', null)
                                        setValueSystem('modellWebcam', null)
                                        setValueSystem('serieWebcam', null)
                                        setValueSystem('licensePlateWebcam', null)
                                        setValueSystem('imageWebcam', null)
                                        setValueSystem('imageWebcamSerial', null)
                                        setValueSystem('statusWebcam', null)
                                        setValueSystem('noveltyWebcam', null)
                                    }} />
                                </div>
                                <div className='divInputs'>
                                    <Input
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        va={"LECTOR DE CODIGO DE BARRAS"}
                                        fontSize={'0.6vw'}
                                        disabled
                                    />
                                    <Controller
                                        name={"brandBarcodeReader"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Marca'
                                                value={watchSystem("brandBarcodeReader")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                            >
                                                <Option value="Lenovo">Lenovo</Option>
                                                <Option value="HP">HP</Option>
                                                <Option value="Acer">Acer</Option>
                                                <Option value="Apple">Apple</Option>
                                                <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                <Option value="Alienware">Alienware</Option>
                                                <Option value="Toshiba">Toshiba</Option>
                                                <Option value="Hitachi">Hitachi</Option>
                                                <Option value="Dell">Dell</Option>
                                                <Option value="Asus">Asus</Option>
                                                <Option value="Samsung">Samsung</Option>
                                                <Option value="Gateway">Gateway</Option>
                                                <Option value="Sony">Sony</Option>
                                                <Option value="MSI">MSI</Option>
                                                <Option value="LG">LG</Option>
                                                <Option value="Genius">Genius</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`brandBarcodeReader`] && <p className='errorMessage'>{errorsSystem[`brandBarcodeReader`].message}</p>}

                                    <Input
                                        {...registerSystem('modellBarcodeReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('modellBarcodeReader')}
                                        setData={e => setValueSystem('modellBarcodeReader', e)}
                                    />
                                    <Input
                                        {...registerSystem('serieBarcodeReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('serieBarcodeReader')}
                                        setData={e => setValueSystem('serieBarcodeReader', e)}
                                    />
                                    <Input
                                        {...registerSystem('licensePlateBarcodeReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('licensePlateBarcodeReader')}
                                        setData={e => setValueSystem('licensePlateBarcodeReader', e)}
                                    />
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageBarcodeReader') ?
                                            <input
                                                {...registerSystem('imageBarcodeReader', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageBarcodeReader', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageBarcodeReader")} className='inputPhoto' onClick={() => setValueSystem('imageBarcodeReader', undefined)}></img>
                                        }
                                    </div>
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageBarcodeReaderSerial') ?
                                            <input
                                                {...registerSystem('imageBarcodeReaderSerial', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageBarcodeReaderSerial', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageBarcodeReaderSerial")} className='inputPhoto' onClick={() => setValueSystem('imageBarcodeReaderSerial', undefined)}></img>
                                        }
                                    </div>
                                    <Controller
                                        name={"statusBarcodeReader"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Estado'
                                                value={watchSystem("statusBarcodeReader")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                            >
                                                <Option value="Funcional">Funcional</Option>
                                                <Option value="No Funcional">No Funcional</Option>
                                                <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option>

                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`statusBarcodeReader`] && <p className='errorMessage'>{errorsSystem[`statusBarcodeReader`].message}</p>}
                                    <Input
                                        {...registerSystem('noveltyBarcodeReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'15vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('noveltyBarcodeReader')}
                                        setData={e => setValueSystem('noveltyBarcodeReader', e)}
                                    />
                                    <ClearOutlined className="iconBrush" onClick={() => {
                                        setValueSystem('brandBarcodeReader', null)
                                        setValueSystem('modellBarcodeReader', null)
                                        setValueSystem('serieBarcodeReader', null)
                                        setValueSystem('licensePlateBarcodeReader', null)
                                        setValueSystem('imageBarcodeReader', null)
                                        setValueSystem('imageBarcodeReaderSerial', null)
                                        setValueSystem('statusBarcodeReader', null)
                                        setValueSystem('noveltyBarcodeReader', null)
                                    }} />
                                </div>
                                {(watchSystem('equipType') === 'Todo en uno' || watchSystem('equipType') === 'Portatil') &&
                                    <div className='divInputs'>
                                        <Input
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            va={"ADAPTADOR DE CORRIENTE"}
                                            fontSize={'0.6vw'}
                                            disabled
                                        />
                                        <Controller
                                            name={"brandPowerAdapter"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Marca'
                                                    value={watchSystem("brandPowerAdapter")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                                >
                                                    <Option value="Lenovo">Lenovo</Option>
                                                    <Option value="HP">HP</Option>
                                                    <Option value="Acer">Acer</Option>
                                                    <Option value="Apple">Apple</Option>
                                                    <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                    <Option value="Alienware">Alienware</Option>
                                                    <Option value="Toshiba">Toshiba</Option>
                                                    <Option value="Hitachi">Hitachi</Option>
                                                    <Option value="Dell">Dell</Option>
                                                    <Option value="Asus">Asus</Option>
                                                    <Option value="Samsung">Samsung</Option>
                                                    <Option value="Gateway">Gateway</Option>
                                                    <Option value="Sony">Sony</Option>
                                                    <Option value="MSI">MSI</Option>
                                                    <Option value="LG">LG</Option>
                                                    <Option value="Genius">Genius</Option>
                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`brandPowerAdapter`] && <p className='errorMessage'>{errorsSystem[`brandPowerAdapter`].message}</p>}

                                        <Input
                                            {...registerSystem('modellPowerAdapter', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("El modelo es requerido");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('modellPowerAdapter')}
                                            setData={e => setValueSystem('modellPowerAdapter', e)}
                                            error={errorsSystem.modellPowerAdapter && errorsSystem.modellPowerAdapter.message}
                                        />
                                        <Input
                                            {...registerSystem('seriePowerAdapter', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La serie es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('seriePowerAdapter')}
                                            setData={e => setValueSystem('seriePowerAdapter', e)}
                                            error={errorsSystem.seriePowerAdapter && errorsSystem.seriePowerAdapter.message}
                                        />
                                        <Input
                                            {...registerSystem('licensePlatePowerAdapter', {
                                                validate: (value) => {
                                                    const errors = [];
                                                    if (!value || value === '') {
                                                        errors.push("La placa es requerida");
                                                    }
                                                    return errors.length === 0 || errors.join(', ');
                                                },
                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'7vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('licensePlatePowerAdapter')}
                                            setData={e => setValueSystem('licensePlatePowerAdapter', e)}
                                            error={errorsSystem.licensePlatePowerAdapter && errorsSystem.licensePlatePowerAdapter.message}
                                        />
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imagePowerAdapter') ?
                                                <input
                                                    {...registerSystem('imagePowerAdapter', {
                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imagePowerAdapter', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imagePowerAdapter")} className='inputPhoto' onClick={() => setValueSystem('imagePowerAdapter', undefined)}></img>
                                            }
                                        </div>
                                        <div style={{
                                            marginTop: '-1.5vw',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            {!watchSystem('imagePowerAdapterSerial') ?
                                                <input
                                                    {...registerSystem('imagePowerAdapterSerial', {

                                                    })}
                                                    type="file"
                                                    accept="image/*"
                                                    className='inputPhoto'
                                                    onChange={(event) => {
                                                        const file = event.target.files[0];
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', () => {
                                                            const base64 = reader.result;
                                                            setValueSystem('imagePowerAdapterSerial', base64)
                                                        });
                                                        reader.readAsDataURL(file);
                                                    }}
                                                />
                                                :
                                                <img src={watchSystem("imagePowerAdapterSerial")} className='inputPhoto' onClick={() => setValueSystem('imagePowerAdapterSerial', undefined)}></img>
                                            }
                                        </div>
                                        <Controller
                                            name={"statusPowerAdapter"}
                                            control={controlSystem}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder='Estado'
                                                    value={watchSystem("statusPowerAdapter")}
                                                    className="select"
                                                    style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                                >
                                                    <Option value="Funcional">Funcional</Option>
                                                    <Option value="No Funcional">No Funcional</Option>
                                                    <Option value="Dado de baja">Dado de baja</Option>
                                                    <Option value="No operativo">No operativo</Option>

                                                </Select>
                                            )}
                                        />
                                        {errorsSystem[`statusPowerAdapter`] && <p className='errorMessage'>{errorsSystem[`statusPowerAdapter`].message}</p>}
                                        <Input
                                            {...registerSystem('noveltyPowerAdapter', {
                                            })}
                                            label={" "}
                                            mt={"-1.4vw"}
                                            mr={"0.7vw"}
                                            width={'15vw'}
                                            height={'2vw'}
                                            labelstyle='inputLabelBordered'
                                            fontSize={'1vw'}
                                            va={watchSystem('noveltyPowerAdapter')}
                                            setData={e => setValueSystem('noveltyPowerAdapter', e)}
                                            error={errorsSystem.noveltyPowerAdapter && errorsSystem.noveltyPowerAdapter.message}
                                        />
                                        <ClearOutlined className="iconBrush" onClick={() => {
                                            setValueSystem('brandPowerAdapter', null)
                                            setValueSystem('modellPowerAdapter', null)
                                            setValueSystem('seriePowerAdapter', null)
                                            setValueSystem('licensePlatePowerAdapter', null)
                                            setValueSystem('imagePowerAdapter', null)
                                            setValueSystem('imagePowerAdapterSerial', null)
                                            setValueSystem('statusPowerAdapter', null)
                                            setValueSystem('noveltyPowerAdapter', null)
                                        }} />
                                    </div>}
                                <div className='divInputs'>
                                    <Input
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        va={"LECTOR DE HUELLA"}
                                        fontSize={'0.6vw'}
                                        disabled
                                    />
                                    <Controller
                                        name={"brandFingerprintReader"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Marca'
                                                value={watchSystem("brandFingerprintReader")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                            >
                                                <Option value="Lenovo">Lenovo</Option>
                                                <Option value="HP">HP</Option>
                                                <Option value="Acer">Acer</Option>
                                                <Option value="Apple">Apple</Option>
                                                <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                <Option value="Alienware">Alienware</Option>
                                                <Option value="Toshiba">Toshiba</Option>
                                                <Option value="Hitachi">Hitachi</Option>
                                                <Option value="Dell">Dell</Option>
                                                <Option value="Asus">Asus</Option>
                                                <Option value="Samsung">Samsung</Option>
                                                <Option value="Gateway">Gateway</Option>
                                                <Option value="Sony">Sony</Option>
                                                <Option value="MSI">MSI</Option>
                                                <Option value="LG">LG</Option>
                                                <Option value="Genius">Genius</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`brandFingerprintReader`] && <p className='errorMessage'>{errorsSystem[`brandFingerprintReader`].message}</p>}

                                    <Input
                                        {...registerSystem('modellFingerprintReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('modellFingerprintReader')}
                                        setData={e => setValueSystem('modellFingerprintReader', e)}
                                    />
                                    <Input
                                        {...registerSystem('serieFingerprintReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('serieFingerprintReader')}
                                        setData={e => setValueSystem('serieFingerprintReader', e)}
                                    />
                                    <Input
                                        {...registerSystem('licensePlateFingerprintReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('licensePlateFingerprintReader')}
                                        setData={e => setValueSystem('licensePlateFingerprintReader', e)}
                                    />
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageFingerprintReader') ?
                                            <input
                                                {...registerSystem('imageFingerprintReader', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageFingerprintReader', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageFingerprintReader")} className='inputPhoto' onClick={() => setValueSystem('imageFingerprintReader', undefined)}></img>
                                        }
                                    </div>
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageFingerprintReaderSerial') ?
                                            <input
                                                {...registerSystem('imageFingerprintReaderSerial', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageFingerprintReaderSerial', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageFingerprintReaderSerial")} className='inputPhoto' onClick={() => setValueSystem('imageFingerprintReaderSerial', undefined)}></img>
                                        }
                                    </div>
                                    <Controller
                                        name={"statusFingerprintReader"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Estado'
                                                value={watchSystem("statusFingerprintReader")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                            >
                                                <Option value="Funcional">Funcional</Option>
                                                <Option value="No Funcional">No Funcional</Option>
                                                <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option>

                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`statusFingerprintReader`] && <p className='errorMessage'>{errorsSystem[`statusFingerprintReader`].message}</p>}
                                    <Input
                                        {...registerSystem('noveltyFingerprintReader', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'15vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('noveltyFingerprintReader')}
                                        setData={e => setValueSystem('noveltyFingerprintReader', e)}
                                    />
                                    <ClearOutlined className="iconBrush" onClick={() => {
                                        setValueSystem('brandFingerprintReader', null)
                                        setValueSystem('modellFingerprintReader', null)
                                        setValueSystem('serieFingerprintReader', null)
                                        setValueSystem('licensePlateFingerprintReader', null)
                                        setValueSystem('imageFingerprintReader', null)
                                        setValueSystem('imageFingerprintReaderSerial', null)
                                        setValueSystem('statusFingerprintReader', null)
                                        setValueSystem('noveltyFingerprintReader', null)
                                    }} />
                                </div>
                                <div className='divInputs'>
                                    <Input
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        va={"DIADEMAS"}
                                        fontSize={'0.6vw'}
                                        disabled
                                    />
                                    <Controller
                                        name={"brandDiadems"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Marca'
                                                value={watchSystem("brandDiadems")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                            >
                                                <Option value="Lenovo">Lenovo</Option>
                                                <Option value="HP">HP</Option>
                                                <Option value="Acer">Acer</Option>
                                                <Option value="Apple">Apple</Option>
                                                <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                <Option value="Alienware">Alienware</Option>
                                                <Option value="Toshiba">Toshiba</Option>
                                                <Option value="Hitachi">Hitachi</Option>
                                                <Option value="Dell">Dell</Option>
                                                <Option value="Asus">Asus</Option>
                                                <Option value="Samsung">Samsung</Option>
                                                <Option value="Gateway">Gateway</Option>
                                                <Option value="Sony">Sony</Option>
                                                <Option value="MSI">MSI</Option>
                                                <Option value="LG">LG</Option>
                                                <Option value="Genius">Genius</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`brandDiadems`] && <p className='errorMessage'>{errorsSystem[`brandDiadems`].message}</p>}

                                    <Input
                                        {...registerSystem('modellDiadems', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('modellDiadems')}
                                        setData={e => setValueSystem('modellDiadems', e)}
                                    />
                                    <Input
                                        {...registerSystem('serieDiadems', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('serieDiadems')}
                                        setData={e => setValueSystem('serieDiadems', e)}
                                    />
                                    <Input
                                        {...registerSystem('licensePlateDiadems', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('licensePlateDiadems')}
                                        setData={e => setValueSystem('licensePlateDiadems', e)}
                                    />
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageDiadems') ?
                                            <input
                                                {...registerSystem('imageDiadems', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageDiadems', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageDiadems")} className='inputPhoto' onClick={() => setValueSystem('imageDiadems', undefined)}></img>
                                        }
                                    </div>
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageDiademsSerial') ?
                                            <input
                                                {...registerSystem('imageDiademsSerial', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageDiademsSerial', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageDiademsSerial")} className='inputPhoto' onClick={() => setValueSystem('imageDiademsSerial', undefined)}></img>
                                        }
                                    </div>
                                    <Controller
                                        name={"statusDiadems"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Estado'
                                                value={watchSystem("statusDiadems")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                            >
                                                <Option value="Funcional">Funcional</Option>
                                                <Option value="No Funcional">No Funcional</Option>
                                                <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option>

                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`statusDiadems`] && <p className='errorMessage'>{errorsSystem[`statusDiadems`].message}</p>}
                                    <Input
                                        {...registerSystem('noveltyDiadems', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'15vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('noveltyDiadems')}
                                        setData={e => setValueSystem('noveltyDiadems', e)}
                                    />
                                    <ClearOutlined className="iconBrush" onClick={() => {
                                        setValueSystem('brandDiadems', null)
                                        setValueSystem('modellDiadems', null)
                                        setValueSystem('serieDiadems', null)
                                        setValueSystem('licensePlateDiadems', null)
                                        setValueSystem('imageDiadems', null)
                                        setValueSystem('imageDiademsSerial', null)
                                        setValueSystem('statusDiadems', null)
                                        setValueSystem('noveltyDiadems', null)
                                    }} />
                                </div>
                                <div className='divInputs'>
                                    <Input
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        va={"FUENTE"}
                                        fontSize={'0.6vw'}
                                        disabled
                                    />
                                    <Controller
                                        name={"brandSource"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Marca'
                                                value={watchSystem("brandSource")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.5vw', marginRight: '0.4vw' }}
                                            >
                                                <Option value="Lenovo">Lenovo</Option>
                                                <Option value="HP">HP</Option>
                                                <Option value="Acer">Acer</Option>
                                                <Option value="Apple">Apple</Option>
                                                <Option value="Otras marcas de computadoras">Otras marcas de computadoras</Option>
                                                <Option value="Alienware">Alienware</Option>
                                                <Option value="Toshiba">Toshiba</Option>
                                                <Option value="Hitachi">Hitachi</Option>
                                                <Option value="Dell">Dell</Option>
                                                <Option value="Asus">Asus</Option>
                                                <Option value="Samsung">Samsung</Option>
                                                <Option value="Gateway">Gateway</Option>
                                                <Option value="Sony">Sony</Option>
                                                <Option value="MSI">MSI</Option>
                                                <Option value="LG">LG</Option>
                                                <Option value="Genius">Genius</Option>
                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`brandSource`] && <p className='errorMessage'>{errorsSystem[`brandSource`].message}</p>}

                                    <Input
                                        {...registerSystem('modellSource', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('modellSource')}
                                        setData={e => setValueSystem('modellSource', e)}
                                    />
                                    <Input
                                        {...registerSystem('serieSource', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('serieSource')}
                                        setData={e => setValueSystem('serieSource', e)}
                                    />
                                    <Input
                                        {...registerSystem('licensePlateSource', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'7vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('licensePlateSource')}
                                        setData={e => setValueSystem('licensePlateSource', e)}
                                    />
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageSource') ?
                                            <input
                                                {...registerSystem('imageSource', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageSource', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageSource")} className='inputPhoto' onClick={() => setValueSystem('imageSource', undefined)}></img>
                                        }
                                    </div>
                                    <div style={{
                                        marginTop: '-1.5vw',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {!watchSystem('imageSourceSerial') ?
                                            <input
                                                {...registerSystem('imageSourceSerial', {
                                                })}
                                                type="file"
                                                accept="image/*"
                                                className='inputPhoto'
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', () => {
                                                        const base64 = reader.result;
                                                        setValueSystem('imageSourceSerial', base64)
                                                    });
                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                            :
                                            <img src={watchSystem("imageSourceSerial")} className='inputPhoto' onClick={() => setValueSystem('imageSourceSerial', undefined)}></img>
                                        }
                                    </div>
                                    <Controller
                                        name={"statusSource"}
                                        control={controlSystem}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder='Estado'
                                                value={watchSystem("statusSource")}
                                                className="select"
                                                style={{ width: '7vw', height: '2vw', marginTop: '-1.4vw', marginRight: '0.7vw' }}
                                            >
                                                <Option value="Funcional">Funcional</Option>
                                                <Option value="No Funcional">No Funcional</Option>
                                                <Option value="Dado de baja">Dado de baja</Option>
                                                <Option value="No operativo">No operativo</Option>

                                            </Select>
                                        )}
                                    />
                                    {errorsSystem[`statusSource`] && <p className='errorMessage'>{errorsSystem[`statusSource`].message}</p>}
                                    <Input
                                        {...registerSystem('noveltySource', {
                                        })}
                                        label={" "}
                                        mt={"-1.4vw"}
                                        mr={"0.7vw"}
                                        width={'15vw'}
                                        height={'2vw'}
                                        labelstyle='inputLabelBordered'
                                        fontSize={'1vw'}
                                        va={watchSystem('noveltySource')}
                                        setData={e => setValueSystem('noveltySource', e)}
                                    />
                                    <ClearOutlined className="iconBrush" onClick={() => {
                                        setValueSystem('brandSource', null)
                                        setValueSystem('modellSource', null)
                                        setValueSystem('serieSource', null)
                                        setValueSystem('licensePlateSource', null)
                                        setValueSystem('imageSource', null)
                                        setValueSystem('imageSourceSerial', null)
                                        setValueSystem('statusSource', null)
                                        setValueSystem('noveltySource', null)
                                    }} />
                                </div>
                            </div>
                            <Input {...registerSystem('observations', {
                            })}
                                height={'2vw'}
                                width={'76.5vw'}
                                fontSize={'1vw'}
                                mt={'1vw'}
                                va={watchSystem("observations")}
                                setData={e => setValueSystem("observations", e)}
                                labelstyle='inputLabelBordered'
                                error={errorsSystem.observations && errorsSystem.observations.message}
                                label="Observaciones :" textArea />
                        </div>
                    }
                    {(watchSystem('equipType') === 'Cableado Estructurado' || watchSystem('equipType') === 'Rack' || watchSystem('equipType') === 'Switch' || watchSystem('equipType') === 'Telefono' || watchSystem('equipType') === 'Router') &&
                        <Input {...registerSystem('clientObservation', {
                            validate: (value) => {
                                const errors = [];
                                if (!value || value === '') {
                                    errors.push("La observación del cliente es requerida");
                                }
                                return errors.length === 0 || errors.join(', ');
                            },
                        })}
                            height={'2vw'}
                            width={'76.5vw'}
                            fontSize={'1vw'}
                            mt={'1vw'}
                            va={watchSystem("clientObservation")}
                            setData={e => setValueSystem("clientObservation", e)}
                            labelstyle='inputLabelBordered'
                            error={errorsSystem.clientObservation && errorsSystem.clientObservation.message}
                            label="Observación del Cliente :" textArea />
                    }
                    {(watchSystem('equipType') === 'UPS' || watchSystem('equipType') === 'Plotter' || watchSystem('equipType') === 'Impresora' || watchSystem('equipType') === 'Video Beam' || watchSystem('equipType') === 'Escaner') &&
                        <div style={{ width: '100%' }}>
                            <p className='titleSection' style={{ alignSelf: 'flex-start', width: '100%' }}>Caracteristicas Tecnicas</p>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <Input
                                    {...registerSystem('brand', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("La marca es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    height={'2vw'}
                                    mr={'1vw'}
                                    mt={'0.125vw'}
                                    width={'7.96vw'}
                                    va={watchSystem('brand')}
                                    setData={e => setValueSystem('brand', e)}
                                    label='Marca'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.brand && errorsSystem.brand.message}
                                />
                                <Input
                                    {...registerSystem('modell', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("El modelo es requerido");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    height={'2vw'}
                                    mr={'1vw'}
                                    mt={'0.125vw'}
                                    width={'7.96vw'}
                                    va={watchSystem('modell')}
                                    setData={e => setValueSystem('modell', e)}
                                    label='Modelo'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.modell && errorsSystem.modell.message}
                                />
                                <Input
                                    {...registerSystem('serie', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("La serie es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    height={'2vw'}
                                    mr={'1vw'}
                                    mt={'0.125vw'}
                                    width={'7.96vw'}
                                    va={watchSystem('serie')}
                                    setData={e => setValueSystem('serie', e)}
                                    label='Serie'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.serie && errorsSystem.serie.message}
                                />
                                <Input
                                    {...registerSystem('licensePlate', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("La placa es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    height={'2vw'}
                                    mr={'1vw'}
                                    mt={'0.125vw'}
                                    width={'7.96vw'}
                                    va={watchSystem('licensePlate')}
                                    setData={e => setValueSystem('licensePlate', e)}
                                    label='Placa'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.licensePlate && errorsSystem.licensePlate.message}
                                />
                                <Input
                                    {...registerSystem('observation', {
                                        validate: (value) => {
                                            const errors = [];
                                            if (!value || value === '') {
                                                errors.push("La observación es requerida");
                                            }
                                            return errors.length === 0 || errors.join(', ');
                                        },
                                    })}
                                    height={'2vw'}
                                    mr={'1vw'}
                                    mt={'0.125vw'}
                                    width={'25vw'}
                                    va={watchSystem('observation')}
                                    setData={e => setValueSystem('observation', e)}
                                    label='Observación'
                                    labelstyle='inputLabelBordered'
                                    error={errorsSystem.observation && errorsSystem.observation.message}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <p className='titleSection' style={{ width: '50%' }}>Conexión</p>
                                <p className='titleSection' style={{ width: '50%' }}>Instalación compartida</p>
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div>
                                    <Radio.Group
                                        className='radioG'
                                        style={{ width: '35vw' }}
                                        {...registerSystem('connection', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La conexión es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        onChange={d => setValueSystem('connection', d.target.value)}
                                        value={watchSystem('connection')}>
                                        <Radio className='radio' value={'Usb'}>Usb</Radio>
                                        <Radio className='radio' value={'Paralelo'}>Paralelo</Radio>
                                        <Radio className='radio' value={'Red/Mac'}>Red/Mac</Radio>
                                    </Radio.Group>
                                    {errorsSystem.connection && <p className='errorMessage'>{errorsSystem.connection.message}</p>}
                                </div>
                                {/* <div>
                                    <Radio.Group
                                        className='radioG'
                                        style={{ width: '20vw', justifyContent: 'space-between', alignItems: 'flex-start' }}
                                        {...registerSystem('isInstalationShared', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (value === undefined || value === '') {
                                                    errors.push("La instalación compartida es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        onChange={d => setValueSystem('isInstalationShared', d.target.value)}
                                        value={watchSystem('isInstalationShared')}>
                                        <Radio className='radio' value={true}>Si</Radio>
                                        <Radio className='radio' value={false}>No</Radio>
                                    </Radio.Group>
                                    {errorsSystem.isInstalationShared && <p className='errorMessage'>{errorsSystem.isInstalationShared.message}</p>}
                                </div> */}
                            </div>
                            {watchSystem('equipType') === 'UPS' && <div style={{ width: '100%' }}>
                                <p className='titleSection' style={{ width: '100%' }}>Ups</p>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        {...registerSystem('batteryNumber', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El numero de baterias es requerido");
                                                }
                                                //numeric
                                                if (isNaN(value)) {
                                                    errors.push("El numero de baterias debe ser numerico")
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        height={'2vw'}
                                        mr={'1vw'}
                                        mt={'0.125vw'}
                                        width={'15vw'}
                                        va={watchSystem('batteryNumber')}
                                        setData={e => setValueSystem('batteryNumber', e)}
                                        label='Numero Baterias'
                                        labelstyle='inputLabelBordered'
                                        error={errorsSystem.batteryNumber && errorsSystem.batteryNumber.message}
                                    />
                                    <Input
                                        {...registerSystem('backupTime', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("El tiempo de respaldo es requerido");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        height={'2vw'}
                                        mr={'1vw'}
                                        mt={'0.125vw'}
                                        width={'15vw'}
                                        va={watchSystem('backupTime')}
                                        setData={e => setValueSystem('backupTime', e)}
                                        label='Tiempo de respaldo'
                                        labelstyle='inputLabelBordered'
                                        error={errorsSystem.backupTime && errorsSystem.backupTime.message}
                                    />
                                    <Input
                                        {...registerSystem('load', {
                                            validate: (value) => {
                                                const errors = [];
                                                if (!value || value === '') {
                                                    errors.push("La carga es requerida");
                                                }
                                                return errors.length === 0 || errors.join(', ');
                                            },
                                        })}
                                        height={'2vw'}
                                        mr={'1vw'}
                                        mt={'0.125vw'}
                                        width={'15vw'}
                                        va={watchSystem('load')}
                                        setData={e => setValueSystem('load', e)}
                                        label='Carga'
                                        labelstyle='inputLabelBordered'
                                        error={errorsSystem.load && errorsSystem.load.message}
                                    />
                                </div>
                            </div>}
                        </div>
                    }

                    <button className='save' onClick={() => handleSubmitSystem((dataSubmit) => {
                        dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                        dataSubmit.userCreator = idUser;
                        !edit && dispatch(EquipmentSystemActions.createEquipmentSystem(dataSubmit));
                        edit && dispatch(EquipmentSystemActions.updateEquipmentSystem({ "payload": dataSubmit, "id": data.id }))
                    })()}>{loadingCreateEquipmentSystem ? <LoadingOutlined /> : (edit ? "Editar" : "Guardar")}</button>
                </div>
            }
        </div >
    )
}