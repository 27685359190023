import { Steps, Button, message, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined, SearchOutlined, CloseCircleFilled } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { useForm } from "react-hook-form"
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';
import { ModalSearchEquipment } from '../ModalSearchEquipment/ModalSearchEquipment';
import { CaseReportPowerPlantActions } from '../../services/caseReportPowerPlant/caseReportPowerPlantSlice';
import { EquipmentCard } from '../EquipmentCard/EquipmentCard';
import { useStepsDailyCheckList } from './StepsDailyCheckLis';
import { dailyVehicleActions } from '../../services/dailyVehicle/dailyVehicleSlice';

export const FormDailyCheckList = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { fullDataEquipment, loadingUpdateEquipment, loadingCreateEquipment, equipmentsSearched } = useSelector(state => state.Equipment)
    const { loadingCreateEquipmentVehicleF, EquipmentVehicleCreatedId } = useSelector(state => state.EquipmentVehicle)
    const { loadingcreateDailyVehicle } = useSelector(state => state.DailyVehicle)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleExitSearch, setVisibleExitSearch] = useState(false)
    const [equipSelected, setEquipSelected] = useState(false)

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        if (EquipmentVehicleCreatedId) {
            setOption('Search')
            dispatch(EquipmentVehicleActions.setEquipmentVehicleCreatedId(false))
        }
    }, [EquipmentVehicleCreatedId])

    const onExitSearch = () => {
        setVisibleExitSearch(false)
        dispatch(EquipmentActions.setEquipmentsFiltered(false))
    }

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    const {
        register: registerCreateDailyCheckList,
        handleSubmit: handleSubmitCreateDailyCheckList,
        setValue: setValueCreateDailyCheckList,
        watch: watchCreateDailyCheckList,
        control: controlCreateDailyCheckList,
        formState: { errors: errorsCreateDailyCheckList },
        trigger: triggerCreateDailyCheckList,
    } = useForm({
        defaultValues: {
            date: edit ? moment(data.date) : '',

        }
    })
    const StepsDailyCheckList = useStepsDailyCheckList(
        registerCreateDailyCheckList,
        handleSubmitCreateDailyCheckList,
        setValueCreateDailyCheckList,
        watchCreateDailyCheckList,
        controlCreateDailyCheckList,
        errorsCreateDailyCheckList,
        triggerCreateDailyCheckList,
        equipSelected,
        setEquipSelected,
    );

    const [areaSelected, setAreaSelected] = useState(false)
    const [Dep, setDep] = useState(false);
    const [compInfo, setCompInfo] = useState([])
    const frontalCanvas = useRef(null)
    const backCanvas = useRef(null)
    const rightCanvas = useRef(null)
    const leftCanvas = useRef(null)
    const aboveCanvas = useRef(null)
    const transitLicenseCanvas = useRef(null)
    const soatCanvas = useRef(null)
    const policyCanvas = useRef(null)
    const mechaTechReviewCanvas = useRef(null)

    const [frontalImage, setFrontalImage] = useState(photo)
    const [backImage, setBackImage] = useState(photo)
    const [leftImage, setLeftImage] = useState(photo)
    const [rightImage, setRightImage] = useState(photo)
    const [aboveImage, setAboveImage] = useState(photo)
    const [transitLicenseImage, setTransitLicenseImage] = useState(photo)
    const [soatImage, setSoatImage] = useState(photo)
    const [policyImage, setPolicyImage] = useState(photo)
    const [mechaTechReviewImage, setMechaTechReviewImage] = useState(photo)

    const { departments, municipalitys } = useSelector(state => state.DepMun)

    const nextDailyCheckList = async () => {
        const fieldsToValidate = {
            0: ["leaksDescription", "controlTypeDescription", "alternatorVoltageDescription", "chargerVoltageDescription",
                "tankCapacityDescription", "fuelLevelDescription",
                "generalCleaningStatus", "generatorStatus", "powerConnectionsStatus", "controlConnectionsStatus",
                "chargerStatus", "batteryVoltageStatus",
                "electrolyteLevelStatus", "batteryStatus", "preheaterStatus", "shockAbsorbersStatus", "alternatorBeltsStatus",
                "fanBeltsStatus", "guardStatus",
                "radiatorStatus", "pipelinesStatus", "sealsStatus", "hosesStatus", "coolantLevelStatus", "oilLevelStatus",
                "initialObservation", "serviceType",
                "date"],
            1: ['cabinetStatus', 'doorsStatus', 'locksStatus', 'hingesStatus', 'GECleaningStatus', 'INDIPilotsStatus',
                'selectorsStatus', 'relaysStatus', 'timersStatus', 'contactorsStatus', 'switchesStatus', 'controlConnectionStatus',
                'powerConnectionStatus', 'powerBarStatus', 'neutralBusbarStatus', 'groundBusStatus', 'plcStatus', 'atsStatus',
                'auxiliarySourcesStatus', 'capacitorsStatus', 'networkAnalyzersStatus'
            ],
            2: ['oilTemperatureValue', 'oilTemperatureUnit', 'turboTemperatureValue', 'turboTemperatureUnit',
                'rpmValue', 'rpmUnit', 'vacPhasesL1L1Description', 'vacPhasesL2L2Description', 'vacPhasesL3L3Description',
                'vacPhasesNL1NDescription', 'vacPhasesNL2NDescription', 'vacPhasesNL3NDescription', 'ampsL1Description',
                'ampsL2Description', 'ampsL3Description', 'powerKVADescription', 'powerHzDescription', 'powerFPDescription',
                'airFilterValue', 'airFilterUnit', 'oilFilterValue', 'oilFilterUnit', 'fuelFilterValue', 'fuelFilterUnit',
                'separatorFilterValue', 'separatorFilterUnit', 'waterFilterValue', 'waterFilterUnit', 'oilQuantityValue',
                'oilQuantityUnit', "lowPressureDescription", "highTemperatureDescription", "lowCoolantLevelDescription",
                "lowVoltageACDescription", "controlAtTheEndStatus", "chargerAtTheEndStatus", "toteAtTheEndStatus",
                "preheaterAtTheEndStatus"],
            3: ["TDSDescription", "TDNEDescription", "TDNPDescription", "TDENDescription", "TDECDescription",
                "batteryVoltageAtTheEndDescription", "batteryVoltageDropAtTheEndDescription", "powerCablesDescription", "terminalsDescription",
                "bodyOfContractorsDescription", "bodyOfSwitchesDescription", "transformersDescription", "hottestSpotDescription",
                "L1NValue", "L1NUnit", "L2NValue", "L2NUnit", "L3NValue", "L3NUnit", "l1PowerDescription", "l2PowerDescription", "l3PowerDescription",
                "hzFrequencyDescription", "kwFrequencyDescription", "l1FrequencyDescription", "l2FrequencyDescription",
                "l3FrequencyDescription", "avgFrequencyDescription", "pfPFactorDescription", "kwaPFactorDescription",
                "l1PFactorDescription", "l2PFactorDescription", "l3PFactorDescription", "avgPFactorDescription", "finalObservation"]
        };

        const isValid = await triggerCreateDailyCheckList(fieldsToValidate[current]);

        if (isValid) {
            setCurrent(current + 1)
            window.scrollTo(0, 0)
        } else {
            message.warn("Falta")
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watchCreateDailyCheckList('dependency')) {
            dispatch(LoginActions.getBoss(watchCreateDailyCheckList('dependency')))
        }
    }, [watchCreateDailyCheckList('dependency')])

    const uploadImage = (e, imageType) => {
        const canvass = {
            "frontalImage": frontalCanvas,
            "backImage": backCanvas,
            "leftImage": leftCanvas,
            "rightImage": rightCanvas,
            "aboveImage": aboveCanvas,
            "transitLicenseImage": transitLicenseCanvas,
            "SOATImage": soatCanvas,
            "policyImage": policyCanvas,
            "mechaTechReviewImage": mechaTechReviewCanvas
        }
        const setState = {
            "frontalImage": setFrontalImage,
            "backImage": setBackImage,
            "leftImage": setLeftImage,
            "rightImage": setRightImage,
            "aboveImage": setAboveImage,
            "transitLicenseImage": setTransitLicenseImage,
            "SOATImage": setSoatImage,
            "policyImage": setPolicyImage,
            "mechaTechReviewImage": setMechaTechReviewImage
        }

        const setImage = setState[imageType];
        const canvas = canvass[imageType];

        setImage(URL.createObjectURL(e.target.files[0]));

        var ctx = canvas.current.getContext('2d')
        var img = new Image()

        canvas.current.width = 350
        canvas.current.height = 280

        img.onload = function () {
            ctx.drawImage(img, 0, 0, 350, 280)
        }
        var dataImg;
        setTimeout(function () { dataImg = canvas.current.toDataURL() }, 1000)
        img.src = URL.createObjectURL(e.target.files[0])

        setTimeout(function () { setValueCreateDailyCheckList(imageType, dataImg) }, 1000)
    };
    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    const Stepss = {
        "Planta eléctrica": StepsDailyCheckList
    }

    return (
        <div className='FormDailyCheckList'>
            <p className='titleSection'>Seleccione equipo</p>
            <div className='divSearchEquips'>
                <Button className="Selected" onClick={() => {
                    setVisibleModal(true)
                    dispatch(EquipmentActions.setLoading(false))
                }}> <SearchOutlined className="plus" />  Buscar</Button>
                {visibleExitSearch && <CloseCircleFilled className="closeIcon" onClick={() => onExitSearch()} />}
            </div>
            <div className='div-equipment'>
                {equipmentsSearched && !equipSelected && equipmentsSearched.map((d, i) =>
                (
                    <EquipmentCard
                        onChange={() => setEquipSelected({
                            "name": d.name,
                            "brand": d.vehicleEquipment.brand,
                            "model": d.vehicleEquipment.modell,
                            "serie": d.vehicleEquipment.engineNumber,
                            "plate": d.licensePlate,
                            "location": d.location.name,
                            "moduleWork": d.moduleWork.id,
                            "state": d.state,
                            "id": d.id
                        })}
                        key={i}
                        name={d.name}
                        brand={d.vehicleEquipment.brand}
                        model={d.vehicleEquipment.modell}
                        serie={d.vehicleEquipment.engineNumber}
                        plate={d.licensePlate}
                        location={d.location.name}
                        moduleWork={d.moduleWork.id}
                        state={d.state}
                        id={d.id}
                    >
                    </EquipmentCard>
                ))

                }
            </div>
            <Steps current={current} >
                {StepsDailyCheckList && StepsDailyCheckList.map(item => (
                    <Step key={item.title} title={item.title} icon={item.icon} />
                ))}
            </Steps>
            <div className="steps-content" >
                {StepsDailyCheckList && StepsDailyCheckList[current].content}
            </div>

            {StepsDailyCheckList &&
                <div className="steps-action" >
                    {current > 0 && (
                        <Button style={{ margin: '0 8px', backgroundColor: '#C4C4C4', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => prev()}>
                            Atras
                        </Button>
                    )}
                    {current < StepsDailyCheckList.length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }} onClick={() => nextDailyCheckList()}>
                            Continuar
                        </Button>
                    )}
                    {current === StepsDailyCheckList.length - 1 && (
                        <Button type="primary" style={{ backgroundColor: '#4E8E30', borderRadius: '10px', width: '12vw', height: '3vw', fontSize: '1.3vw' }}
                            onClick={() => {
                                handleSubmitCreateDailyCheckList((dataSubmit) => {
                                    dataSubmit.userCreator = idUser
                                    dataSubmit.moduleWork = moduleWork[0].moduleWork.id;
                                    dataSubmit.equipSelected = equipSelected.id;
                                    dispatch(dailyVehicleActions.createDailyVehicle(dataSubmit));
                                })()
                            }
                            }
                        >
                            <img src={SaveIcon} alt='inventoryIcon' className='icon' style={{ marginRight: '0.5vw' }}></img>
                            {(loadingcreateDailyVehicle) ? <LoadingOutlined /> : "Hecho"}
                        </Button>
                    )}
                </div>}
            <ModalSearchEquipment visibleModal={visibleModal} setVisibleModal={setVisibleModal} />

        </div >
    )
}