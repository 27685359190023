import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    option: 'Search',
    loadingCreateCaseReportSystem: false,
    messageCreateCaseReportSystem: false,
    CaseReportSystemCreatedId: false,
    personAssign: false,
    areaShared: false,
    CaseReportSystemsSearched: false,
    quantity: false,
    loadingCaseReportSystem: false,
    loadingPDF: false,
    fullDataCaseReportSystem: false,
    loadingFullDataCaseReportSystem: false,
    allCaseReportSystemsByEquipmentId: false,
    loadingAllCaseReportSystemsByEquipmentId: false,
    idEquipmentSelected: false,
    visibleModalBitacora: false,
    visibleModalBitacoraNotFirmed: false,
    notFirmed: [],
    currentPage: 0,
    loadingFirmCaseReportSystem: false,
    masiveReports: false,
    loadingMasiveReports: false,
    loadingFirmAllByDependency: false,
    lastNumber: false,
};

const CaseReportSystemSlice = createSlice({
    name: "CaseReportSystem",
    initialState,
    reducers: {
        setOption(state, { payload }) {
            state.option = payload
        },
        setCaseReportSystemCreatedId(state, { payload }) {
            state.CaseReportSystemCreatedId = payload
        },
        createCaseReportSystem(state, { payload }) {
            state.loadingCreateCaseReportSystem = true
        },
        setLoadingCreateCaseReportSystem(state, { payload }) {
            state.loadingCreateCaseReportSystem = payload
        },
        setMessageCreateCaseReportSystem(state, { payload }) {
            state.messageCreateCaseReportSystem = payload
        },
        createCaseReportSystemPDF(state) {
            state.loadingPDF = true
        },
        createTimelinePDF(state) {
            state.loadingPDF = true
        },
        setLoadingPDF(state, { payload }) {
            state.loadingPDF = payload
        },
        updateCaseReportSystem() { },
        createReportComponent() { },
        deleteCaseReportSystem() { },
        setPersonAssign(state, { payload }) {
            state.personAssign = payload
        },
        setAreaShared(state, { payload }) {
            state.areaShared = payload
        },
        setCaseReportSystemsSearched(state, { payload }) {
            state.CaseReportSystemsSearched = payload
        },
        setQuantity(state, { payload }) {
            state.quantity = payload
        },
        getCaseReportSystems(state) {
            state.loadingCaseReportSystem = true
            state.CaseReportSystemsSearched = false
        },
        setLoading(state, { payload }) {
            state.loadingCaseReportSystem = payload
        },
        createCaseReportSystemUser() { },
        createImageReport() { },
        getFullDataCaseReportSystem(state) {
            state.loadingFullDataCaseReportSystem = true
        },
        setFullDataCaseReportSystem(state, { payload }) {
            state.fullDataCaseReportSystem = payload
        },
        setLoadingFullDataCaseReportSystem(state, { payload }) {
            state.loadingFullDataCaseReportSystem = payload
        },
        getAllCaseReportSystemsByEquipmentId(state) {
            state.loadingAllCaseReportSystemsByEquipmentId = true
        },
        setAllCaseReportSystemsByEquipmentId(state, { payload }) {
            state.allCaseReportSystemsByEquipmentId = payload
        },
        setLoadingAllCaseReportSystemsByEquipmentId(state) {
            state.loadingAllCaseReportSystemsByEquipmentId = false
        },
        setIdEquipmentSelected(state, { payload }) {
            state.idEquipmentSelected = payload
        },
        setVisibleModalBitacora(state, { payload }) {
            state.visibleModalBitacora = payload
        }, setVisibleModalBitacoraNotFirmed(state, { payload }) {
            state.visibleModalBitacoraNotFirmed = payload
        },
        getNotFirmed(state) {
        },
        setNotFirmed(state, { payload }) {
            state.notFirmed = payload
        },
        setCurrentPage(state, { payload }) {
            state.currentPage = payload
        },
        firmCaseReportSystem(state, payload) {
            state.loadingFirmCaseReportSystem = true
        },
        setLoadingFirmCaseReportSystem(state, { payload }) {
            state.loadingFirmCaseReportSystem = payload
        },
        getMasiveReports(state, { payload }) {
            state.loadingMasiveReports = true;
        },
        setMasiveReports(state, { payload }) {
            state.masiveReports = payload
        },
        setLoadingMasiveReports(state, { payload }) {
            state.loadingMasiveReports = payload;
        },
        firmAllByDependency(state, { payload }) {
            state.loadingFirmAllByDependency = true
        },
        setLoadingFirmAllByDependency(state, { payload }) {
            state.loadingFirmAllByDependency = payload
        },
        getLastNumber(state, { payload }) {
        },
        setLastNumber(state, { payload }) {
            state.lastNumber = payload
        }
    },
});

const CaseReportSystemActions = CaseReportSystemSlice.actions;
const CaseReportSystemReducer = CaseReportSystemSlice.reducer;

export { CaseReportSystemActions, CaseReportSystemReducer };
