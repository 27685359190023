import { message } from 'antd'
import { takeLatest, all, put, call } from 'redux-saga/effects'
import Api from "../../common/api"
import { CaseReportActions } from './CaseReportSlice'
import { EquipmentActions } from '../Equipment/EquipmentSlice'
import { storage } from '../../common/config/firebase'
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(CaseReportActions.setMunicipalitys(muns.payload))
  }
  yield put(CaseReportActions.setLoading(false))
} */

function* createCaseReport({ payload }) {
  const e = yield Api.post('/caseReport', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
}

function* createReportComponent({ payload }) {
  const bc = yield Api.post(`/reportComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getCaseReports({ payload }) {
  const eq = yield Api.get(`/caseReport/byGroup/${payload.area}?take=${payload.take}&page=${payload.page}`)
  yield put(CaseReportActions.setCaseReportsSearched(eq.payload[0]))
  yield put(CaseReportActions.setQuantity(eq.payload[1]))
  yield put(CaseReportActions.setLoading(false))
}

function* createImageReport({ payload }) {
  const im = yield Api.post('/imageReport', payload)
  if (im.ok) {
    message.success("Imagenes añadidas correctamente")
  } else {
    message.error("Error al añadir imagenes")
  }
}

function* createCaseReportUser({ payload }) {
  yield put(CaseReportActions.setCaseReportCreatedId(false))
  const cru = yield Api.post('/caseReportUser', payload)
  if (!cru.ok) {
    message.error('Error al asignar usuarios al reporte')
  } else {
    message.success("Reporte vinculado")
  }
}

function* deleteCaseReport({ payload }) {
  const caseReportDeleted = yield Api.post(`/caseReport/delete?idd=${payload.id}`)
  if (caseReportDeleted.ok) {
    message.success("Reporte eliminado")
  } else {
    message.error("Error al eliminar reporte")
  }
}

function* updateCaseReport({ payload }) {
  const e = yield Api.post('/caseReport/update', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
}

function* createCaseReportPDF({ payload }) {
  try {
    const auth = getAuth();
    const userCredential = yield call(signInWithEmailAndPassword, auth, "ivancaldsalinas7@gmail.com", "Warcryysaurom1#");

    const uniqueId = uuidv4(); // Genera un UUID
    const storageRef = ref(storage, `${"ruta/a/caseReportPDfs"}/${uniqueId}.pdf`);
    const snapshot = yield call(uploadBytes, storageRef, payload.pdf);
    console.log('Archivo subido exitosamente!', snapshot);
    const url = yield call(getDownloadURL, storageRef);
    payload.pdf = url;

    const CaseReport = yield Api.post('/caseReport/createPdf', payload)
    if (CaseReport.ok) {
      message.success(CaseReport.payload.message)
    } else {
      message.error(CaseReport.payload.message)
    }
    yield put(CaseReportActions.setLoadingPDF(false))
  } catch (error) {
    console.error('Error al subir el archivo o al obtener la URL:', error);
    throw error; // Propaga el error para manejarlo en el flujo principal
  }
}

function* getFullDataCaseReport({ payload }) {
  const c = yield Api.get(`/caseReport/getFullData/byId?id=${payload.id}&moduleWork=${payload.moduleWork}`)
  if (c.ok) {
    yield put(CaseReportActions.setFullDataCaseReport(c.payload))
    yield put(CaseReportActions.setLoadingFullDataCaseReport(false))
  } else {
    message.error("Error al obtener pdf")
  }
}

function* getAllCaseReportsByEquipmentId({ payload }) {
  const c = yield Api.get(`/caseReport/allByEquipmentId?take=6&page=${payload.page}&id=${payload.id}`)
  if (c.ok) {
    yield put(CaseReportActions.setAllCaseReportsByEquipmentId(c.payload))
    yield put(CaseReportActions.setLoadingAllCaseReportsByEquipmentId(false))
  } else {
    message.error("Error al obtener reportes del equipo")
  }
}

function* getNotFirmed({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    if (element.status === "Jefe") {
      dataUrl += `&id${index}=${element.dependency.id}`
    }
  })
  const c = yield Api.get(`/caseReport/notFirmed?take=${payload.take}&page=${payload.page}&moduleWork=${payload.moduleWork}` + dataUrl)
  if (c.ok) {
    yield put(CaseReportActions.setNotFirmed(c.payload))
  } else {
    message.error("Error al obtener no firmados")
  }
  yield put(CaseReportActions.setLoadingNotFirmed(false))
}

function* firmCaseReport({ payload }) {
  const c = yield Api.post(`/caseReport/firm?id=${payload.id}&user=${payload.user}`)
  if (c.ok) {
    message.success(c.payload.message)
  } else {
    message.error(c.payload.message)
  }
}

function* getMasiveReports({ payload }) {
  const c = yield Api.get(`/caseReport/getFullDataMasiveByDependencyAndMonth?month=${payload.month}&dependency=${payload.dependency}&moduleWork=${payload.moduleWork}`)
  if (c.ok) {
    yield put(CaseReportActions.setMasiveReports(c.payload))
  } else {
    message.error("Error al obtener reportes masivos")
  }
  yield put(CaseReportActions.setLoadingMasiveReports(false))
}

function* firmAllByDependency({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    dataUrl += `&id${index}=${element.dependency.id}`
  })
  const c = yield Api.post(`/caseReport/firmAllByDependency?idUser=${payload.idUser}` + dataUrl)
  if (c.ok) {
    message.success("Firmados correctamente")
  } else {
    message.error("Error al firmar todos")
  }
  yield put(CaseReportActions.setLoadingFirmAllByDependency(false))
}

function* createCalibrationCertificate({ payload }) {
  try {
    const auth = getAuth();
    const userCredential = yield call(signInWithEmailAndPassword, auth, "ivancaldsalinas7@gmail.com", "Warcryysaurom1#");

    const uniqueId = uuidv4(); // Genera un UUID
    const storageRef = ref(storage, `${"ruta/a/calibrationCertificate"}/${uniqueId}.pdf`);
    const snapshot = yield call(uploadBytes, storageRef, payload.pdf);
    console.log('Archivo subido exitosamente!', snapshot);
    const url = yield call(getDownloadURL, storageRef);
    payload.pdf = url;

    const c = yield Api.post(`/calibrationCertificate`, payload)
    if (c.ok) {
      message.success("Certificado creado")
    } else {
      message.error("Error al crear certificado")
    }
    yield put(CaseReportActions.setLoadingCreateCalibrationCertificate(false))
  } catch (error) {
    console.error('Error al subir el archivo o al obtener la URL:', error);
    throw error; // Propaga el error para manejarlo en el flujo principal
  }
}

function* getCalibrationCertificate({ payload }) {
  const c = yield Api.get(`/calibrationCertificate/all?take=${payload.take}&page=${payload.page}`)
  if (c.ok) {
    yield put(CaseReportActions.setCalibrationCertificate(c.payload))
  } else {
    message.error("Error al obtener certificado")
  }
  yield put(CaseReportActions.setLoadingGetCalibrationCertificate(false))
}

function* getPdfFromCalibrationCertificate({ payload }) {
  const c = yield Api.get(`/calibrationCertificate/getPdfFromCalibrationCertificate?id=${payload}`)
  if (c.ok) {
    yield put(CaseReportActions.setPdfFromCalibrationCertificate(c.payload))
  } else {
    message.error("Error al obtener pdf")
  }
  yield put(CaseReportActions.setLoadingPdfFromCalibrationCertificate(false))
}

function* getLastCalibrationCertificateByEquipByModuleWork({ payload }) {
  const dateParams = payload.date
    ? `&date=${payload.date[0]}&date1=${payload.date[1]}`
    : '';
  const url = `/equipment/getLastCalibrationCertificate/byModuleWork?take=${payload.take}&page=${payload.page}&moduleWork=${payload.moduleWork}&licensePlate=${payload.licensePlate}&location=${payload.location}&state=${payload.state}&name=${payload.name}&dependency=${payload.dependency}${dateParams}`
  const c = yield Api.get(url)
  if (c.ok) {
    yield put(CaseReportActions.setLastCalibrationCertificateByEquipByModuleWork(c.payload))
    yield put(EquipmentActions.setQuantityEquipsWithCaseReports(c.payload[1]))

  } else {
    message.error("Error al obtener pdf")
  }
  yield put(CaseReportActions.setLoadingLastCalibrationCertificateByEquipByModuleWork(false))
}

function* getAllCalibrationCertificateByEquipmentId({ payload }) {
  const c = yield Api.get(`/calibrationCertificate/allByEquipmentId?take=${payload.take}&page=${payload.page}&id=${payload.id}`)
  if (c.ok) {
    yield put(CaseReportActions.setAllCalibrationCertificateByEquipmentId(c.payload))
  } else {
    message.error("Error al obtener pdfs")
  }
  yield put(CaseReportActions.setLoadingAllCalibrationCertificateByEquipmentId(false))
}

function* getLastNumber({ payload }) {
  const c = yield Api.get(`/systemCaseReport/lastNumber`)
  if (c.ok) {
    yield put(CaseReportActions.setLastNumber(c.payload))
  } else {
    message.error("Error al obtener numero")
  }
}

function* getModulesWorkToFirm({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    if (element.status === "Jefe") {
      dataUrl += `&id${index}=${element.dependency.id}`
    }
  })
  const c = yield Api.get(`/caseReport/getIfThereAreNotFirmed?take=${payload.take}&page=${payload.page}` + dataUrl)
  if (c.ok) {
    yield put(CaseReportActions.setModulesWorkToFirm(c.payload))
  } else {
    message.error("Error al obtener no firmados")
  }
}

function* actionWatcher() {
  yield takeLatest(CaseReportActions.createCaseReport, createCaseReport)
  yield takeLatest(CaseReportActions.createReportComponent, createReportComponent)
  yield takeLatest(CaseReportActions.getCaseReports, getCaseReports)
  yield takeLatest(CaseReportActions.createImageReport, createImageReport)
  yield takeLatest(CaseReportActions.createCaseReportUser, createCaseReportUser)
  yield takeLatest(CaseReportActions.updateCaseReport, updateCaseReport)
  yield takeLatest(CaseReportActions.deleteCaseReport, deleteCaseReport)
  yield takeLatest(CaseReportActions.createCaseReportPDF, createCaseReportPDF)
  yield takeLatest(CaseReportActions.getFullDataCaseReport, getFullDataCaseReport)
  yield takeLatest(CaseReportActions.getAllCaseReportsByEquipmentId, getAllCaseReportsByEquipmentId)
  yield takeLatest(CaseReportActions.getNotFirmed, getNotFirmed)
  yield takeLatest(CaseReportActions.firmCaseReport, firmCaseReport)
  yield takeLatest(CaseReportActions.getMasiveReports, getMasiveReports)
  yield takeLatest(CaseReportActions.firmAllByDependency, firmAllByDependency)
  yield takeLatest(CaseReportActions.createCalibrationCertificate, createCalibrationCertificate)
  yield takeLatest(CaseReportActions.getCalibrationCertificate, getCalibrationCertificate)
  yield takeLatest(CaseReportActions.getPdfFromCalibrationCertificate, getPdfFromCalibrationCertificate)
  yield takeLatest(CaseReportActions.getLastCalibrationCertificateByEquipByModuleWork, getLastCalibrationCertificateByEquipByModuleWork)
  yield takeLatest(CaseReportActions.getAllCalibrationCertificateByEquipmentId, getAllCalibrationCertificateByEquipmentId)
  yield takeLatest(CaseReportActions.getModulesWorkToFirm, getModulesWorkToFirm)
}

export default function* CaseReportSaga() {
  yield all([actionWatcher()])
}