import { Steps, Button, message } from 'antd';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Input } from '../Input/Input'
import { LoadingOutlined } from "@ant-design/icons";
import photo from '../../assets/images/photo.png'
import { DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DepMunActions } from '../../services/DepMun/DepMunSlice';
import { useForm, Controller } from "react-hook-form"
import moment from 'moment';
import { AreaActions } from '../../services/Area/AreaSlice';
import { EnterpriseActions } from '../../services/Enterprise/EnterpriseSlice';
import { LoginActions } from '../../services/Login/LoginSlices';
import SaveIcon from '../../assets/images/save.svg'
import { EditOutlined, SaveOutlined, SearchOutlined } from '@material-ui/icons';
import { SpecializedCenterActions } from '../../services/SpecializedCenter/SpecializedCenterSlice';
import { EquipmentActions } from '../../services/Equipment/EquipmentSlice';
import { EquipmentVehicle } from '../EquipmentVehicle/EquipmentVehicle';
import { AssignDriverDiv } from './AssignDriverDiv';
import { DriverActions } from '../../services/Driver/DriverSlice';
import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';

export const FormAssignDriver = ({ edit, data, setOption }) => {
    const { Step } = Steps;
    const { Option } = Select;
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch()
    const [current, setCurrent] = React.useState(0);
    const { moduleWork, dependencySelected, locations } = useSelector(state => state.Area)
    const { enterprise, idUser, boss, dependencys } = useSelector(state => state.Login)
    const { headquarters } = useSelector(state => state.Enterprise)
    const { equipmentsSearched, loadingUpdateEquipment, loadingCreateEquipment, loadingEquipment } = useSelector(state => state.Equipment)
    const { loadingCreateSpecializedCenter } = useSelector(state => state.SpecializedCenter)
    const [currentPage, setCurrentPage] = useState(0)
    const [vehicleSelected, setVehicleSelected] = useState(false)

    useEffect(() => {
        dispatch(AreaActions.getLocations())
    }, [])

    useEffect(() => {
        if (vehicleSelected) {
            dispatch(DriverActions.getDrivers())
            dispatch(EquipmentVehicleActions.getVehicleEquipmentUsers({ vehicleEquipment: vehicleSelected.vehicleEquipment.id, month: moment().month() + 1 }))
        }
    }, [vehicleSelected])

    useEffect(() => {
        moduleWork &&
            dispatch(EquipmentActions.search(
                {
                    "take": 4,
                    "page": currentPage,
                    "moduleWork": moduleWork[0].moduleWork.id
                }))
    }, [currentPage, moduleWork])

    useEffect(() => {
        enterprise && dispatch(EnterpriseActions.getHeadquarters(enterprise.id))
    }, [enterprise])

    /* const defaultValues = useMemo(() => {
        return {
            "name": data ? data.name : "",
            "nit": data ? data.nit : "",
            "address": data ? data.address : "",
            "neighborhood": data ? data.neighborhood : "",
            "phone": data ? data.phone : "",
            "legalRepresentative": data ? data.legalRepresentative : "",
            "email": data ? data.email : "",
            "type": data ? data.type : "",
            "image": data ? data.image : "",
        }
    }, [fullDataEquipment]); */

    const {
        register: registerSpecializedCenter,
        handleSubmit: handleSubmitSpecializedCenter,
        setValue: setValueSpecializedCenter,
        watch: watchSpecializedCenter,
        control: controlSpecializedCenter,
        formState: { errors: errorsSpecializedCenter },
        trigger: triggerSpecializedCenter,
    } = useForm({
        /* defaultValues */
    })

    const {
        register: registerSearchVehicle,
        handleSubmit: handleSubmitSearchVehicle,
        setValue: setValueSearchVehicle,
        watch: watchSearchVehicle,
        control: controlSearchVehicle,
        formState: { errors: errorsSearchVehicle },
        trigger: triggerSearchVehicle,
    } = useForm({
        /* defaultValues */
    })

    const [areaSelected, setAreaSelected] = useState(false)
    const [Dep, setDep] = useState(false);

    useEffect(() => {
        Dep && dispatch(DepMunActions.getMunicipalitys(Dep))
    }, [Dep, dispatch])

    useEffect(() => {
        if (watchSpecializedCenter('dependency')) {
            dispatch(LoginActions.getBoss(watchSpecializedCenter('dependency')))
        }
    }, [watchSpecializedCenter('dependency')])

    const areas = dependencys && [...new Map(dependencys.map(item => [item.area.id, item.area])).values()];

    return (
        <div className='FormAssignDriver'>
            {!vehicleSelected &&
                <div className='row'>
                    <div className='divDate'>
                        <p className='label'>Área</p>
                        <Select
                            placeholder='Selecciona el area'
                            value={areaSelected}
                            onChange={(e) => {
                                setAreaSelected(e)
                            }}
                            className="select"
                        >
                            {dependencys && areas.map(d => (
                                <Option key={d.id} value={d.id} >{d.name}</Option>
                            ))}
                        </Select>
                        {errorsSearchVehicle.type && <p className='errorMessage'>{errorsSearchVehicle.type.message}</p>}
                    </div>
                    {areaSelected &&
                        <div className='divDate'>
                            <p className='label'>Dependencia</p>
                            <Controller
                                name="dependency"
                                control={controlSearchVehicle}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder='Dependencia'
                                        value={watchSearchVehicle('dependency')}
                                        className="select"
                                    >
                                        {areaSelected && dependencys.filter(item => item.area.id === areaSelected).map(d => (
                                            <Option key={d.id} value={d.id}>{d.name}</Option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </div>}
                    <div className='divDate'>
                        <p className='label'>Tipo de Vehiculo</p>
                        <Controller
                            name="equipType"
                            control={controlSearchVehicle}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    className="select"
                                    placeholder='Tipo de Vehiculo'
                                >
                                    <Option value={"Auto"}>Auto</Option>
                                    <Option value={"Maquinaria"}>Maquinaria</Option>
                                    <Option value={"Motocicleta"}>Motocicleta</Option>
                                </Select>
                            )}
                        />
                        {errorsSearchVehicle.equipType && <p className='errorMessage'>{errorsSearchVehicle.equipType.message}</p>}
                    </div>
                    <Input
                        {...registerSpecializedCenter('vehiclePlate', {
                        })}
                        label={"Placa de Vehículo"}
                        width={'13vw'}
                        height={'2vw'}
                        labelstyle='inputLabelBordered'
                        fontSize={'1vw'}
                        va={watchSpecializedCenter('vehiclePlate')}
                        setData={e => setValueSpecializedCenter('vehiclePlate', e)}
                        error={errorsSpecializedCenter.vehiclePlate && errorsSpecializedCenter.vehiclePlate.message}
                    />
                </div>}


            {!vehicleSelected &&
                <div className='buttonSave' style={{ marginTop: Object.keys(errorsSpecializedCenter).length !== 0 && '-1vw' }} onClick={() => {
                    handleSubmitSearchVehicle((dataSubmit) => {
                        !loadingEquipment ?
                            dispatch(EquipmentActions.search(
                                {
                                    "dependency": dataSubmit.dependency,
                                    "vehiclePlate": dataSubmit.vehiclePlate,
                                    "equipType": dataSubmit.type,
                                    "take": 4,
                                    "page": currentPage,
                                    "moduleWork": moduleWork[0].moduleWork.id
                                }))
                            :
                            message.loading("Espere un momento por favor")
                    })()
                }}>
                    {!data ? <SearchOutlined className='iconButton' /> : <EditOutlined className='iconButton' />}
                    {loadingEquipment ? <LoadingOutlined /> : !data ? "Buscar" : "Editar"}
                </div>}
            {!vehicleSelected &&
                <p className='titleSection'>Resultados de la Búsqueda del Vehículo a asignar al Conductor</p>}
            {!vehicleSelected &&
                <div className="columnsName">
                    <p>Imagen</p>
                    <p>Nombre</p>
                    <p>Marca</p>
                    <p>Modelo</p>
                    <p>Serie</p>
                    <p>Placa</p>
                    <p>Ubicación</p>
                    <p>Estado</p>
                    <p>Conductor</p>
                </div>}
            {!vehicleSelected &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3vw', height: '18vw', overflowY: 'auto' }}>
                    {equipmentsSearched && equipmentsSearched.map((d, i) => (
                        <EquipmentVehicle key={i} data={d} width={'98%'} height={'4vw'} extraOnClick={setVehicleSelected} />
                    ))
                    }
                </div>}
            {vehicleSelected && <AssignDriverDiv vehicleEquipmentId={vehicleSelected.vehicleEquipment.id} setVehicleSelected={() => setVehicleSelected(false)} />}
        </div>
    )
}