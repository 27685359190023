import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { DriverActions } from './DriverSlice'
import { TimelineActions } from '../Timeline/TimelineSlice'

function* getDrivers() {
  const d = yield Api.get(`/user/getUsersDrivers`)
  if (d.ok) {
    yield put(DriverActions.setDrivers(d.payload))
  } else {
    message.error(d.payload.message)
  }
}

function* actionWatcher() {
  yield takeLatest(DriverActions.getDrivers, getDrivers)
}

export default function* DriverSaga() {
  yield all([actionWatcher()])
}