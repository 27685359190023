import { Document, PDFViewer } from "@react-pdf/renderer";
import { Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { EquipmentActions } from "../../services/Equipment/EquipmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { CaseReportActions } from "../../services/caseReport/CaseReportSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { ModalBitacora } from "../ModalBitacora/ModalBitacora";
import { apiUrl } from '../../common/config/Environments'
import { PdfCaseReport } from "../PdfCaseReport/PdfCaseReport";

export const CaseReport = ({ data, setEdit, setEquipData }) => {
    const { enterpriseImage } = useSelector(state => state.Enterprise)
    const [visibleModal, setVisibleModal] = useState(false)
    const [caseReportHigh, setCaseReportHigh] = useState(false)
    const [image, setImage] = useState(false)
    const [visibleModalBitacora, setVisibleModalBitacora] = useState(false)

    const { idUser, fullUser } = useSelector(state => state.Login)
    const { moduleWork } = useSelector(state => state.Area)
    const { fullDataCaseReport, loadingFullDataCaseReport, allCaseReportsByEquipmentId, loadingAllCaseReportsByEquipmentId, idEquipmentSelected } = useSelector(state => state.CaseReport)
    const dispatch = useDispatch()

    useEffect(() => {
        let aux = false
        aux = data.caseReport.map(d => new Date(d.date))
        var maximumDate = new Date(Math.max.apply(null, aux));
        for (let index = 0; index < data.caseReport.length; index++) {
            if (new Date(data.caseReport[index].date).getTime() == maximumDate.getTime()) {
                setCaseReportHigh(data.caseReport[index])
            }
        }
    }, [])

    useEffect(() => {
        fetch(`${apiUrl}/biomedicEquipment/imageById?id=${data.id}`)
            .then(response => response.json())
            .then(data => setImage(data))
            .catch(error => console.error(error))
    }, [])

    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    useEffect(() => {
        fullDataCaseReport && fullDataCaseReport.equipment.id === data.id && setVisibleModal(true)
    }, [fullDataCaseReport])

    useEffect(() => {
        if (allCaseReportsByEquipmentId && allCaseReportsByEquipmentId[0][0].id === data.caseReport[0].id) {
            setVisibleModal(false)
            setVisibleModalBitacora(true)
        }
    }, [allCaseReportsByEquipmentId])

    const ModalEquip = () => {
        const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        }
        /* const onClickEdit = () => {
            setEdit(true)
            setEquipData(data)
        } */

        return (
            <Modal
                className='ModalEquip'
                width={fullDataCaseReport ? '90vw' : '40vw'}
                visible={visibleModal} footer={null}
                onCancel={() => {
                    setVisibleModal(false)
                    dispatch(CaseReportActions.setIdEquipmentSelected(false))
                    dispatch(CaseReportActions.setFullDataCaseReport(false))
                }}
            >
                {!fullDataCaseReport && <p className="title">{data.name}</p>}
                {!fullDataCaseReport && <div className='div'>
                    <button className='button' onClick={() => dispatch(CaseReportActions.getFullDataCaseReport({ "id": caseReportHigh.id, "moduleWork": moduleWork[0].moduleWork.id }))
                    }>
                        {loadingFullDataCaseReport ? <LoadingOutlined /> : "Ver reporte"}
                    </button>
                    {idUser == data.caseReport[0].userCreator.id && fullUser.canCreate && <button className='button' onClick={() => onClickEdit(true)}>Editar</button>}
                    <button className='button' onClick={() => {
                        dispatch(CaseReportActions.getAllCaseReportsByEquipmentId({ "id": idEquipmentSelected, "page": 0 }))
                    }}>{loadingAllCaseReportsByEquipmentId ? <LoadingOutlined /> : "Bitacora"}
                    </button>
                    {/* <button className='button' onClick={() => dispatch(CaseReportActions.setFullDataCaseReport(
                        {
                            "id": 3523,
                            "idM": null,
                            "date": null,
                            "endDate": null,
                            "createdDate": null,
                            "dbState": true,
                            "statusEquip": "",
                            "pdf": null,
                            "firmedDate": null,
                            "equipment": {
                                "id": 858,
                                "idM": null,
                                "licensePlate": " ",
                                "dbState": true,
                                "state": " ",
                                "createdDate": null,
                                "sectorCode": " ",
                                "nit": " ",
                                "number": 1,
                                "name": " ",
                                "dependency": {
                                    "id": 995,
                                    "name": " ",
                                    "email": " ",
                                    "area": {
                                        "id": 425,
                                        "name": " "
                                    }
                                },
                                "municipality": {
                                    "id": 22,
                                    "nombre": " "
                                },
                                "biomedicEquipment": {
                                    "brand": " ",
                                    "active": null,
                                    "serie": " ",
                                    "modell": " ",
                                    "service": " "
                                }
                            },
                            "coordinatorAreaWhenDone": {
                                "id": 222,
                                "profesion": " ",
                                "position": " ",
                                "email": " ",
                                "dbState": true,
                                "canCreate": true,
                                "password": " ",
                                "person": {
                                    "id": 148,
                                    "name": " ",
                                    "secondName": " ",
                                    "lastName": " ",
                                    "secondLastName": " ",
                                    "cardId": " ",
                                    "phone": " ",
                                    "firm": null,
                                    "photo": null
                                }
                            },
                            "bossDependencyWhenDone": null,
                            "reportComponent": [],
                            "userCreator": {
                                "id": 3,
                                "profesion": " ",
                                "position": " ",
                                "email": " ",
                                "dbState": true,
                                "canCreate": true,
                                "password": " ",
                                "role": {
                                    "id": 3,
                                    "name": " "
                                },
                                "person": {
                                    "id": 3,
                                    "name": " ",
                                    "secondName": " ",
                                    "lastName": " ",
                                    "secondLastName": " ",
                                    "cardId": " ",
                                    "phone": null,
                                    "firm": null,
                                    "photo": null
                                }
                            },
                            "biomedicCaseReport": {
                                "id": 2006,
                                "serviceType": " ",
                                "descriptionAnswer": " ",
                                "descriptionAsking": " ",
                                "status": " ",  
                                "processDescription": "",
                                "observations": " "
                            },
                            "systemCaseReport": null
                        }
                    ))
                    }>
                        {loadingFullDataCaseReport ? <LoadingOutlined /> : "Formato vacio"}
                    </button> */}

                </div>}
                {fullDataCaseReport && fullDataCaseReport.pdf === null ?
                    <PDFViewer className='pdf'>
                        <Document>
                            <PdfCaseReport fullDataCaseReport={fullDataCaseReport} enterprise={enterpriseImage}></PdfCaseReport>
                        </Document>
                    </PDFViewer> :
                    fullDataCaseReport && <embed className="pdf" src={fullDataCaseReport.pdf} type="application/pdf"></embed>}
            </Modal >
        )
    }
    const onDelete = () => {
        if (window.confirm('¿Seguro deseas eliminar este reporte?')) {
            const dataDelete = {
                "id": caseReportHigh.id
/*                 "id": data.userAssigned.dependencyUser !== undefined ? data.id : data.userAssigned.person1
 */            }
            dispatch(CaseReportActions.deleteCaseReport(dataDelete))
        }
    }

    return (
        <div className='CaseReport' >
            {image ?
                <img className='equipmentImage' src={image.image} alt='img' />
                :
                <div style={{ marginRight: '5vw' }}></div>
            }
            <div className='dataDiv' onClick={() => {
                setVisibleModal(true)
                dispatch(CaseReportActions.setIdEquipmentSelected(data.id))
            }}>
                <div><p className='pFontData name'>{data.name}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.brand}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.modell}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.serie}</p></div>
                <div><p className='pFontData'>{data.licensePlate}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.healthRegister}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.riskClasification}</p></div>
                <div><p className='pFontData'>{data.biomedicEquipment.location}</p></div>
                {caseReportHigh && (caseReportHigh.statusEquip === "Funcional" ? <div className='status'><p className='pFontStatus'>Funcional</p></div>
                    : caseReportHigh.statusEquip === "No funcional" ? <div className='status1'><p className='pFontStatus'>{caseReportHigh.statusEquip}</p></div>
                        : caseReportHigh.statusEquip === "Dado de baja" ? <div className='status2'><p className='pFontStatus'>Baja</p></div>
                            : caseReportHigh.statusEquip === "Comodato devuelto" ? <div className='status4'><p className='pFontStatus'>Comodato devuelto</p></div>
                                : <div className='status3'><p className='pFontStatus'>PDF</p></div>)}
                <div><p className='pFontData date'>{caseReportHigh && padStr(new Date(caseReportHigh.date).getDate()) + "/" +
                    padStr(1 + new Date(caseReportHigh.date).getMonth()) + "/" +
                    padStr(new Date(caseReportHigh.date).getFullYear())
                }</p></div>
            </div>
            <div className='deleteDiv'><CloseOutlined className='deleteIcon' onClick={() => onDelete()} /></div>
            <ModalEquip />
            <ModalBitacora
                data={allCaseReportsByEquipmentId}
                setEdit={setEdit}
                setEquipData={setEquipData}
                visibleModalBitacora={visibleModalBitacora}
                setVisibleModalBitacora={setVisibleModalBitacora}
            />
        </div>
    )
}