import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"
import { CaseReportSystemActions } from './caseReportSystemSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(CaseReportSystemActions.setMunicipalitys(muns.payload))
  }
  yield put(CaseReportSystemActions.setLoading(false))
} */

function* createCaseReportSystem({ payload }) {
  const e = yield Api.post('/caseReport/system', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
  yield put(CaseReportSystemActions.setMessageCreateCaseReportSystem(e.payload.message))
  yield put(CaseReportSystemActions.setLoadingCreateCaseReportSystem(false))
}

function* createReportComponent({ payload }) {
  const bc = yield Api.post(`/reportComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getCaseReportSystems({ payload }) {
  const eq = yield Api.get(`/CaseReportSystem/byGroup/${payload.area}?take=${payload.take}&page=${payload.page}`)
  yield put(CaseReportSystemActions.setCaseReportSystemsSearched(eq.payload[0]))
  yield put(CaseReportSystemActions.setQuantity(eq.payload[1]))
  yield put(CaseReportSystemActions.setLoading(false))
}

function* createImageReport({ payload }) {
  const im = yield Api.post('/imageReport', payload)
  if (im.ok) {
    message.success("Imagenes añadidas correctamente")
  } else {
    message.error("Error al añadir imagenes")
  }
}

function* createCaseReportSystemUser({ payload }) {
  yield put(CaseReportSystemActions.setCaseReportSystemCreatedId(false))
  const cru = yield Api.post('/CaseReportSystemUser', payload)
  if (!cru.ok) {
    message.error('Error al asignar usuarios al reporte')
  } else {
    message.success("Reporte vinculado")
  }
}

function* deleteCaseReportSystem({ payload }) {
  const CaseReportSystemDeleted = yield Api.post(`/CaseReportSystem/delete?idd=${payload.id}`)
  if (CaseReportSystemDeleted.ok) {
    message.success("Reporte eliminado")
  } else {
    message.error("Error al eliminar reporte")
  }
}

function* updateCaseReportSystem({ payload }) {
  const e = yield Api.post('/CaseReportSystem/update', payload)
  if (e.ok) {
    message.success(e.payload.message)
  } else {
    message.error(e.payload.message)
  }
}

function* createCaseReportSystemPDF({ payload }) {
  const CaseReportSystem = yield Api.post('/CaseReportSystem/createPdf', payload)
  if (CaseReportSystem.ok) {
    message.success(CaseReportSystem.payload.message)
  } else {
    message.error(CaseReportSystem.payload.message)
  }
}

function* getFullDataCaseReportSystem({ payload }) {
  const c = yield Api.get(`/CaseReportSystem/getFullData/byId?id=${payload}`)
  if (c.ok) {
    yield put(CaseReportSystemActions.setFullDataCaseReportSystem(c.payload))
    yield put(CaseReportSystemActions.setLoadingFullDataCaseReportSystem(false))
  } else {
    message.error("Error al obtener pdf")
  }
}

function* getAllCaseReportSystemsByEquipmentId({ payload }) {
  const c = yield Api.get(`/CaseReportSystem/allByEquipmentId?take=3&page=${payload.page}&id=${payload.id}`)
  if (c.ok) {
    yield put(CaseReportSystemActions.setAllCaseReportSystemsByEquipmentId(c.payload))
    yield put(CaseReportSystemActions.setLoadingAllCaseReportSystemsByEquipmentId(false))
  } else {
    message.error("Error al obtener reportes del equipo")
  }
}

function* getNotFirmed({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    if (element.status === "Jefe") {
      dataUrl += `&id${index}=${element.dependency.id}`
    }
  })
  const c = yield Api.get(`/CaseReportSystem/notFirmed?take=${payload.take}&page=${payload.page}` + dataUrl)
  if (c.ok) {
    yield put(CaseReportSystemActions.setNotFirmed(c.payload))
  } else {
    message.error("Error al obtener no firmados")
  }
}

function* firmCaseReportSystem({ payload }) {
  const c = yield Api.post(`/CaseReportSystem/firm?id=${payload.id}&user=${payload.user}`)
  if (c.ok) {
    message.success(c.payload.message)
  } else {
    message.error(c.payload.message)
  }
}

function* getMasiveReports({ payload }) {
  const c = yield Api.get(`/CaseReportSystem/getFullDataMasiveByDependencyAndMonth?month=${payload.month}&dependency=${payload.dependency}`)
  if (c.ok) {
    yield put(CaseReportSystemActions.setMasiveReports(c.payload))
  } else {
    message.error("Error al obtener reportes masivos")
  }
  yield put(CaseReportSystemActions.setLoadingMasiveReports(false))
}

function* firmAllByDependency({ payload }) {
  let dataUrl = ""
  payload.payload.forEach((element, index) => {
    dataUrl += `&id${index}=${element.dependency.id}`
  })
  const c = yield Api.post(`/CaseReportSystem/firmAllByDependency?idUser=${payload.idUser}` + dataUrl)
  if (c.ok) {
    message.success("Firmados correctamente")
  } else {
    message.error("Error al firmar todos")
  }
  yield put(CaseReportSystemActions.setLoadingFirmAllByDependency(false))
}

function* getLastNumber({ payload }) {
  const c = yield Api.get(`/systemCaseReport/lastNumber`)
  if (c.ok) {
    yield put(CaseReportSystemActions.setLastNumber(c.payload))
  } else {
    message.error("Error al obtener numero")
  }
}

function* actionWatcher() {
  yield takeLatest(CaseReportSystemActions.createCaseReportSystem, createCaseReportSystem)
  yield takeLatest(CaseReportSystemActions.createReportComponent, createReportComponent)
  yield takeLatest(CaseReportSystemActions.getCaseReportSystems, getCaseReportSystems)
  yield takeLatest(CaseReportSystemActions.createImageReport, createImageReport)
  yield takeLatest(CaseReportSystemActions.createCaseReportSystemUser, createCaseReportSystemUser)
  yield takeLatest(CaseReportSystemActions.updateCaseReportSystem, updateCaseReportSystem)
  yield takeLatest(CaseReportSystemActions.deleteCaseReportSystem, deleteCaseReportSystem)
  yield takeLatest(CaseReportSystemActions.createCaseReportSystemPDF, createCaseReportSystemPDF)
  yield takeLatest(CaseReportSystemActions.getFullDataCaseReportSystem, getFullDataCaseReportSystem)
  yield takeLatest(CaseReportSystemActions.getAllCaseReportSystemsByEquipmentId, getAllCaseReportSystemsByEquipmentId)
  yield takeLatest(CaseReportSystemActions.getNotFirmed, getNotFirmed)
  yield takeLatest(CaseReportSystemActions.firmCaseReportSystem, firmCaseReportSystem)
  yield takeLatest(CaseReportSystemActions.getMasiveReports, getMasiveReports)
  yield takeLatest(CaseReportSystemActions.firmAllByDependency, firmAllByDependency)
  yield takeLatest(CaseReportSystemActions.getLastNumber, getLastNumber)
}

export default function* CaseReportSystemSaga() {
  yield all([actionWatcher()])
}