import { Select, Calendar, message } from 'antd';
import { useEffect } from 'react';
import ProfileImageDefault from '../../assets/images/profile.jpeg'
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EquipmentVehicleActions } from '../../services/EquipmentVehicle/EquipmentVehicleSlice';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

export const AssignDriverDiv = ({ vehicleEquipmentId, setVehicleSelected }) => {
    const dispatch = useDispatch();
    const { loadingCreateVehicleEquipmentUser, wasCreatedVehicleEquipmentUser, vehicleEquipmentUsers } = useSelector(state => (state.EquipmentVehicle));

    const { drivers } = useSelector(state => state.Driver)
    const { idUser } = useSelector(state => state.Login)
    const [listData, setListData] = useState({});
    const [dayTrip, setDayTrip] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(moment());

    const handleDragStart = (e, driver) => {
        e.dataTransfer.setData("driver", JSON.stringify(driver));
    };

    // Función para permitir el arrastre sobre las celdas del calendario
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // Función para manejar el evento de soltar en la celda del calendario
    const handleDrop = (e, date) => {
        e.preventDefault();
        if (!dayTrip) return message.warning('Selecciona un turno');
        const driver = JSON.parse(e.dataTransfer.getData("driver"));

        const listDataAux = { ...listData };

        if (!listDataAux[date.date()]) {
            listDataAux[date.date()] = [];
        } else {
            const isDuplicate = listDataAux[date.date()].some(item => item.dayTrip === dayTrip);
            if (isDuplicate) {
                message.warning("Ya existe tomada esa jornada en esta fecha.");
                return;
            }
        }
        message.loading("Asignando conductor...")

        listDataAux[date.date()].push({
            dayTrip: dayTrip,
            driver: driver.person.name + " " + driver.person.lastName,
        });
        setListData(listDataAux);
        dispatch(EquipmentVehicleActions.createVehicleEquipmentUser({
            dateAssignment: new Date(),
            date: date,
            dayTrip: dayTrip,
            user: driver.id,
            vehicleEquipment: vehicleEquipmentId,
            dbState: true,
        })
        )
    };

    useEffect(() => {
        if (vehicleEquipmentUsers) {

            const listDataAux = { ...listData };

            vehicleEquipmentUsers.forEach(vehicleEquipmentUser => {
                const { date, dayTrip, user, id } = vehicleEquipmentUser;
                if (!listDataAux[moment(date).date()]) {
                    listDataAux[moment(date).date()] = [];
                }
                listDataAux[moment(date).date()].push({
                    id: id,
                    dayTrip: dayTrip,
                    driver: user.person.name + " " + user.person.lastName,
                });

            });
            setListData(listDataAux);
        }
    }, [vehicleEquipmentUsers])


    const getMonthData = (value) => {
        if (value.month() === 8) {
            return 1394;
        }
    };

    const monthCellRender = (value) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };

    function getDayTripClassName(dayTrip) {
        switch (dayTrip) {
            case 'M':
                return 'dayTripM';
            case 'T':
                return 'dayTripT';
            case 'N':
                return 'dayTripN';
            case 'CD':
                return 'dayTripCD';
            case 'CN':
                return 'dayTripCN';
            default:
                return 'defaultClassName'; // Clase por defecto si ninguna coincide
        }
    }
    const dateCellRender = (value) => {
        return (
            <div
                key={value.date()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, value)}
                style={{
                    width: '6.5vw',
                    height: '6vw',
                    border: '1px solid gray',
                    overflowY: 'auto',
                    borderRadius: '5px',
                    fontSize: '0.5vw',
                    textAlign: 'left',
                    padding: '0vw 0.5vw',
                    backgroundColor: 'white',
                }}
            >
                {listData[value.date()] && listData[value.date()].map((item) => (
                    <p className='pTable'>
                        <span className={getDayTripClassName(item.dayTrip)}>
                            {item.dayTrip}
                        </span>: {item.driver}
                        <DeleteOutlined
                            className='delete'
                            onClick={() => {
                                if (window.confirm("¿Estás seguro de eliminar esta asignación?")) {
                                    dispatch(EquipmentVehicleActions.deleteVehicleEquipmentUser(item.id))
                                    message.loading("Eliminando asignación...")
                                    const listDataAux = { ...listData };
                                    listDataAux[value.date()] = listDataAux[value.date()].filter(i => i.dayTrip !== item.dayTrip);
                                    setListData(listDataAux);
                                }
                            }}
                        />
                    </p>
                ))}
            </div >
        );
    };

    return (
        <div className='assignDriversDiv'>
            <div className='selectAndGoBack'>
                <Select
                    className='selectDayTrip'
                    placeholder="Selecciona un turno"
                    onChange={(value) => setDayTrip(value)}
                >
                    <Select.Option value="M">M (06:00 - 14:00)</Select.Option>
                    <Select.Option value="T">T (12:00 - 22:00)</Select.Option>
                    <Select.Option value="N">N (22:00 - 06:00)</Select.Option>
                    <Select.Option value="CD">CD (06:00 - 18:00)</Select.Option>
                    <Select.Option value="CN">CN (18:00 - 06:00)</Select.Option>
                </Select>
                <button className='buttonGoBack' onClick={setVehicleSelected}>Volver</button>
            </div>
            <div className='sectionDriversAndCalendar'>
                <div className='divDrivers'>
                    <p className='textDrivers'>Conductores Activos</p>
                    {drivers && drivers.map((d, i) => (
                        <div
                            className='driverCard'
                            draggable
                            onDragStart={(e) => handleDragStart(e, d)}
                            key={i}
                        >
                            <img className='image' src={d.person.photo ? d.person.photo : ProfileImageDefault} alt='img' />
                            <p className='name'>{d.person.name + " " + d.person.lastName}</p>
                        </div>
                    ))}
                </div>
                <div className='divCalendar'>
                    <Calendar defaultValue={currentMonth} fullscreen={false} dateCellRender={dateCellRender} monthCellRender={monthCellRender} />;
                </div>
            </div>
        </div>
    )
}
