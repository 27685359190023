import { message } from 'antd'
import { takeLatest, all, put } from 'redux-saga/effects'
import Api from "../../common/api"

import { EquipmentVehicleActions } from './EquipmentVehicleSlice'
import { TimelineActions } from '../Timeline/TimelineSlice'

/* function* getMunicipalityByDepartment({ payload }) {
  const muns = yield Api.get(`/municipality/department/${payload}`)
  if (muns.ok) {
    yield put(EquipmentVehicleActions.setMunicipalitys(muns.payload))
  }
  yield put(EquipmentVehicleActions.setLoading(false))
} */

function* createEquipmentVehicle({ payload }) {
  const e = yield Api.post('/equipment/vehicle', payload)
  if (e.ok) {
    message.success(e.payload.message)
    yield put(EquipmentVehicleActions.setEquipmentVehicleCreatedId(e.payload.message))
  } else {
    message.error(e.payload.message)
  }
  yield put(EquipmentVehicleActions.setLoadingCreateEquipmentVehicle(false))
}

function* deleteEquipmentVehicle({ payload }) {
  const EquipmentVehicleDeleted = yield Api.post(`/EquipmentVehicle/delete?idd=${payload.id}`)
  if (EquipmentVehicleDeleted.ok) {
    message.success("Equipo eliminado")
  } else {
    message.error("Error al eliminar equipo")
  }
}

function* createBiomedicComponent({ payload }) {
  const bc = yield Api.post(`/biomedicComponent`, payload)
  if (bc.ok) {
    message.success('Componentes añadidos')
  } else {
    message.error("Error creando componentes")
  }
}

function* getEquipmentVehicles({ payload }) {
  const eq = yield Api.get(`/EquipmentVehicle/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentVehicleActions.setEquipmentVehiclesSearched(eq.payload[0]))
  yield put(EquipmentVehicleActions.setQuantity(eq.payload[1]))
  yield put(EquipmentVehicleActions.setLoading(false))
}

function* getEquipmentVehiclesByModuleWork({ payload }) {
  const eq = yield Api.get(`/EquipmentVehicle/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  yield put(EquipmentVehicleActions.setEquipmentVehiclesSearched(eq.payload[0]))
  yield put(EquipmentVehicleActions.setQuantity(eq.payload[1]))
  yield put(EquipmentVehicleActions.setLoading(false))
}

function* searchEquipmentVehicle({ payload }) {
  const eq = yield Api.get(`/EquipmentVehicle/byDependencySearch?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&state=${payload.state}`)
  yield put(EquipmentVehicleActions.setEquipmentVehiclesSearched(eq.payload))
  yield put(EquipmentVehicleActions.setLoading(false))
}

function* search({ payload }) {
  const eq = yield Api.get(`/EquipmentVehicle/search?dependency=${payload.dependency}&nameEquip=${payload.nameEquip}&licensePlate=${payload.licensePlate}&year=${payload.year}&take=${payload.take}&page=${payload.page}`)
  if (eq.ok) {
    yield put(EquipmentVehicleActions.setEquipmentVehiclesSearched(eq.payload))
    yield put(EquipmentVehicleActions.setIsFiltered(true))
  } else {
    message.error("Error obteniendo equipos filtrados")
  }
  yield put(EquipmentVehicleActions.setLoading(false))
}

function* getStates() {
  const s = yield Api.get('/EquipmentVehicle/distinctState')
  if (s.ok) {
    yield put(EquipmentVehicleActions.setStates(s.payload))
  } else {
    message.error("Error obteniendo estados")
  }
}

function* getLocations() {
  const m = yield Api.get('/biomedicEquipmentVehicle/distinctLocation')
  if (m.ok) {
    yield put(EquipmentVehicleActions.setLocations(m.payload))
  } else {
    message.error("Error obteniendo ubicaciones")
  }
}

function* getEquipsWithCaseReports({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/getLastCaseReport/byDependency?dependency=${payload.area}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentVehicleActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentVehicleActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* getEquipsWithCaseReportsByModuleWork({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/getLastCaseReport/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setEquipsWithCaseReports(e.payload[0]))
    yield put(EquipmentVehicleActions.setQuantityEquipsWithCaseReports(e.payload[1]))
    yield put(EquipmentVehicleActions.setLoadingEquipsWithCaseReport(false))
  } else {
    message.error("Error al obtener reportes")
  }
}

function* searchCaseReport({ payload }) {
  const c = yield Api.get(`/EquipmentVehicle/getCaseReportSearch?dependency=${payload.dependency}&take=8&page=${payload.page}&name=${payload.name}&location=${payload.location}&state=${payload.state}&date=${payload.date[0]}&date1=${payload.date[1]}`)
  if (c.ok) {
    yield put(EquipmentVehicleActions.setEquipsWithCaseReports(c.payload[0]))
    yield put(EquipmentVehicleActions.setQuantityEquipsWithCaseReports(c.payload[1]))
    yield put(EquipmentVehicleActions.setLoadingEquipsWithCaseReport(false))
    yield put(EquipmentVehicleActions.setIsFiltered(true))
  } else {
    message.error("Error al realizar la busqueda")
  }
}

function* getEquipAndReportPDF({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/getByIdAndReportsPDF?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setEquipAndReportPDF(e.payload))
  } else {
    message.error("Error al obtener PDFs")
  }
}

function* getEquipsForTimeline({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/getForTimeline?dependency=${payload.dependency}&string=${payload.string}`)
  if (e.ok) {
    if (payload.string === "") {
      yield put(EquipmentVehicleActions.setAllEquipsForTimeline(e.payload))
    } else {
      yield put(EquipmentVehicleActions.setEquipsForTimeline(e.payload))
    }
  } else {
    message.error("Error al obtener equipos buscados")
  }
  yield put(EquipmentVehicleActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelines({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/getEquipsWithTimelines/byDependency?dependency=${payload.dependency}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipmentVehicle(aux))
    yield put(EquipmentVehicleActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentVehicleActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the EquipmentVehicles

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentVehicleActions.setLoadingEquipsForTimeline(false))
}

function* getEquipsWithTimelinesByModuleWork({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/getEquipsWithTimelines/byModuleWork?moduleWork=${payload.moduleWork}&take=${payload.take}&page=${payload.page}`)
  if (e.ok) {
    let aux = []
    e.payload[0].map((equip) => {
      aux.push({ "id": equip.id, "timelines": [], "quantity": 0, "currentPage": 0 })
    })
    yield put(TimelineActions.setTimelinesByEquipmentVehicle(aux))
    yield put(EquipmentVehicleActions.setEquipsWithTimelines(e.payload[0]))
    yield put(EquipmentVehicleActions.setQuantityEquipsWithTimelines(e.payload[1]))
    //create an array "aux" where save the ids from the EquipmentVehicles

  } else {
    message.error("Error al obtener cronogramas")
  }
  yield put(EquipmentVehicleActions.setLoadingEquipsForTimeline(false))
}

function* getFullDataEquipmentVehicle({ payload }) {
  const e = yield Api.get(`/EquipmentVehicle/fullDataEquipmentVehicleById?id=${payload}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setFullDataEquipmentVehicle(e.payload))
  } else {
    message.error("Error al obtener los datos completos del equipo")
  }
  yield put(EquipmentVehicleActions.setLoadingFullDataEquipmentVehicle(false))
}

function* updateEquipmentVehicle({ payload }) {
  const e = yield Api.post(`/equipment/updateComputersEquipment?id=${payload.id}`, payload.payload)
  if (e.ok) {
    message.success("Equipo actualizado")
  } else {
    message.error("Error al actualizar equipo")
  }
  yield put(EquipmentVehicleActions.setLoadingCreateEquipmentVehicle(false))
}

function* getVehicleImages({ payload }) {
  const e = yield Api.get(`/vehicleEquipment/allImages?id=${payload.id}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setVehicleImages(e.payload))
  } else {
    message.error("Error al obtener imagenes")
  }
  yield put(EquipmentVehicleActions.setLoadingGetVehicleImages(false))
}

function* getVehiclePapers({ payload }) {
  const e = yield Api.get(`/vehicleEquipment/currentPapers?id=${payload.id}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setVehiclePapers(e.payload))
  } else {
    message.error("Error al obtener documentos")
  }
  yield put(EquipmentVehicleActions.setLoadingGetVehiclePapers(false))
}

function* createVehicleEquipmentUser({ payload }) {
  const e = yield Api.post(`/vehicleEquipmentUser/`, payload)
  if (e.ok) {
    message.success("Conductor asignado")
    yield put(EquipmentVehicleActions.setWasCreatedVehicleEquipmentUser(true))
  } else {
    message.error("Error al asignar conductor")
    yield put(EquipmentVehicleActions.setWasCreatedVehicleEquipmentUser("false"))
  }
  yield put(EquipmentVehicleActions.setLoadingCreateVehicleEquipmentUser(false))
}

function* getVehicleEquipmentUsers({ payload }) {
  const e = yield Api.get(`/vehicleEquipmentUser/getByVehicleAndMonth?vehicleEquipment=${payload.vehicleEquipment}&month=${payload.month}`)
  if (e.ok) {
    yield put(EquipmentVehicleActions.setVehicleEquipmentUsers(e.payload))
  } else {
    message.error("Error al consultar fechas")
  }
}

function* deleteVehicleEquipmentUser({ payload }) {
  const e = yield Api.post(`/vehicleEquipmentUser/deleteVehicleEquipmentUser?id=${payload}`)
  if (e.ok) {
    message.success("Asignación Conductor/Fecha eliminada")
  } else {
    message.error("Error al eliminar")
  }
}

function* actionWatcher() {
  yield takeLatest(EquipmentVehicleActions.createEquipmentVehicle, createEquipmentVehicle)
  yield takeLatest(EquipmentVehicleActions.updateEquipmentVehicle, updateEquipmentVehicle)
  yield takeLatest(EquipmentVehicleActions.getEquipmentVehicles, getEquipmentVehicles)
  yield takeLatest(EquipmentVehicleActions.searchEquipmentVehicle, searchEquipmentVehicle)
  yield takeLatest(EquipmentVehicleActions.deleteEquipmentVehicle, deleteEquipmentVehicle)
  yield takeLatest(EquipmentVehicleActions.getStates, getStates)
  yield takeLatest(EquipmentVehicleActions.getLocations, getLocations)
  yield takeLatest(EquipmentVehicleActions.getEquipsWithCaseReports, getEquipsWithCaseReports)
  yield takeLatest(EquipmentVehicleActions.searchCaseReport, searchCaseReport)
  yield takeLatest(EquipmentVehicleActions.getEquipAndReportPDF, getEquipAndReportPDF)
  yield takeLatest(EquipmentVehicleActions.getEquipsForTimeline, getEquipsForTimeline)
  yield takeLatest(EquipmentVehicleActions.getEquipsWithTimelines, getEquipsWithTimelines)
  yield takeLatest(EquipmentVehicleActions.getFullDataEquipmentVehicle, getFullDataEquipmentVehicle)
  yield takeLatest(EquipmentVehicleActions.getEquipmentVehiclesByModuleWork, getEquipmentVehiclesByModuleWork)
  yield takeLatest(EquipmentVehicleActions.search, search)
  yield takeLatest(EquipmentVehicleActions.getEquipsWithCaseReportsByModuleWork, getEquipsWithCaseReportsByModuleWork)
  yield takeLatest(EquipmentVehicleActions.getEquipsWithTimelinesByModuleWork, getEquipsWithTimelinesByModuleWork)
  yield takeLatest(EquipmentVehicleActions.getVehicleImages, getVehicleImages)
  yield takeLatest(EquipmentVehicleActions.getVehiclePapers, getVehiclePapers)
  yield takeLatest(EquipmentVehicleActions.createVehicleEquipmentUser, createVehicleEquipmentUser)
  yield takeLatest(EquipmentVehicleActions.getVehicleEquipmentUsers, getVehicleEquipmentUsers)
  yield takeLatest(EquipmentVehicleActions.deleteVehicleEquipmentUser, deleteVehicleEquipmentUser)
}

export default function* EquipmentVehicleSaga() {
  yield all([actionWatcher()])
}