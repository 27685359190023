import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    drivers: false,
};

const DriverSlice = createSlice({
    name: "Driver",
    initialState,
    reducers: {
        getDrivers() { },
        setDrivers(state, { payload }) {
            state.drivers = payload;
        },
    },
});

const DriverActions = DriverSlice.actions;
const DriverReducer = DriverSlice.reducer;

export { DriverActions, DriverReducer };
