import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import blanco from "../../assets/images/blanco.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const PdfComputersEquipment = ({ fullDataEquipment, enterprise }) => {
  function padStr(i) {
    return i < 10 ? "0" + i : "" + i;
  }
  console.log(fullDataEquipment)
  let brand,
    serie,
    modell,
    licensePlate,
    type,
    photo,
    status,
    validity,
    lastMtto,
    novelty,
    image,
    imageSerial;
  if (fullDataEquipment.computersEquipment) {
    if (fullDataEquipment.computersEquipment.brandCPU) {
      brand = fullDataEquipment.computersEquipment.brandCPU;
      serie = fullDataEquipment.computersEquipment.serieCPU;
      modell = fullDataEquipment.computersEquipment.modellCPU;
      type = "CPU";
      licensePlate = fullDataEquipment.computersEquipment.licensePlateCPU;
      photo = fullDataEquipment.computersEquipment.photoCPU;
      status = fullDataEquipment.computersEquipment.statusCPU;
      validity = fullDataEquipment.computersEquipment.validityCPU;
      lastMtto = fullDataEquipment.computersEquipment.lastMttoCPU;
      novelty = fullDataEquipment.computersEquipment.noveltyCPU;
      image = fullDataEquipment.computersEquipment.photoCPU;
      imageSerial = fullDataEquipment.computersEquipment.photoCPUSerial;
    } else if (fullDataEquipment.computersEquipment.brandAllInOne) {
      brand = fullDataEquipment.computersEquipment.brandAllInOne;
      serie = fullDataEquipment.computersEquipment.serieAllInOne;
      modell = fullDataEquipment.computersEquipment.modellAllInOne;
      licensePlate = fullDataEquipment.computersEquipment.licensePlateAllInOne;
      type = "EQUIPO TODO EN UNO INTEGRADO";
      photo = fullDataEquipment.computersEquipment.photoAllInOne;
      status = fullDataEquipment.computersEquipment.statusAllInOne;
      validity = fullDataEquipment.computersEquipment.validityAllInOne;
      lastMtto = fullDataEquipment.computersEquipment.lastMttoAllInOne;
      novelty = fullDataEquipment.computersEquipment.noveltyAllInOne;
      image = fullDataEquipment.computersEquipment.photoAllInOne;
      imageSerial = fullDataEquipment.computersEquipment.photoAllInOneSerial;
    } else if (fullDataEquipment.computersEquipment.brandLaptop) {
      brand = fullDataEquipment.computersEquipment.brandLaptop;
      serie = fullDataEquipment.computersEquipment.serieLaptop;
      modell = fullDataEquipment.computersEquipment.modellLaptop;
      licensePlate = fullDataEquipment.computersEquipment.licensePlateLaptop;
      type = "PORTATIL";
      photo = fullDataEquipment.computersEquipment.photoLaptop;
      status = fullDataEquipment.computersEquipment.statusLaptop;
      validity = fullDataEquipment.computersEquipment.validityLaptop;
      lastMtto = fullDataEquipment.computersEquipment.lastMttoLaptop;
      novelty = fullDataEquipment.computersEquipment.noveltyLaptop;
      image = fullDataEquipment.computersEquipment.photoLaptop;
      imageSerial = fullDataEquipment.computersEquipment.photoLaptopSerial;
    }
  }
  image = image;
  imageSerial = imageSerial;

  let serieMouse;
  if (fullDataEquipment.computersEquipment.serieMouse) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieMouse;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serieMouse = result + str;
  }
  let serieKeyboard;
  if (fullDataEquipment.computersEquipment.serieKeyboard) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieKeyboard;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serieKeyboard = result + str;
  }
  let serieSource;
  if (fullDataEquipment.computersEquipment.serieSource) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieSource;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serieSource = result + str;
  }
  let seriePowerAdapter;
  if (fullDataEquipment.computersEquipment.seriePowerAdapter) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.seriePowerAdapter;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    seriePowerAdapter = result + str;
  }
  if (fullDataEquipment.computersEquipment.serieCPU) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieCPU;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serie = result + str;
  }
  if (fullDataEquipment.computersEquipment.serieGPU) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieGPU;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serie = result + str;
  }
  if (fullDataEquipment.computersEquipment.serieAllInOne) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieAllInOne;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serie = result + str;
  }
  if (fullDataEquipment.computersEquipment.serieLaptop) {
    var result = "";
    var str = fullDataEquipment.computersEquipment.serieLaptop;
    while (str.length > 7) {
      var pos = str.substring(0, 7).lastIndexOf(" ");
      pos = pos <= 0 ? 7 : pos;
      result += str.substring(0, pos) + "\n";
      str = str.substring(pos);
    }
    serie = result + str;
  }

  return (
    <Page
      size="A1"
      style={{
        flexDirection: "column",
        backgroundColor: "#fff",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          height: "3.7%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View
          style={{
            width: "20%",
            height: "100%",
            borderRight: "2px solid #000",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            style={{ width: "60%", height: "60%" }}
            src={enterprise[0].image}
          ></Image>
        </View>
        <View
          style={{
            width: "80%",
            height: "100%",
            backgroundColor: "#525252",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "33px",
              fontWeight: "bolder",
            }}
          >
            Hoja de Inventarios Equipos
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
          backgroundColor: "#525252",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "33px",
            fontWeight: "bolder",
          }}
        >
          DATOS BÁSICOS
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "50%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "25%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Fecha de Visita
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {new Date(fullDataEquipment.createdDate).getDate() +
                "/" +
                (new Date(fullDataEquipment.createdDate).getMonth() + 1) +
                "/" +
                new Date(fullDataEquipment.createdDate).getFullYear()}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "50%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "25%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Nombre Sede
            </Text>
          </View>
          <View
            style={{
              width: "75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {enterprise[0].name} SEDE CENTRAL
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "50%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "25%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Ciudad
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              FLORENCIA
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "50%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "25%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Departamento
            </Text>
          </View>
          <View
            style={{
              width: "75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              CAQUETA
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "50%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "25%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Dirección
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {" "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "50%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "25%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              N° Consecutivo
            </Text>
          </View>
          <View
            style={{
              width: "75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {" "}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          marginTop: "0.7%",
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
          borderTop: "3.2px solid #000",
          backgroundColor: "#525252",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "33px",
            fontWeight: "bolder",
          }}
        >
          DATOS DE QUIEN REALIZA LA VISITA
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Nombres y Apellidos
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {(fullDataEquipment.userCreator.person.name ? fullDataEquipment.userCreator.person.name + " " : '') +
                (fullDataEquipment.userCreator.person.secondName ? fullDataEquipment.userCreator.person.secondName + " " : '') +
                (fullDataEquipment.userCreator.person.lastName ? fullDataEquipment.userCreator.person.lastName + " " : '') +
                (fullDataEquipment.userCreator.person.secondLastName ? fullDataEquipment.userCreator.person.secondLastName + " " : '')}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Correo
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.userCreator.email + " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Cargo
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.userCreator.position + " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Télefono
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.userCreator.person.phone + " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
          backgroundColor: "#525252",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "33px",
            fontWeight: "bolder",
          }}
        >
          DATOS DE QUIEN ATIENDE LA VISITA
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "100%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "23.076923076923077%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Nombre y Apellidos
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "76.92307692307693%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.dependency.dependencyUser.length > 0
                ? fullDataEquipment.dependency.dependencyUser[0].user.person
                  .name +
                " " +
                fullDataEquipment.dependency.dependencyUser[0].user.person
                  .secondName +
                " " +
                fullDataEquipment.dependency.dependencyUser[0].user.person
                  .lastName +
                " " +
                fullDataEquipment.dependency.dependencyUser[0].user.person
                  .secondLastName +
                " "
                : "No tiene Jefe Asignado"}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "100%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "23.076923076923077%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Cargo:
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "76.92307692307693%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.dependency.dependencyUser.length > 0
                ? fullDataEquipment.dependency.dependencyUser[0].user.position +
                " "
                : "No tiene Jefe Asignado"}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Télefono
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.dependency.dependencyUser.length > 0
                ? fullDataEquipment.dependency.dependencyUser[0].user.person
                  .phone + " "
                : "No tiene Jefe Asignado"}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Ubicación:
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.location.name + " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
          backgroundColor: "#525252",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "33px",
            fontWeight: "bolder",
          }}
        >
          DATOS DEL EQUIPO
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Fecha Inicio Garantia
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.startWarranty
                ? new Date(
                  fullDataEquipment.computersEquipment.startWarranty
                ).getDate() +
                "/" +
                (new Date(
                  fullDataEquipment.computersEquipment.startWarranty
                ).getMonth() +
                  1) +
                "/" +
                new Date(
                  fullDataEquipment.computersEquipment.startWarranty
                ).getFullYear()
                : " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Fecha Fin Garantia
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.endWarranty
                ? new Date(
                  fullDataEquipment.computersEquipment.endWarranty
                ).getDate() +
                "/" +
                (new Date(
                  fullDataEquipment.computersEquipment.endWarranty
                ).getMonth() +
                  1) +
                "/" +
                new Date(
                  fullDataEquipment.computersEquipment.endWarranty
                ).getFullYear()
                : " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              IP
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.ipAddress + " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Mac
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.macAddress + " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Proveedor
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.supplier ? fullDataEquipment.computersEquipment.supplier : " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Telefono proveedor
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.supplierPhone ? fullDataEquipment.computersEquipment.supplierPhone : " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Correo electrónico:
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.dependency.email === null
                ? " "
                : fullDataEquipment.dependency.email + " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Forma de Adquisición
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.adquisitionWay ? fullDataEquipment.computersEquipment.adquisitionWay : " "}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Sistema operativo
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "65%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.SO === null
                ? " "
                : fullDataEquipment.computersEquipment.SO + " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Versión
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.version ? fullDataEquipment.computersEquipment.version : " "}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          height: "2.2%",
          borderBottom: "3.2px solid #000",
        }}
      >
        <View style={{ flexDirection: "row", width: "65%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "35%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Nombre versión
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "23.75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.nameVersion === null
                ? " "
                : fullDataEquipment.computersEquipment.nameVersion + " "}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "17.5%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Numero versión
            </Text>
          </View>
          <View
            style={{
              borderRight: "2px solid #000",
              width: "23.75%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.numberVersion === null
                ? " "
                : fullDataEquipment.computersEquipment.numberVersion + " "}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", width: "35%", height: "100%" }}>
          <View
            style={{
              backgroundColor: "#525252",
              borderRight: "2px solid #000",
              width: "50%",
              height: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "white",
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bolder",
                marginLeft: "5%",
              }}
            >
              Compilacion
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {fullDataEquipment.computersEquipment.compilation ? fullDataEquipment.computersEquipment.compilation : " "}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
          backgroundColor: "#525252",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "33px",
            fontWeight: "bolder",
          }}
        >
          HARDWARE
        </Text>
      </View>

      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          backgroundColor: "#525252",
          borderBottom: "2px solid #000",
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Tipo
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "11%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Marca
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "14%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Modelo (Version)
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Serial
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Placa de Inventario
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Registro fotografico
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Registro fotografico Serial
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "7%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Estado
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "8%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Novedad
          </Text>
        </View>
      </View>

      <View
        style={{
          width: "100%",
          height: "4.3%",
          flexDirection: "row",
          borderBottom: "2px solid #000",
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {fullDataEquipment.name + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "11%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {brand + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "14%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {modell + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {serie + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {licensePlate + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Image
            style={{ width: "100%", height: "100%" }}
            src={image ? image : blanco}
          ></Image>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Image
            style={{ width: "100%", height: "100%" }}
            src={imageSerial ? imageSerial : blanco}
          ></Image>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "7%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {status + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "8%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {novelty + " "}
          </Text>
        </View>
      </View>
      {fullDataEquipment.computersEquipment.brandGPU &&
        fullDataEquipment.computersEquipment.brandGPU!== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                MONITOR
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandGPU+ " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellGPU+ " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieGPU+ " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateGPU+ " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoMonitor
                    ? fullDataEquipment.computersEquipment.photoMonitor
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoMonitorSerial
                    ? fullDataEquipment.computersEquipment.photoMonitorSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusGPU+ " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyGPU+ " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandMonitor &&
        fullDataEquipment.computersEquipment.brandMonitor !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                MONITOR
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandMonitor + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellMonitor + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieMonitor + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateMonitor + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoMonitor
                    ? fullDataEquipment.computersEquipment.photoMonitor
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoMonitorSerial
                    ? fullDataEquipment.computersEquipment.photoMonitorSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusMonitor + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyMonitor + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandKeyboard &&
        fullDataEquipment.computersEquipment.brandKeyboard !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                TECLADO
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandKeyboard + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellKeyboard + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {serieKeyboard + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateKeyboard +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoKeyboard
                    ? fullDataEquipment.computersEquipment.photoKeyboard
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoKeyboardSerial
                    ? fullDataEquipment.computersEquipment.photoKeyboardSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusKeyboard + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyKeyboard + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandMouse &&
        fullDataEquipment.computersEquipment.brandMouse !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                MOUSE
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandMouse + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellMouse + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {serieMouse + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateMouse + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoMouse
                    ? fullDataEquipment.computersEquipment.photoMouse
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoMouseSerial
                    ? fullDataEquipment.computersEquipment.photoMouseSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusMouse + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyMouse + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandSignaturePad !== null &&
        fullDataEquipment.computersEquipment.brandSignaturePad !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                PAD DE FIRMAS
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandSignaturePad + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellSignaturePad + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieSignaturePad + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateSignaturePad +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoSignaturePad
                    ? fullDataEquipment.computersEquipment.photoSignaturePad
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoSignaturePadSerial
                    ? fullDataEquipment.computersEquipment
                      .photoSignaturePadSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusSignaturePad + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltySignaturePad + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandWebcam !== null &&
        fullDataEquipment.computersEquipment.brandWebcam !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                WEBCAM
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandWebcam + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellWebcam + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieWebcam + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateWebcam + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoWebcam
                    ? fullDataEquipment.computersEquipment.photoWebcam
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoWebcamSerial
                    ? fullDataEquipment.computersEquipment.photoWebcamSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusWebcam + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyWebcam + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandBardcodeReader !== null &&
        fullDataEquipment.computersEquipment.brandBardcodeReader !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                LECTOR DE CODIGO DE BARRAS
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandBardcodeReader + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellBardcodeReader +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieBardcodeReader + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment
                  .licensePlateBardcodeReader + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoBardcodeReader
                    ? fullDataEquipment.computersEquipment.photoBardcodeReader
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoBardcodeReaderSerial
                    ? fullDataEquipment.computersEquipment
                      .photoBardcodeReaderSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusBardcodeReader +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyBardcodeReader +
                  " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandPowerAdapter !== null &&
        fullDataEquipment.computersEquipment.brandPowerAdapter !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                ADAPTADOR DE CORRIENTE
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandPowerAdapter + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellPowerAdapter + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {seriePowerAdapter + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlatePowerAdapter +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoPowerAdapter
                    ? fullDataEquipment.computersEquipment.photoPowerAdapter
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoPowerAdapterSerial
                    ? fullDataEquipment.computersEquipment
                      .photoPowerAdapterSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusPowerAdapter + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyPowerAdapter + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandFingerprintReader !== null &&
        fullDataEquipment.computersEquipment.brandFingerprintReader !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                LECTOR DE HUELLA
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandFingerprintReader +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellFingerprintReader +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieFingerprintReader +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment
                  .licensePlateFingerprintReader + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoFingerprintReader
                    ? fullDataEquipment.computersEquipment
                      .photoFingerprintReader
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment
                    .photoFingerprintReaderSerial
                    ? fullDataEquipment.computersEquipment
                      .photoFingerprintReaderSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusFingerprintReader +
                  " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyFingerprintReader +
                  " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandDiadems !== null &&
        fullDataEquipment.computersEquipment.brandDiadems !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                DIADEMAS
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandDiadems + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellDiadems + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.serieDiadems + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateDiadems + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoDiadems
                    ? fullDataEquipment.computersEquipment.photoDiadems
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoDiademsSerial
                    ? fullDataEquipment.computersEquipment.photoDiademsSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusDiadems + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltyDiadems + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.brandSource !== null &&
        fullDataEquipment.computersEquipment.brandSource !== "" && (
          <View
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                FUENTE
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.brandSource + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.modellSource + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {serieSource + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.licensePlateSource + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoSource
                    ? fullDataEquipment.computersEquipment.photoSource
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={
                  fullDataEquipment.computersEquipment.photoSourceSerial
                    ? fullDataEquipment.computersEquipment.photoSourceSerial
                    : blanco
                }
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.statusSource + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {fullDataEquipment.computersEquipment.noveltySource + " "}
              </Text>
            </View>
          </View>
        )}
      {fullDataEquipment.computersEquipment.processors !== null &&
        fullDataEquipment.computersEquipment.processors !== "" &&
        fullDataEquipment.computersEquipment.processors.length > 0 &&
        fullDataEquipment.computersEquipment.processors.map((item, index) => (
          <View
            key={index}
            style={{
              width: "100%",
              height: "4.3%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                PROCESADOR
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {item + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={blanco}
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={blanco}
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
              </Text>
            </View>
          </View>
        ))}
      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          backgroundColor: "#525252",
          borderBottom: "2px solid #000",
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Tipo
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "11%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Marca
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "14%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Tipo
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Serie
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            Capacidad
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "7%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "8%",
          }}
        >
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
      </View>
      {fullDataEquipment.computersEquipment.diskComputer !== null &&
        fullDataEquipment.computersEquipment.diskComputer !== "" &&
        fullDataEquipment.computersEquipment.diskComputer.length > 0 &&
        fullDataEquipment.computersEquipment.diskComputer.map((item, index) => {
          let serie;
          if (item.serie) {
            var result = "";
            var str = item.serie;
            while (str.length > 7) {
              var pos = str.substring(0, 7).lastIndexOf(" ");
              pos = pos <= 0 ? 7 : pos;
              result += str.substring(0, pos) + "\n";
              str = str.substring(pos);
            }
            serie = result + str;
          }
          return (
            <View
              key={index}
              style={{
                width: "100%",
                height: "3.7%",
                flexDirection: "row",
                borderBottom: "2px solid #000",
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "10%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  DISCO DURO
                </Text>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "11%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  {item.brand + " "}
                </Text>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "14%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  {item.type + " "}
                </Text>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "10%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  {serie + " "}
                </Text>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "10%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  {item.capacity + " "}
                </Text>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "15%",
                }}
              >
                <Image
                  style={{ width: "100%", height: "100%" }}
                  src={blanco}
                ></Image>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "15%",
                }}
              >
                <Image
                  style={{ width: "100%", height: "100%" }}
                  src={blanco}
                ></Image>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "7%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  {item.status + " "}
                </Text>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "2px solid #000",
                  heigth: "100%",
                  width: "8%",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "22px",
                    fontWeight: "bolder",
                  }}
                >
                  {" "}
                </Text>
              </View>
            </View>
          );
        })}
      {fullDataEquipment.computersEquipment.ramComputer !== null &&
        fullDataEquipment.computersEquipment.ramComputer !== "" &&
        fullDataEquipment.computersEquipment.ramComputer.length > 0 &&
        fullDataEquipment.computersEquipment.ramComputer.map((item, index) => (
          <View
            key={index}
            style={{
              width: "100%",
              height: "3.7%",
              flexDirection: "row",
              borderBottom: "2px solid #000",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                MEMORIA RAM
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "11%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {item.brand + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "14%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {item.type + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {item.serie + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "10%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {item.capacity + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={blanco}
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "15%",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={blanco}
              ></Image>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "7%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {item.status + " "}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRight: "2px solid #000",
                heigth: "100%",
                width: "8%",
              }}
            >
              <Text
                style={{
                  color: "black",
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
              </Text>
            </View>
          </View>
        ))}
      <View
        style={{
          width: "100%",
          height: "1.8%",
          flexDirection: "row",
          borderBottom: "3.2px solid #000",
          backgroundColor: "#525252",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "33px",
            fontWeight: "bolder",
          }}
        >
          SOFTWARE
        </Text>
      </View>
      <View
        style={{
          width: "100%",
          height: "3.7%",
          flexDirection: "row",
          borderBottom: "2px solid #000",
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            SISTEMA OPERATIVO
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "11%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {fullDataEquipment.computersEquipment.SO.split(
              " "
            )[0].toUpperCase() + " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "14%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {fullDataEquipment.computersEquipment.SO +
              " " +
              fullDataEquipment.computersEquipment.version +
              " "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "10%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Image style={{ width: "100%", height: "100%" }} src={blanco}></Image>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "15%",
          }}
        >
          <Image style={{ width: "100%", height: "100%" }} src={blanco}></Image>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "7%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRight: "2px solid #000",
            heigth: "100%",
            width: "8%",
          }}
        >
          <Text
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bolder",
            }}
          >
            {" "}
          </Text>
        </View>
      </View>

      <View
        style={{
          marginTop: "0%",
          width: "100%",
          minHeigth: "50px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          border: "2px solid #000",
          borderTop: 0,
        }}
      >
        <Text
          style={{
            color: "black",
            textAlign: "left",
            fontSize: "22px",
            fontWeight: "bolder",
          }}
        >
          Observaciones:
        </Text>
        <Text
          style={{
            color: "black",
            textAlign: "left",
            fontSize: "22px",
            fontWeight: "bolder",
          }}
        >
          {fullDataEquipment.computersEquipment.observations === null
            ? " "
            : fullDataEquipment.computersEquipment.observations + " "}
        </Text>
      </View>
      <View
        style={{
          marginTop: "1%",
          marginBottom: "-5%",
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Image
          style={{ width: "30%", height: "150%" }}
          src={
            fullDataEquipment.userCreator.person.firm
              ? fullDataEquipment.userCreator.person.firm
              : blanco
          }
        ></Image>
      </View>
      <View
        style={{
          marginTop: "3%",
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={{ width: "30%", borderBottom: "3px solid #00" }}></View>
      </View>
      <View
        style={{
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "-3%",
        }}
      >
        <Text style={{ fontSize: "20px", color: "black" }}>
          {fullDataEquipment.userCreator &&
            fullDataEquipment.userCreator.profesion}
        </Text>
      </View>
      <View
        style={{
          width: "95%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "-2.7%",
        }}
      >
        <Text style={{ fontSize: "18px" }}>
          {fullDataEquipment.userCreator.person &&
            fullDataEquipment.userCreator.person.firm &&
            fullDataEquipment.userCreator.person.name +
            " " +
            fullDataEquipment.userCreator.person.secondName +
            " " +
            fullDataEquipment.userCreator.person.lastName +
            " " +
            fullDataEquipment.userCreator.person.secondLastName}
        </Text>
      </View>

      <View
        style={{
          width: "95%",
          marginTop: "0%",
          height: "3%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Text style={{ fontSize: "18px" }}>AGENTE QUE REALIZA EL REPORTE</Text>
      </View>
    </Page>
  );
};
