// Importa las funciones necesarias desde el SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA1NmEOXZjUHRNgWoiX9OSXdpYa-oke-tE",
  authDomain: "zeusdesk-4c79b.firebaseapp.com",
  databaseURL: "https://zeusdesk-4c79b-default-rtdb.firebaseio.com",
  projectId: "zeusdesk-4c79b",
  storageBucket: "zeusdesk-4c79b.appspot.com",
  messagingSenderId: "604663195566",
  appId: "1:604663195566:web:5b9c2b87c36e444a660a3f",
  measurementId: "G-JZL92RMGY0"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firebase Storage
const storage = getStorage(app);

// Exporta `app` y `storage` para usarlos en otros archivos

export { app, storage };

